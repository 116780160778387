var vdEp = {
	IsMobile: false,
	IsTablet: false,
	IsLGPD: false,
	LandingPage: false,
	CalcConfigs: false,
	DeviceItensLimit: 40,
	DeviceValueLimits: {
		Default: {
			CalculateItensLimit: '10000.00',
			Device: {
				max: '10000.00',
				min: '500.00'
			},
			Accessory: {
				max: '10000.00',
				min: '100.00'
			}
		},
		Cameras: {
			Device: {
				max: '10000.00',
				min: '500.00'
			},
			Accessory: {
				max: '10000.00',
				min: '100.00'
			}
		}
	},
	DeviceDepreciation: {
		notebook: [
			{
				"min": 0,
				"max": 13,
				"depreciation": "0%"
			},
			{
				"min": 13,
				"max": 24,
				"depreciation": "15%"
			},
			{
				"min": 24,
				"max": 36,
				"depreciation": "30%"
			},
			{
				"min": 36,
				"max": 48,
				"depreciation": "50%"
			}
		],
		tablet: [
			{
				"min": 0,
				"max": 13,
				"depreciation": "0%"
			},
			{
				"min": 13,
				"max": 24,
				"depreciation": "15%"
			},
			{
				"min": 24,
				"max": 36,
				"depreciation": "30%"
			},
			{
				"min": 36,
				"max": 48,
				"depreciation": "50%"
			}
		],
		camera: [
			{
				"min": 0,
				"max": 60,
				"depreciation": "0%"
			}
		],
		videocamera: [
			{
				"min": 0,
				"max": 60,
				"depreciation": "0%"
			}
		],
		smartphone: [
			{
				"min": 0,
				"max": 6,
				"depreciation": "0%"
			},
			{
				"min": 6,
				"max": 14,
				"depreciation": "15%"
			},
			{
				"min": 14,
				"max": 30,
				"depreciation": "30%"
			}
		],
		smartwatch: [
			{
				"min": 0,
				"max": 6,
				"depreciation": "0%"
			},
			{
				"min": 6,
				"max": 12,
				"depreciation": "15%"
			}
		]
	},
	DeviceImei: {
		//1 - android
		//2 - apple
		//3 - windows phone
		1: {
				"text" : "Para encontrar o n&uacute;mero de s&eacute;rie no seu celular Android siga o passo a passo:",
				"steps" : [
					"Acesse Configura&ccedil;&otilde;es",
					"Dentro de Configura&ccedil;&atilde;o, entre em Sistema",
					"Em seguida, localize o item Sobre o dispositivo",
					"Depois de entrar nele, clique em Status",
					"Pronto! O n&uacute;mero de s&eacute;rie do seu celular Android vai estar vis&iacute;vel no t&oacute;pico &ldquo;N&uacute;mero de serie&rdquo;."
				],
				"path" : "Configura&ccedil;&otilde;es &gt; Sistema &gt; Sobre o dispositivo &gt; Status",
				"text2" : "Alguns dispositivos Android podem variar e mostrar estas informa&ccedil;&otilde;es em n&iacute;veis diferentes. Portanto, caso voc&ecirc; n&atilde;o tenha encontrado o n&uacute;mero de s&eacute;rie seguindo os passos acima, &eacute; importante procurar por &ldquo;Status&rdquo; em cada n&iacute;vel",
				"text3" : "Outro caminho poss&iacute;vel, bastante comum: Configura&ccedil;&otilde;es &gt; Sobre o telefone &gt; Status"
			},
		2: {
				"text" : "Para encontrar o n&uacute;mero de s&eacute;rie no seu celular Apple siga o passo a passo:",
				"steps" : [
					"Acesse Ajuste",
					"Dentro de Ajuste, entre em Geral",
					"Em Geral, localize o item Sobre",
					"Pronto! O n&uacute;mero de s&eacute;rie do seu celular Apple vai estar visivel no t&oacute;pico &ldquo;N&uacute;mero de s&eacute;rie&rdquo;."
				],
				"path" : "Ajuste > Geral > Sobre"
			},
		3: {
				"text" : "Para encontrar o n&uacute;mero IMEI no seu celular Windows siga o passo a passo:",
				"steps" : [
					"Acesse Configura&ccedil;&otilde;es",
					"Dentro de Configura&ccedil;&otilde;es, entre em Sistema",
					"Em Sistema, localize o item Sobre",
					"Depois de entrar nele, clique em Mais Informa&ccedil;&otilde;es",
					"Pronto! O n&uacute;mero IMEI do seu celular Windows vai estar visivel dentro de &ldquo;N&uacute;mero de s&eacute;rie&rdquo;."
				],
				"path" : "Configura&ccedil;&otilde;es &gt; Sistema &gt; Sobre &gt; Mais Informa&ccedil;&otilde;es"
			}
	},
	CalcStepsPosition: {
		Step1: 0,
		Step2: null,
		Step3: null
	}, 
	Init: function(container) {
		if(typeof container == "undefined") container = "body";
		vdEp.StartScreens();

		if(vdEp.LandingPage)
			vdEp.LandingPageBindings(container);

		if(vdEp.CalcConfigs)
			vdEp.CalcConfigsBindings(container);

		vdEp.FormBindings(container);

		$(window).on("keydown",function(e) {
			if(e.keyCode == 27)
				$(".ep-modal:visible .ep-modalClose").trigger("click");
		});

		$("form.showFeedback").on("submit",function() {
			$(".ep-modal-loading").fadeIn("fast");
		});

		var	parentMonth = $("#mesValidacao:visible").parent(),
			parentYear = $("#anoValidacao:visible").parent(),
			month = $("#mesValidacao:visible"),
			
			year = $("#anoValidacao:visible"),
			
			setErrorMsg = $("#card-error-msg");	

		year.off("change.checkCreditCard").on("change.checkCreditCard", function() {
			var monthVal = $("#mesValidacao:visible").val(),
				yearVal = $(this).val();

			var status = vdEp.ValidateCreditCardDate(monthVal,yearVal);
			vdEp.ShowCreditCardError(status,parentMonth,parentYear,setErrorMsg);

			month.off("change.checkCreditCard").on("change.checkCreditCard", function() {
				var monthVal = $(this).val(),
					yearVal = $("#anoValidacao:visible").val();

				var status = vdEp.ValidateCreditCardDate(monthVal,yearVal);
				vdEp.ShowCreditCardError(status,parentMonth,parentYear,setErrorMsg);
			});
		});
	},
	ValidateCreditCardDate: function(settedMonth,settedYear) {
		var rtn = true,
			dt = new Date(),
			currentMonth = dt.getMonth(),
			currentYear = dt.getFullYear();

		if(
			(settedMonth < currentMonth && settedYear == currentYear) ||
			(settedYear < currentYear)
		)
			rtn = false;

		return rtn;
	},
	ShowCreditCardError: function(status,monthField,yearField,message) {
		if(!status) {
			monthField.addClass("ep-error");
			yearField.addClass("ep-error");
			message.html("M&ecirc;s e ano inv&aacute;lidos. Por favor verifique os dados informados.").slideDown();
		} else {
			monthField.removeClass("ep-error");
			yearField.removeClass("ep-error");
			message.slideUp();
		}
	},
	FormBindings: function(container) {
		if(typeof container == "undefined") container = "body";
		
		var maskDefaults = {
			watchInterval: 10,
			clearIfNotMatch: true,
			dataMask: false,
			translation:  {
			 	'a': {pattern: /[a-zA-Z]/},
			 	'*': {pattern: /[a-zA-Z0-9]/},
			 	'?': {pattern: /\d/, optional: true},
            	'9': {pattern: /\d/, optional: false},
			}
		};

		$(".ep-form-control",container).on("blur",function() {
			var t = $(this),
				v = this.value;

			v = $.trim(v);
			$(this).val(v);
		});

		$(".ep-dateMask",container).unmask().mask("99/99/9999",maskDefaults);
		$(".ep-cepMask",container).unmask().mask("99999-999",maskDefaults);
		$(".ep-phoneNumber",container).unmask().mask("(99) 9.9999.999?",maskDefaults).on("blur",function() {
			var v = this.value;
			v = v.replace(/\(/g,"").replace(/\)/g,"").replace(/ /g,"").replace(/\./g,"");

			if(v != "") {
				if(
					v.indexOf("0000000") > -1 ||
					v.indexOf("1111111") > -1 ||
					v.indexOf("2222222") > -1 ||
					v.indexOf("3333333") > -1 ||
					v.indexOf("4444444") > -1 ||
					v.indexOf("5555555") > -1 ||
					v.indexOf("6666666") > -1 ||
					v.indexOf("7777777") > -1 ||
					v.indexOf("8888888") > -1 ||
					v.indexOf("9999999") > -1
				) {
					$(this).addClass("ep-error");
				} else {
					$(this).removeClass("ep-error");
				}
			}
		});
		$(".ep-cardMask",container).unmask().mask("9999 9999 9999 9?999",maskDefaults).on("blur",function() {
			var t = $(this),
				v = this.value,
				icons = t.next(".ep-creditCard-icons"),
				typeField = t.data("creditcardtype"),
				parcelamento = t.data("parcelamento");


			if(typeof parcelamento != "undefined") parcelamento = parcelamento.split(";");

			var type = vdEp.CheckCreditCard(v);
			$(typeField).val(type);

			t.removeClass("ep-error");
			icons.addClass("ep-creditCard-icons-setted").find("span").removeClass("ep-creditCard-icons-setted");
			$(parcelamento[0]).slideDown("fast");
			$(parcelamento[1]).hide();

			vdEpSkin.Checkout4CreditPayment();
			
			switch(type) {
				case 1:
					icons.find(".ep-ico-creditCardVisa").addClass("ep-creditCard-icons-setted");
					break;
				case 2:
					icons.find(".ep-ico-creditCardMaster").addClass("ep-creditCard-icons-setted");
					break;
				case 3:
					icons.find(".ep-ico-creditCardDiners").addClass("ep-creditCard-icons-setted");
					break;
				case 4:
					icons.find(".ep-ico-creditCardPorto").addClass("ep-creditCard-icons-setted");
					$(parcelamento[0]).hide();
					$(parcelamento[1]).slideDown("fast");
					vdEpSkin.Checkout4CreditPortoPayment();
					break;
				case 5:
					icons.find(".ep-ico-creditCardElo").addClass("ep-creditCard-icons-setted");
					break;
				case -1:
					icons.removeClass("ep-creditCard-icons-setted").find("span").removeClass("ep-creditCard-icons-setted");
					break;
				default:
					t.addClass("ep-error");
					icons.removeClass("vdo-frm-creditCard-icons-setted").find("span").removeClass("ep-creditCard-icons-setted");
					break;
			}

			v = v.split(" ");
			for(var i=0,l=v.length;i<l;i++) {
				if(type != 4) {
					$(".ep-cc-default-"+[i]).val(v[i]);
					$(".ep-cc-porto-"+[i]).val("");
					$(".ep-cc-"+[i]).val(v[i]);
				} else {
					$(".ep-cc-porto-"+[i]).val(v[i]);
					$(".ep-cc-default-"+[i]).val("");
					$(".ep-cc-"+[i]).val(v[i]);
				}
			}
		});

		$(".ep-doBuscaCEP",container).on("submit",vdEp.DoAddressFind);
		$(".ep-doBuscaCorretor",container).on("submit",vdEp.DoAgentFind);

		$(".ep-jumpNext",container).on("keyup",function(e) {
			if($.inArray(e.keyCode, [37, 39, 46, 16, 9, 8]) == -1) {
				var max = $(this).attr("maxlength"),
					l = $(this).val(),
					next = $(this).data("jumpnext");

				max = parseInt(max);
				l = l.length;

				if(l == max) {
					if(typeof next == "undefined")
						$(this).next().focus();
					else
						$(next).focus();
				}
			}
		});

		$(".ep-showModal",container).off("click").on("click",function() {
			var t = $(this),
				callback = t.data("modalcallback"),
				target = t.data("modalref"),
				src = t.data("modalsrc");

			if(typeof target != "undefined") {
				vdEp.Modal(target,callback,src);
			}
		});

		$(".ep-modalClose",container).off("click").on("click",function() {
			var t = $(this).parent();
			
			if(vdEp.IsMobile)
				t.find(".ep-modal-content").animate({
					right: "-100%"
				},300,function() {
					t.removeClass("ep-visible");
				});
			else
				t.removeClass("ep-visible");

			if(t.find("iframe").length > 0)
				t.find("iframe").attr("src","about:blank");

			$("body").css("overflow","visible");
			setTimeout(function() {
				t.hide();	
			},400);
		});

		$(".ep-exec-valueAdd, .ep-exec-valueRemove",container).on("click.fb",function() {
			var cmd = $(this).data("valueaddremove"),
				ipt, max = null, v;
			cmd = cmd.split(";");
			ipt = $(cmd[0]);
			v = parseInt(ipt.val());
			if(cmd[1])
				max = cmd[1];

			if(max != null && v == max) {
				ipt.addClass("ep-error");
			} else {
				ipt.removeClass("ep-error");
				if($(this).is(".ep-exec-valueAdd"))
					ipt.val(v+1).trigger("change");
				else {
					ipt.val(v-1).trigger("change");
				}
			}
		});

		$("a.ep-accordion-header",container).on("click.fb",function() {
			var t = $(this),
				accordion = t.parent(),
				ctt = accordion.find(".ep-accordion-container");

			if(ctt.is(":visible")) {
				ctt.slideUp("fast",function() {
					accordion.addClass("ep-accordion-closed");
				});
			} else {
				accordion.removeClass("ep-accordion-closed");

				if(typeof t.data("accordionscroll") == "undefined") {
					$(".ep-form-accordion").addClass("ep-accordion-closed");
					$(".ep-accordion-container").slideUp("fast");
				}
				
				ctt.slideDown("fast",function() {
					if(typeof t.data("accordionscroll") == "undefined") {
						var p = t.offset();
						$("html,body").animate({
							scrollTop: (p.top-(vdEp.IsMobile ? 65 : 135))
						},200);
					}
				});
			}
		});

		$(".ep-setValue",container).on("click.fb blur.fb change.fb",function(e) {
			var t = $(this);
			setTimeout(function() {
				var v = t.val(),
					cmd = t.data("setvalue");
				
				cmd = cmd.split(";");
				for(var i=0,l=cmd.length;i<l;i++) {
					cmd[i] = cmd[i].split("==");
				}
				
				if(
					(t.is("input:text,input[type='tel'],input[type='number'],input[type='email']") && e.type == "blur") ||
					(t.is("select") && e.type == "change") || 
					(t.is("input:radio, input:checkbox") && e.type == "click") || 
					(t.is("a") && e.type == "click")
				) {
					for(var i=0,l=cmd.length;i<l;i++) {
						if(cmd[i][1] == "{value}") {
							if(t.is(".ep-currency")) v = v.replace("R$ ","").replace(".","").replace(",",".");
							else if(t.is(".ep-cpfMask")) v = v.replace(/\./g,"").replace(/-/g,"");
							else if(t.is(".ep-cnpjMask")) v = v.replace(/\./g,"").replace(/\//g,"").replace(/-/g,"");
							cmd[i][1] = v;
						}
						if($(cmd[i][0]).is(":radio")) {
							$(cmd[i][0]).each(function() {
								if($(this).val() == cmd[i][1])
									$(this).trigger("click");
							});
						} else {
							$(cmd[i][0]).val(cmd[i][1]).trigger("change");
						}

					}
				} 
			},100);
		});

		$(".ep-conditionalSetValue",container).on("change.fb",function(e) {
			var t = $(this),
				v = t.val(),
				cmd = t.data("conditionalsetvalue");

			cmd = cmd.split(";");
			for(var i=0,l=cmd.length;i<l;i++) {
				cmd[i] = cmd[i].split("::");

				cmd[i][1] = cmd[i][1].split("==");

				if(cmd[i][0].indexOf("!") > -1) {
					var bv = cmd[i][0].replace("!","");
					if(bv != v)
						$(cmd[i][1][0]).val(cmd[i][1][1]);
				} else {
					if(cmd[i][0] == v)
						$(cmd[i][1][0]).val(cmd[i][1][1]);
				}
			}
		});

		$("select.ep-getOptionText",container).on("change.fb",function() {
			var t = $(this),
				v = t.val() != "" ? t.find("option:selected").text() : "",
				d = t.data("getoptiontext");

			$(d).val(v);
		});

		$(".ep-setEnabledDisabled",container).on("click.fb",function(e) {
			var t = $(this),
				v = t.val(),
				cmd = t.data("setenableddisabled");
			
			cmd = cmd.split(";");
			for(var i=0,l=cmd.length;i<l;i++) {
				cmd[i] = cmd[i].split("==");
			}

			for(var i=0,l=cmd.length;i<l;i++) {
				$(cmd[i][0]).prop("disabled",(cmd[i][1] === "true"));
				if($(cmd[i][0]).is("select")) {
					if(cmd[i][1] === "true")
						$(cmd[i][0]).parent().addClass("ep-disabled");
					else
						$(cmd[i][0]).parent().removeClass("ep-disabled");
				}
			} 
		});

		$(".ep-cleanupText",container).on("blur.fb",function() {
			var v = this.value,
				allowNumbers = $(this).data("cleanuptext") == "allownumbers" ? true : false;

			v = v.toUpperCase();
			v = vdEp.CleanupString(v,allowNumbers);

			$(this).val(v);
		});

		$(".ep-fillOptionText").on("change",function() {
			var v = $("option:selected",this).text(),
				to = $(this).data("filloptiontext");

			$(to).val(v);
		});

		$(".ep-onlyNumbers",container).on("keydown.fb",function(e) {
			if($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
				(e.keyCode == 65 && e.ctrlKey === true) || 
				(e.keyCode == 86 && e.ctrlKey === true) || 
				(e.keyCode == 82 && e.ctrlKey === true) || 
				(e.keyCode >= 35 && e.keyCode <= 39) ||
				(!e.shiftKey && ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)))
				) {
					return;
			} else {
				e.preventDefault();
			}
		});

		$(".ep-cpfMask",container).unmask().mask("999.999.999-99",maskDefaults).on("blur.fb",function() {
			cpf = this.value.replace(/[^\d]+/g,'');
			if(cpf != "") {
				var status = vdEp.ValidateCPF(this.value);
				vdEp.ShowError(this,status);
			}
		});

		$(".ep-cnpjMask",container).unmask().mask("99.999.999/9999-99",maskDefaults).on("blur",function() {
			cnpj = this.value.replace(/[^\d]+/g,'');
			if(cnpj != "") {
				var status = vdEp.ValidateCNPJ(this.value);
				vdEp.ShowError(this,status);
			}
		});

		$(".ep-emailMask",container).on("blur.fb",function() {
			if(this.value != "") {
				var status = vdEp.ValidateMail(this.value);
				vdEp.ShowError(this,status);
			}
		});

		$(".ep-split",container).on("change.fb",function() {
			var params = $(this).data("split"),
				v = this.value;
			if(typeof params == "string") {
				params = params.split(";");
				v = v.replace(/\./g,"").replace(/\(/g,"");
				v = v.split(params[0]);

				for(var i=1,l=params.length;i<l;i++) {
					$(params[i]).val(v[i-1]).trigger("change");
				}
			} 
		});

		$(".ep-callback",container).on("change.fb click.fb",function(e) {
			if(
				($(e.target).is("select") && e.type == "change") || 
				(!$(e.target).is("select") && e.type == "click")
			) {
				var exec = $(this).data("callback");
				eval(exec);
			}
		});

		$(".ep-callback-keyup",container).on("keyup.fb",function(e) {
			var exec = $(this).data("callback");
			if(
				$.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 38, 37, 39, 40]) !== -1 || 
				(e.keyCode == 65 && e.ctrlKey === true) || 
				(e.keyCode == 86 && e.ctrlKey === true) || 
				(e.keyCode == 82 && e.ctrlKey === true)
			)
				return;
			else
				eval(exec);
		});

		$(".ep-callback-blur",container).on("blur.fb",function(e) {
			var exec = $(this).data("callback");
			if(
				$.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 38, 37, 39, 40]) !== -1 || 
				(e.keyCode == 65 && e.ctrlKey === true) || 
				(e.keyCode == 86 && e.ctrlKey === true) || 
				(e.keyCode == 82 && e.ctrlKey === true)
			)
				return;
			else
				eval(exec);
		});

		$(".ep-trigger",container).on("click.fb",function() {
			var cmd = $(this).data("trigger");
			cmd = cmd.split(";");

			if(cmd.length == 1)
				cmd[1] = "click";

			$(cmd[0]).trigger(cmd[1]);
		});

		$(".ep-currency",container).on("keyup.fb",function() {
			var t = $(this).get(0),
				v = this.value,
				currency = "R$ ";

			v = v.replace(currency,"").replace(".","").replace(",","").replace(/\D/g,"");

			if(t.value == currency || t.value == "") {
				t.value = "";
				return;
			}

			var result = new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
				parseFloat(v) / 100
			);

			t.value = currency+result;

			
		});

		$(".ep-setCurrencyLimit",container).on("blur.fb",function() {
			var t = $(this)
				v = t.val(),
				limit = t.data("currencylimit"),
				s = true;

			limit = limit.split(";");
			for(var i=0,l=limit.length;i<l;i++)
				limit[i] = parseFloat(limit[i]);

			v = parseFloat(v.replace("R$ ","").replace(".","").replace(",","."));

			if(v > limit[0] || (limit.length > 1 && v < limit[1]))
				s = false;

			vdEp.ShowError(t,s);
		});

		$(".ep-calculateItensLimit",container).on("change.fb",function() {
			vdEp.calculateItensLimit();
		});

		$(".ep-valDate",container).on("blur.fb",function() {
			var t = $(this),
				v = t.val(),
				cmd = t.data("validdate");

			cmd = cmd.split(";");

			var	minDate = cmd[1].indexOf("/") == -1 ? parseInt(cmd[1]) : cmd[1].split("/"),
				vDate, vMinDate = new Date(), today = new Date();
				s = true;

			if(v == "") {
				vdEp.ShowError(t,s);
				return;
			}

			v = v.split("/");

			vDate = new Date(v[2],(parseInt(v[1])-1),v[0]);

			if(typeof minDate === "object")
				vMinDate = new Date(parseInt(minDate[2]),(parseInt(minDate[1])-1),parseInt(minDate[0]));
			else 
				vMinDate.setMonth(vMinDate.getMonth() - minDate);

			// data digitada inválida
			if(
				(parseInt(v[0]) != parseInt(vDate.getDate()) || parseInt(v[1]) != (parseInt(vDate.getMonth())+1)) || // data inválida
				(cmd[0] == "after" && (vMinDate > vDate || today < vDate)) || // intervalo máximo meses dentro do parametro
				(cmd[0] == "before" && (vMinDate < vDate)) // intervalo mínimo meses dentro do parametro
			) {
				s = false;
			}
			vdEp.ShowError(t,s);
		});

		$(".ep-depreciation",container).on("keyup.fb",function() {
			var t = $(this),
				v = t.val(),
				cmd = t.data("depreciation");

			cmd = cmd.split(";");
			v = v.replace(/_/g,"");

			var deviceType = cmd[0],
				errorMessage = $(cmd[1]),
				depreciateMessage = $(cmd[2]);

			if(v.length < 10) {
				depreciateMessage.hide();
				errorMessage.hide();
				return;
			}

			function checkIfValidDate(dateArr,dateObj) {
				var rtn = true;

				if(
					parseInt(dateArr[0]) != parseInt(dateObj.getDate()) ||
					parseInt(dateArr[1]) != (parseInt(dateObj.getMonth())+1)
				) rtn = false;

				return rtn;
			}

			function monthDiff(d1,d2) {
				var diff = d2 - d1,
					num_years = diff/31536000000,
					num_months = (diff % 31536000000)/2628000000,
					num_days = ((diff % 31536000000) % 2628000000)/86400000;

				num_years = Math.floor(num_years);
				num_months = Math.floor(num_months);
				num_days = Math.ceil(num_days);

				if(num_days >= 30) {
					num_days = 0;
					num_months++;
				}

				var rtn = {
					"months": (num_years * 12)+num_months,
					"days": num_days
				}

				return rtn;
			}

			v = v.split("/");

			var vDate = new Date(v[2],(parseInt(v[1])-1),v[0]),
				today = new Date(),
				depreciationDefs = vdEp.DeviceDepreciation[deviceType],
				dateDiff = monthDiff(vDate,today),
				showMessage = false,
				monthsCalcLimit = 0;

			if(!checkIfValidDate(v,vDate) ||  dateDiff.months < 0 || dateDiff.days < 0) { // Verificando se data é válida
				showMessage = "error";
			} else {
				for(var i=0,l=depreciationDefs.length;i<l;i++) {
					var min = depreciationDefs[i].min,
						max = depreciationDefs[i].max,
						val = depreciationDefs[i].depreciation;

					if(max > monthsCalcLimit) // Incrementando var de limite de meses
						monthsCalcLimit = max;

					if(val == "0%") continue; // Não exibindo mensagem para depreciação 0%

					if(
						(dateDiff.months > min || (dateDiff.months == min && dateDiff.days > 0)) &&
						(dateDiff.months < max || (dateDiff.months == max && dateDiff.days <= 1))
					) { // Checando se diferença excede o limite
						showMessage = "depreciate";
						depreciateMessage.find("span.ep-dd-months").html(min);
						depreciateMessage.find("span.ep-dd-days span").html(dateDiff.days+" "+(dateDiff.days == 1 ? "dia" : "dias"));

						if(dateDiff.days > 0) 
							depreciateMessage.find("span.ep-dd-days").show();
						else
							depreciateMessage.find("span.ep-dd-days").hide();

						depreciateMessage.find("strong").html(val);
					}
				}

				if(dateDiff.months > monthsCalcLimit || (dateDiff.months == monthsCalcLimit && dateDiff.days > 1)) // Checando se diferença excede o limite
					showMessage = "error";
			}

			t.removeClass("ep-error");

			if(showMessage !== false) {
				depreciateMessage.hide();
				errorMessage.hide();

				if(showMessage == "error") {
					t.addClass("ep-error");
					errorMessage.fadeIn("fast");
				}

				if(showMessage == "depreciate")
					depreciateMessage.fadeIn("fast");

			} else {
				depreciateMessage.hide();
				errorMessage.hide();
			}
		});

		$(".ep-valAge",container).on("blur.fb",function() {
			var t = $(this),
				v = t.val(),
				s = true;

			if(v != "") {
				var d = vdEp.AgeByDate(v);
				if(d < 18)
					s = false;
			}

			vdEp.ShowError(t,s);
		});

		$("select.ep-setFieldsToValid",container).on("change.fb",function() {
			var t = $(this),
				v = t.val(),
				fields = t.data("setfieldsvalid");

			fields = fields.split(";");

			for(var i=0,l=fields.length;i<l;i++) {
				var buf = fields[i].split("=="),
					dif = fields[i].split("!="),
					field = buf.length > 1 ? buf[1] : (dif.length > 1 ? dif[1] : buf[0]);

				if(v == "" || v == "0") {
					$(field).removeClass("ep-valid");
				} else {
					if(buf.length > 1) {
						if(v == buf[0])
							$(field).addClass("ep-valid");
						else
							$(field).removeClass("ep-valid");
					} else if(dif.length > 1) {
						if(v != dif[0])
							$(field).addClass("ep-valid");
						else
							$(field).removeClass("ep-valid");
					} else {
						$(field).addClass("ep-valid");	
					}
					
				}
					
			}
		});

		$("input.ep-setFieldsToValid",container).on("click.fb",function() {
			var t = $(this),
				fields = t.data("setfieldsvalid");

			fields = fields.split(";");

			$(fields[0]).addClass("ep-valid");
			$(fields[1]).removeClass("ep-valid");
		});

		$("select.ep-showHide",container).on("change.fb",function() {
			var t = $(this),
				v = t.val(),
				obj = t.data("showhide"),
				multi = t.data("showhidemultiple") !== "undefined" ? t.data("showhidemultiple") : false;

			obj = obj.split(";");
			if(multi) {
				for(var i=0,l=obj.length;i<l;i++) {
					var c = obj[i].split("==");
					if(v == c[0]) {
						$(c[1]).slideDown("fast");
					} else {
						$(c[1]).hide();
					}
				}
			} else {
				if(obj[0].indexOf("==") > 0) {
					obj[0] = obj[0].split("==");
					if(v != obj[0][0]) {
						$(obj[0][1]).slideUp("fast");
						if(obj.length > 1) $(obj[1]).slideDown("fast");
					} else {
						$(obj[0][1]).slideDown("fast");
						if(obj.length > 1) $(obj[1]).hide();
					}
				} else if(obj[0].indexOf("!=") > 0) {
					obj[0] = obj[0].split("!=");
					if(v == obj[0][0]) {
						$(obj[0][1]).slideUp("fast");
						if(obj.length > 1) $(obj[1]).slideDown("fast");
					} else {
						$(obj[0][1]).slideDown("fast");
						if(obj.length > 1) $(obj[1]).hide();
					}
				} else {
					if(v == "") 
						$(obj[0]).slideUp("fast");
					else {
						$(obj[0]).slideDown("fast");

						if(obj.length == 4) {
							if(v == obj[1]) {
								$(obj[3]).hide();
								$(obj[2]).fadeIn("fast");
							} else {
								$(obj[2]).hide();
								$(obj[3]).fadeIn("fast");
							}
						}
					}
				}
			}
		});

		$(".ep-show",container).on("click",function() {
			var t = $(this),
				r = t.data("show");

			if(typeof r != "undefined" && r != "")
				$(r).fadeIn("fast");
		});

		$(".ep-hide",container).on("click",function() {
			var d = $(this).data("hide");

			if(typeof d != "undefined" && d != "")
				$(d).fadeOut("fast");
		});

		$(".ep-duplicateBlock",container).on("click.fb",function() {
			var t = $(this),
				currentIdx = parseInt(t.data("duplicatecurrent")),
				cmd = t.data("duplicateblock"),
				matrix, duplicateTo, limit, bindBlock, currentItens;

			cmd = cmd.split(";");
			matrix = $(cmd[0]);
			duplicateTo = $(cmd[1]);
			limit = parseInt(cmd[2]);

			var ctt = matrix.clone().html();
			ctt = ctt.replace(/\{i\}/g,currentIdx);
			
			duplicateTo.append(ctt);
			bindBlock = duplicateTo.find(".ep-duplicatedItem:last").attr("id");
			vdEp.FormBindings("#"+bindBlock);

			currentItens = duplicateTo.find(".ep-duplicatedItem").length;
			if(currentItens < limit)
				t.data("duplicatecurrent",currentIdx+1);
			else
				t.hide();
		});

		$(".ep-duplicateEraser",container).on("click.fb",function() {
			var cmd = $(this).data("duplicateerase");
			cmd = cmd.split(";");

			$(cmd[1]).slideUp("fast",function() {
				$(this).find("select,input").val("").removeClass("ep-valid").removeClass("ep-error");
			});
			$(cmd[0]).fadeIn("fast");
		});

		$(".ep-nextAccordion",container).on("click.fb",function() {
			var t = $(this),
				next = t.data("nextaccordion"),
				context = t.parent().parent().parent().parent();

			if(vdEp.ValidateForm(context)) {
				context.slideUp("fast",function() {
					$(next).trigger("click");
				});
			} else {
				var p = $(".ep-error:eq(0)").offset();
				$("html,body").animate({
					scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
				},200);
			}
		});

		$("select.ep-fillDeviceInfoMenu",container).on("change.fb",function() {
			var t = $(this),
				v = t.val(),
				txt = t.find("option:selected").text(),
				cmd = t.data("filldeviceinfomenu");
			cmd = cmd.split(";");

			if(v != "") {
				$(".ep-"+cmd[0]+"-info").show();
				$(".ep-"+cmd[0]+"-brand").html(txt);

				if(cmd.length == 2) {
					var os = $(cmd[1]).data("text") != "" ? " | " + $(cmd[1]).data("text") : "";
					$(".ep-"+cmd[0]+"-os").html(os);
				}
			} else {
				$(".ep-"+cmd[0]+"-info").hide();
			}
		});

		$(".ep-deviceList-showMore",container).on("click.fb",function() {
			var ref = $(this).data("ref");
			ref = ref.split(";");

			$(ref[0]).slideDown("fast",function() {
				$(this).find(".ep-slider").each(vdEp.Slider);
			}).find(".ep-deviceList-hideMore").data("ref",ref[0]+";"+ref[1]);
			$(ref[1]).removeClass("ep-showDeviceList").find(".ep-step").addClass("ep-showList");
			$("html,body").animate({
				scrollTop: 0
			},500);		
		});

		$(".ep-deviceList-hideMore",container).on("click.fb",function() {
			var ref = $(this).data("ref");
			ref = ref.split(";");

			$(ref[0]).slideUp("fast");
			$(ref[1]).addClass("ep-showDeviceList").find(".ep-step").removeClass("ep-showList");
		});

		$(".ep-showHideBlock",container).on("click.fb",function() {
			var t = $(this),
				block = t.data("showhideblock");

			block = block.split(";");

			$(block[0]).hide();
			$(block[1]).fadeIn("fast");

			if(block.length == 3) {
				$(block[2]).removeClass("ep-selected");
				t.addClass("ep-selected");
			}
		});

		$(".ep-setCreditCardType",container).on("blur",function() {
			var d = $(this).data("setcreditcard"),
				v = this.value;
			v = v.replace(/_/g,"").replace(/ /g,"");

			if(v.length > 0) {
				if($(d).val() != "1") {
					if(v.search(/^4[0-9]{15}/) > -1) {
						$(this).prev("label").addClass("ep-ipt-cardVisa").removeClass("ep-ipt-cardMaster").removeClass("ep-error");
						$(this).removeClass("ep-error");
						$(d).val("3");
					} else if(v.search(/^5[0-9]{15}/) > -1) { 
						$(this).prev("label").removeClass("ep-ipt-cardVisa").addClass("ep-ipt-cardMaster").removeClass("ep-error");
						$(this).removeClass("ep-error");
						$(d).val("2");
					} else {
						$(this).prev("label").removeClass("ep-ipt-cardVisa").removeClass("ep-ipt-cardMaster").addClass("ep-error");
						$(this).addClass("ep-error");
					}
				} else {
					if(v.indexOf("412177") == 0 || // Visa Classic
						v.indexOf("415274") == 0 || // Visa Gold
						v.indexOf("415275") == 0 || // Visa Platinum
						v.indexOf("484635") == 0 || // Visa Básico
						v.indexOf("446690") == 0// Visa Infinite
					) {
						$(this).prev("label").addClass("ep-ipt-cardVisa").removeClass("ep-ipt-cardMaster").removeClass("ep-error");
						$(this).removeClass("ep-error");
					} else if(v.indexOf("553659") == 0 || // Master Classic
						v.indexOf("536380") == 0 || // Master Gold
						v.indexOf("532930") == 0 || // Master Platinum
						v.indexOf("536537") == 0 // Master Black
					) { 
						$(this).prev("label").removeClass("ep-ipt-cardVisa").addClass("ep-ipt-cardMaster").removeClass("ep-error");
						$(this).removeClass("ep-error");
					} else {
						$(this).prev("label").removeClass("ep-ipt-cardVisa").removeClass("ep-ipt-cardMaster").addClass("ep-error");
						$(this).addClass("ep-error");
					}
				}

			} 
		});

		$("form.ep-ajaxForm",container).on("submit",function(e) {
			e.preventDefault();

			var callback = $(this).data("callback"),
				action = $(this).attr("action"),
				method = typeof $(this).attr("method") != "undefined" ? $(this).attr("method") : "post",
				param = $(this).serialize();

			if(vdEp.ValidateForm(this)) {
				$.ajax({
					url: action,
					type: method,
					data: param,
					dataType: "json",
					beforeSend: function () {
						$(".ep-modal-loading").show();
						console.log("Entrou aqui");
					}
				}).done(function(data) {
					$(".ep-modal-loading").hide();
					if(typeof callback != "")
						eval(callback);
				});
			}
		});

		$(".ep-remove-accordion-item",container).on("click",function() {
			var modal = $(this).data("modalref"),
				btn = $(modal).find(".ep-doEraseAccordionItem"),
				p = $(this).parent().attr("id");

			btn.data("accordion",p);
		});

		$(".ep-doEraseAccordionItem",container).on("click",function() {
			var d = $(this).data("accordion"),
				accordionContainer = $(this).data("accordioncontainer");

			vdEp.RemoveAccordionDevice(d,accordionContainer);
			$(".ep-deviceList-add").show();
			$(".ep-modalClose").trigger("click");
			
		});

		$(".ep-accordion-nextStep",container).on("click",function() {
			if(!vdEp.calculateItensLimit())
				return false;

			if(!vdEp.ValidateForm("#ep-deviceList-form",false)) {
				$(".ep-form-accordion").each(function() {
					var hasError = $(this).find(".ep-error");

					if(hasError.length > 0) {
						$(this).removeClass("ep-accordion-closed");
						$(this).find(".ep-accordion-container").show();
					} 
				});

				var p = $(".ep-error:eq(0)").offset();
				$("html,body").animate({
					scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
				},300);
			} else {
				var	step2Body = $("#ep-calc-step2-lgpd");
				step2Body.show();

				var p = step2Body.offset();
				vdEp.CalcStepsPosition.Step2 = (p.top-(vdEp.IsMobile ? 30 : 100));

				window.dataLayer.push({
					'event': 'step_change_home_ep',
					'step': 'DadosProprietario'
				});

				$("html,body").animate({
					scrollTop: vdEp.CalcStepsPosition.Step2
				},500);
			}

		});

		$(".ep-device-add",container).on("click",function() {
			var ctt = $("#ep-deviceList-form"),
				type = $(this).data("type"),
				idx = ctt.find(".ep-device-accordion").length;
				total = idx+1,
				currentIdx = ctt.find(".ep-device-accordion[data-type='"+type+"']").length+1,
				before = null;

			if(total == 2)
				$(".ep-remove-accordion-item").show().parent().removeClass("ep-device-accordion-single");
			else if(total == vdEp.DeviceItensLimit) {
				$(".ep-deviceList-add").hide();
			}
			
			var inc = vdEp.BuildDeviceAccordionItem(type,currentIdx,currentIdx,idx,total);

			ctt.append(inc);
			setTimeout(function() {
				vdEp.FormBindings("#ep-accordion-device"+idx);	
				
				if(total != 1)
					$("#ep-device"+idx+"-header").trigger("click");	
				/*
				$(".ep-form-accordion").addClass("ep-accordion-closed");
				$(".ep-accordion-container").slideUp(700,function() {
					$("#ep-accordion-device"+idx+" .ep-accordion-container").show();
					$("#ep-accordion-device"+idx).removeClass("ep-accordion-closed");

					var p = $("#ep-accordion-device"+idx).offset();
					$("#ep-accordion-device"+idx).hide();

					$("html,body").animate({
						scrollTop: (p.top-(vdEp.IsMobile ? 30 : 170))
					},700,function() {
						$("#ep-accordion-device"+idx).fadeIn(500);
					});
				});
				*/
			},200);

			vdEp.UpdateAccordionDeviceList(ctt);
		});

		$("input.ep-helpDesk",container).on("click",function() {
			var step3Body = $("#ep-calc-step3-form");
				
			setTimeout(function() {
				step3Body.show();
				var p = step3Body.offset();

				vdEp.CalcStepsPosition.Step3 = p.top-(vdEp.IsMobile ? 30 : 100);

				$("html,body").animate({
					scrollTop: vdEp.CalcStepsPosition.Step3
				},500);
			},300);
		});

		$(".ep-duplicateFormData",container).on("click",function() {
			var t = $(this),
				type = t.data("duplicatetype"),
				os = $(".ep-field-"+type+"-os"),
				os_val = $(".ep-field-"+type+"-os:checked").eq(0).val(),
				marca = $(".ep-field-"+type+"-marca"),
				marca_val = marca.eq(0).val(),
				modelo = $(".ep-field-"+type+"-modelo"),
				modelo_val = modelo.eq(0).val(),
				NF_data = $(".ep-field-"+type+"-nfdata"),
				NF_data_val = NF_data.eq(0).val(),
				NF_valor = $(".ep-field-"+type+"-nfValor"),
				NF_valor_val = NF_valor.eq(0).val(),
				utilizador = $(".ep-field-"+type+"-utilizador"),
				utilizador_val = $(".ep-field-"+type+"-utilizador").eq(0).val(),
				alugado = $(".ep-field-"+type+"-alugado"),
				alugado_val = $(".ep-field-"+type+"-alugado:checked").eq(0).val(),
				nasc = $(".ep-field-"+type+"-nasc"),
				nasc_val = nasc.eq(0).val(),
				acessorios = $(".ep-field-"+type+"-acessorios"),
				acessorios_val = acessorios.eq(0),
				c = 0;


			acessorios_val.find("select").each(function() {
				var opt = $(this).find("option"),
					v = $(this).val();

				opt.each(function() {
					if($(this).val() == v)
						$(this).attr("selected","selected");
					else
						$(this).removeAttr("selected");	
				});
			});

			acessorios_val.find("input").each(function() {
				$(this).attr("value",$(this).val());
			});

			acessorios_val = acessorios_val.clone().html();

			if(type == "smartphone") {
				os.each(function() {
					if($(this).val() == os_val)
						$(this).trigger("click");
				});

				c = 0;
				utilizador.each(function() {
					if(t.is(":checked")) {
						$(this).val(utilizador_val);
					} else {
						if(c > 0) $(this).val("");
					}
					c++;
				});

				c = 0;
				nasc.each(function() {
					if(t.is(":checked")) {
						$(this).val(nasc_val).trigger("blur");
					} else {
						if(c > 0) $(this).val("").trigger("blur");
					}
					c++;
				});
			}

			c = 0;
			if(type == "smartphone") {
				setTimeout(function() {
					c = 0;
					marca.each(function() {
						if(t.is(":checked")) {
							$(this).val(marca_val).trigger("change");
						} else {
							if(c > 0) $(this).val("").trigger("change");
						}
						
						c++;
					});
				},200);
			} else {
				c = 0;
				marca.each(function() {
					if(t.is(":checked")) {
						$(this).val(marca_val).trigger("change");
					} else {
						if(c > 0) $(this).val("").trigger("change");
					}
					
					c++;
				});
			}
			

			c = 0;
			modelo.each(function() {
				if(t.is(":checked")) {
					$(this).val(modelo_val);
				} else {
					if(c > 0) $(this).val("");
				}
				c++;
			});

			c = 0;
			NF_data.each(function() {
				if(t.is(":checked")) {
					$(this).val(NF_data_val).trigger("blur");
				} else {
					if(c > 0) $(this).val("").trigger("blur");
				}
				c++;
			});

			c = 0;
			NF_valor.each(function() {
				if(t.is(":checked")) {
					$(this).val(NF_valor_val).trigger("blur");
				} else {
					if(c > 0) $(this).val("").trigger("blur");
				}
				c++;
			});

			if(type == "camera" || type == "videocamera") {
				alugado.each(function() {
					if($(this).val() == alugado_val)
						$(this).trigger("click");
				});
			}

			c = 0;
			acessorios.each(function() {
				if(t.is(":checked")) {
					if(c > 0) {
						var idx = $(this).data("idx"),
							ctt = acessorios_val.replace(/aparelhos\[\d\]/g,"aparelhos["+idx+"]").replace(/equipamentosPortateisItemCalculo\[\d\]/g,"equipamentosPortateisItemCalculo["+idx+"]").replace(/device\d/g,"device"+idx);

						$(this).html(ctt);
						vdEp.FormBindings(this);
					}
				} else {
					if(c > 0) $(this).html("");
				}
				c++;
			});

		});

		$(".ep-configItem",container).on("click",function(e) {
			if(!$(e.target).is(".ep-slide-disabled-36"))
				$(this).find("input:checkbox").trigger("click");
		});

		$(".ep-form-changeAction-submit",container).on("click",function(e) {
			e.preventDefault();

			var t = $(this),
				d = t.attr("href"),
				frm = t.data("form");

			$(frm).attr("action",d).attr("onsubmit","").submit();
		});

		$(".ep-switcher",container).on("click",function() {
			var t = $(this),
				cmd = t.data("switcher");

			cmd = cmd.split(";");

			if(t.is(":checked")) {
				$(cmd[2]).val(cmd[0]);
			} else {
				$(cmd[2]).val(cmd[1]);
			}
		});

		$("select[name*='marcaFake']:eq(0)",container).trigger("change");

		$(".ep-setValid",container).on("click change",function(e) {
			var t = $(this);

			if(	t.is("select") && e.type == "change" ||
				!t.is("select") && e.type == "click") {

				var cmd = t.data("setvalid");
				cmd = cmd.split(";");

				for(var i=0,l=cmd.length;i<l;i++) {
					var buf = cmd[i];
					if(t.is("select")) {
						if(buf.indexOf("==") > -1) {
							cmd[i] = cmd[i].split("==");

							if(cmd[i][0] == "{default}") cmd[i][0] = "";

							if(t.val() != cmd[i][0]) continue;

						} else if(buf.indexOf("!=") > -1) {
							cmd[i] = cmd[i].split("!=");

							if(cmd[i][0] == "{default}") cmd[i][0] = "";

							if(t.val() == cmd[i][0]) continue;
						}
						buf = cmd[1];
					}

					if(buf.indexOf("true:") == 0) {
						buf = buf.replace("true:","");
						$(buf).addClass("ep-valid");
					} else if(buf.indexOf("false:") == 0) {
						buf = buf.replace("false:","");
						$(buf).removeClass("ep-valid");
					}
				}
			}
		});

		$(".ep-tooltip",container).hover(function() {
			$(".tooltip strong").css("visibility", "visible").css("z-index", "999");
		  }, function() {
			$(".tooltip strong").css("visibility", "hidden").css("z-index", "");
		  }
		);

		$(".ep-fieldTooltip-trigger",container).on("click mouseenter mouseleave", function(e) {
			e.stopPropagation();
			var p = $(this).parent(".ep-fieldTooltip-container");
			var active = 'focused';

			if(window.innerWidth > 1023) {
				if(e.type === 'mouseleave')	 {
					p.removeClass(active);	
				}
				if(e.type === 'mouseenter')	 {
					p.addClass(active);
				}
			} else {
				if(e.type === 'click') {
					p.toggleClass(active);
					var clickOutside = p.hasClass(active);

					$(window).on('click.tooltip', function(e) {
						if(clickOutside) {
							p.removeClass(active);
							$(window).off('click.tooltip');
						}
					});
				}
			}
		});

		$(".vdo-cookie",container).on("click blur change",function(e) {
			var formId = "#"+$(this).parents("form").attr("id"),
				t = $(this);

			if(
				(t.is("select") && e.type == "change") ||
				(t.is(":radio") && e.type == "click" || t.is(":checkbox") && e.type == "click") ||
				(t.is("input") && e.type == "blur")
			) {
				window.voAction.UpdateFormCookie(formId);
			}
		});

		$("*[data-exec]",container).off("click.exec change.exec blur.exec").on("click.exec change.exec blur.exec",function(e) {
			var t = $(this),
				c = t.data("exec"),
				exec = false;
			
			if(c.indexOf("change:") > -1 && e.type == "change") {
				c = c.replace("change:","");
				exec = true;
			} else if(c.indexOf("click:") > -1 && e.type == "click") {
				c = c.replace("click:","");
				exec = true;
			} else if(c.indexOf("blur:") > -1 && e.type == "blur") {
				c = c.replace("blur:","");
				exec = true;
			}

			if(exec)
				eval(c);
		});

		$("input.ep-showHide-toggle",container).on("click",function() {
			var dest = $(this).data("showhide");

			if($(this).is(":checked")) {
				$(dest).slideDown('fast');
			} else {
				$(dest).slideUp('fast');
			}
		});

		vdEp.OldBrowserSupport(container);
	},
	calculateItensLimit: function() {
		var sum = 0,
			limit = parseFloat(window.vdEp.DeviceValueLimits.Default.CalculateItensLimit),
			itens = $(".ep-calculateItensLimit"),
			rtn = true;

		itens.each(function() {
			var t = $(this),
				v = t.val();
			
			if(v == "") return;

			v = parseFloat(v);
			sum = sum + v;
		});

		if(sum > limit) {
			vdEp.Modal("#ModalLimiteValorApolice");
			rtn = false;
		}

		return rtn;
	},
	validadorParcelamento: function(obj){
		$(".ep-modal-loading").fadeIn("fast");
		$(".ep-submitRow:visible .ep-btn").prop("disabled","disabled");
		
		if(typeof obj == "undefined") return false;

		var parametrosValidacao = "",
			numeroCartaoCredito = new StringBuffer(),
			parentMonth = $("#ep-mesValidadeCartaoCredito").parent(),
			parentYear = $("#ep-anoValidadeCartaoCredito").parent(),
			anoValidade = $("#ep-anoValidadeCartao"),
			mesValidade = $("#ep-mesValidadeCartao"),
			setErrorMsg = $(".ep-row:visible #card-error-msg"),
			creditcardtype = $(".ep-creditCard-type").val();


		if(creditcardtype != "4") {
			numeroCartaoCredito.append($(".ep-cc-default-0").val()); 
			numeroCartaoCredito.append($(".ep-cc-default-1").val());
			numeroCartaoCredito.append($(".ep-cc-default-2").val());
			numeroCartaoCredito.append($(".ep-cc-default-3").val());
			// anoValidade = $("#ep-anoValidadeDemaisCartoes");
			// mesValidade = $("#ep-mesValidadeDemaisCartoes");	
		} else {
			numeroCartaoCredito.append($(".ep-cc-porto-0").val()); 
			numeroCartaoCredito.append($(".ep-cc-porto-1").val());
			numeroCartaoCredito.append($(".ep-cc-porto-2").val());
			numeroCartaoCredito.append($(".ep-cc-porto-3").val());
			// anoValidade = $("#ep-anoValidadeCartaoPorto");
			// mesValidade = $("#ep-mesValidadeCartaoPorto");
		}

		parametrosValidacao += "&codigoEmpresa=1";
		parametrosValidacao += "&codigoProduto="+$("input[name='codigoProduto']","#ep-checkoutForm").val();
		parametrosValidacao += "&codigoBanco="+($("#ep-form-bankData").is(":visible") ? $("select[name='proposta.dadosPagamento.codigoBanco']","#ep-checkoutForm").val() : "");
		parametrosValidacao += "&codigoAgencia="+($("#ep-form-bankData").is(":visible") ? $("input[name='proposta.dadosPagamento.codigoAgencia']","#ep-checkoutForm").val() : "");
		parametrosValidacao += "&numeroContaCorrente="+($("#ep-form-bankData").is(":visible") ? $("input[name='proposta.dadosPagamento.numeroContaDebito']","#ep-checkoutForm").val() : "");
		parametrosValidacao += "&digitoContaCorrente="+($("#ep-form-bankData").is(":visible") ? $("input[name='proposta.dadosPagamento.digitoContaDebito']","#ep-checkoutForm").val() : "");

		parametrosValidacao += "&numeroCartaoCredito="+($("#ep-form-cardData").is(":visible") ? numeroCartaoCredito : "");
		parametrosValidacao += "&dataVencimento="+($("#ep-form-cardData").is(":visible") ? mesValidade.val()+"/"+anoValidade.val() : "");
		parametrosValidacao += "&codigoSegurancaCartao="+($("#ep-form-cardData").is(":visible") ? $("#codeCard").val() : "");
		
		parametrosValidacao += "&cache="+Math.random();

		$.ajax({
			url: "/vendaonline/vocomponente.ns?dispatch=validarDadosBancarios"+parametrosValidacao,
			//url: "./vendaonline/xmlFake-cartao.xml?dispatch=validarDadosBancarios"+parametrosValidacao+random,
			//url: "./vendaonline/service.php?dispatch=validarDadosBancarios"+parametrosValidacao+random,
			
			type: "GET",
			dataType: "xml",
			timeout: 50000,
			async: true,
			error: function(erro){
				$(".ep-modal-loading").hide();
				return false;
			},
			success: function(xml){

				if($(xml).find("retorno").text() === "true"){
					rtn = true;
					obj.submit();

				}else{
					setErrorMsg.html("Dados de pagamento inv&aacute;lidos. Por favor verifique os dados informados.").slideDown();
					$(".ep-modal-loading").hide();
					rtn = false;

					$(".ep-submitRow:visible .ep-btn").prop("disabled",false);

					$(".ep-form-control:visible, .ep-select:visible").addClass("ep-error");

					$(".ep-form-control:visible, .ep-select:visible").change(function(){
						$(".ep-form-control:visible, .ep-select:visible").removeClass("ep-error");
						$(".card-error-msg:visible").slideUp();
					});

					return false;
				} 
			}
		});
	},
	ShowError: function(obj,mode) {
		var errorShowInfo = typeof $(obj).data("errorshowinfo") !== "undefined" ? $(obj).data("errorshowinfo") : false;

		if(mode) {
			$(obj).removeClass("ep-error");
			if(errorShowInfo) $(errorShowInfo).fadeOut("fast");
		} else {
			$(obj).addClass("ep-error");
			if(errorShowInfo) $(errorShowInfo).fadeIn("fast");
		}
	},
	LandingPageBindings: function(container) {
		$(".ep-lp-deviceSelect .ep-device-showHide",container).on("click",function() {
			var t = $(this),
				qtdeForm = t.next(".ep-qtdeForm"),
				ipt = qtdeForm.find("input");

			if(t.is(".ep-selected")) {
				t.removeClass("ep-selected");
				
				qtdeForm.slideUp("fast",function() {
					if(!vdEp.IsMobile) ipt.val("0").trigger("change");	
				});
			} else {
				if(vdEp.IsMobile) {
					$(".ep-lp-deviceSelect .ep-device-showHide").removeClass("ep-selected");
					$(".ep-lp-deviceSelect .ep-qtdeForm").hide();
					var p = $(".ep-lp-deviceSelect").offset();
					$("html,body").animate({
						scrollTop: (p.top-50)
					},200);
				}

				t.addClass("ep-selected");
				if(ipt.val() === "0")
					ipt.val("1").trigger("change");

				
				qtdeForm.slideDown("fast");
			}
		});

		$(".ep-lp-deviceSelect input, #ModalDeviceQtt input",container).on("change",function() {
			var t = $(this),
				v = t.val(),
				context = t.data("context"),
				deviceCountIpt = $("input[name='deviceCount']",context),
				deviceCount = 0,
				deviceItens = $("input.ep-form-control",context),
				btnSubmit = $(".ep-calcStartup",context);

			if(v == "") {
				t.val("0");
				v = "0";
			}
			t.removeClass("ep-error");
			$(".ep-limit-error").slideUp();

			if(v == "0" && context != "#ModalDeviceQtt") {
				t.parent().slideUp("fast").prev(".ep-device-showHide").removeClass("ep-selected");
				if(vdEp.IsMobile) t.parent().prev(".ep-device-showHide").find(".ep-counter").hide();
			} else {
				if(vdEp.IsMobile && context != "#ModalDeviceQtt")
					t.parent().prev(".ep-device-showHide").find(".ep-counter").html(v).fadeIn("fast");
			}

			deviceItens.each(function() {
				var v = parseInt($(this).val());
				deviceCount = deviceCount+v;
			});
			deviceCountIpt.val(deviceCount);

			if(deviceCount == 0) {
				btnSubmit.addClass("ep-disabled");
			} else if(deviceCount > vdEp.DeviceItensLimit) {
				t.addClass("ep-error");
				$(".ep-limit-error",t.parent()).slideDown("fast");
				btnSubmit.addClass("ep-disabled");
			} else {
				btnSubmit.removeClass("ep-disabled");
			}
		});

		$(".ep-backStep",container).on("click",function() {
			$(".ep-calc-form").hide();
			$(".ep-lp").fadeIn("fast");
			$("html,body").animate({
				scrollTop: 0
			},200);
		});

		$(".ep-calcStartup",container).on("click",function() {
			if(!$(this).is(".ep-disabled")) {
				var scope = $(this).data("calcstartup"),
					config = {};

				$(scope).find("input").each(function() {
					var n = $(this).attr("name"),
						v = parseInt($(this).val());

					config[n] = v;
				});

				vdEp.CalcStartup(config);
			}
		});

		$(".ep-calcStartup-single",container).on("click",function() {
            var scope = $(this).data("calcstartup"),
				startDevice = (typeof $(this).data("startdevice") !== "undefined" ? $(this).data("startdevice") : false)
				config = {};

			$(scope).find("input").each(function() {
				var n = $(this).attr("name"),
					v = parseInt($(this).val());
				if(startDevice && (startDevice == n || n == "deviceCount"))
					v = 1;

				config[n] = v;
			});

			vdEp.CalcStartup(config);
		});
		
		$(".vdo__landpages-form-equip a",container).on("click",function() {
			var t = $(this),
				ipt = t.next("input"),
				scope = t.data("calcstartup"),
				config = {};

			ipt.val("1").trigger("change");

			$(scope).find("input").each(function() {
				var n = $(this).attr("name"),
					v = parseInt($(this).val());

				config[n] = v;
			});

			vdEp.CalcStartup(config);
		});

		/*
		$(".vdo__lgd-checked").off("change").on("change", function(){
			var activeButton = $(".btn_passo1"),
				rtn;

			if($("input[name='calculo.optinPolitica']").is(":checked") 
				&& $("input[name='calculo.optinCompartilhamento']").is(":checked")) {
				activeButton.removeClass("ep-disabled");
				rtn = true;
			} else {
				activeButton.addClass("ep-disabled");
				rtn = false;
			}

			vdEp.CalcLGPDVerify(rtn);
		});
		*/

		$(".vdo__lgd-cpfcnpj").off("click").on("click",function() {
			if(psLib.FormValidate("#ep-calc-step2-lgpd"))
				window.checkLogin();
		});

		
	},
	CalcLGPDVerify: function(config) {
		return vdEp.IsLGPD = config;
	},
	CalcConfigsBindings: function(container) {
		$(".ep-slider",container).each(vdEp.Slider);

		$(".ep-configList input:checkbox",container).on("click",function() {
			var t = $(this),
				block = t.parents(".ep-configItem");

			if(t.is(":checked")) {
				block.addClass("ep-enabled");
			} else {
				block.removeClass("ep-enabled");
			}
		});

		$(".ep-deviceItem",container).on("click",function() {
			var t = $(this),
				block = t.parent().parent().parent(),
				configBlock = t.data("configblock"),
				singlePage = $(configBlock).children().length,
				slider = $(".ep-slider"),
				sliderPage = $(".ep-sliderContainer"),
				pageH = singlePage < 6 ? '654px' : '752px';

				slider.height(pageH);
				sliderPage.height(pageH);

			block.find(".ep-deviceItem").removeClass("ep-selected");
			t.addClass("ep-selected");

			block.find(".ep-configListItem").hide();
			$(configBlock).fadeIn("fast");
		});

		$(".ep-smartphoneValidate-startup").on("blur",function() {
			var nfNumber = $(this).val()
				device = $(this).data("device");

			if(nfNumber != "") {
				var bl = $("#ep-device"+device+"-smartphoneValidateBlock");

				$(".ep-imei-device"+device+"").hide();

				bl.slideDown("fast",function() {
					var p = $(this).offset();

					$("html,body").animate({
						scrollTop: p.top-20
					},200);
				});
			}
		});

		$(".ep-smartphoneValidate-stepPass").on("click",function() {
			var device = $(this).data("device");
			vdEp.SmartPortoStepsStartup(device);
		});
	},
	CalcStartup: function(config) {
		var count = $(".ep-calc-form .ep-counter"),
			deviceListMenu = $(".ep-calc-form .ep-lp-deviceSelect"),
			mobileDeviceListMenu = $("#ModalDeviceList .ep-deviceList"),
			deviceListExtMenu = $(".ep-calc-form .ep-deviceListBig"),
			deviceListForm = $("#ep-deviceList-form"),
			winH = $(window).height(),
			get = vdEp.GetVar();

		dataLayer.push({
			'event': 'step_change_home_ep',
			'step': 'DadosEquipamentos'
		});
		
		if(parseInt($("#ep-deviceCount").val()) == vdEp.DeviceItensLimit) $(".ep-deviceList-add").hide();

		$("html,body").animate({
			scrollTop: 0
		},200);
		$(".ep-lp").hide();
		$(".ep-modal .ep-modalClose").trigger("click");

		//	#Configuração retirada para captação dos dados do PDC
		//	if(typeof get.data == "undefined")
		//	$(".ep-calc-form .ep-form-control").val("");

		$(".ep-calc-form .ep-form-control").removeClass("ep-error");
		deviceListExtMenu.hide().find(".ep-slider").removeData("sliderenabled");

		$(".ep-calc-form").fadeIn("fast");
		//$(".ep-calc-step").css("min-height",winH);

		deviceListForm.html(vdEp.BuildDeviceFormContainer(config));
		vdEp.ConfigDeviceMenu(config);

		vdEp.FormBindings(deviceListForm);

		// if(config.deviceCount == 0)
        //     $(".ep-deviceList-add .ep-accordion-header").click();

		var step1 = $("#ep-calc-step-1"),
			step2 = $("#ep-calc-step-2"),
			step3 = $("#ep-calc-step-3");

		$(window).on("scroll",function() {
			var y = window.scrollY > 0 ? window.scrollY : window.pageYOffset;

			if(vdEp.CalcStepsPosition.Step3 != null && y >= vdEp.CalcStepsPosition.Step3) {
				step1.removeClass("ep-selected");
				step2.removeClass("ep-before").removeClass("ep-selected");
				step3.removeClass("ep-before").addClass("ep-selected");
			} else if(vdEp.CalcStepsPosition.Step2 != null && y >= vdEp.CalcStepsPosition.Step2) {
				step1.removeClass("ep-selected");
				step2.removeClass("ep-before").addClass("ep-selected");
				step3.addClass("ep-before").removeClass("ep-selected");
			} else {
				step1.addClass("ep-selected");
				step2.addClass("ep-before").removeClass("ep-selected");
				step3.addClass("ep-before").removeClass("ep-selected");
			}
		});

		step1.on("click",function() {
			$("html,body").animate({
				scrollTop: vdEp.CalcStepsPosition.Step1
			},300);
		});

		step2.on("click",function() {
			if(vdEp.CalcStepsPosition.Step2 != null) {
				$("html,body").animate({
					scrollTop: vdEp.CalcStepsPosition.Step2
				},300);
			}
		});

		step3.on("click",function() {
			if(vdEp.CalcStepsPosition.Step3 != null) {
				$("html,body").animate({
					scrollTop: vdEp.CalcStepsPosition.Step3
				},300);
			}
		});

		$(".ep-calc-validate").off("click").on("click",function() {
			vdEp.CalcDeviceValidate("#ep-calculoEpForm");
		});
	},
	ConfigDeviceMenu: function(config) {
		var count = $(".ep-calc-form .ep-counter"),
			deviceListMenu = $(".ep-calc-form .ep-deviceList"),
			mobileDeviceListMenu = $("#ModalDeviceList .ep-deviceList"),
			deviceListExtMenu = $(".ep-calc-form .ep-deviceListBig"),
			deviceListForm = $("#ep-deviceList-form");

		if(config.deviceCount > 1) {
			count.parent().removeClass("ep-showList");
			count.css("display","inline-block").html(config.deviceCount);

			if(vdEp.IsMobile) {
				count.parent().on("click",function() {
					vdEp.Modal("#ModalDeviceList");
				});
				mobileDeviceListMenu.html(vdEp.BuildDeviceMenu(config,false));
				vdEp.FormBindings(mobileDeviceListMenu);
			} else {
				count.parent().parent().addClass("ep-showDeviceList");
				deviceListMenu.html(vdEp.BuildDeviceMenu(config));
				vdEp.FormBindings(deviceListMenu);		

				if(config.deviceCount > 4) {
					deviceListExtMenu.find(".ep-sliderContainer").html(vdEp.BuildDeviceExtensiveMenu(config));
					vdEp.FormBindings(deviceListExtMenu);
				}
			}
		} else
			count.hide().parent().parent().removeClass("ep-showDeviceList");

		var stepText = deviceListForm.find("h1:eq(0)").text();
		$("#ep-calc-step-1 .ep-text").html(stepText);
	},
	BuildDeviceMenu: function(config,limit) {
		if(typeof limit == "undefined") limit = true;
		var ctt = "",
			idx = 0;
		ctt += '<ul>';
		for(var i=1;i<=config.notebookQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-notebook'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-notebook-white-36"></span>\
							<strong>Notebook '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		for(var i=1;i<=config.smartphoneQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-smartphone'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-smartphone-white-36"></span>\
							<strong>Smartphone '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
								<span class="ep-device'+idx+'-os"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		for(var i=1;i<=config.tabletQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-tablet'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-tablet-white-36"></span>\
							<strong>Tablet '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		for(var i=1;i<=config.cameraQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-camera'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-camera-white-36"></span>\
							<strong>C&acirc;mera '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		for(var i=1;i<=config.videocameraQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-videocamera'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-camera-white-36"></span>\
							<strong>Filmadora '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		for(var i=1;i<=config.smartwatchQtt;i++) {
			if(limit && idx > 4) break;
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger=".ep-device-smartwatch'+i+(vdEp.IsMobile ? ',#ModalDeviceList .ep-modalClose' : '')+'">\
							<span class="ep-icon ep-36 ep-smartwatch-white-36"></span>\
							<strong>Smartwatch '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
		}
		if(limit && config.deviceCount > 4)
			ctt += '<li><a href="javascript:;" class="ep-deviceList-showMore" data-ref="#ep-deviceListBig;#ep-showDeviceList">ver mais aparelhos</a></li>';

		ctt += '</ul>';


		return ctt;
	},
	BuildDeviceExtensiveMenu: function(config) {
		var ctt = "",
			idx = 0,
			pvtIdx = 0;

		ctt += '<div class="ep-sliderPage">\
					<ul>';
		for(var i=1;i<=config.notebookQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-notebook-white-36"></span>\
							<strong>Notebook '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}
		for(var i=1;i<=config.smartphoneQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-smartphone-white-36"></span>\
							<strong>Smartphone '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span> |\
								<span class="ep-device'+idx+'-os"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}
		for(var i=1;i<=config.tabletQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-tablet-white-36"></span>\
							<strong>Tablet '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}
		for(var i=1;i<=config.cameraQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-camera-white-36"></span>\
							<strong>C&acirc;mera '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}
		for(var i=1;i<=config.videocameraQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-camera-white-36"></span>\
							<strong>Filmadora '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}
		for(var i=1;i<=config.smartwatchQtt;i++) {
			if(pvtIdx % 8 == 0 && idx > 1) {
				ctt += '	</ul>\
						</div>\
						<div class="ep-sliderPage">\
							<ul>';
			}
			ctt += '<li>\
						<a href="javascript:;" class="ep-trigger ep-accordion-device'+idx+'" data-trigger="#ep-device'+idx+'-header, .ep-deviceList-hideMore">\
							<span class="ep-icon ep-36 ep-smartwatch-white-36"></span>\
							<strong>Smartwatch '+i+'</strong>\
							<div class="ep-device'+idx+'-info" style="display: none;">\
								<span class="ep-device'+idx+'-brand"></span>\
							</div>\
						</a>\
					</li>';
			idx++;
			pvtIdx++;
		}

		ctt += '	</ul>\
				</div>';

		return ctt;
	},
	BuildDeviceFormContainer: function(config) {
		var ctt = '';
		if(config.deviceCount == 1) {
			var t, s;
			if(config.notebookQtt > 0) {
				t = "notebook";
				s = "Dados do <strong>Notebook</strong>";
			} else if(config.smartphoneQtt > 0) {
				t = "smartphone";
				s = "Dados do <strong>Smartphone</strong>";
			} else if(config.tabletQtt > 0) {
				t = "tablet";
				s = "Dados do <strong>Tablet</strong>";
			} else if(config.cameraQtt > 0) {
				t = "camera";
				s = "Dados da <strong>C&acirc;mera</strong>";
			} else if(config.videocameraQtt > 0) {
				t = "videocamera";
				s = "Dados da <strong>Filmadora</strong>";
			} else if(config.smartwatchQtt > 0) {
				t = "smartwatch";
				s = "Dados do <strong>Smartwatch</strong>";
			}

			ctt += '<h1>'+s+'</h1>';
			//ctt += vdEp.BuildDeviceForm(t,1,1);
			ctt += vdEp.BuildDeviceAccordionItem(t,1,1,0,config.deviceCount);
		} else {
			ctt += '<h1>Dados dos <strong>Aparelhos</strong></h1>';
			var idx = 0;
			for(var i=1;i<=config.notebookQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("notebook",i,config.notebookQtt,idx,config.deviceCount);
				idx++;
			}
			for(var i=1;i<=config.smartphoneQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("smartphone",i,config.smartphoneQtt,idx,config.deviceCount); 
				idx++;
			}
			for(var i=1;i<=config.tabletQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("tablet",i,config.tabletQtt,idx,config.deviceCount); 
				idx++;
			}
			for(var i=1;i<=config.smartwatchQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("smartwatch",i,config.tabletQtt,idx,config.deviceCount); 
				idx++;
			}
			for(var i=1;i<=config.cameraQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("camera",i,config.cameraQtt,idx,config.deviceCount); 
				idx++;
			}
			for(var i=1;i<=config.videocameraQtt;i++) {
				ctt += vdEp.BuildDeviceAccordionItem("videocamera",i,config.videocameraQtt,idx,config.deviceCount); 
				idx++;
			}
		}
		return ctt;
	},
	BuildDeviceForm: function(type,currentIdx,typeTotal,idx,total) {
		var ctt = '',
			nfMinMonth = 12,
			el, equipCode, tipoEquipCode, equipName;

		if(type == "tablet" || type == "notebook")
			nfMinMonth = 48;
		else if(type == "camera" || type == "videocamera")
			nfMinMonth = 60;

		switch(type) {
			case "smartphone":
				el = $(".item-equip .vd-fakeEntry:eq(0)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
			case "smartwatch":
				el = $(".item-equip .vd-fakeEntry:eq(1)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
			case "notebook":
				el = $(".item-equip .vd-fakeEntry:eq(2)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
			case "tablet":
				el = $(".item-equip .vd-fakeEntry:eq(3)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
			case "camera":
				el = $(".item-equip .vd-fakeEntry:eq(4)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
			case "videocamera":
				el = $(".item-equip .vd-fakeEntry:eq(5)");
				tipoEquipCode = el.data("codigoTipoEquipamento");
				equipCode = el.data("codigoEquipamento");
				equipName = el.data("equipamento").toUpperCase();
				break;
		}

		ctt += '<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].codigoEquipamento" value="'+equipCode+'" />\
				<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].codigoTipoEquipamento" value="'+tipoEquipCode+'" />\
				<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoEquipamento" value="'+equipName+'" />\
				<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].codigoRelevancia" value="1" />\
				<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].valorLimiteCobertura" value="" />';				
		/*
		if(type == "smartphone") {
			if(total > 1) 
				ctt += '<h2>Qual sistema operacional?</h2>';
			
			ctt += '<div class="ep-row">\
						<div class="ep-mod2 ep-lspan2 ep-sm-mod3 '+(vdEp.IsMobile ? 'ep-noPadding' : '')+'">\
							<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional" value="1" id="ep-device'+idx+'-os-android" class="ep-radio ep-setValue ep-setEnabledDisabled ep-setValid ep-field-'+type+'-os ep-hide ep-show" \
								data-hide="select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\'],.ep-calc-submit-2" \
								data-show="select[name=\'aparelhos['+idx+'].marcaFake.android\'],.ep-calc-submit-1" \
								data-setenableddisabled="select[name=\'aparelhos['+idx+'].marcaFake.android\']==false" \
								data-setvalue="select[name=\'aparelhos['+idx+'].marcaFake.android\']==;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']==" \
								data-setvalid="true:select[name=\'aparelhos['+idx+'].marcaFake.android\'];false:select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\']" \
								data-text="Android" checked />\
							<label class="ep-radio ep-radioBlock ep-deviceOS" for="ep-device'+idx+'-os-android">\
								<span class="ep-icon ep-48 ep-android-gray-48"></span>\
								<strong>ANDROID</strong>\
							</label>\
						</div>\
						<div class="ep-mod2 ep-sm-mod3 '+(vdEp.IsMobile ? 'ep-noPadding' : '')+'">\
							<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional" value="2" id="ep-device'+idx+'-os-ios" class="ep-radio ep-setValue ep-setEnabledDisabled ep-setValid ep-field-'+type+'-os ep-hide ep-show" \
								data-hide="select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\'],.ep-calc-submit-2"\
								data-show="select[name=\'aparelhos['+idx+'].marcaFake.apple\'],.ep-calc-submit-1" \
								data-setenableddisabled="select[name=\'aparelhos['+idx+'].marcaFake.apple\']==true" \
								data-setvalue="select[name=\'aparelhos['+idx+'].marcaFake.apple\']==;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']==" \
								data-setvalid="true:select[name=\'aparelhos['+idx+'].marcaFake.apple\'];false:select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\']" \
								data-text="iOS" /> \
							<label class="ep-radio ep-radioBlock ep-deviceOS" for="ep-device'+idx+'-os-ios"> \
								<span class="ep-icon ep-48 ep-ios-gray-48"></span> \
								<strong>IOS</strong> \
							</label> \
						</div> \
						<div class="ep-mod2 ep-sm-mod3 '+(vdEp.IsMobile ? 'ep-noPadding' : '')+'">\
							<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional" value="3" id="ep-device'+idx+'-os-wp8" class="ep-radio ep-setValue ep-setEnabledDisabled ep-setValid ep-field-'+type+'-os ep-hide ep-show" \
								data-hide="select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\'],.ep-calc-submit-1" \
								data-show="select[name=\'aparelhos['+idx+'].marcaFake.wp\'],.ep-calc-submit-2" \
								data-setenableddisabled="select[name=\'aparelhos['+idx+'].marcaFake.wp\']==false" \
								data-setvalue="select[name=\'aparelhos['+idx+'].marcaFake.wp\']==;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']==" \
								data-setvalid="true:select[name=\'aparelhos['+idx+'].marcaFake.wp\'];false:select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.outros\']" \
								data-text="Windows Phone" />\
							<label class="ep-radio ep-radioBlock ep-deviceOS" for="ep-device'+idx+'-os-wp8">\
								<span class="ep-icon ep-48 ep-wp8-gray-48"></span>\
								<strong>WINDOWS PHONE</strong>\
							</label>\
						</div>\
						<div class="ep-mod2 ep-sm-mod3 '+(vdEp.IsMobile ? 'ep-noPadding' : '')+'">\
							<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional" value="4" id="ep-device'+idx+'-os-other" class="ep-radio ep-setValue ep-setEnabledDisabled ep-setValid ep-field-'+type+'-os ep-hide ep-show" \
								data-hide="select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\'],.ep-calc-submit-1" \
								data-show="select[name=\'aparelhos['+idx+'].marcaFake.outros\'],.ep-calc-submit-2" \
								data-setenableddisabled="select[name=\'aparelhos['+idx+'].marcaFake.outros\']==true" \
								data-setvalue="select[name=\'aparelhos['+idx+'].marcaFake.outros\']==;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']==" \
								data-setvalid="true:select[name=\'aparelhos['+idx+'].marcaFake.outros\'];false:select[name=\'aparelhos['+idx+'].marcaFake.android\'],select[name=\'aparelhos['+idx+'].marcaFake.apple\'],select[name=\'aparelhos['+idx+'].marcaFake.wp\']" \
								data-text="" />\
							<label class="ep-radio ep-radioBlock ep-deviceOS" for="ep-device'+idx+'-os-other">\
								<span class="ep-icon ep-48 ep-otherOS-gray-48"></span>\
								<strong>OUTROS</strong>\
							</label>\
						</div>\
					</div>';
		}
		*/

		ctt += '<div class="ep-row ep-form-group"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">Marca:</label></div>\
					<div class="ep-mod6"> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento" value="" /> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento" value="" /> \
						<label class="ep-select">';
		if(type == "smartphone") {					
			ctt += '		<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional" value="" />\
							<select name="aparelhos['+idx+'].marcaFake.android" class="ep-valid ep-setValue ep-conditionalSetValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}"  \
								data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}" \
                                data-conditionalsetvalue="!94::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']==1;!604::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']==1;94::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']==2;604::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']==3"> \
								'+vdEp.GetDeviceBrands(type+"|android")+'\
							</select>';
							/*
							<select name="aparelhos['+idx+'].marcaFake.apple" style="display: none;" class="ep-valid ep-setValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}"> \
								'+vdEp.GetDeviceBrands(type+"|apple")+'\
							</select>\
							<select name="aparelhos['+idx+'].marcaFake.wp" style="display: none;" class="ep-valid ep-setValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}"> \
								'+vdEp.GetDeviceBrands(type+"|wp")+'\
							</select>\
							<select name="aparelhos['+idx+'].marcaFake.outros" style="display: none;" class="ep-valid ep-setValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}"> \
								'+vdEp.GetDeviceBrands(type+"|other")+'\
							</select>';
							*/
		} else if(type == "notebook") {
			ctt += '		<select name="aparelhos['+idx+'].marcaFake" class="ep-valid ep-setValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca ep-conditionalSetValue" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}" data-conditionalsetvalue="94::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].codigoEquipamento\']==401;!94::input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].codigoEquipamento\']=='+equipCode+'"> \
								'+vdEp.GetDeviceBrands(type)+'\
							</select>';
		} else {
			ctt += '		<select name="aparelhos['+idx+'].marcaFake" class="ep-valid ep-setValue ep-getOptionText ep-fillDeviceInfoMenu ep-field-'+type+'-marca" data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoMarcaEquipamento\']" data-filldeviceinfomenu="device'+idx+';'+(type == "smartphone" ? 'input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].sistemaOperacional\']:checked' : '')+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].marcaEquipamento\']=={value}"> \
								'+vdEp.GetDeviceBrands(type)+'\
							</select>';
		}
		ctt += '		</label> \
					</div> \
				</div> \
				<div class="ep-row ep-form-group"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">Modelo:</label></div> \
					<div class="ep-mod6"> \
						<input type="text" name="calculo.equipamentosPortateisItemCalculo['+idx+'].descricaoModelo" class="ep-form-control ep-valid ep-cleanupText ep-field-'+type+'-modelo" data-cleanuptext="allownumbers" /> \
					</div> \
					<div class="ep-mod3" style="display:'+(type == "camera" || type == "videocamera" ? 'block' : 'none')+'"> \
						<div class="ep-fieldTooltip ep-fieldTooltip-drone"> \
							Sem aceita&ccedil;&atilde;o para Drones.\
						</div> \
					</div> \
				</div> \
				<div class="ep-row ep-form-group"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">Data de aquisi&ccedil;&atilde;o*:</label></div> \
					<div class="ep-mod3"> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].diaNotaFiscal" value="" /> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].mesNotaFiscal" value="" /> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].anoNotaFiscal" value="" /> \
						<input  type="tel" \
								name="aparelhos['+idx+'].nf.dataEmissao" \
								class="ep-form-control ep-dateMask ep-valid ep-split ep-depreciation ep-field-'+type+'-nfdata" \
								data-split="/;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].diaNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].mesNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].anoNotaFiscal\']" \
								data-depreciation="'+type+';#ep-device'+idx+'-dataEmissao-error;#ep-device'+idx+'-dataEmissao-depreciation;" \
								data-errorshowinfo="#ep-device'+idx+'-dataEmissao-error" \
								placeholder="dd/mm/aaaa" /> \
					</div> \
					<div class="ep-mod3"> \
						<div class="ep-fieldTooltip ep-fieldTooltip-error" id="ep-device'+idx+'-dataEmissao-error" style="display: none;"> \
							Data inv&aacute;lida: Contrata&ccedil;&otilde;es permitidas para equipamento com at&eacute; '+nfMinMonth+' meses de aquisi&ccedil;&atilde;o. \
						</div> \
						<div class="ep-fieldTooltip" id="ep-device'+idx+'-dataEmissao-depreciation" style="display: none;"> \
							Data acima de <span class="ep-dd-months"></span> meses: Contrata&ccedil;&atilde;o permitida com o valor do equipamento depreciado em <strong></strong>.\
						</div> \
					</div> \
					<div class="ep-mod6 ep-lspan3"> \
						<small class="ep-fieldInfo"> \
							* Informe a data de aquisi&ccedil;&atilde;o/compra do equipamento.\
						</small> \
					</div> \
				</div> \
				<div class="ep-row ep-form-group"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">Valor de aquisi&ccedil;&atilde;o*:</label></div> \
					<div class="ep-mod3"> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].valorNotaFiscal" value="" class="ep-calculateItensLimit" /> \
						<input type="tel" name="valorNF_'+idx+'" class="ep-form-control ep-currency ep-valid ep-setCurrencyLimit ep-setValue ep-field-'+type+'-nfValor" placeholder="R$" data-currencylimit="'+(type == "camera" || type == "videocamera" ? vdEp.DeviceValueLimits.Cameras.Device.max+';'+vdEp.DeviceValueLimits.Cameras.Device.min : vdEp.DeviceValueLimits.Default.Device.max+';'+vdEp.DeviceValueLimits.Default.Device.min)+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].valorNotaFiscal\']=={value}" /> \
					</div> \
					<div class="ep-mod3"> \
						<div class="ep-fieldTooltip"> \
							Para valores acima de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Device.max) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Device.max))+', fale com seu corretor. Valor m&iacute;nimo aceito &eacute; de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Device.min) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Device.min))+'.\
						</div> \
					</div> \
					<div class="ep-mod6 ep-lspan3"> \
						<small class="ep-fieldInfo"> \
							* Informe o valor do equipamento que ser&aacute; segurado e que consta na nota fiscal, recibo de compra e venda, invoice, declara&ccedil;&atilde;o de importa&ccedil;&atilde;o ou cupom fiscal.\
						</small> \
					</div> \
				</div>';
			if(type == "smartphone" || type == "smartwatch") {
				ctt += '\
				<div class="ep-row ep-form-group"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">Quem &eacute; o principal<br class="ep-responsive-break" /> usu&aacute;rio do aparelho?*</label></div> \
					<div class="ep-mod6"> \
						<label class="ep-select"> \
							<select name="calculo.equipamentosPortateisItemCalculo['+idx+'].principalUsuario" \
							class="ep-valid ep-field-'+type+'-utilizador ep-setValid ep-setValue ep-showHide" \
							data-showhide="1!=#ep-device'+idx+'-nascBlock" \
							data-setvalid="1!=true:.ep-field-'+type+'-nasc" \
							data-setvalue=".ep-device'+idx+'-acessorio-principalUsuario=={value}"\
							> \
								<option value="1">O pr&oacute;prio segurado</option> \
								<option value="2">Pai</option> \
								<option value="3">M&atilde;e</option> \
								<option value="4">Filhos</option> \
								<option value="5">C&ocirc;njuge</option> \
							</select> \
						</label> \
					</div> \
					<div class="ep-mod6 ep-lspan3"> \
						<small class="ep-fieldInfo"> \
							* Em rela&ccedil;&atilde;o ao segurado \
						</small> \
					</div> \
				</div>\
				<div class="ep-row ep-form-group" id="ep-device'+idx+'-nascBlock" style="display: none;"> \
					<div class="ep-mod3 ep-right"> \
						<label class="ep-lbl ep-lbl-inline">Data de nascimento<br class="ep-responsive-break" /> do principal usu&aacute;rio:</label> \
					</div> \
					<div class="ep-mod4"> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].diaNascimentoUsuario" value="" /> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].mesNascimentoUsuario" value="" /> \
						<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].anoNascimentoUsuario" value="" /> \
						<input type="tel" name="nascimentoProprietario" class="ep-form-control ep-dateMask ep-valDate ep-split ep-field-'+type+'-nasc" \
							data-errorshowinfo="#ep-device'+idx+'-nascimentoProprietario-error" \
							data-validdate="before;216" \
							data-split="/;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].diaNascimentoUsuario\'],.ep-device'+idx+'-acessorio-diaNascimentoUsuario;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].mesNascimentoUsuario\'],.ep-device'+idx+'-acessorio-mesNascimentoUsuario;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].anoNascimentoUsuario\'],.ep-device'+idx+'-acessorio-anoNascimentoUsuario" placeholder="dd/mm/aaaa" /> \
					</div> \
					<div class="ep-mod3"> \
						<div class="ep-fieldTooltip ep-fieldTooltip-error" id="ep-device'+idx+'-nascimentoProprietario-error" style="display: none;"> \
							'+(type != "smartphone" ? 'A contrata&ccedil;&atilde;o do Seguro de Equipamentos Port&aacute;teis &eacute; permitida apenas para maiores de 18 anos.' : 'A contrata&ccedil;&atilde;o do seguro e a utiliza&ccedil&atilde;o do equipamento &eacute; permitida apenas para maiores de 18 anos.')+' \
						</div> \
					</div> \
				</div>';
			} else if(type == "camera" || type == "videocamera") {
				ctt += '\
				<div class="ep-row"> \
					<div class="ep-mod3 ep-right"><label class="ep-lbl">A '+(type == "camera" ? "c&acirc;mera" : 'filmadora')+' &eacute; alugada?</label></div> \
					<div class="ep-mod8"> \
						<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].equipamentoAlugado" value="0" id="ep-device'+idx+'-alugado-nao" class="ep-radio ep-field-'+type+'-alugado" checked /> \
						<label class="ep-radio" for="ep-device'+idx+'-alugado-nao"> \
							<span class="ep-icon ep-24 ep-radio-icon"></span> N&atilde;o \
						</label> \
						<input type="radio" name="calculo.equipamentosPortateisItemCalculo['+idx+'].equipamentoAlugado" value="1" id="ep-device'+idx+'-alugado-sim" class="ep-radio ep-field-'+type+'-alugado" /> \
						<label class="ep-radio" for="ep-device'+idx+'-alugado-sim"> \
							<span class="ep-icon ep-24 ep-radio-icon"></span> Sim \
						</label> \
					</div> \
				</div>';
			}
			if(type != "smartwatch") {
				ctt += '\
					<div class="ep-sm-subForm"> \
						<h1>Acess&oacute;rios</h1> \
						<div class="ep-form-group"> \
							<div class="ep-row"> \
								<div class="ep-mod3 ep-right"><label class="ep-lbl">Voc&ecirc; tamb&eacute;m pode incluir <br class="ep-responsive-break" /> os acess&oacute;rios no seguro:</label></div> \
								<div class="ep-mod8 ep-field-'+type+'-acessorios" id="ep-device'+idx+'-acessorios-container" data-idx="'+idx+'"> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].quantidadeAcessorios" value="" /> \
									<div id="ep-device'+idx+'-acessorios0"> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].codigoRelevancia" value="2" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].codigoTipoEquipamento" value="'+tipoEquipCode+'" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].principalUsuario" class="ep-device'+idx+'-acessorio-principalUsuario" value="1" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].valorLimiteCobertura" value="" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].diaNascimentoUsuario" class="ep-device'+idx+'-acessorio-diaNascimentoUsuario" value="" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].mesNascimentoUsuario" class="ep-device'+idx+'-acessorio-mesNascimentoUsuario" value="" /> \
										<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].anoNascimentoUsuario" class="ep-device'+idx+'-acessorio-anoNascimentoUsuario" value="" /> \
										<div class="ep-row"> \
											<div class="ep-mod10 ep-sm-mod11"> \
												<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].descricaoEquipamento" value="" /> \
												<label class="ep-select"> \
													<select name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].codigoEquipamento" \
														class="ep-setFieldsToValid ep-showHide ep-sm-select-small ep-getOptionText ep-acessorio-codigoEquipamento" \
														data-showhide="#ep-device'+idx+'-acessorios0-data,#ep-device'+idx+'-acessorios-duplicateAction'+(type == "videocamera" || type == "camera" ? ';391;#ep-device'+idx+'-acessorios0-marcaModelo;#ep-device'+idx+'-acessorios0-modelo' : '')+'" \
														data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].descricaoEquipamento\']" \
														data-setfieldsvalid="input[name=\'equipamento_'+idx+'_valorAcessorio_0\'];input[name=\'aparelhos['+idx+'].acessorios[0].dataNF\']'+(type == "videocamera" || type == "camera" ? ';391!=input[name=\'aparelhos['+idx+'].acessorios[0].modeloSingle\'];391==input[name=\'aparelhos['+idx+'].acessorios[0].modeloLente\'],select[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].marcaEquipamento\']' : '')+'"> \
														'+vdEp.AccessoriesByType(type)+' \
													</select> \
												</label> \
											</div> \
											<div class="ep-mod2 ep-center ep-sm-mod1" style="padding: 0;"> \
												<a href="javascript:;" class="ep-duplicateEraser" data-duplicateerase="#ep-device'+idx+'-acessorios-duplicateAction;#ep-device'+idx+'-acessorios0"><span class="ep-icon ep-36 ep-close-gray-36"></span></a> \
											</div> \
										</div> \
										<div class="ep-row" id="ep-device'+idx+'-acessorios0-data" style="display: none;">';
				if(type == "camera" || type == "videocamera") {
					ctt += '				<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].acessoriodescricaoModelo" value="" /> \
											<div class="ep-row" id="ep-device'+idx+'-acessorios0-modelo" style="margin-left: 0;margin-right: 0;"> \
												<div class="ep-mod10"> \
													<input type="text" name="aparelhos['+idx+'].acessorios[0].modeloSingle" class="ep-form-control ep-setValue" placeholder="Modelo" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].acessoriodescricaoModelo\']=={value}" /> \
												</div> \
												<div class="clearfix"></div> \
											</div> \
											<div class="ep-row" id="ep-device'+idx+'-acessorios0-marcaModelo" style="margin-left: 0;margin-right: 0;"> \
												<div class="ep-mod4"> \
													<label class="ep-select"> \
														<select name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].marcaEquipamento" class="ep-sm-select-small"> \
															<option value="">Marca</option> \
															'+vdEp.BrandListByType("lens")+' \
														</select> \
													</label> \
												</div> \
												<div class="ep-mod6" style="padding-left: 0"> \
													<input type="text" name="aparelhos['+idx+'].acessorios[0].modeloLente" class="ep-form-control ep-setValue" placeholder="Modelo" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].acessoriodescricaoModelo\']=={value}" /> \
												</div> \
												<div class="clearfix"></div> \
											</div>\
											<div class="clearfix"></div>';
				}
				ctt += '					<div class="ep-mod4 ep-sm-mod11"> \
												<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].valorNotaFiscal" value="" class="ep-calculateItensLimit" /> \
												<span class="tooltip">\
													<input type="tel" name="equipamento_'+idx+'_valorAcessorio_0" class="ep-form-control ep-currency ep-setCurrencyLimit ep-setValue" placeholder="Valor de aquisi&ccedil;&atilde;o" data-currencylimit="'+(type == "camera" || type == "videocamera" ? vdEp.DeviceValueLimits.Cameras.Accessory.max+';'+vdEp.DeviceValueLimits.Cameras.Accessory.min : vdEp.DeviceValueLimits.Default.Accessory.max+';'+vdEp.DeviceValueLimits.Default.Accessory.min)+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].valorNotaFiscal\']=={value}"  data-errorshowinfo="#ep-device'+idx+'-acessorios0-valorError" /> \
													<strong>Informe o valor do equipamento que ser&aacute; segurado e que consta na nota fiscal, recibo de compra e venda, invoice, declara&ccedil;&atilde;o de importa&ccedil;&atilde;o ou cupom fiscal.</strong>\
												</span>\
												<small class="ep-lbl-error" style="display: none;" id="ep-device'+idx+'-acessorios0-valorError">O valor m&iacute;nimo permitido &eacute; de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Accessory.min) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Accessory.min))+'. O m&aacute;ximo &eacute; de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Accessory.max) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Accessory.max))+'</small>\
											</div> \
											<div class="ep-mod2 ep-right ep-sm-hide" style="padding-left: 0"> \
												<label class="ep-lbl">Data de<br/> aquisi&ccedil;&atilde;o:</label> \
											</div> \
											<div class="ep-mod4 ep-sm-mod11"> \
												<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].diaNotaFiscal" value="" /> \
												<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].mesNotaFiscal" value="" /> \
												<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].anoNotaFiscal" value="" /> \
												<span class="tooltip">\
													<input type="tel" name="aparelhos['+idx+'].acessorios[0].dataNF" class="ep-form-control ep-dateMask ep-split '+(vdEp.IsMobile ? 'ep-form-control-join' : '')+' ep-valDate" data-validdate="after;'+nfMinMonth+'" data-split="/;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].diaNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].mesNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[0].anoNotaFiscal\']" placeholder="'+(vdEp.IsMobile ? 'Data da nota fiscal' : 'dd/mm/aaaa')+'" data-errorshowinfo="#ep-device'+idx+'-acessorios0-dateError" /> \
													<strong>Informe a data de aquisi&ccedil;&atilde;o/compra do equipamento.</strong>\
												</span>\
												<small class="ep-lbl-error" style="display: none;" id="ep-device'+idx+'-acessorios0-dateError">Equipamento com idade inv&aacute;lida, s&atilde;o permitidas datas menores que hoje e superiores ao permitido ('+nfMinMonth+' meses).</small>\
											</div> \
										</div> \
										<div class="ep-row-divisor ep-mod12"></div> \
									</div> \
								</div> \
								<div class="ep-mod8 ep-lspan3"> \
									<a href="javascript:;" class="ep-btn ep-gray ep-duplicateBlock" id="ep-device'+idx+'-acessorios-duplicateAction" data-duplicateblock="#ep-device'+idx+'-acessorios-matrix;#ep-device'+idx+'-acessorios-container;3" data-duplicatecurrent="1">+ INCLUIR MAIS ACESS&Oacute;RIOS</a> \
									<br/><br/> \
								</div> \
							</div> \
							<div class="ep-row-divisor ep-mod12"></div> \
							<div class="ep-form-group" id="ep-device'+idx+'-acessorios-matrix" style="display: none;"> \
								<div class="ep-duplicatedItem" id="ep-device'+idx+'-acessorios{i}"> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].codigoRelevancia" value="2" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].codigoTipoEquipamento" value="'+tipoEquipCode+'" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].principalUsuario" class="ep-device'+idx+'-acessorio-principalUsuario" value="1" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].valorLimiteCobertura" value="" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].diaNascimentoUsuario" class="ep-device'+idx+'-acessorio-diaNascimentoUsuario" value="" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].mesNascimentoUsuario" class="ep-device'+idx+'-acessorio-mesNascimentoUsuario" value="" /> \
									<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].anoNascimentoUsuario" class="ep-device'+idx+'-acessorio-anoNascimentoUsuario" value="" /> \
									<div class="ep-row"> \
										<div class="ep-mod10 ep-sm-mod11"> \
											<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].descricaoEquipamento" value="" /> \
											<label class="ep-select"> \
												<select name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].codigoEquipamento" \
													class=" ep-setValid ep-showHide ep-sm-select-small ep-getOptionText ep-acessorio-codigoEquipamento" \
													data-showhide="#ep-device'+idx+'-acessorios{i}-data,#ep-device'+idx+'-acessorios-duplicateAction'+(type == "camera" || type == "videocamera" ? ';391;#ep-device'+idx+'-acessorios{i}-marcaModelo;#ep-device'+idx+'-acessorios{i}-modelo' : '')+'" \
													data-getoptiontext="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].descricaoEquipamento\']" \
													data-setvalid="{default}==false:input[name=\'equipamento_'+idx+'_valorAcessorio_{i}\'],input[name=\'aparelhos['+idx+'].acessorios[{i}].dataNF\'];{default}!=true:input[name=\'equipamento_'+idx+'_valorAcessorio_{i}\'],input[name=\'aparelhos['+idx+'].acessorios[{i}].dataNF\']'+(type == "camera" || type == "videocamera" ? ';391==true:input[name=\'aparelhos['+idx+'].acessorios[{i}].modeloSingle\'],select[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].marcaEquipamento\'];391!=false:input[name=\'aparelhos['+idx+'].acessorios[{i}].modeloLente\'],select[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].marcaEquipamento\']' : '')+'"> \
													'+vdEp.AccessoriesByType(type)+' \
												</select> \
											</label> \
										</div> \
										<div class="ep-mod2 ep-center ep-sm-mod1" style="padding: 0;"> \
											<a href="javascript:;" class="ep-duplicateEraser" data-duplicateerase="#ep-device'+idx+'-acessorios-duplicateAction;#ep-device'+idx+'-acessorios{i}"><span class="ep-icon ep-36 ep-close-gray-36"></span></a> \
										</div> \
									</div> \
									<div class="ep-row" id="ep-device'+idx+'-acessorios{i}-data" style="display: none;">';
				if(type == "camera" || type == "videocamera") {
					ctt += '			<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].acessoriodescricaoModelo" value="" /> \
										<div class="ep-row" id="ep-device'+idx+'-acessorios{i}-modelo" style="margin-left: 0;margin-right: 0;"> \
											<div class="ep-mod10"> \
												<input type="text" name="aparelhos['+idx+'].acessorios[{i}].modeloSingle" class="ep-form-control ep-setValue" placeholder="Modelo" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].acessoriodescricaoModelo\']=={value}" /> \
											</div> \
											<div class="clearfix"></div> \
										</div> \
										<div class="ep-row" id="ep-device'+idx+'-acessorios{i}-marcaModelo" style="margin-left: 0;margin-right: 0;"> \
											<div class="ep-mod4"> \
												<label class="ep-select"> \
													<select name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].marcaEquipamento" class="ep-sm-select-small"> \
														<option value="">Marca</option> \
														'+vdEp.BrandListByType("lens")+' \
													</select> \
												</label> \
											</div> \
											<div class="ep-mod6" style="padding-left: 0"> \
												<input type="text" name="aparelhos['+idx+'].acessorios[{i}].modeloLente" class="ep-form-control ep-setValue" placeholder="Modelo" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].acessoriodescricaoModelo\']=={value}" /> \
											</div> \
											<div class="clearfix"></div> \
										</div>\
										<div class="clearfix"></div>';
				}
				ctt +=	'				<div class="ep-mod4 ep-sm-mod11"> \
											<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].valorNotaFiscal" value=""  class="ep-calculateItensLimit" /> \
											<span class="tooltip">\
												<input type="tel" name="equipamento_'+idx+'_valorAcessorio_{i}" class="ep-form-control ep-currency ep-setCurrencyLimit ep-setValue" placeholder="Valor de aquisi&ccedil;&atilde;o" data-currencylimit="'+(type == "camera" || type == "videocamera" ? vdEp.DeviceValueLimits.Cameras.Accessory.max+';'+vdEp.DeviceValueLimits.Cameras.Accessory.min : vdEp.DeviceValueLimits.Default.Accessory.max+';'+vdEp.DeviceValueLimits.Default.Accessory.min)+'" data-setvalue="input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].valorNotaFiscal\']=={value}" data-errorshowinfo="#ep-device'+idx+'-acessorios{i}-valorError" /> \
												<strong>Informe o valor do equipamento que ser&aacute; segurado e que consta na nota fiscal, recibo de compra e venda, invoice, declara&ccedil;&atilde;o de importa&ccedil;&atilde;o ou cupom fiscal.</strong>\
											</span>\
											<small class="ep-lbl-error" style="display: none;" id="ep-device'+idx+'-acessorios{i}-valorError">O valor m&iacute;nimo permitido &eacute; de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Accessory.min) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Accessory.min))+'. O m&aacute;ximo &eacute; de '+(type == "camera" || type == "videocamera" ? vdEp.FormatCurrency(vdEp.DeviceValueLimits.Cameras.Accessory.max) : vdEp.FormatCurrency(vdEp.DeviceValueLimits.Default.Accessory.max))+'</small>\
										</div> \
										<div class="ep-mod2 ep-right ep-sm-hide" style="padding-left: 0"> \
											<label class="ep-lbl">Data de aquisi&ccedil;&atilde;o:</label> \
										</div> \
										<div class="ep-mod4 ep-sm-mod11"> \
											<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].diaNotaFiscal" value="" /> \
											<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].mesNotaFiscal" value="" /> \
											<input type="hidden" name="calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].anoNotaFiscal" value="" /> \
											<span class="tooltip">\
												<input type="tel" name="aparelhos['+idx+'].acessorios[{i}].dataNF" class="ep-form-control ep-dateMask ep-split'+(vdEp.IsMobile ? 'ep-form-control-join' : '')+' ep-valDate" data-validdate="after;'+nfMinMonth+'" data-split="/;input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].diaNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].mesNotaFiscal\'];input[name=\'calculo.equipamentosPortateisItemCalculo['+idx+'].acessorioEquipamento[{i}].anoNotaFiscal\']" placeholder="'+(vdEp.IsMobile ? 'Data da nota fiscal' : 'dd/mm/aaaa')+'" data-errorshowinfo="#ep-device'+idx+'-acessorios{i}-dateError" /> \
												<strong>Informe a data de aquisi&ccedil;&atilde;o/compra do equipamento.</strong>\
											</span>\
											<small class="ep-lbl-error" style="display: none;" id="ep-device'+idx+'-acessorios{i}-dateError">Equipamento com idade inv&aacute;lida, s&atilde;o permitidas datas menores que hoje e superiores ao permitido ('+nfMinMonth+' meses).</small>\
										</div> \
									</div> \
									<div class="ep-row-divisor ep-mod12"></div> \
								</div> \
							</div> \
						</div> \
					</div> \
					<div class="ep-row" '+(!(currentIdx == 1 && typeTotal > 1) ? 'style="display: none;"' : '')+'> \
						<div class="ep-lspan3 ep-mod6 ep-subForm ep-subForm-single"> \
							<input type="checkbox" name="duplicateInfo" id="ep-form-duplicateInfo-'+idx+'" class="ep-checkbox ep-duplicateFormData" data-duplicatetype="'+type+'" value="true" /> \
							<label class="ep-checkbox" for="ep-form-duplicateInfo-'+idx+'"> \
								<span class="ep-icon ep-24 ep-checkbox-icon"></span> \
								Replicar as informa&ccedil;&otilde;es para '+(type == "videocamera" ? 'as pr&oacute;ximas '+(typeTotal-1)+' filmadoras' : (type == "camera" ? 'as pr&oacute;ximas '+(typeTotal-1)+' c&acirc;meras' : 'os pr&oacute;ximos '+(typeTotal-1)+' '+type+'s'))+' \
							</label> \
						</div> \
					</div> \
				';
			}

			ctt += '<div class="ep-accordion-submitRow">'+vdEp.BuildDeviceAccordionItemButtons(idx,total)+'</div>';

		return ctt;
	},
	BuildDeviceAccordionItem: function(type,currentIdx,typeTotal,idx,total) {
		var ctt = '',
			str = 'Notebook';

		if(type == "smartphone")
			str = "Smartphone";
		else if(type == "tablet")
			str = "Tablet";
		else if(type == "camera")
			str = "C&acirc;mera";
		else if(type == "videocamera")
			str = "Filmadora";
		else if(type == "smartwatch")
			str = "Smartwatch";

		ctt += '<div class="ep-form-accordion '+(idx > 0 ? 'ep-accordion-closed' : '')+' ep-device-accordion '+(total == 1 ? 'ep-device-accordion-single' : '')+'" id="ep-accordion-device'+idx+'" data-type="'+type+'"> \
					<a href="javascript:;" class="ep-remove-accordion-item ep-showModal" data-modalref="#ModalAccordionRemove" title="Remover item" '+(total == 1 ? 'style="display: none;"' : '')+'><span class="ep-icon ep-36 ep-removeItem-36"></span></a> \
					<a href="javascript:;" class="ep-accordion-header ep-device-'+type+currentIdx+'" id="ep-device'+idx+'-header"> \
						<span class="ep-icon ep-36 ep-'+type+'-gray-36"></span> \
						<strong>'+str+' '+(total > 1 ? currentIdx : '')+'</strong> \
					</a> \
					<div class="ep-accordion-container" '+(idx > 0 ? 'style="display: none;"' : '')+'> \
						'+vdEp.BuildDeviceForm(type,currentIdx,typeTotal,idx,total)+' \
					</div> \
				</div>';

		return ctt;
	},
	BuildDeviceAccordionItemButtons: function(idx,total) {
		var ctt = "";

		if(idx == 0 && total > 1) {
			ctt += '<div class="ep-row ep-submitRow"> \
						<div class="ep-mod4 ep-lspan4 ep-sm-mod6 ep-sm-lspan3"> \
							<a href="javascript:;" class="ep-btn ep-nextAccordion" data-nextaccordion="#ep-device'+(idx+1)+'-header">PR&Oacute;XIMO APARELHO</a> \
						</div>\
					</div>';
		} else if(idx < (total-1)) {
			ctt += '<div class="ep-row ep-submitRow"> \
						<div class="ep-mod3 ep-sm-mod6"> \
							'+(idx > 0 ? '<a href="javascript:;" class="ep-btn ep-gray ep-trigger" data-trigger="#ep-device'+(idx-1)+'-header">APARELHO ANTERIOR</a>' : '&#160;')+' \
						</div> \
						<div class="ep-mod3 ep-lspan6 ep-sm-mod6"> \
							'+(idx < total ? '<a href="javascript:;" class="ep-btn ep-nextAccordion" data-nextaccordion="#ep-device'+(idx+1)+'-header">PR&Oacute;XIMO APARELHO</a>' : '&#160;')+' \
						</div>\
					</div>';
		}

		return ctt;
	},
	RemoveAccordionDevice: function(id,container) {
		$("#"+id).fadeOut(1000,function() {
			$(this).remove();
			
			vdEp.UpdateAccordionDeviceList(container);
		});
	},
	UpdateAccordionDeviceList: function(container) {
		var list = $(container).find(".ep-device-accordion"),
			config = {
				"notebookQtt": 0,
				"smartphoneQtt": 0,
				"tabletQtt": 0,
				"cameraQtt": 0,
				"videocameraQtt": 0,
				"smartwatchQtt": 0,
				"deviceCount": list.length
			},
			idx = 0,
			toBind = $(".ep-calc-form .ep-deviceList, #ModalDeviceList .ep-deviceList, .ep-calc-form .ep-deviceListBig");

		list.each(function() {
			var newId = "ep-accordion-device"+idx,
				newHeaderId = "ep-device"+idx+"-header",
				type = $(this).data("type"),
				submitRow = $(this).find(".ep-accordion-submitRow"),
				ipt = $(this).find("input[name^='calculo.equipamentosPortateisItemCalculo'],input[name^='aparelhos'],select[name^='calculo.equipamentosPortateisItemCalculo'],select[name^='aparelhos']"),
				div = $(this).find("div[id^='ep-device'], a.ep-btn, a.ep-duplicateEraser"),
				lbl = $(this).find("label[for^='ep-device']");

			$(this).attr("id",newId);
			$(this).find(".ep-accordion-header").attr("id",newHeaderId);

			if(type == "notebook") {
				config.notebookQtt++;
				$(this).find(".ep-accordion-header strong").html("Notebook "+config.notebookQtt);
			} else if(type == "smartphone") {
				config.smartphoneQtt++;
				$(this).find(".ep-accordion-header strong").html("Smartphone "+config.smartphoneQtt);
			} else if(type == "tablet") {
				config.tabletQtt++;
				$(this).find(".ep-accordion-header strong").html("Tablet "+config.tabletQtt);
			} else if(type == "camera") {
				config.cameraQtt++;
				$(this).find(".ep-accordion-header strong").html("C&acirc;mera "+config.cameraQtt);
			} else if(type == "videocamera") {
				config.videocameraQtt++;
				$(this).find(".ep-accordion-header strong").html("Filmadora "+config.videocameraQtt);
			} else if(type == "smartwatch") {
				config.smartwatchQtt++;
				$(this).find(".ep-accordion-header strong").html("Smartwatch "+config.smartwatchQtt);
			}

			ipt.each(function() {
				var t = $(this),
					n = t.attr("name"),
					d = t.data(),
					id = t.attr("id");

				n = n.replace(/equipamentosPortateisItemCalculo\[\d\]/g,"equipamentosPortateisItemCalculo["+idx+"]").replace(/aparelhos\[\d\]/g,"aparelhos["+idx+"]").replace(/device\d/g,"device"+idx);
				if(typeof id != "undefined") {
					id = id.replace(/device\d/g,"device"+idx);
					t.attr("id",id);
				}

				t.attr("name",n);
				
				$.each(d,function(i,e) {
					if(typeof e == "string") {
						var ne = e.replace(/equipamentosPortateisItemCalculo\[\d\]/g,"equipamentosPortateisItemCalculo["+idx+"]").replace(/aparelhos\[\d\]/g,"aparelhos["+idx+"]").replace(/device\d/g,"device"+idx);
						t.data(i,ne);
					}				
				});
			});

			div.each(function() {
				var t = $(this),
					d = t.data(),
					id = t.attr("id");

				if(typeof id != "undefined") {
					id = id.replace(/device\d/g,"device"+idx);
					t.attr("id",id);
				}

				$.each(d,function(i,e) {
					if(typeof e == "string") {
						var ne = e.replace(/equipamentosPortateisItemCalculo\[\d\]/g,"equipamentosPortateisItemCalculo["+idx+"]").replace(/aparelhos\[\d\]/g,"aparelhos["+idx+"]").replace(/device\d/g,"device"+idx);
						t.data(i,ne);
						if(t.is(".ep-duplicateEraser"))
							t.attr("data-"+i,ne);
					}				
				});

				if(t.is(".ep-field-smartphone-acessorios"))
					t.data("idx",idx);
			});

			lbl.each(function() {
				var t = $(this),
					f = t.attr("for");

				if(typeof f != "undefined") {
					f = f.replace(/device\d/g,"device"+idx);
					t.attr("for",f);
				}
			});

			submitRow.html(vdEp.BuildDeviceAccordionItemButtons(idx,config.deviceCount));
			if(config.deviceCount == 1)
				$(this).addClass("ep-device-accordion-single").find(".ep-remove-accordion-item").hide();

			vdEp.FormBindings(submitRow);

			idx++;
		});
		
		vdEp.ConfigDeviceMenu(config);
	},
	CheckoutSubmit: function(frm) {
		var rtn = true;
		if(!vdEp.ValidateForm(frm)) {
			rtn = false;
			var p = $(".ep-error:eq(0)").offset();
			$("html,body").animate({
				scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
			},300);
		} else {
			$(".ep-modal-loading").fadeIn("fast");
		}

		return rtn;
	},
	Checkout4Submit: function(frm) {
		if(!vdEp.ValidateForm(frm)) {
			var p = $(".ep-error:eq(0)").offset();
			$("html,body").animate({
				scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
			},300);
		} else if(vdEp.ValidateForm(frm)) {
			// precisa validar a data
			vdEp.validadorParcelamento(frm);
		}

		return false;
	},
	CheckoutSubmitStep2: function(obj) {
		var p = {
			"top": 0
		};
		if(vdEp.ValidateForm(obj)) {
			var r = true;
			
			$(".ep-form-codProf, .ep-form-codActivity",obj).each(function() {
				if(this.value == 0) {
					var pval = $(this).parent().parent().find(".ep-profession-validate");
					pval.show();

					p = pval.offset();
					if(r == true)
						r = false;
				}
			});

			if(r == true) {
				$("input:disabled,select:disabled",obj).prop("disabled",false);
				$(".ep-modal-loading").show();
				return true;
			}
		} 

		if(p.top == 0) 
			p = $(obj).find(".ep-error:eq(0)").offset();
		

		$("html,body").animate({
			scrollTop: (p.top-30)
		},300);

		return false;
	},
	DeviceValidateSubmit: function(frm) {
		var rtn = true,
			accordion = $(".ep-form-accordion");

		accordion.each(function() {
			if($(".ep-smartphoneValidate",this).length > 0) {
				if($(".ep-smartphoneValidate",this).data("validated") == false) {
					rtn = false;
					$(this).removeClass("ep-accordion-closed");
					$(".ep-accordion-container",this).show();
					$(".ep-smartphoneValidate",this).addClass("ep-error");
				}
			}
		});

		if(!vdEp.ValidateForm(frm,false)) {
			rtn = false;
			$(".ep-form-accordion").each(function() {
				var hasError = $(this).find(".ep-error");

				if(hasError.length > 0) {
					$(this).removeClass("ep-accordion-closed");
					$(this).find(".ep-accordion-container").show();
				}
			});

			var p = $(".ep-error:eq(0)").offset();
			$("html,body").animate({
				scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
			},300);
		} else {
			$(".ep-modal-loading").fadeIn("fast");
		}

		return rtn;
	},
	CalcSubmit: function(frm) {
		var rtn = true;

		if(!vdEp.ValidateForm(frm)) {
			rtn = false;
			$(".ep-form-accordion").each(function() {
				var hasError = $(this).find(".ep-error");

				if(hasError.length > 0) {
					$(this).removeClass("ep-accordion-closed");
					$(this).find(".ep-accordion-container").show();
				} 
			});

			var p = $(".ep-error:eq(0)").offset();
			$("html,body").animate({
				scrollTop: (p.top-(vdEp.IsMobile ? 30 : 100))
			},300);
		} else {
			var i = 0;

			$(".ep-accordion-container",frm).each(function() {
				var qtdeEquip = 0;
				$(".ep-acessorio-codigoEquipamento",this).each(function() {
					if($(this).val() != "") qtdeEquip++;
				});
				$("input[name='calculo.equipamentosPortateisItemCalculo["+i+"].quantidadeAcessorios']").val(qtdeEquip);
				$("#ep-device"+i+"-acessorios-matrix").remove();

				if($("input[name='calculo.equipamentosPortateisItemCalculo["+i+"].principalUsuario']").length > 0) {
					var principalUsuario = $("input['calculo.equipamentosPortateisItemCalculo["+i+"].principalUsuario']").val(),
						diaNasc = $("input[name='calculo.equipamentosPortateisItemCalculo["+i+"].diaNascimentoUsuario']").val(),
						mesNasc = $("input[name='calculo.equipamentosPortateisItemCalculo["+i+"].mesNascimentoUsuario']").val(),
						anoNasc = $("input[name='calculo.equipamentosPortateisItemCalculo["+i+"].anoNascimentoUsuario']").val();

					$(".ep-device"+i+"-acessorio-principalUsuario").val(principalUsuario);
					$(".ep-device"+i+"-acessorio-diaNascimentoUsuario").val(diaNasc);
					$(".ep-device"+i+"-acessorio-mesNascimentoUsuario").val(mesNasc);
					$(".ep-device"+i+"-acessorio-anoNascimentoUsuario").val(anoNasc);
				}
				i++;
			});
			//$(".ep-modal-loading").fadeIn("fast");
		} 

		return rtn; 
	},
	CalcDeviceValidate: function(frm) {
		frm = $(frm);

		//não existe mais a consulta de depreciação do item
		/*if(!vdEp.calculateItensLimit())
			return false;

		if(!vdEp.CalcSubmit(frm))
			return false;

		vdEp.CalcVerify(frm);*/

		if(vdEp.CalcSubmit(frm)) {
			$(".ep-modal-loading").fadeIn("fast");
			
			//função LGPD para remover os disabled dos campos
			checkDisable();
			frm.submit();
		} else {
			$(".ep-modal-loading").fadeOut("fast");
		}
	},
	/*CalcVerify: function(frm) {
		var submitData = frm.serialize();

		$.ajax({ 
			url: vdEpSkin.XHR_Actions.CalcDeviceValidation, 
			type: "POST", 
			data: submitData, 
			beforeSend: function(e) {
				$(".ep-modal-loading").fadeIn("fast");
			},
			error: function(e){ 
				$(".ep-modal-loading").fadeOut("fast");
				vdEp.Modal("#ModalErroVerificacao");
				console.warn("Erro 302: Ocorreu um erro ao processar valida&ccedil;&atilde;o dos dispositivos");
			}, 
			success: function(d) { 
				if(vdEp.ProccessVerify(frm,d)) { 
					window.setTimeout(function() {
						//console.log("submit");
						frm.submit();
					},500); 
				} 
			} 
		});
	},
	ProccessVerify: function(frm,d) {
		var rtn = true,
			msgCriticas = "",
			equipRoot = d.equipamentosPortateisItemCalculo,
			equipTotal = equipRoot.length,
			equipErrorCount = 0,
			equipErrorMsg = "";

		$(".ep-proccessVerify").remove();
		
		if(equipTotal > 0) {
			for(var e=0;e<equipTotal;e++) {
				var cEquip = equipRoot[e],
					cEquipLmi = cEquip.valorLimiteCobertura,
					cEquipAccessory = cEquip.acessorioEquipamento,
					cEquipCritica = cEquip.mensagensCritica,
					cEquipAccordion = $(".ep-form-accordion:eq("+e+")",frm),
					cEquipAccordionContent = cEquipAccordion.find(".ep-accordion-container");

				if(typeof cEquipCritica !== "undefined" && cEquipCritica != "") {
					var cEquipMessage = '<div class="ep-mod12 ep-proccessVerify ep-panel-error">'+cEquipCritica+'</div>';

					cEquipAccordion.removeClass("ep-accordion-closed");
					cEquipAccordionContent.show().prepend(cEquipMessage);

					equipErrorCount++;
					rtn = false;
				} else {
					$("input[name='calculo.equipamentosPortateisItemCalculo["+e+"].valorLimiteCobertura']",frm).val(cEquipLmi);
				}

				for(var a=0;a<cEquipAccessory.length;a++) {
					var cAccessory = cEquipAccessory[a],
						cAccessoryLmi = cAccessory.valorLimiteCobertura,
						cAccessoryCritica = cAccessory.mensagensCritica;

					if(typeof cAccessoryCritica !== "undefined" && cAccessoryCritica != "") {
						var cAccessoryMessage = '<div class="ep-row ep-proccessVerify">\
													<div class="ep-mod10 ep-sm-mod11"><div class="ep-panel-error">'+cAccessoryCritica+'</div></div>\
												</div>';

						cEquipAccordion.removeClass("ep-accordion-closed");
						cEquipAccordionContent.show();

						$("#ep-device"+e+"-acessorios"+a+"-data").before(cAccessoryMessage);

						equipErrorCount++;
						rtn = false;
					} else {
						if(typeof cAccessoryLmi !== "undefined") {
							$("input[name='calculo.equipamentosPortateisItemCalculo["+e+"].acessorioEquipamento["+a+"].valorLimiteCobertura']",frm).val(cAccessoryLmi);
						}
					}
				}
			}
		}

		if(!rtn) {
			$(".ep-modal-loading").fadeOut("fast");

			if(equipErrorCount > 1)
				equipErrorMsg = 'Encontramos <span class="ep-count">'+equipErrorCount+'</span> equipamentos que est&atilde;o fora dos padrões de aceita&ccedil;&atilde;o da Porto Seguro.';
			else
				equipErrorMsg = 'Encontramos <span class="ep-count">'+equipErrorCount+'</span> equipamento que est&aacute; fora dos padrões de aceita&ccedil;&atilde;o da Porto Seguro.';

			$("#ModalErroCriticas").find("p").html(equipErrorMsg);

			vdEp.Modal("#ModalErroCriticas");

			$("#ModalErroCriticas .ep-modalClose").off("click.verify").on("click.verify",function() {
				var p = $(".ep-proccessVerify:eq(0)").offset();
				$("html,body").animate({
					scrollTop: p.top-100
				},500);
			});
		}

		return rtn;
	},*/
	BrandListByType: function(type) {
		var ctt = "";
 
		if(type == "notebook") {
			var opts = $(".marcas_notebook select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "smartphone") {
			var opts = $(".marcas_smartphone select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "smartphone|apple") {
			var opts = $(".marcas_smartphone select").find("option");
			opts.each(function() {
				var t = $(this);
				if(t.text() == "APPLE") {
					ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
				}
			});
		} else if(type == "smartphone|android") {
			var opts = $(".marcas_smartphone select").find("option");
			opts.each(function() {
				var t = $(this);
				if(t.text() != "APPLE") {
					ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
				}
			});
		} else if(type == "smartphone|wp") {
			var opts = $(".marcas_smartphone select").find("option");
			opts.each(function() {
				var t = $(this);
				if(t.text() == "MICROSOFT" || t.text() == "HTC" || t.text() == "NOKIA") {
					ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
				}
			});
		} else if(type == "smartphone|other") {
			var opts = $(".marcas_smartphone select").find("option");
			opts.each(function() {
				var t = $(this);
				if(t.text() == "OUTRAS MARCAS") {
					ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
				}
			});
		} else if(type == "smartwatch") {
			var opts = $(".marcas_smartwatch select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "tablet") {
			var opts = $(".marcas_tablet select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "camera") {
			var opts = $(".marcas_camera select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "videocamera") {
			var opts = $(".marcas_filmadora select").find("option");
			opts.each(function() {
				var t = $(this);
				ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
			});
		} else if(type == "lens") {
			var opts = $(".marcas_lente select").find("option");
			opts.each(function() {
				var t = $(this);
				if(t.text() != "Selecione") {
					ctt += '<option value="'+t.val()+'">'+t.text()+'</option>';
				}
			});
		}

		return ctt;
	},
	GetDeviceBrands: function(type) {

	    var brands = [],
	        originalBrands = null,
	        brandsOrder = null,
	        otherBrandsStartCounter = null,
	        othersValue = null;

	    if(type == "notebook") {
	        originalBrands = $(".marcas_notebook select").find("option");
	        brandsOrder = ["ACER","APPLE","DELL","LG","POSITIVO","SONY"];

	    } else if(type == "smartphone") {
	        originalBrands = $(".marcas_smartphone select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	        
	    } else if(type == "smartphone|apple") {
	        originalBrands = $(".marcas_smartphone select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	        
	    } else if(type == "smartphone|android") {
	        originalBrands = $(".marcas_smartphone select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	       
	    } else if(type == "smartphone|wp") {
	        originalBrands = $(".marcas_smartphone select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	        
	    } else if(type == "smartphone|other") {
	        originalBrands = $(".marcas_smartphone select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	    
	    } else if(type == "smartwatch") {
	        originalBrands = $(".marcas_smartwatch select").find("option");
	        brandsOrder = ["APPLE","ASUS","MOTOROLA","LG","SAMSUNG","XIAOMI"];
	    
	    } else if(type == "tablet") {
	        originalBrands = $(".marcas_tablet select").find("option");
	        brandsOrder = ["APPLE","ASUS","SAMSUNG","POSITIVO","XIAOMI"];
	    
	    } else if(type == "camera") {
	        originalBrands = $(".marcas_camera select").find("option");
	        brandsOrder = ["CANON","GOPRO","JVC","KODAK","SONY"];
	        
	    } else if(type == "videocamera") {
	        originalBrands = $(".marcas_filmadora select").find("option");
	        brandsOrder = ["CANON","GOPRO","JVC","KODAK","SONY"];
	    
	    } else if(type == "lens") {
	        originalBrands = $(".marcas_lente select").find("option");
	        brandsOrder = ["CANON","GOPRO","JVC","KODAK","SONY"];
	        
	    }

	    otherBrandsStartCounter = brandsOrder.length;

	    originalBrands.each(function() {
	        if($(this).val() == "") return;
	        if($(this).text() == "OUTRAS MARCAS") othersValue = $(this).val();

	        brands.push({
	            order: -1,
	            value: $(this).val(),
	            name: $(this).text()
	        });
	    });

	    // Ordenar alfabéticamente antes de inserir marcas
	    brands.sort(function(a,b) {
	        if(a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
	        if(a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
	        return 0;
	    });

	    // Adicionar marcas e definir ordem
	    var brandsWhithoutId = brandsOrder;
	    for(var x=0,l=brands.length;x<l;x++) {
	        for(var i=0;i<otherBrandsStartCounter;i++) {
	            if(brandsOrder[i] == brands[x].name) {    
	                brandsWhithoutId[i] = null;
	                brands[x].order = i;
	            }
	        }

	        if(brands[x].order == -1) {
	            if(brands[x].name == "OUTRAS MARCAS") {
	                brands[x].order = brands.length;
	            } else {
	                brands[x].order = otherBrandsStartCounter;
	                otherBrandsStartCounter++;
	            }
	        }
	    }

	    // Incluir marcas da lista com o valor Outros
	    for(var i=0,l=brandsWhithoutId.length;i<l;i++) {
	        if(brandsWhithoutId[i] == null) continue;

	        brands.push({
	            order: i,
	            value: othersValue,
	            name: brandsWhithoutId[i]
	        });
	    }

	    // Ordenar pela número de ordem
	    brands.sort(function(a,b) {
	        return a.order-b.order;
	    });

	    // Retorno
	    var str = "<option value=''>Selecione</option>";
	    for(var i=0,l=brands.length;i<l;i++) {
	        if(i == brandsOrder.length) str += "</optgroup>";

	        if(i == 0 || i == brandsOrder.length) {
	            str += '<optgroup label="'+(i == 0 ? "Mais populares" : "Demais marcas")+'">';
	        }

	        str += '<option value="'+brands[i].value+'">'+brands[i].name+'</option>';

	        if(i == brands.length-1) str += "</optgroup>";
	    }

	    return str;
	},
	AccessoriesByType: function(type) {
		var ctt = '';
		if(type == "notebook") {
			ctt += $(".acessorios_notebook select").html();
		} else if(type == "smartphone") {
			ctt += $(".acessorios_smartphone select").html();
		} else if(type == "tablet") {
			ctt += $(".acessorios_tablet select").html();
		} else if(type == "camera") {
			ctt += $(".acessorios_camera select").html();
		} else if(type == "videocamera") {
			ctt += $(".acessorios_filmadora select").html();
		}

		return ctt;
	},
	DoCEPSearch: function(obj,src,container) {
		var v = obj.value,
			ctt = $(".ep-addressFields",container),
			load = $(".ep-cep-loading",container),
			error = $(".ep-cep-error",container);


		v = v.replace(/_/g,"");
		if(v.length == 9) {
			v = v.split("-");
			var nocache = new Date().getTime(),
				param = "dispatch=validaCep&numeroCep="+v[0]+"&complementoCep="+v[1]+"&nocache="+nocache;

			$.ajax({
				url: src,
				type: "GET",
				data: param,
				dataType: "xml",
				beforeSend: function () {
					ctt.hide();
					error.hide();
					load.show();
				}
			}).done(function(data) {
				load.hide();

				if($(data).find("valido").text() == "true") {
					if($(data).find("logradouro").length == 0) {
						ctt.find(".ep-logradouro",container).hide();
						ctt.find(".ep-fake-logradouro",container).show();
						ctt.find(".ep-fake-logradouro .ep-logradouro",container).show();
						ctt.find(".ep-form-tipoLogradouro",container).val("RUA");
					} else {
						ctt.find(".ep-fake-logradouro",container).hide();
						ctt.find(".ep-logradouro",container).show();
						ctt.find(".ep-form-tipoLogradouro",container).val($(data).find("tipoLogradouro").text());
					}
					ctt.find(".ep-logradouro",container).html(($(data).find("endereco").text() != "" ? $(data).find("endereco").text() : $(data).find("cidade").text()+'/'+$(data).find("codigoUF").text()));
					ctt.find(".ep-form-nomeLogradouro",container).val($(data).find("logradouro").text());
					ctt.find(".ep-form-nomeBairro",container).val($(data).find("bairro").text());
					ctt.find(".ep-form-nomeCidade",container).val($(data).find("cidade").text());
					ctt.find(".ep-form-codigoUF",container).val($(data).find("codigoUF").text());

					ctt.slideDown("fast",function() {
						$(this).find(".ep-form-control").eq(0).focus();
					});
				} else {
					ctt.find(".ep-form-tipoLogradouro",container).val("");
					ctt.find(".ep-form-nomeLogradouro",container).val("");
					ctt.find(".ep-form-nomeBairro",container).val("");
					ctt.find(".ep-form-nomeCidade",container).val("");
					ctt.find(".ep-form-codigoUF",container).val("");
					error.fadeIn("fast");
				}
			}).error(function(data) {
				load.hide();
				ctt.find(".ep-form-tipoLogradouro",container).val("");
				ctt.find(".ep-form-nomeLogradouro",container).val("");
				ctt.find(".ep-form-nomeBairro",container).val("");
				ctt.find(".ep-form-nomeCidade",container).val("");
				ctt.find(".ep-form-codigoUF",container).val("");
				error.fadeIn("fast");
				console.warn("Erro 301: Ocorreu um erro ao transmitir os dados para a busca");
			});
		}
	},
	DoAddressFind: function(e) {
		e.preventDefault();

		if(vdEp.ValidateForm(this)) {
			$("input:text",this).trigger("blur");

			var action = $(this).attr("action"),
				param = $(this).serialize(),
				load = $("#ep-buscacep-loading"),
				error = $("#ep-buscacep-error"),
				ctt = $("#ep-buscacep-ctt"),
				nocache = new Date().getTime(),
				uf = $(this).find("select[name='unidadeFederacao']").val(),
				dest = typeof $(this).data("destination") !== "undefined" ? $(this).data("destination") : "#ep-addressData";

			param = param + "&nocache="+nocache;


			$.ajax({
				url: action,
				type: "GET",
				data: param,
				dataType: "html",
				beforeSend: function () {
					ctt.hide();
					error.hide();
					load.show();
				}
			}).done(function(data) {
				load.hide();
				ctt.html(data).slideDown("fast");
				ctt.find("li").on("click",function() {
					$(dest+" .ep-form-cep1").val($(this).find(".numeroCepPesq").text());
					$(dest+" .ep-form-cep2").val($(this).find(".complementoCepPesq").text());
					$(dest+" input[name='cep']").val($.trim($(this).find(".cep .numeroCepPesq").text())+$(this).find(".cep .complementoCepPesq").text());
					
					var ctt = "";
					if($(this).find(".logradouroPesq").text() == "") {
						$(dest+" .ep-logradouro").hide();
						$(dest+" .ep-fake-logradouro").show();
						$(dest+" .ep-fake-logradouro .ep-logradouro").show();
						$(dest+" .ep-form-tipoLogradouro").val("RUA");
						ctt = $(this).find(".cidadePesq").text()+" - "+uf;
					} else {
						$(dest+" .ep-fake-logradouro").hide();
						$(dest+" .ep-logradouro").show();
						$(dest+" .ep-form-tipoLogradouro").val($(this).find(".tipoLogradouroPesq").text());
						$(dest+" input[name='cep']").trigger("blur")
						ctt = $(this).find(".tipoLogradouroPesq").text()+" "+$(this).find(".logradouroPesq").text()+" - "+$(this).find(".cidadePesq").text()+" - "+uf;
					}
					$(dest+" .ep-form-nomeLogradouro").val($(this).find(".logradouroPesq").text());
					$(dest+" .ep-form-nomeBairro").val($(this).find(".bairroPesq").text());
					$(dest+" .ep-form-nomeCidade").val($(this).find(".cidadePesq").text());
					$(dest+" .ep-form-codigoUF").val(uf);

					$(dest+" .ep-logradouro").html(ctt);

					$("#ModalCEP .ep-modalClose").trigger("click");
					$(dest+" .ep-addressFields").slideDown("fast",function() {
						$(this).find(".ep-form-control").eq(0).focus();
					});
				});
			}).error(function(data) {
				load.hide();
				error.fadeIn("fast");
				console.warn("Erro 301: Ocorreu um erro ao transmitir os dados para a busca");
			});
		}
	},
	DoAgentFind: function(e) {
		e.preventDefault();

		if(vdEp.ValidateForm(this)) {
			$("input:text",this).trigger("blur");

			var action = $(this).attr("action"),
				param = $(this).serialize(),
				load = $("#ep-buscaCorretor-loading"),
				error = $("#ep-buscaCorretor-error"),
				ctt = $("#ep-buscaCorretor-ctt"),
				nocache = new Date().getTime();

			param = param + "&nocache="+nocache;


			$.ajax({
				url: action,
				type: "GET",
				data: param,
				dataType: "xml",
				beforeSend: function () {
					ctt.hide();
					error.hide();
					load.show();
				}
			}).done(function(d) {
				load.hide();
				var generateHTML = "<ul>";
			
				$(d).find("corretor").each(function() {
					var nomeCorretor = $(this).find("nomeCorretor").text(),
						endereco = $(this).find("nomeLogradouroEnderecoCorretor").text()+', '+$(this).find("numeroImovelLogradouroEnderecoCorretor").text()+' - '+$(this).find("nomeBairroEnderecoCorretor").text()+' - '+$(this).find("nomeCidadeEnderecoCorretor").text()+'/'+$(this).find("siglaUfEnderecoCorretor").text();
						susep = $(this).find("susepY").text(),
						email = $(this).find("emailContatoCorretor").text(),
						telefone = '('+$(this).find("dddContatoCorretor").text()+') '+$(this).find("numeroContatoCorretor").text();

					generateHTML += '<li data-susep="'+susep+'" data-nomecorretor="'+nomeCorretor+'" data-email="'+email+'" data-telefone="'+telefone+'" data-endereco="'+endereco+'">\
										<strong>'+nomeCorretor+'</strong> - \
										'+endereco+' \
									</li>';
				});

				generateHTML += "</ul>";

				ctt.html(generateHTML).slideDown("fast");

				ctt.find("li").on("click",function() {
					$("#corretorId").html($(this).data("susep"));
					$("#corretorNome").html($(this).data("nomecorretor"));
					$("#corretorAddr").html($(this).data("endereco"));
					$("#corretorPhone").html($(this).data("telefone"));
					$("#corretorEmail").html($(this).data("email"));

					$(".ep-modalClose").trigger("click");

					param = "susepY=" + $(this).data("susep") + "&codigoAtendimento=" +dataObject.codigoAtendimento+ "&codigoProduto=2&codigoEmpresa=1",
					$.ajax({
						url: vdEpSkin.XHR_Actions.AgentSetter,
						type: "POST",
						data: param,
						dataType: "xml"
					});
				});
			}).error(function(data) {
				load.hide();
				error.fadeIn("fast");
				console.warn("Erro 301: Ocorreu um erro ao transmitir os dados para a busca");
			});
		}
	},
	DoProfessionSearch: function(obj,src,container,param) {
		var v = obj.value;
			ctt = $(".ep-profession-ctt",container),
			load = $(".ep-profession-loading",container),
			error = $(".ep-profession-error,.ep-profession-validate",container),
			setValue = $(obj).data("professionsearchcallback");

		setValue = setValue.split(";");
		
		v = vdEp.CleanupString(v);
		v = v.toUpperCase();

		if(v.length > 3) {
			var idx = $(".ep-profession-ctt").index(ctt);
			param = $(param).attr("name")+"="+$(param).val();
			param = param+"&nomeProfissao="+v;
			clearTimeout(window.t);
			ctt.hide();
			error.hide();
			load.show();
			window.t = setTimeout(function() {
				$.ajax({
					url: src,
					type: "POST",
					data: param,
					dataType: "xml",
				}).done(function(data) {
					load.hide();
					var itens = $(data).find("itens item");
					if(itens.length > 0) {
						var buf = '';
						
						$(itens).each(function() {
							buf += '<div>';
							buf += '	<input type="radio" name="profissoes" value="'+$(this).find("codigoProfissao").text()+'" class="ep-radio" id="ep-form-profissoes-'+idx+'-'+$(this).find("codigoProfissao").text()+'" />';
							buf += '	<label class="ep-radio" for="ep-form-profissoes-'+idx+'-'+$(this).find("codigoProfissao").text()+'">';
							buf += '	<span class="ep-icon ep-24 ep-radio-icon"></span> '+$(this).find("descricaoProfissao").text()+'</label>';
							buf += '</div>';
						});
						
						ctt.find(".ep-formList").html(buf);
						ctt.find(".ep-formList input:radio").on("click",function() {
							var v = $(this).val(),
								t = $.trim($(this).next("label").text());

							$(setValue[0]).val(t);
							$(setValue[1]).val(v);
							$(setValue[2]).val(t);
						});
						vdEp.OldBrowserSupport(ctt);
						ctt.children(":eq(0)").show();
						ctt.children(":eq(1)").hide();
					} else {
						ctt.children(":eq(1)").show();
						ctt.children(":eq(0)").hide();
					}

					ctt.slideDown("fast");

				}).error(function(data) {
					load.hide();
					$(".ep-profession-error",container).fadeIn("fast");
					console.warn("Erro 301: Ocorreu um erro ao transmitir os dados para a busca");
				});

			},1000);
		} else {
			load.hide();
			error.hide();
			ctt.slideUp("fast");
		}
	},
	DoBusinnessActivitiesSearch: function(obj,src,container,param) {
		var v = obj.value;
			ctt = $(".ep-profession-ctt",container),
			load = $(".ep-profession-loading",container),
			error = $(".ep-profession-error,.ep-profession-validate",container),
			setValue = $(obj).data("activitiessearchcallback");

		setValue = setValue.split(";");
		
		v = vdEp.CleanupString(v);
		v = v.toUpperCase();

		if(v.length > 3) {
			var idx = $(".ep-profession-ctt").index(ctt);
			param = "codigoParametroSistema="+param;
			param = param+"&nomeAtividadeEconomica="+v;
			clearTimeout(window.t);
			ctt.hide();
			error.hide();
			load.show();
			window.t = setTimeout(function() {
				$.ajax({
					url: src,
					type: "POST",
					data: param,
					dataType: "xml",
				}).done(function(data) {
					load.hide();
					var itens = $(data).find("dominio");
					if(itens.length > 0) {
						var buf = '';
						
						$(itens).each(function() {
							buf += '<div>';
							buf += '	<input type="radio" name="atividades" value="'+$(this).find("codigo").text()+'" class="ep-radio" id="ep-form-atividades-'+idx+'-'+$(this).find("codigo").text()+'" />';
							buf += '	<label class="ep-radio" for="ep-form-atividades-'+idx+'-'+$(this).find("codigo").text()+'">';
							buf += '	<span class="ep-icon ep-24 ep-radio-icon"></span> '+$(this).find("descricao").text()+'</label>';
							buf += '</div>';
						});
						
						ctt.find(".ep-formList").html(buf);
						ctt.find(".ep-formList input:radio").on("click",function() {
							var v = $(this).val(),
								t = $.trim($(this).next("label").text());

							$(setValue[0]).val(t);
							$(setValue[1]).val(v);
							$(setValue[2]).val(t);
						});
						vdEp.OldBrowserSupport(ctt);
						ctt.children(":eq(0)").show();
						ctt.children(":eq(1)").hide();
					} else {
						ctt.children(":eq(1)").show();
						ctt.children(":eq(0)").hide();
					}

					ctt.slideDown("fast");

				}).error(function(data) {
					load.hide();
					error.fadeIn("fast");
					console.warn("Erro 301: Ocorreu um erro ao transmitir os dados para a busca");
				});

			},1000);
		} else {
			load.hide();
			error.hide();
			ctt.slideUp("fast");
		}
	},
	SmartPortoStepsStartup: function(device) {

		$(".ep-device"+device+"-smartphoneValidate-step").hide();
		$("#ep-device"+device+"-screen").animate({
			'background-position-x': '-144px'
		},300);

		dataLayer.push({
			'event': 'step_change_smartphone_validation',
			'step': 'Passo1'
		});

		$("#ep-device"+device+"-smartphoneValidate-step2").fadeIn("fast").find(".ep-nextStep").off("click").on("click",function() {
			$(".ep-device"+device+"-smartphoneValidate-step").hide();
			$("#ep-device"+device+"-smartphoneValidate-step2b").fadeIn("fast");
			$("#ep-device"+device+"-screen").animate({
				'background-position-x': '-288px'
			},300);

			dataLayer.push({
				'event': 'step_change_smartphone_validation',
				'step': 'Passo2'
			});
		});

		$("#ep-device"+device+"-smartphoneValidate-step2b .ep-nextStep").off("click").on("click",function() {
			var token = $("#ep-device"+device+"-validateCode"),
				codigoAtendimento = $("#ep-device"+device+"-codigoAtendimento").html(),
				numeroNotaFiscal = $("#ep-device"+device+"-nfNumero").val();

			if(token.val() == "") 
				token.addClass("ep-error");
			else 
				vdEp.SmartPortoValidate(device,codigoAtendimento,numeroNotaFiscal,token.val());

			dataLayer.push({
				'event': 'step_change_smartphone_validation',
				'step': 'Passo2b'
			});
		});

		$(".ep-wizardFinish").off("click").on("click",function() {
			var t = $(this),
				hasAcessory = t.data("hasacessory"),
				deviceIdx = t.data("deviceidx"),
				fieldIdx = t.data("fieldidx");

			if(hasAcessory) {
				fieldIdx++;				
				$("input[name='proposta.equipamentosPortateisItemCalculo["+fieldIdx+"].numeroNotaFiscal']").focus();
			} else {
				console.log($("#ep-nextAccordion-"+deviceIdx).length > 0);

				if($("#ep-nextAccordion-"+deviceIdx).length > 0)
					$("#ep-nextAccordion-"+deviceIdx).trigger("click");
				else
					$("input[type='submit'].ep-btn").trigger("click");
			}
		});
	},
	SmartPortoValidate: function(device,codigoAtendimento,numeroNotaFiscal,token) {
		var param = "dispatch=validarToken&codigoAtendimento="+codigoAtendimento+"&numeroNotaFiscal="+numeroNotaFiscal+"&token="+token,
			error = "#ModalErrorValidate",
			load = $(".ep-modal-loading"),
			src = vdEpSkin.XHR_Actions.SmartPortoValidate;

		if(numeroNotaFiscal != "") {
			$.ajax({
				url: src,
				type: "POST",
				data: param,
				dataType: "xml",
				beforeSend: function () {
					load.show();
				}
			}).done(function(data) {
				load.hide();
				if($(data).find("token").text() == "true") {
					$("#ep-device"+device+"-validateCode").removeClass("ep-error");
					$(".ep-device"+device+"-smartphoneValidate-step").hide();
					$("#ep-device"+device+"-screen").animate({
						'background-position-x': '-432px'
					},300);
					$("#ep-device"+device+"-smartphoneValidate-step3").fadeIn("fast");
					$("#ep-device"+device+"-smartphoneValidate").data("validated",true);

					dataLayer.push({
						'event': 'step_change_smartphone_validation',
						'step': 'Passo3'
					});
				} else {
					$("#ep-device"+device+"-validateCode").addClass("ep-error").parent().parent().find(".ep-fieldTooltip-error").fadeIn("fast");
				}
			}).error(function(data) {
				load.hide();
				vdEp.Modal(error);
			});
		} else {
			$("#ep-device"+device+"-validateCode").parent().parent().find(".ep-fieldTooltip-error").hide();
			$("#ep-device"+device+"-validateCode").addClass("ep-error").parent().parent().find(".ep-fieldTooltip-errorVoid").fadeIn("fast");
		}
	},
	CheckCreditCard: function(v) {
		v = v.replace(/_/g,"").replace(/ /g,"");
		var type = -1; // 0: inválido; 1: visa; 2: master; 3: diners; 4: porto; 5: elo
		
		if(v.length > 0) {
			if(vdEp.CheckCreditCardElo(v)) {
				type = 5;
			} else {
				if(v.search(/^4[0-9]{15}/) > -1) {
					type = 1;
					if(v.indexOf("412177") == 0 || // Visa Classic
						v.indexOf("415274") == 0 || // Visa Gold
						v.indexOf("415275") == 0 || // Visa Platinum
						v.indexOf("484635") == 0 || // Visa Básico
						v.indexOf("446690") == 0// Visa Infinite
					) {
						type = 4;
					}
				} else if(v.search(/^5[0-9]{15}/) > -1) { 
					type = 2;
					if(v.indexOf("553659") == 0 || // Master Classic
						v.indexOf("536380") == 0 || // Master Gold
						v.indexOf("532930") == 0 || // Master Platinum
						v.indexOf("536537") == 0 // Master Black
					) 
						type = 4;	
					
				} else if(v.search(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/) > -1)
					type = 3;
				else
					type = 0;
			}
		}

		return type;
	},
	CheckCreditCardElo: function(v) {
		var pattern = [401178,
			401179,
			438935,
			457631,
			457632,
			431274,
			451416,
			457393,
			504175,
			[506699, 506778],
			[509000, 509999],
			627780,
			636297,
			636368,
			[650031, 650033],
			[650035, 650051],
			[650405, 650439],
			[650485, 650538],
			[650541, 650598],
			[650700, 650718],
			[650720, 650727],
			[650901, 650978],
			[651652, 651679],
			[655000, 655019],
			[655021, 655058]];
			var bin = parseInt(v.substring(0,6));
			for(var i=0; i<pattern.length; i++ ){
				if(Array.isArray(pattern[i])){
					for(var j=0; j<pattern[i].length; j++ ){
						var start = pattern[i][j];
						var end = pattern[i][j+1];
						if(bin >= start && bin <= end){
							return true;
						}else{
							break;
						}
					}
				}else if(pattern[i] == bin){
					return true;
				}
			}
			return false;
	},
	ChangeCreditCard: function(obj) {
		var block = $(obj).parent().parent().parent().parent(),
			v = $(obj).val(),
			splitval = $(obj).data("split"),
			setval = $(obj).data("setvalue"),
			showhideblock = $(obj).data("showhideblock");

		if(typeof splitval == "string") {
			v = v.split("|");
			splitval = splitval.split(";");
			for(var i=0,l=splitval.length;i<l;i++) {
				$(splitval[i]).val(v[i]);
			}
		}		

		if(typeof setval == "string") {
			setval = setval.split(";");
			for(var i=0,l=setval.length;i<l;i++) {
				var buf = setval[i].split(":");
				$(buf[0]).val(buf[1]);
			}
		}

		$(".ep-card-highlight").removeClass("ep-selected");
		block.addClass("ep-selected");

		if(vdEp.IsMobile) {
			if(showhideblock !== undefined) { 
				showhideblock = showhideblock.split(";");
				showhideblock[1] = showhideblock[1].split(",");

				setTimeout(function() {
					var p = $(showhideblock[1][1]).offset();
					$("html,body").animate({
						scrollTop: p.top-150
					},300);
				},100);
			}
		}
	},
	ChangeDocumentType: function(obj) {
		var obj = $(obj),
			v = obj.val(),
			setVal = $(obj).data("changedocumenttype");

		if(v == "1") {
			$(setVal).val("1").trigger("change");
			$(setVal).prop("disabled",true).parent().addClass("ep-disabled");
		} else if(v == "3") {
			$(setVal).val("2").trigger("change");
			$(setVal).prop("disabled",true).parent().addClass("ep-disabled");
		} else {
			$(setVal).val("").trigger("change");
			$(setVal).prop("disabled",false).parent().removeClass("ep-disabled");
		}
	},
	ChangeBankFields: function(obj) {
		var bank = $(obj).val(),
			operacao=$(obj).data("changebankfields") ,
			fields = $(obj).data("changebankfields"),
			setValueAccount = function() {
				if($("#ep-cefAccount").data("operacao")==true){
					$("#ep-numeroContaDebito").val($("#ep-cefAccount").val()+$("#ep-account").val());
				}else {
					$("#ep-numeroContaDebito").val($("#ep-account").val());
				}
			}

		fields = fields.split(";");

		$(obj).off("change.operacaoCaixa").on("change.operacaoCaixa",setValueAccount)

		$(fields[0]).val("");
		$(fields[1]).val("").off("change.operacaoCaixa").on("change.operacaoCaixa",setValueAccount);
		$(fields[2]).val("").off("change.operacaoCaixa").on("change.operacaoCaixa",setValueAccount);
		$(fields[3]).val("").off("change.operacaoCaixa").on("change.operacaoCaixa",setValueAccount);

		if(bank != "104"){
			$(fields[3]).data("operacao",false);
			$(fields[0]).parent().removeClass("ep-mod3").addClass("ep-mod6");
		} else {
			$(fields[3]).data("operacao",true);
			$(fields[0]).parent().removeClass("ep-mod6").addClass("ep-mod3");
		}

		if(bank == "1") {
			$(fields[0]).attr("maxlength",5);
			$(fields[1]).attr("maxlength",8).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else if (bank == "33") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",8).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else if (bank == "104") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",6).on("blur.caixa",function() {
				var v = this.value,
					l = v.length,
					cc, ccBefore='', y = '0';
				if(v != "") {
					for(var x = 6-l; x>0; x--) {
						ccBefore = ccBefore.concat(y);
					}
					cc = ccBefore + v;
					$(this).val(cc);
				}
			});
			$(fields[2]).attr("maxlength",1);
			$(fields[3]).attr("maxlength",3);
		} else if (bank == "237") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",7).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else if (bank == "341") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",5).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else if (bank == "399") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",6).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else if (bank == "745") {
			$(fields[0]).attr("maxlength",4);
			$(fields[1]).attr("maxlength",7).off("blur.caixa");
			$(fields[2]).attr("maxlength",1);
		} else {
			$(fields[0]).attr("maxlength",7);
			$(fields[1]).attr("maxlength",9).off("blur.caixa");
			$(fields[2]).attr("maxlength",5);
		}
	},
	ShowHideCountries: function(obj) {
		var obj = $(obj),
			v = obj.val(),
			ref = $(obj).data("showhidecountries");

		ref = ref.split(";");
		if(v == "1") {
			$(ref[0]).fadeOut("fast");
		} else {
			$(ref[0]).fadeIn("fast");
		}
		$(ref[0]+","+ref[1]).val("");
	},
	FillHomeForm: function(params) {
		var defaultContainer = "#equipamentosportateisForm",
			defaults = {
				tipoProponente: $("input[name='calculo.tipoProponente']",defaultContainer).val(),
				nomeProponente: $("input[name='calculo.nomeProponente']",defaultContainer).val(),
				nascimento: $("input[name='calculo.dataNascimento']",defaultContainer).val(),
				telefone: $("input[name='calculo.dddCelular']",defaultContainer).val()+$("input[name='calculo.numeroCelular']",defaultContainer).val(),
				cep: $(".te_cep1",defaultContainer).val()+"-"+$(".te_cep2",defaultContainer).val(),
				numeroResidencial: $("input[name='calculo.numeroResidencial']",defaultContainer).val(),
				emailProponente: $("input[name='calculo.emailProponente']",defaultContainer).val()
			},
			fields = {
				tipoProponente: $(".ep-container input[name='calculo.tipoProponente'][value='"+defaults.tipoProponente+"']"),
				nomeProponente: defaults.tipoProponente == "1" ? $(".ep-container input[name='pf.nomeCompleto']") : $(".ep-container input[name='pj.nomeEmpresa']")
			};

		//console.log(defaults.tipoProponente);

		if(defaults.tipoProponente == "1")
			defaults.documento = $("input[name='calculo.numeroCpfCnpjProponente']",defaultContainer).val()+$("input[name='calculo.digitoCnpjCpfProponente']",defaultContainer).val();
		else if(defaults.tipoProponente == "2") {
			var ordem = "000"+$("input[name='calculo.ordemCnpjProponente']",defaultContainer).val();
			ordem = ordem.slice(-4);
			defaults.documento = $("input[name='calculo.numeroCpfCnpjProponente']",defaultContainer).val()+ordem+$("input[name='calculo.digitoCnpjCpfProponente']",defaultContainer).val();
		}

		if(typeof params !== "undefined" && params.obj !== "undefined") {
			if(params.obj.calculo.isObjetoClonado == true) {
				var d = params.obj.calculo;
				defaults.tipoProponente = d.tipoProponente;
				defaults.nomeProponente = d.nomeProponente;
				defaults.nascimento = d.dataNascimento;
				defaults.telefone = ""+d.dddCelular+d.numeroCelular;
				defaults.cep = ("00000"+d.numeroCepResidencial).slice(-5)+"-"+("000"+d.complementoCepResidencial).slice(-3);
				defaults.numeroResidencial = d.numeroResidencial;
				defaults.emailProponente = d.emailProponente;
				if(defaults.tipoProponente == "1")
					defaults.documento = ("000000000"+d.numeroCpfCnpjProponente).slice(-9)+("00"+d.digitoCnpjCpfProponente).slice(-2);
				else
					defaults.documento = ("00000000"+d.numeroCpfCnpjProponente).slice(-8)+("0000"+d.ordemCnpjProponente).slice(-4)+("00"+d.digitoCnpjCpfProponente).slice(-2);

				$("#ep-calculoEpForm input[name='codigoAtendimento']").val(params.obj.codigoAtendimento);
				$("#ep-calculoEpForm input[name='calculo.isObjetoClonado']").val(d.isObjetoClonado);
			}
		} 
		
		//console.log(defaults);

		if(defaults.tipoProponente == "1") {
			window.setTimeout(function() {
				$("#ep-tipoPessoaControl-PF").trigger("click");
			},100);
			$(".ep-container input[name='pf.nomeCompleto']").val(defaults.nomeProponente).trigger("blur").trigger("change");
			$(".ep-container input[name='cpfProponente']").val(defaults.documento).trigger("focus").trigger("blur").trigger("change");
			$(".ep-container input[name='calculo.dataNascimento']").val(defaults.nascimento).trigger("change");
		} else if(defaults.tipoProponente == "2") {
			window.setTimeout(function() {
				$("#ep-tipoPessoaControl-PJ").trigger("click");
			},100);
			$(".ep-container input[name='pj.nomeEmpresa']").val(defaults.nomeProponente).trigger("blur").trigger("change");
			$(".ep-container input[name='cnpjProponente']").val(defaults.documento).trigger("focus").trigger("blur").trigger("change");
		}

		$(".ep-container input[name='calculo.emailProponente']").val(defaults.emailProponente).trigger("change");
		$(".ep-container input[name='pf.foneCelular']").val(defaults.telefone).trigger("focus").trigger("blur").trigger("change");
		$(".ep-container input[name='cep']").val(defaults.cep).trigger("focus").trigger("blur").trigger("keyup").trigger("change");
		$(".ep-container input[name='calculo.numeroResidencial']").val(defaults.numeroResidencial).trigger("focus").trigger("blur").trigger("change");
	},
	Slider: function() {
		if(typeof $(this).data("sliderenabled") != "undefined") return true;

		var t = $(this),
			ctt = t.find(".ep-sliderContainer"),
			pages = t.find(".ep-sliderPage"),
			singlePage = pages.eq(0),
			totalPages = pages.length,
			cmd = t.data("slider"),
			sliderLeft, sliderRight,sliderMargin;

		ctt.attr("style","");

		var pageH = singlePage.outerHeight(),
			pageW = ctt.outerWidth();

		cmd = cmd.split(";");
		sliderLeft = $(cmd[0]);
		sliderRight = $(cmd[1]);
		sliderMargin = (pageH/2)-(sliderLeft.height()/2);

		t.height(pageH);
		ctt.width((pageW*totalPages)+20);
		ctt.height(pageH);
		pages.each(function() {
			$(this).width(pageW);
		});
		t.data({
			"page":1,
			"sliderenabled": true
		});

		if(totalPages == 1) {
			sliderLeft.hide();
			sliderRight.hide();
		} else {
			//console.log(sliderLeft);
			sliderLeft.css("margin-top",sliderMargin+"px").addClass("ep-disabled").off("click").on("click",function() {
				var tt = $(this),
					currentPage = parseInt(t.data("page")),
					newPage = currentPage-1;

				if(!$(this).is(".ep-disabled")) {
					
					ctt.stop(true,true).animate({
						left: -(pageW*(newPage-1))
					},300,function() {
						if(newPage == 1)
							tt.addClass("ep-disabled");

						sliderRight.removeClass("ep-disabled");
						t.data("page",newPage);
					});
				}
			});
			sliderRight.css("margin-top",sliderMargin+"px").off("click").on("click",function() {
				var tt = $(this),
					currentPage = parseInt(t.data("page")),
					newPage = currentPage+1;

				if(!$(this).is(".ep-disabled")) {
					//console.log(currentPage);
					ctt.stop(true,true).animate({
						left: -(pageW*currentPage)
					},300,function() {
						if(newPage == totalPages)
							tt.addClass("ep-disabled");

						sliderLeft.removeClass("ep-disabled");
						t.data("page",newPage);
					});
				}
			});
		}
	},
	Modal: function(target,callback,src) {
		$(target).show();
		setTimeout(function() {$(target).addClass("ep-visible")},100);

		if(vdEp.IsMobile) {
			var h = $(window).outerHeight();
			$(target).find(".ep-modal-content").animate({
				right: 0
			},500).height(h);
		}

		$("body").css("overflow","hidden");

		if(typeof src != "undefined") {
			$(target+" iframe[name='src']").attr("src",src);
		}

		if(typeof callback != "undefined") {
			eval(callback);
		}
	},
	FormatCurrency: function(str) {
		if(typeof str == "string") str = str.replace(/,/g,"");
		var n = str, 
			c = 2, 
			d = ",", 
			t = ".",
			m = "R$ "
			ii = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
			j = (j = ii.length) > 3 ? j % 3 : 0;

		return m + (j ? ii.substr(0, j) + t : "") + ii.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - ii).toFixed(c).slice(2) : "");
	},
	ValidateMail: function(email) { 
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	},
	ValidateCPF: function(cpf) {
		cpf = cpf.replace(/[^\d]+/g,'');

		var Soma;
		var Resto;
		Soma = 0;
		
		if (
				cpf == "00000000000" ||
				cpf == "11111111111" ||
				cpf == "22222222222" ||
				cpf == "33333333333" ||
				cpf == "44444444444" ||
				cpf == "55555555555" ||
				cpf == "66666666666" ||
				cpf == "77777777777" ||
				cpf == "88888888888" ||
				cpf == "99999999999"
			) return false;
		 
		for (i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;
		 
		if ((Resto == 10) || (Resto == 11))  Resto = 0;
		if (Resto != parseInt(cpf.substring(9, 10)) ) return false;
		 
		Soma = 0;
		for (i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;
		 
		if ((Resto == 10) || (Resto == 11))  Resto = 0;
		if (Resto != parseInt(cpf.substring(10, 11) ) ) return false;

		return true;
	},
	ValidateCNPJ: function(cnpj) {
		cnpj = cnpj.replace(/[^\d]+/g,'');
 
		if (cnpj == '') return false;
		if (cnpj.length != 14) return false;
 
		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" || 
			cnpj == "11111111111111" || 
			cnpj == "22222222222222" || 
			cnpj == "33333333333333" || 
			cnpj == "44444444444444" || 
			cnpj == "55555555555555" || 
			cnpj == "66666666666666" || 
			cnpj == "77777777777777" || 
			cnpj == "88888888888888" || 
			cnpj == "99999999999999")
		return false;

		tamanho = cnpj.length - 2
		numeros = cnpj.substring(0,tamanho);
		digitos = cnpj.substring(tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(0)) return false;
		 
		tamanho = tamanho + 1;
		numeros = cnpj.substring(0,tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		
		if (resultado != digitos.charAt(1)) return false;
		   
		return true;
	},
	AgeByDate: function(d) {
		var t = new Date(),
			d = d.split("/");

		for(var i=0,l=d.length;i<l;i++) d[i] = parseInt(d[i]);

		var dDate = new Date(d[2],d[1]-1,d[0]),
			diff = t.getFullYear() - dDate.getFullYear();

		if (t < new Date(t.getFullYear(), dDate.getMonth(), dDate.getDate())) diff--;

		return diff;
	},
	CleanupString: function(string,allowNumbers) {
		if(typeof allowNumbers == "undefined") allowNumbers = false;
		string = encodeURI(string);
		string = string.replace(/%C3%81/g, 'A'); //Á
		string = string.replace(/%C3%80/g, 'A'); //À
		string = string.replace(/%C3%82/g, 'A'); //Â
		string = string.replace(/%C3%83/g, 'A'); //Ã
		string = string.replace(/%C3%84/g, 'A'); //Ä
		string = string.replace(/%C3%89/g, 'E'); //É
		string = string.replace(/%C3%88/g, 'E'); //È
		string = string.replace(/%C3%8A/g, 'E'); //Ê
		string = string.replace(/%C3%8B/g, 'E'); //Ë
		string = string.replace(/%C3%8D/g, 'I'); //Í
		string = string.replace(/%C3%8C/g, 'I'); //Ì
		string = string.replace(/%C3%8E/g, 'I'); //Î
		string = string.replace(/%C3%8F/g, 'I'); //Ï
		string = string.replace(/%C3%93/g, 'O'); //Ó
		string = string.replace(/%C3%92/g, 'O'); //Ò
		string = string.replace(/%C3%94/g, 'O'); //Ô
		string = string.replace(/%C3%95/g, 'O'); //Õ
		string = string.replace(/%C3%96/g, 'O'); //Ö
		string = string.replace(/%C3%9A/g, 'U'); //Ú
		string = string.replace(/%C3%99/g, 'U'); //Ù
		string = string.replace(/%C3%9B/g, 'U'); //Û
		string = string.replace(/%C3%9C/g, 'U'); //Ü
		string = string.replace(/%C3%87/g, 'C'); //Ç
		string = string.replace(/\%CB%9C/g, ''); //˜
		string = string.replace(/\%C2%B4/g, ''); //´
		string = string.replace(/\%20/g, ' '); //space
		string = string.replace(/\%60/g, ''); //`
		string = string.replace(/\;/g, ''); //;
		string = string.replace(/\'/g, ''); //'
		string = string.replace(/\//g, '');
		string = string.replace(/\\/g, '');
		string = string.replace(/\|/g, '');
		string = string.replace(/\[/g, '');
		string = string.replace(/\]/g, '');
		string = string.replace(/\{/g, '');
		string = string.replace(/\}/g, '');
		string = string.replace(/\?/g, '');
		string = string.replace(/\</g, '');
		string = string.replace(/\>/g, '');
		string = string.replace(/\-/g, '');
		string = string.replace(/\+/g, '');
		string = string.replace(/\=/g, '');
		string = string.replace(/\(/g, '');
		string = string.replace(/\)/g, '');
		string = string.replace(/\!/g, '');
		string = string.replace(/\@/g, '');
		string = string.replace(/\#/g, '');
		string = string.replace(/\$/g, '');
		string = string.replace(/\%/g, '');
		string = string.replace(/\^/g, '');
		string = string.replace(/\&/g, '');
		string = string.replace(/\*/g, '');

		if(!allowNumbers)
			string = string.replace(/\d/g, '');

		return string;
	},
	ValidateForm: function(container,visible) {
		if(typeof visible === "undefined") visible = true;
		var entryStr = "input[type='text']:visible,input[type='tel']:visible, input[type='number']:visible,input[type='email']:visible,input[type='password']:visible,textarea:visible,select:visible,input:radio,input:checkbox",
			rtn = true;
		
		if(!visible) {
			entryStr = "input[class],textarea[class],select[class]";
		} 

		var entry = $(entryStr,container);

		for(var i=0,l=entry.length;i<l;i++) {
			var v = $(entry[i]).val(), rules = $(entry[i]).attr("class"), isSel = $(entry[i]).is("select"), isRadio = $(entry[i]).is(":radio"), isCheckbox = $(entry[i]).is(":checkbox");

			if(isSel)
				v = $("option:selected",entry[i]).val();

			if(isRadio || isCheckbox) {
				var n = $(entry[i]).attr("name");
				v = typeof $("input[name='"+n+"']:checked").val() != "undefined" ? $("input[name='"+n+"']:checked").val() : '';
			}

			// validações
			if(rules.match(/ep-error/i)) {
				rtn = false;
			} else {
				if(rules.match(/ep-valid/i)) {
					if(v == "" || rules.match(/ep-notFilled/i)) {
						rtn = false;
						if(isSel) {
							$(entry[i]).on("change",function(e) {
								$(this).parent().removeClass("ep-error");
							}).parent().addClass("ep-error");
						} else if(isRadio || isCheckbox) { 
							$(entry[i]).on("click",function(e) {
								var nm = $(this).attr("name");
								$("input[name='"+nm+"']").next().removeClass("ep-error");
							}).next().addClass("ep-error");
						} else {
							$(entry[i]).addClass("ep-error").on("keyup",function(e) {
								$(this).removeClass("ep-error");
							});
						}
					} else {
						$(entry[i]).removeClass("ep-error");
					}
				}
			}
		}

		return rtn;
	},
	GetVar: function() {
		var rtn  = {};
		var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
		var queryString = location.search.substring(1); 
		var keyValues = queryString.split('&'); 

		for(var i=0,l=keyValues.length;i<l;i++) { 
			var key = keyValues[i].split('=');
			if (key.length > 1) {
				rtn[decode(key[0])] = decode(key[1]);
			}
		} 

		return rtn; 
	},
	CloneFieldValues: function(from,to) {
		var from = $(from),
			to = $(to);

		from.each(function() {
			var n = $(this).attr("name"),
				v = $(this).val();

			if($("*[name='"+n+"']",to).length > 0)
				$("*[name='"+n+"']",to).val(v).trigger("change");
			
		});
	},
	StartScreens: function() {
		if($(document).width() < 767) vdEp.IsMobile = true 
		else vdEp.IsMobile = false;
		
		if(!vdEp.IsMobile && $(document).width() < 992) vdEp.IsTablet = true;
		else  vdEp.IsTablet = false;
	},
	OldBrowserSupport: function(container) {
		if(typeof container == "undefined") container = "body";

		if(window.navigator.appVersion.indexOf("MSIE 8") > -1) {
			$("label.ep-radio, label.ep-checkbox",container).each(function() {
				var ipt = $(this).prev("input");

				if(ipt.is(":checked"))
					$(this).addClass("ep-selected");
				else
					$(this).removeClass("ep-selected");

			}).on("click",function() {
				var t = $(this),
					ipt = t.prev("input"),
					nm = ipt.attr("name");

				$("input[name='"+nm+"']").each(function() {
					$(this).next("label").removeClass("ep-selected");
				});

				ipt.trigger("click");
				if(ipt.is(":radio"))
					t.addClass("ep-selected");
				else if(ipt.is(":checkbox")) {

					if(ipt.is(":checked"))
						t.addClass("ep-selected");
					else
						t.removeClass("ep-selected");
				}
			});

			window.setTimeout(function() {
				$("input[placeholder!='']",container).each(function() {
					var ph = $(this).attr("placeholder");
					if(typeof ph != "undefined" && ph != "") {
						//console.log(ph+":"+this.value+":"+($.trim(this.value) == "" ? "1":"0"));	
						$(this).on("focus blur keydown",function(e) {
							if(e.type == "focus") {
								if(this.value == ph)
									$(this).val("");
							} else if(e.type == "blur") {
								if($.trim(this.value) == "")
									$(this).val(ph);
								else
									$(this).removeClass("ep-notFilled");
							}
						}).addClass("ep-notFilled");

						$(this).val("").trigger("blur");
					}
				});
			},200);
			
		}
	},
	DetectDispositive: function() {
		var rtn = "desktop";

		if(
			(navigator.userAgent.match(/Android/i) && $(document).width() > 767) ||
			navigator.userAgent.match(/iPad/i)
		) {
			rtn = "tablet";
		}

		if (
			(navigator.userAgent.match(/Android/i) && $(document).width() <= 767) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Opera Mini/i) ||
			navigator.userAgent.match(/IEMobile/i)
		) {
			rtn = "mobile";
		}

		return rtn;
	},
	GTMBehavior: function(id) {
		window.customData.page.error = {
			'event': 'modal',
			'name': id
		};

		/*if(id == "aceite") {
			window.customData.page = {
				'template': '11',
				'brand': '1'
			};
		*/
	},
	CloneModal: function(id) {
		var t = $("#"+id),
			ctt = "",
			virtualClone = t.clone(),
			close = $(virtualClone).find(".ps-modal-close");

			close.each(function(){
				var e = $(this),
					d = $(e).data();
					
				e.data("modal","#ModalCookie").attr("data-modal","#ModalCookie");
				ctt = virtualClone.html();
			});

		return ctt;
	},
	FillContent: function(ctt,container,type) {
		if(container == "undefined") return;
		if(type == "undefined") type = false;

		if(!type)
			ctt = $(ctt).html();
		else
			ctt = $(ctt).attr("href");

		return ctt;
	},
	SlickSlides: function(container,cfg) {
		$(container).each(function() {
			var t = $(this),
				setCfg = typeof t.data("slickconfig") !== "undefined" ? t.data("slickconfig") : null,
				defaultCfg = cfg;

			if(t.children("div").length < 2) return;

			if(setCfg != null) {
				setCfg = setCfg.replace(/'/g,"\"");
				setCfg = JSON.parse(setCfg);
				$.extend(defaultCfg,setCfg);
			}

			t.slick(defaultCfg);
		});
	},
	ExpireVisualization: function(objClassname) {
		var currentDate = Date.now(),  
		promobar = document.getElementsByClassName(objClassname);
		
		for (var i = 0; promobar.length > i; i++) {
			if(typeof promobar !== "undefined" && typeof promobar[0].dataset.expires !== "undefined") {
				var expireDate = Date.parse(promobar[0].dataset.expires);
				
				if(!isNaN(expireDate) && currentDate - expireDate > 0)  
					promobar[i].style.display = 'none'; 
			}
		}
	},
	RedirectShop: function() {
		$("#ep-device-smartphone").off("click");

		var epClick = document.querySelector("#ep-device-smartphone"),
			epGetParameter = window.location.search.replace(/[?]/g, "").split("&"),
			epBuildParam = '',
			susep;

		if(epGetParameter.length > 1) {
			epGetParameter.forEach(function(param){
				param = param.split("=");
				
				if(param[0] == "cod") return;
	
				if(param[0] == "utm_source") epBuildParam += 'susep='+param[1];
				
				epBuildParam += '&'+param[0]+'='+param[1];
			});
		} else {
			susep = $("input[name='calculo.susepCorretorWeb']").val();

			if(susep != '')
				epBuildParam += '&susep='+susep+'&utm_source='+susep;
		}

		var listener = function (event) {
			psLib.ModalShowHide(".vd-loading-skin");
			epClick.setAttribute("href","https://loja.portoseguro.com.br/porto-seguro-celular/bem-segurado.html"+(susep != '' ? '?'+epBuildParam : ''));
		};

		epClick.addEventListener("click", listener);
	}
};