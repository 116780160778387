var dataLayer = dataLayer || [];
var vdEpSkin = {
	XHR_Actions: {
		AddressByZipcode: "/vendaonline/guiapostal.ns?dispatch=validaCep",
		ZipcodeByAddress: "/vendaonline/guiapostal.ns?escolhaPesquisa=1",
		SmartPortoValidate: "/vendaonline/equipamentosportateis/validarToken.ns?dispatch=validarToken",
		ProfessionSearch: "/vendaonline/consultasvo.ns?dispatch=buscarProfissoes&codigoProduto=2&isInnovare=true&opcaoOutros=S",
		ActivitiesSearch: "/vendaonline/consultasvo.ns?dispatch=buscarAtividadeEonomica&codigoProduto=2",
		AgentFinder: "/vendaonline/componente/pesqcorretor.ns",
		AgentSetter: "/vendaonline/componente/pesqcorretor.ns?dispatch=popularCorretorEscolhido",
		CalcDeviceValidation: "valorseguro.ns"
	},
	DefaultLinks: {
		CondicoesGerais: "https://www.portoseguro.com.br/equipamentos-portateis#condicoes-gerais",
		PoliticaPrivacidade: "#vdo-skin-politic",
		Ouvidoria: "http://www.portoseguro.com.br/a-porto-seguro/fale-com-a-porto-seguro/ouvidoria",
		Cancelamento: "https://wwws.portoseguro.com.br/vendaonline/cancelamento.ns",
		MapSite: "#vdo-skin-sitemap",
		EP: "https://www.portoseguro.com.br/equipamentos-portateis"
	},
	IsTopoCorretor: false,
	CheckTopoCorretor: function (params) {
		if ($("#divTopoCorretor").length > 0 || $("#vdo-skin-topCorretor").length > 0)
			vdEpSkin.IsTopoCorretor = true;
		else {
			if (typeof params.obj !== "undefined" && typeof params.obj.codigoCanal !== "undefined" && params.obj.codigoCanal == 2)
				vdEpSkin.IsTopoCorretor = true;
			else
				vdEpSkin.IsTopoCorretor = false;
		}
	},
	IsMostraEndereco: false,
	CheckMostraEndereco: function (params) {
		if (typeof params.obj == "undefined" ||
			typeof params.obj.calculo == "undefined")
			return;

		if (params.obj.calculo.nomeLogradouro != "" && params.obj.calculo.nomeLogradouro != "GENERICA")
			vdEpSkin.IsMostraEndereco = true;
	},
	IsModalCookie: false,
	CheckModalCookie: function () {
		if ($("#ModalRecover").length > 0) {
			vdEpSkin.IsModalCookie = true;
		} else {
			vdEpSkin.IsModalCookie = false;
		}
	},
	IsLoginEnabled: false,
	CheckLoginEnable: function () {
		if ($("#isLoginHabilitado").length > 0) {
			if($("#isLoginHabilitado").val() !== 'false') {
				vdEpSkin.IsLoginEnabled = true;
			}
		}
	},
	IsSkinPartner: false,
	CheckSkinPartner: function () {
		if (typeof window.skinBroker != "undefined")
			vdEpSkin.IsSkinPartner = true;
		else {
			vdEpSkin.IsSkinPartner = false;
		}
	},
	Init: function (page, params) {
		if (typeof params === "undefined") params = {};
		$("body").removeAttr("id");

		var get = vdEp.GetVar();

		vdEpSkin.CheckTopoCorretor(params);

		vdEpSkin.CheckMostraEndereco(params);

		//vdEpSkin.CheckModalCookie();

		vdEpSkin.CheckLoginEnable();

		vdEpSkin.CheckSkinPartner();

		if (typeof params.Dev != "undefined" && params.Dev) {
			vdEpSkin.XHR_Actions.AddressByZipcode = "./static/json/consultaCEP.php";
			vdEpSkin.XHR_Actions.ZipcodeByAddress = "./static/json/guiaPostal.html";
			vdEpSkin.XHR_Actions.SmartPortoValidate = "./static/json/smartPorto.validate.xml";
			vdEpSkin.XHR_Actions.ProfessionSearch = "./static/json/profissoes.xml";
			vdEpSkin.XHR_Actions.ActivitiesSearch = "./static/json/buscaAtividadeEconomica.xml";
			vdEpSkin.XHR_Actions.AgentFinder = "./static/json/buscaCorretor.xml";
			vdEpSkin.XHR_Actions.AgentSetter = "./static/json/buscaCorretor.xml";
			vdEpSkin.XHR_Actions.CalcDeviceValidation = "./static/json/submitCalculo.json";
		}

		switch (page) {
			case "home":
				$("body").prepend(vdEpSkin.Home(params));
				vdEp.LandingPage = true;
				vdEp.Init();
				psLib.Init(".ep-calc-form");
				vdEp.FillHomeForm(params);
				vdEp.RedirectShop();

				if(typeof get.skin !== "undefined") {
					$("#ep-device-" + get.skin + "").trigger("click");
				}

				if(typeof get.marcaSmartphone !== "undefined" && get.marcaSmartphone != "") {
					$("#ep-device-smartphone").trigger("click");
					$(".ep-calcStartup").trigger("click");

					if(get.marcaSmartphone == 94)
						$("#ep-device0-os-ios").trigger("click");
					else if(get.marcaSmartphone == 604 || get.marcaSmartphone == 656 || get.marcaSmartphone == 457)
						$("#ep-device0-os-wp8").trigger("click");
					else if(get.marcaSmartphone == 683)
						$("#ep-device0-os-other").trigger("click");

					setTimeout(function() {
						$(".ep-field-smartphone-marca option[value="+get.marcaSmartphone+"]").prop("selected",true);
					},100);
				}

				if(vdEpSkin.IsModalCookie) {
					psLib.ModalShowHide("#ModalCookie", true, false);
					psLib.Modal("#ModalCookie");
				}
				break;
			case "valorSeguro":
				$("body").prepend(vdEpSkin.ValorSeguro(params));
				vdEp.ExpireVisualization("vdo-promobar");
				vdEp.Init();
				break;
			case "valorPersonalizar":
				$("body").prepend(vdEpSkin.ValorPersonalizar(params));
				vdEp.CalcConfigs = true;
				vdEp.Init();
				break;
			case "checkout1":
				$("body").prepend(vdEpSkin.Checkout1(params));
				vdEp.CalcConfigs = true;
				vdEp.Init();

				if(vdEpSkin.IsModalCookie) {
					psLib.ModalShowHide("#ModalCookie", true, false);
					psLib.Modal("#ModalCookie");
				}

				psLib.Init(".ep-form-accordion");
				break;
			case "checkout2":
				$("body").prepend(vdEpSkin.Checkout2(params));
				vdEp.CalcConfigs = true;
				vdEp.Init();

				if(typeof params.obj.calculo.isObjetoClonado !== "undefined" && params.obj.calculo.isObjetoClonado == true)
					vdEpSkin.Checkout2PrefillData(params);

				setTimeout(function () {
					$("#fakeCep").trigger("keyup");
					$("input[name='calculo.numeroResidencial']").trigger("blur");

					$(".ep-datagrid-edit").on("click",function() {
						var p = $(this).parents(".ep-datagrid");
						p.removeClass("ep-datagrid");
					});
				}, 200);
				break;
			case "checkout3":
				// $("body").prepend(vdEpSkin.Checkout3(params));
				// vdEp.CalcConfigs = true;
				// vdEp.Init();
				setTimeout(function() {
					window.enviarFormulario();
				},100);
				break;
			case "checkout4":
				$("body").prepend(vdEpSkin.Checkout4(params));
				vdEp.CalcConfigs = true;
				vdEp.Init();
				break;
			case "checkout5":
				$("body").prepend(vdEpSkin.Checkout5(params));
				vdEp.Init();
				$(".ep-backStep").off();
				break;
			case "recuperar":
				$("body").prepend(vdEpSkin.Recuperar(params));
				vdEp.Init();
				break;
		}

		// dom fcaJoin1234

		//window.customData.site.version = "v2-"+vdEp.DetectDispositive();
	},
	TplLogin: function (param) {
		var rtn = '';
		//classes de exibição Portocom
		//botaoLoginPorto
		//vdo-login-header-hello logado
		//naoLogado
		//nomeCliente
		if(!vdEpSkin.IsLoginEnabled) {
			return rtn;
		}

		rtn += '<div class="botaoLoginPorto">\
					<div class="vd-login vd-login-logged">\
						<a href="javascript:;" class="vd-login-protocol naoLogado ps-open-modal" data-modal="#modalAvisoLoginPorto" data-modalonshow="vdEp.GTMBehavior(\'login\');">Login / Cadastro</a>\
						<strong class="vdo-login-preheader vdo-login-header-hello logado" style="display: none">\
							<span class="ps-ico ps-ico-man"></span> Ol&aacute;, <span class="nomeCliente"></span>\
						</strong>\
					</div>\
            	</div>';
		return rtn;
	},
	BuildTop: function (page, param, mode) {
		var ctt = "";

		ctt += '<div class="ep-top">\
					<div class="ep-container">\
						<div class="ep-row">';

		ctt += '			<div class="ep-mod3 ep-sm-mod7">\
								<strong class="ps-logo">Porto Seguro - Seguro Equipamentos Port&aacute;teis</strong>\
							</div>';

		if(page == "home") {
			if(mode == "lp") {
				ctt += '	<div class="ep-mod9 ep-sm-mod4 ep-sm-lspan1 ep-ultimasCotacoes ep-right">\
								<small class="ep-sm-hide">Resgate seguros contratados ou propostas incompletas:</small>\
								<a href="'+param.recuperarAtendimentoAction+'" class="ep-btn ep-gray">\
									&Uacute;ltimas cota&ccedil;&otilde;es\
									<span class="ep-icon ep-sm-hide ep-24 ep-ultimasCotacoes-white-24"></span>\
								</a>\
							</div>';
			} else {
				ctt += '	<div class="ep-mod1 ep-sm-mod5 ep-right">\
								<a href="javascript:;" class="ep-backStep"><span class="ep-icon ep-24 ep-arrow-left-lightGray-24-especial"></span></a>\
							</div>\
							<div class="ep-mod8">\
								<ul class="ep-calc-steps">\
									<li id="ep-showDeviceList">\
										<a href="javascript:;" class="ep-step ep-selected" id="ep-calc-step-1">\
											<span class="ep-icon ep-48 ep-calculoStep-2-after-48"></span>\
											<span class="ep-counter"></span>\
											<span class="ep-text">Dados do Aparelho</span>\
										</a>\
										<div class="ep-deviceList">\
										</div>\
									</li>\
									<li>\
										<a href="javascript:;" class="ep-step ep-before" id="ep-calc-step-2">\
											<span class="ep-icon ep-48 ep-calculoStep-3new-after-48"></span>\
											<span class="ep-text">Dados do propriet&aacute;rio</span>\
										</a>\
									</li>\
									<li>\
										<a href="javascript:;" class="ep-step ep-before">\
											<span class="ep-icon ep-48 ep-calculoStep-3-before-48"></span>\
											<span class="ep-text">Cota&ccedil;&atilde;o</span>\
										</a>\
									</li>\
								</ul>\
							</div>';
			}
		} else if(page == "valorSeguro" || page == "valorPersonalizar") {
			ctt += '		<div class="ep-mod1 ep-sm-mod5 ep-right">\
								<a href="'+param.voltarAction+'" class="ep-backStep"><span class="ep-icon ep-24 ep-arrow-left-lightGray-24-especial"></span></a>\
							</div>\
							<div class="ep-mod8">\
								<div class="ep-row">\
									<div class="ep-mod12">\
										<ul class="ep-calc-steps">\
											<li>\
												<span class="ep-step">\
													<span class="ep-icon ep-48 ep-calculoStep-2-after-48"></span>\
													'+(param.obj.calculo.equipamentosPortateisItemCalculo.length > 1 ? '<span class="ep-counter">'+param.obj.calculo.equipamentosPortateisItemCalculo.length+'</span>' : '')+'\
													<span class="ep-text">Dados do Aparelho</span>\
												</span>\
											</li>\
											<li>\
												<span class="ep-step">\
													<span class="ep-icon ep-48 ep-calculoStep-3new-after-48"></span>\
													<span class="ep-text">Dados do propriet&aacute;rio</span>\
												</span>\
											</li>\
											<li>\
												<span class="ep-step ep-selected">\
													<span class="ep-icon ep-48 ep-calculoStep-3-in-48"></span>\
													<span class="ep-text">Cota&ccedil;&atilde;o</span>\
												</span>\
											</li>\
										</ul>\
									</div>\
								</div>\
							</div>';
		} else if(page == "recuperar") {
			ctt += '		<div class="ep-mod1 ep-sm-mod5 ep-right">\
								<a href="'+param.voltarAction+'" class="ep-backStep"><span class="ep-icon ep-24 ep-arrow-left-lightGray-24-especial"></span></a>\
							</div>';
		} else {
			var step = parseInt(page.replace("checkout",""));

			ctt += '		<div class="ep-mod1 ep-sm-mod5 ep-right">\
								<a href="'+param.voltarAction+'" class="ep-backStep ep-form-changeAction-submit" data-form="#ep-checkoutForm"><span class="ep-icon ep-24 ep-arrow-left-lightGray-24-especial"></span></a>\
							</div>\
							<div class="ep-mod8">\
								<div class="ep-row">\
									<div class="ep-mod12">\
										<ul class="ep-calc-steps ep-step-checkout">\
											<li>\
												<span class="ep-step '+(step == 1 ? 'ep-selected' : '')+'">\
													<span class="ep-icon ep-48 ep-contratarStep-3-'+(step == 1 ? 'in' : 'after')+'-48"></span>\
													<span class="ep-text">Valida&ccedil;&atilde;o</span>\
												</span>\
											</li>\
											<li>\
												<span class="ep-step '+(step == 2 ? 'ep-selected' : step > 2 ? 'ep-after' : 'ep-before')+'">\
													<span class="ep-icon ep-48 ep-contratarStep-1-'+(step == 2 ? 'in' : step > 2 ? 'after' : 'before')+'-48"></span>\
													<span class="ep-text">Dados do propriet&aacute;rio</span>\
												</span>\
											</li>\
											<li>\
												<span class="ep-step '+(step == 4 ? 'ep-selected' : step > 4 ? 'ep-after' : 'ep-before')+'">\
													<span class="ep-icon ep-48 ep-contratarStep-4-'+(step == 4 ? 'in' : step > 4 ? 'after' : 'before')+'-48"></span>\
													<span class="ep-text">Pagamento</span>\
												</span>\
											</li>\
											<li>\
												<span class="ep-step '+(step == 5 ? 'ep-selected' : step > 5 ? 'ep-after' : 'ep-before')+'">\
													<span class="ep-icon ep-48 ep-contratarStep-5-'+(step == 5 ? 'in' : step > 5 ? 'after' : 'before')+'-48"></span>\
													<span class="ep-text">Finaliza&ccedil;&atilde;o</span>\
												</span>\
											</li>\
										</ul>\
									</div>\
								</div>\
							</div>';
		}
		ctt += '		</div>\
					</div>\
				</div>';

		if(vdEpSkin.IsTopoCorretor)
			ctt += vdEpSkin.BuildTopBroker(param,vdEpSkin.IsSkinPartner,vdEpSkin.IsTopoCorretor);

		return ctt;
	},
	BuildTopBroker: function(param, isSkinBroker, isCorretor) {
		var ctt = '',
			skinBroker = typeof isSkinBroker != "undefined" ? isSkinBroker : false;

		isCorretor = typeof isCorretor != "undefined" ? isCorretor : false;  

		if(!skinBroker && !isCorretor) return "";   

		var getParams = (new URL(document.location)).searchParams,
			testSusep = getParams.get("susepTestSkin");

		if(typeof param.obj.calculo.codigoAtendimento == "undefined") { // está na home
			if(testSusep != null) {
				window.sessionStorage.setItem("susepTestSkin",testSusep);
			} else {
				window.sessionStorage.removeItem("susepTestSkin");
			}			
		} else { // demais páginas
			testSusep = window.sessionStorage.getItem("susepTestSkin");
		}

		if(isCorretor) {
			if(param.codigoCanal == "2" || param.obj.codigoCanal == 2) {
				var ddd = typeof param.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroDddTelefoneCorretor != "undefined" ? param.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroDddTelefoneCorretor : "",
					tel = typeof param.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroTelefoneCorretor != "undefined" ? param.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroTelefoneCorretor : "";

				tel = tel.toString();
				tel = tel.length > 8 ? tel.substr(0,5)+"-"+tel.substr(5,4) : tel.substr(0,4)+"-"+tel.substr(4,4);

				var	corretorInfo = {
						susep: testSusep == null ? param.obj.calculo.corretor.susepWeb : testSusep,
						nm: param.obj.calculo.corretor.nome,
						fn: ddd == 0 ? tel : '(' + ddd + ') ' + tel,
					};
				
			} else {
				var ddd = $(".vdo-corretor-fone").html(),
					corretorInfo = {
						susep: testSusep == null ? param.obj.calculo.corretor.susepWeb : testSusep,
						nm: $(".vdo-corretor-nome").html(),
						fn: ddd.length >= 15 ? ddd : ddd.substr(5,10)
					};
			}
		}

		ctt = '\
				<div class="vdo__preheader--broker" data-susep="'+corretorInfo.susep+'" style="display:'+(skinBroker || isCorretor  ? 'block' : 'none')+'">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 '+(skinBroker ? 'ps-sm-mod5 vdo__preheader--broker-info' : 'ps-sm-mod10')+' ps-sm-lspan1">\
								<p>'+(isCorretor ? corretorInfo.nm : '')+'\</p>\
								<p>'+(isCorretor ? corretorInfo.fn : '')+'</p>\
							</div>\
							<div class="ps-mod8 ps-sm-mod5 vdo__preheader--broker-partner" style="display:'+(skinBroker ? 'block' : 'none')+'">\
								Uma parceria <img src="'+(skinBroker ? window.skinBroker.brokerPath : '')+'">\
							</div>\
						</div>\
					</div>\
				</div>\
		';

		return ctt;
	},
	BuildFooter: function() {
		var rtn = "";

		rtn = '<footer class="vdo-footer vdo-footer-checkout">\
					<div class="vdo-footer-infos">\
						<div class="ps-container">\
							<div class="ps-row">\
								<div class="ps-mod8 ps-sm-mod12 ps-md-mod4 vdo-footer-column">\
									<strong>Cart&atilde;o de cr&eacute;dito</strong>\
									<ul>\
										<li class="vdo-footer-payments vdo-footer-payments-porto">Porto Seguro</li>\
										<li class="vdo-footer-payments vdo-footer-payments-mastercard">Mastercard</li>\
										<li class="vdo-footer-payments vdo-footer-payments-visa">Visa</li>\
										<li class="vdo-footer-payments vdo-footer-payments-diners">Visa</li>\
										<li class="vdo-footer-payments vdo-footer-payments-elo">Visa</li>\
									</ul>\
								</div>\
								<div class="ps-mod8 ps-sm-mod12 ps-md-mod4 vdo-footer-column">\
									<strong>D&eacute;bito em conta</strong>\
									<ul>\
										<li class="vdo-footer-payments vdo-footer-payments-bb">Banco do Brasil</li>\
										<li class="vdo-footer-payments vdo-footer-payments-bradesco">Bradesco</li>\
										<li class="vdo-footer-payments vdo-footer-payments-caixa">Caixa Econ&ocirc;mica Federal</li>\
										<li class="vdo-footer-payments vdo-footer-payments-citi">Citi Bank</li>\
										<li class="vdo-footer-payments vdo-footer-payments-itau">Ita&uacute;</li>\
										<li class="vdo-footer-payments vdo-footer-payments-santander">Santander</li>\
									</ul>\
								</div>\
								<div class="ps-mod8 ps-sm-mod12 ps-md-mod4 vdo-footer-column">\
									<strong>Site seguro</strong>\
									P&aacute;gina criptografada Privacidade <br class="ps-hide ps-sm-only"> e seguran&ccedil;a para voc&ecirc;.\
								</div>\
							</div>\
							<div class="ps-row vdo-footer-column vdo-footer-notes">\
								<div class="ps-mod8 ps-sm-mod12"> \
									<p><small>\
										* Este seguro tem EXCLUS&Otilde;ES ESPEC&Iacute;FICAS. \
										Para maiores informa&ccedil;&otilde;es sobre as garantias e exclus&otilde;es consulte as <a href="' + vdEpSkin.DefaultLinks.CondicoesGerais + '" target="_blank">Condi&ccedil;&otilde;es Gerais</a> deste seguro. Porto Seguro Cia. De Seguros Gerais. \
										CNPJ: 61.198.164/0001-60 - Processo SUSEP: 15414.002547/2012-05. \
										O registro do produto &eacute; autom&aacute;tico e n&atilde;o representa aprova&ccedil;&atilde;o ou recomenda&ccedil;&atilde;o por parte da Susep. \
										Em atendimento &agrave; regulamenta&ccedil;&atilde;o vigente, informamos que incidem as al&iacute;quotas PIS 0,65%; COFINS 4,00%, sobre forma&ccedil;&atilde;o de pre&ccedil;o. \
										Observa&ccedil;&atilde;o: IOF informado na ap&oacute;lice de seguro. \
										<strong>&Eacute; facultado ao segurado o direito de arrependimento em at&eacute; 07 (sete) dias da data de recebimento da ap&oacute;lice, conforme previsto no C&oacute;digo de Defesa do Consumidor, desde que n&atilde;o tenha sido utilizado nenhum servi&ccedil;o da ap&oacute;lice ou em caso de &quot;vig&ecirc;ncia j&aacute; terminada/risco j&aacute; decorrido&quot;.</strong>\
									</small></p>\
								</div>\
							</div>\
						</div>\
					</div>\
					<div class="vdo-footer-colophon">\
						<div class="ps-container">\
							<div class="ps-row">\
								<div class="ps-mod8 ps-sm-mod12 ps-md-mod2 ps-alignCenter ps-md-alignLeft vdo-footer-logo">\
									<span class="ps-logo '+(psLib.IsMobile || psLib.IsTablet ? 'ps-md-hide ps-lg-hide' : 'ps-logo-white ps-hide ps-md-show ps-lg-show')+' "></span>\
								</div>\
								<div class="ps-mod8 ps-md-mod10 ps-md-alignRight ps-hide ps-md-show vdo-footer-colophon-links">\
									<ul>\
										<li><a href="' + vdEp.FillContent(vdEpSkin.DefaultLinks.PoliticaPrivacidade, "", true) + '" target="_blank">Pol&iacute;tica de privacidade</a></li>\
										<li><a href="' + vdEpSkin.DefaultLinks.Ouvidoria + '" target="_blank">Ouvidoria</a></li>\
										<li><a href="' + vdEpSkin.DefaultLinks.Cancelamento + '" target="_blank">Cancelamento</a></li>\
										<li><a href="' + vdEpSkin.DefaultLinks.CondicoesGerais + '" target="_blank">Condi&ccedil;&otilde;es Gerais: Porto Seguro Port&aacute;teis</a></li>\
										<li><a href="' + vdEp.FillContent(vdEpSkin.DefaultLinks.MapSite, "", true) + '" target="_blank">Mapa do site</a></li>\
										<li><a id="ot-sdk-btn" class="vo-lgpd-cookies ot-sdk-show-settings">Configura&ccedil;&otilde;es de Cookies</a></li>\
									</ul>\
								</div>\
							</div>\
						</div>\
					</div>\
				</footer>\
				' + vdEpSkin.BuildModal("ModalPoliticas") + vdEpSkin.BuildModal("loading");

		return rtn;
	},
	BuildModal: function (id, params) {
		var ctt = "";

		// default loading
		if (id == "loading")
			ctt += '<div class="ep-modal-loading" style="display: none;"></div>';
		else if (id == "ModalPoliticas") {
			ctt += '<div id="ModalPoliticas" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<iframe name="src" width="100%" height="315" src="about:blank" frameborder="0" allowfullscreen></iframe>\
						</div>\
					</div>';
		} else if (id == "ModalAccordionRemove") {
			ctt += '<div id="ModalAccordionRemove" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content">\
							<h2>Deseja realmente excluir o aparelho?</h2>\
							<div class="ep-row ep-form">\
								<div class="ep-mod12">\
									Voc&ecirc; ir&aacute; perder os dados preenchidos para este aparelho.\
								</div>\
							</div>\
							<div class="ep-row">\
								<div class="ep-mod6 ep-sm-mod6">\
									<a href="javascript:;" class="ep-btn ep-doEraseAccordionItem" data-accordioncontainer="#ep-deviceList-form">Sim</a>\
								</div>\
								<div class="ep-mod6 ep-sm-mod6">\
									<a href="javascript:;" class="ep-btn ep-gray ep-trigger" data-trigger="#ModalAccordionRemove .ep-modalClose">N&atilde;o</a>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalCEP") {
			ctt += '<div id="ModalCEP" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<h2>Pesquisa de <strong>CEP</strong></h2>\
							<div class="ep-row ep-form">\
								<form name="buscaCEP" action="' + vdEpSkin.XHR_Actions.ZipcodeByAddress + '" class="ep-form-group ep-doBuscaCEP" method="GET">\
									<input type="hidden" name="dispatch" value="pesquisaGuiaPostal">\
									<input type="hidden" name="skin" value="">\
									<div class="ep-mod2 ep-sm-mod4">\
										<label class="ep-lbl ep-lbl-inline">Estado:</label>\
										<label class="ep-select">\
											<select name="unidadeFederacao" class="ep-valid" data-lucida-form-traked="true">\
												<option value="" selected="selected"></option>\
												<option value="AC">AC</option>\
												<option value="AL">AL</option>\
												<option value="AM">AM</option>\
												<option value="AP">AP</option>\
												<option value="BA">BA</option>\
												<option value="CE">CE</option>\
												<option value="DF">DF</option>\
												<option value="ES">ES</option>\
												<option value="GO">GO</option>\
												<option value="MA">MA</option>\
												<option value="MG">MG</option>\
												<option value="MS">MS</option>\
												<option value="MT">MT</option>\
												<option value="PA">PA</option>\
												<option value="PB">PB</option>\
												<option value="PE">PE</option>\
												<option value="PI">PI</option>\
												<option value="PR">PR</option>\
												<option value="RJ">RJ</option>\
												<option value="RO">RO</option>\
												<option value="RR">RR</option>\
												<option value="RN">RN</option>\
												<option value="RS">RS</option>\
												<option value="SC">SC</option>\
												<option value="SP">SP</option>\
												<option value="SE">SE</option>\
												<option value="TO">TO</option>\
											</select>\
										</label>\
									</div>\
									<div class="ep-mod3 ep-sm-mod8">\
										<label class="ep-lbl ep-lbl-inline">Cidade:</label>\
										<input type="text" name="cidade" placeholder="" class="ep-form-control ep-valid ep-cleanupText" />\
									</div>\
									<div class="ep-mod4">\
										<label class="ep-lbl ep-lbl-inline">Logradouro: <small>(apenas o nome do logradouro)</small></label>\
										<input type="text" name="logradouro" placeholder="" class="ep-form-control ep-valid ep-cleanupText" />\
									</div>\
									<div class="ep-mod3">\
										<label class="ep-lbl ep-lbl-inline ep-sm-hide">&#160;</label>\
										<input type="submit" name="s" value="PESQUISAR" class="ep-btn" />\
									</div>\
								</form>\
							</div>\
							<div class="ep-row">\
								<div id="ep-buscacep-loading" style="display: none;">\
									<span class="ep-icon ep-md ep-loading-md-blue"></span>&#160;\
									Aguarde\
								</div>\
								<div id="ep-mod12 ep-buscacep-error" style="display: none;">\
									<span class="ep-icon ep-md ep-alert-md-red"></span>\
									Ocorreu um erro ao fazer a busca\
								</div>\
								<div id="ep-buscacep-ctt" class="ep-formList">\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalAceite") {
			ctt += '<div id="ModalAceite" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<h2>Voc&ecirc; precisa ler e aceitar os <strong>termos gerais</strong> para prosseguir.</h2>\
							<div class="ep-row">\
								<div class="ep-mod6">\
									<p>\
										Para prosseguir na formaliza&ccedil;&atilde;o da sua proposta de seguro &eacute; necess&aacute;rio que voc&ecirc; esteja ciente quanto &agrave;s garantias e exclus&otilde;es existentes para este seguro.\
									</p>\
									<br/>\
									<p>\
										Leia as Condi&ccedil;&otilde;es Gerais deste seguro para maiores informa&ccedil;&otilde;es sobre as garantias e exclus&otilde;es das coberturas.\
									</p>\
								</div>\
								<div class="ep-mod6">\
									<a href="' + vdEpSkin.DefaultLinks.CondicoesGerais + '" target="_blank" class="ep-link"><span class="ep-icon ep-24 ep-protocolo-white-24"></span> Condi&ccedil;&otilde;es Gerais</a>\
									<a href="' + vdEpSkin.DefaultLinks.PoliticaPrivacidade + '" target="_blank" class="ep-link"><span class="ep-icon ep-24 ep-protocolo-white-24"></span> Pol&iacute;tica de privacidade</a>\
								</div>\
							</div>\
							<div class="ep-row ep-submitRow">\
								<div class="ep-mod6 ep-lspan3">\
									<input type="submit" class="ep-btn" value="DECLARO QUE LI E ACEITO" />\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalResumoSeguro") {
			var equip = params.obj.retornoCalculo.equipamentosPortateisItemCalculo,
				notebookIdx = 0,
				smartphoneIdx = 0,
				tabletIdx = 0,
				cameraIdx = 0,
				videocameraIdx = 0,
				smartwatchIdx = 0,
				accessoryIdx = 0;
			ctt += '<div id="ModalResumoSeguro" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<h2>\
								Resumo do Seguro\
								<small>Vig&ecirc;ncia do seguro: ' + params.vigencia.inicioDateStr + ' a ' + params.vigencia.fimDateStr + '</small>\
							</h2>\
							<div class="ep-row ep-form">';
			for (var i = 0, l = equip.length; i < l; i++) {
				if (equip[i].codigoRelevancia == 2) continue;
				var type = "",
					currentIdx = 0;
				switch (equip[i].codigoEquipamento) {
					//401 apple
					//512 notebook
					case 401:
					case 512:
						type = "notebook";
						if (equip[i].codigoRelevancia == 1) {
							notebookIdx++;
							currentIdx = notebookIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
					case 646:
						type = "smartphone";
						if (equip[i].codigoRelevancia == 1) {
							smartphoneIdx++;
							currentIdx = smartphoneIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
					case 647:
						type = "smartwatch";
						if (equip[i].codigoRelevancia == 1) {
							smartwatchIdx++;
							currentIdx = smartwatchIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
					case 655:
						type = "tablet";
						if (equip[i].codigoRelevancia == 1) {
							tabletIdx++;
							currentIdx = tabletIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
					case 124:
						type = "camera";
						if (equip[i].codigoRelevancia == 1) {
							cameraIdx++;
							currentIdx = cameraIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
					case 302:
						type = "videocamera";
						if (equip[i].codigoRelevancia == 1) {
							videocameraIdx++;
							currentIdx = videocameraIdx;
						} else {
							accessoryIdx++;
							currentIdx = accessoryIdx;
						}
						break;
				}
				ctt += '	<div class="ep-form-accordion ' + (equip.length == 1 ? 'ep-accordion-single' : 'ep-accordion-closed') + '">\
									' + (equip.length == 1 ? '<span class="ep-accordion-header">' : '<a href="javascript:;" class="ep-accordion-header" data-accordionscroll="true">') + '\
										<span class="ep-icon ep-36 ep-' + type + '-white-36"></span>\
										' + (equip[i].codigoRelevancia == 2 ? 'ACESS&Oacute;RIO: ' : '') + equip[i].descricaoEquipamento + ' ' + currentIdx + '\
									' + (equip.length == 1 ? '</span>' : '</a>') + '\
									<div class="ep-accordion-container" ' + (equip.length > 1 ? 'style="display: none;"' : '') + '>\
										<div class="ep-row">\
											<div class="ep-mod4">\
												<span>Equipamento</span><br/>\
												<strong>' + equip[i].descricaoEquipamento + '</strong>\
											</div>\
											<div class="ep-mod4">\
												<span>Data de aquisi&ccedil;&atilde;o</span><br/>\
												<strong>' + (('0' + equip[i].diaNotaFiscal).slice(-2) + '/' + ('0' + equip[i].mesNotaFiscal).slice(-2)) + '/' + equip[i].anoNotaFiscal + '</strong>\
											</div>\
										</div>\
										<div class="ep-row">\
											<div class="ep-mod4">\
												<span>Valor de aquisi&ccedil;&atilde;o do equipamento</span><br/>\
												<strong>' + vdEp.FormatCurrency(equip[i].valorNotaFiscal) + '</strong>\
											</div>\
											<div class="ep-mod4">\
												<span>Deprecia&ccedil;&atilde;o conforme o tempo de uso do equipamento, vide condi&ccedil;&otilde;es gerais.</span><br/>\
												<strong style="display: none;">' + vdEp.FormatCurrency(equip[i].valorDepreciacao) + '</strong>\
											</div>\
											<div class="ep-mod4">\
												<span>Valor do pr&ecirc;mio sem imposto</span><br/>\
												<strong>' + vdEp.FormatCurrency(equip[i].premioTotalItem) + '</strong>\
											</div>\
										</div>\
									</div>\
								</div>';
			}

			ctt += '		</div>\
						</div>\
					</div>';
		} else if (id == "ModalBuscaCorretor") {
			ctt += '\
			<div id="ModalBuscaCorretor" class="ep-modal">\
				<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
				<div class="ep-modal-content ep-modal-small">\
					<h2>Pesquisa de <strong>Corretor</strong></h2>\
					<div class="ep-row ep-form">\
						<div class="ep-tab">\
							<a href="javascript:;" class="ep-tabItem ep-selected ep-showHideBlock" data-showhideblock=".ep-showHide-form;#ep-buscaCorretor-nome;.ep-tabItem">Buscar por nome</a>\
							<a href="javascript:;" class="ep-tabItem ep-showHideBlock" data-showhideblock=".ep-showHide-form;#ep-buscaCorretor-cep;.ep-tabItem">Buscar por CEP</a>\
						</div>\
						<form name="buscaCorretor" action="' + vdEpSkin.XHR_Actions.AgentFinder + '" class="ep-form-group ep-doBuscaCorretor" method="GET">\
							<input type="hidden" name="dispatch" value="executeInitCorretor" />\
							<div class="ep-showHide-form" id="ep-buscaCorretor-nome">\
								<div class="ep-mod9">\
									<label class="ep-lbl ep-lbl-inline">Digite o nome do corretor</label>\
									<input type="text" name="nomeCorretor" placeholder="" class="ep-form-control ep-valid ep-cleanupText" />\
								</div>\
								<div class="ep-mod3">\
									<label class="ep-lbl ep-lbl-inline ep-sm-hide">&#160;</label>\
									<input type="submit" name="s" value="PESQUISAR" class="ep-btn" />\
								</div>\
							</div>\
							<div class="ep-showHide-form" id="ep-buscaCorretor-cep" style="display: none;">\
								<div class="ep-mod4">\
									<label class="ep-lbl ep-lbl-inline">CEP (somente n&uacute;mero)</label>\
									<input type="hidden" name="it_cep" value="" id="ep-corretor-cep1" />\
									<input type="hidden" name="it_cepComp" value="" id="ep-corretor-cep2" />\
									<input type="text" name="cep" placeholder="" class="ep-form-control ep-valid ep-cepMask ep-split" data-split="-;#ep-corretor-cep1;#ep-corretor-cep2" />\
								</div>\
								<div class="ep-mod2">\
									<label class="ep-lbl ep-lbl-inline">N&uacute;mero</label>\
									<input type="text" name="numero" placeholder="" class="ep-form-control ep-valid ep-onlyNumbers" />\
								</div>\
								<div class="ep-mod3">\
									<label class="ep-lbl ep-lbl-inline">Dist&acirc;ncia (km)</label>\
									<input type="text" name="it_distancia" placeholder="" class="ep-form-control ep-valid ep-onlyNumbers" maxlength="2" />\
								</div>\
								<div class="ep-mod3">\
									<label class="ep-lbl ep-lbl-inline ep-sm-hide">&#160;</label>\
									<input type="submit" name="s" value="PESQUISAR" class="ep-btn" />\
								</div>\
							</div>\
						</form>\
					</div>\
					<div class="ep-row">\
						<div class="ep-mod12 ep-center" id="ep-buscaCorretor-loading" style="display: none;">\
							<span class="ep-icon ep-48 ep-loading-48"></span>&#160;\
							Aguarde\
						</div>\
						<div id="ep-mod12 ep-buscaCorretor-error" style="display: none;">\
							<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
							Ocorreu um erro ao fazer a busca\
						</div>\
						<div id="ep-buscaCorretor-ctt" class="ep-formList">\
						</div>\
					</div>\
				</div>\
			</div>';
		} else if (id == "ModalErroVerificacao") {
			ctt += '<div id="ModalErroVerificacao" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<span class="ep-icon ep-48 ep-verifyAlert-48"></span>\
							<h2>Aconteceu um erro ao executar o c&aacute;lculo, tente novamente.</h2>\
						</div>\
					</div>';
		} else if (id == "ModalErroCriticas") {
			ctt += '<div id="ModalErroCriticas" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<span class="ep-icon ep-48 ep-verifyAlert-48"></span>\
							<h2>Ops, n&atilde;o conseguimos calcular seu seguro</h2>\
							<div class="ep-row ep-form">\
								<div class="ep-mod12">\
									<p></p>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalLimiteValorApolice") {
			ctt += '<div id="ModalLimiteValorApolice" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<span class="ep-icon ep-48 ep-verifyAlert-48"></span>\
							<h2>A soma de todos os seus equipamentos excedeu o limite de ' + vdEp.FormatCurrency(window.vdEp.DeviceValueLimits.Default.CalculateItensLimit) + '</h2>\
							<div class="ep-row ep-form">\
								<div class="ep-mod12">\
									<p>Para contratar o seguro com valores acima do limite, fale com seu corretor.</p>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalLogin") {
			ctt += '<div id="ModalLogin" class="ep-modal">\
						<a href="javascript:;" class="ep-modalClose"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
						<div class="ep-modal-content ep-modal-small">\
							<div class="ep-row">\
								<div class="ep-mod12">\
									<h2>Agora, voc&ecirc; ser&aacute; direcionado para a tela de login/cadastro</h2>\
									<p>\
										Para seguir com a contrata&ccedil;&atilde;o, voc&ecirc; precisa fazer o login no portal do cliente.\
									</p>\
									<p>\
										Se voc&ecirc; ainda n&atilde;o &eacute; cadastrado, fique tranquilo! &Eacute; s&oacute; clicar em &quot;Quero me cadastrar&rdquo; e criar sua conta.\
									</p>\
								</div>\
							</div>\
							<div class="ep-row ep-submitRow ep-center">\
								<div class="ep-mod8 ep-lspan2">\
									<a href="javascript:;" class="ep-btn ep-login-pdc">Ir para o login/cadastro</a>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if (id == "ModalSmartPortoID") {
			ctt += '<div class="ps-modal" id="ModalAceiteSmartPortoID">\
						<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>\
						<div class="ps-modal-container  ps-sm-modal-large">\
							<div class="ps-modal-title">\
								Para prosseguir na formaliza&ccedil;&atilde;o da sua proposta de seguro &eacute; necess&aacute;rio que voc&ecirc; esteja ciente quanto &agrave;s garantias e exclus&otilde;es existentes para este seguro.\
							</div>\
							<div class="ps-modal-content">\
								<embed src="'+params.assetPath+'/pdf/Termos_de_Uso.pdf" type="application/pdf" width="100%" height="100%" />\
							</div>\
							<div class="ps-modal-foot">\
								<input type="checkbox" class="ep-checkbox vdo__lgd-checked" name="fake.calculo.optinTermo" id="vd-calculo-optin-smart" value="true">\
								<label class="ep-checkbox" for="vd-calculo-optin-smart" style="margin-bottom:14px;">\
									<span class="ep-icon ep-24 ep-checkbox-icon"></span>\
									Li e concordo com os termos\
								</label>\
								<a href="#" class="ps-btn ps-btn-disabled ps-modal-close" data-modal="#ModalAceiteSmartPortoID">Continuar</a>\
							</div>\
						</div>\
					</div>';
		}

		return ctt;
	},
	BuildModalCookie: function () {
		var ctt = "";

		if (vdEpSkin.IsModalCookie) {
			ctt += '<div id="ModalCookie" class="ps-modal ps-modal-cookie">\
					' + vdEp.CloneModal("ModalRecover") + '\
			</div>';
		}

		return ctt;
	},
	BuildHeaderCalcInfo: function (params) {
		var ctt = "";

		ctt += '<div class="ep-mod7 ep-calc-detail">\
					<div class="ep-mod4 ep-sm-mod4">\
						<span class="ep-icon ep-24 ep-protocolo-white-24 ep-sm-hide"></span>\
						<small>Protocolo de Atendimento:</small>\
						' + params.codigoAtendimento + '\
					</div>\
					<div class="ep-mod4 ep-sm-mod4">\
						<span class="ep-icon ep-24 ep-preco-white-24 ep-sm-hide"></span>\
						<small>Valor Segurado:</small>\
						' + vdEp.FormatCurrency(params.obj.retornoCalculo.valorSegurado) + ' (<a href="javascript:;" class="ep-showModal" data-modalref="#ModalResumoSeguro" data-modalcallback="vdEp.GTMBehavior(\'resumo-seguro\')">resumo</a>)\
					</div>\
					<div class="ep-mod4 ep-sm-mod4">\
						<span class="ep-icon ep-24 ep-vigencia-white-24 ep-sm-hide"></span>\
						<small>Vig&ecirc;ncia do Seguro:</small>\
						' + params.vigencia.inicioDateStr + ' - ' + params.vigencia.fimDateStr + '\
					</div>\
				</div>';

		return ctt;
	},
	BuildCoverageIcons: function (list, equipamentType) {
		var ctt = "",
			coverage1 = false,
			coverage2 = false,
			coverage3 = false,
			coverage4 = false,
			coverage5 = false,
			coverage6 = false;

		for(var i = 0 ; i <  list.length ; i++){
			var c = list[i];
			value = c;

			switch(c) {
				case "1":
					coverage1 = true;
					break;
				case "2":
					coverage2 = true;
					break;
				case "3":
					coverage3 = true;
					break;
				case "4":
					coverage4 = true;
					break;
				case "5":
					coverage5 = true;
					break;
				case "6":
					coverage6 = true;
					break;
			}
		}

		ctt += '<div class="ep-ico-block '+(coverage1 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-danosFisicos-blue-24"></span><strong>Danos f&iacute;sicos ao bem</strong></div> ';
		ctt += '<div class="ep-ico-block '+(coverage2 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-roubo-blue-24"></span><strong>Subtra&ccedil;&atilde;o do bem</strong></div> ';
		ctt += '<div class="ep-ico-block '+(coverage3 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-danosEletricos-blue-24"></span><strong>Danos el&eacute;tricos</strong></div> ';
		ctt += '<div class="ep-ico-block '+(coverage4 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-garantiaInternacional-blue-24"></span><strong>Garantia internacional</strong></div> ';
		ctt += '<div class="ep-ico-block '+(coverage5 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-danoLiquido-blue-24"></span><strong>Danos l&iacutequidos</strong></div> ';
		
		if(equipamentType == "33")
			ctt += '<div class="ep-ico-block '+(coverage6 == true  ? '' : 'ep-ico-block-disable')+'"><span class="ep-icon ep-24 ep-perdaAluguel-blue-24"></span><strong>Perda ou pagamento de aluguel</strong></div> ';

		return ctt;
	},
	BuildCEPGeneric: function (params) {
		var ctt = "";

		ctt += '<div class="ep-mod3" id="pf_field_address1" ' + (vdEpSkin.IsMostraEndereco == false ? '' : 'style="display: none;"') + '>\
					<input type="hidden" name="calculo.numeroCepResidencial" class="ep-form-cep1" value="' + ("00000" + params.obj.calculo.numeroCepResidencial).slice(-5) + '" />\
					<input type="hidden" name="calculo.complementoCepResidencial" class="ep-form-cep2" value="' + ("00000" + params.obj.calculo.complementoCepResidencial).slice(-3) + '" />\
					<input type="hidden" name="proposta.numeroCep" class="ep-form-cep1" value="' + ("00000" + params.obj.calculo.numeroCepResidencial).slice(-5) + '" />\
					<input type="hidden" name="proposta.complementoCep" class="ep-form-cep2" value="' + ("00000" + params.obj.calculo.complementoCepResidencial).slice(-3) + '" />\
					<input type="tel" name="cep" id="fakeCep" class="ep-form-control ep-valid ep-split ep-cepMask ep-callback-keyup" \
						data-callback="vdEp.DoCEPSearch(this,\'' + vdEpSkin.XHR_Actions.AddressByZipcode + '\',\'#ep-addressData\');" \
						data-split="-;.ep-form-cep1;.ep-form-cep2" \
						value=" ' + (vdEpSkin.IsMostraEndereco == false ? (("00000" + params.obj.calculo.numeroCepResidencial).slice(-5) + '-' + ("00000" + params.obj.calculo.numeroCepResidencial).slice(-3)) : '') + '" " />\
					<small class="ep-CepModal">\
						<a href="javascript:;" class="ep-showModal ep-setData" data-modalref="#ModalCEP" data-command="#ModalCEP form:destination:#ep-addressData" data-modalcallback="vdEp.GTMBehavior(\'busca-cep\')">n&atilde;o sei o CEP</a>\
					</small>\
				</div>\
				<div class="ep-mod6" id="pf_field_address2" ' + (vdEpSkin.IsMostraEndereco == false ? '' : 'style="display: none;"') + '>\
					<div class="ep-cep-loading" style="display: none;">\
						<span class="ep-icon ep-48 ep-loading-48"></span>&#160;\
						Aguarde\
					</div>\
					<div class="ep-cep-error" style="display: none;">\
						<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
						Este CEP n&atilde;o &eacute; v&aacute;lido\
					</div>\
					<div class="ep-subForm ep-subForm-single ep-addressFields" style="display: none;">\
						<div class="ep-row">\
							<input type="hidden" name="proposta.tipoLogradouro" value="" class="ep-form-tipoLogradouro" />\
							<input type="hidden" name="proposta.nomeLogradouro" value="" class="ep-form-nomeLogradouro" />\
							<input type="hidden" name="proposta.nomeBairro" value="" class="ep-form-nomeBairro" />\
							<input type="hidden" name="proposta.nomeCidade" value="" class="ep-form-nomeCidade" />\
							<input type="hidden" name="proposta.codigoUF" value="" class="ep-form-codigoUF" />\
							<input type="hidden" name="proposta.numeroLogradouro" value="" class="ep-form-numero" />\
							<input type="hidden" name="proposta.compLogradouro" value="" class="ep-form-complemento"/>\
							<input type="hidden" name="calculo.tipoLogradouro" value="" class="ep-form-tipoLogradouro" />\
							<input type="hidden" name="calculo.nomeLogradouro" value="" class="ep-form-nomeLogradouro" />\
							<input type="hidden" name="calculo.nomeBairro" value="" class="ep-form-nomeBairro" />\
							<input type="hidden" name="calculo.nomeCidade" value="" class="ep-form-nomeCidade" />\
							<input type="hidden" name="calculo.codigoUF" value="" class="ep-form-codigoUF" />\
							<input type="hidden" name="calculo.numeroLogradouro" value="" class="ep-form-numero"/>\
							<input type="hidden" name="calculo.compLogradouro" value="" class="ep-form-complemento"/>\
							<span class="ep-mod12 ep-logradouro"></span>\
						</div>\
						<div class="ep-fake-logradouro" style="display: none;">\
							<div class="ep-row">\
								<div class="ep-mod7">\
									<input type="text" name="fake.nomeCidade" placeholder="Cidade" class="ep-form-control ep-valid ep-form-nomeCidade" disabled />\
								</div>\
								<div class="ep-mod4">\
									<input type="text" name="fake.codigoUF" placeholder="Estado" class="ep-form-control ep-valid ep-form-codigoUF" disabled/>\
								</div>\
							</div>\
							<div class="ep-row">\
								<div class="ep-mod11">\
									<input type="text" name="fake.nomeLogradouro" placeholder="Endere&ccedil;o" class="ep-form-control ep-cleanupText ep-valid ep-setValue" data-setvalue=".ep-form-nomeLogradouro=={value}" />\
								</div>\
							</div>\
							<div class="ep-row">\
								<div class="ep-mod11">\
									<input type="text" name="fake.nomeBairro" placeholder="Bairro" class="ep-form-control ep-cleanupText ep-valid ep-setValue" data-setvalue=".ep-form-nomeBairro=={value}" />\
								</div>\
							</div>\
						</div>\
						<div class="ep-row">\
							<div class="ep-mod4 ep-md-mod12">\
								<input type="text" name="fake.numeroResidencial" placeholder="n&uacute;mero" \
									class="ep-form-control ep-valid ep-onlyNumbers ep-setValue" \
									data-setvalue=".ep-form-numero=={value}" \
									value=" ' + (vdEpSkin.IsMostraEndereco == false && params.obj.calculo.nomeLogradouro != "GENERICA" ? params.obj.calculo.numeroResidencial : '') + '" />\
							</div>\
							<div class="ep-mod6 ep-lspan1 ep-md-mod12 ep-md-lspan0 ep-sm-join">\
								<input type="text" name="fake.compLogradouro" placeholder="complemento" class="ep-form-control ep-cleanupText vdo-cookie ep-setValue" data-cleanuptext="allownumbers" data-setvalue=".ep-form-complemento=={value}" value="' + $("input[name='proposta.compLogradouro']").val() + '" />\
							</div>\
						</div>\
					</div>\
				</div>';

		return ctt;
	},
	SetInsuranceDates: function (params) {
		var rtn = {},
			start = new Date(params.obj.retornoCalculo.dataInicioVigencia),
			end = new Date(params.obj.retornoCalculo.dataFinalVigencia);

		rtn = {
			inicioDateStr: [("0" + start.getDate()).slice(-2), ("0" + (start.getMonth() + 1)).slice(-2), start.getFullYear().toString().substr(2, 2)].join('/'),
			fimDateStr: [("0" + end.getDate()).slice(-2), ("0" + (end.getMonth() + 1)).slice(-2), end.getFullYear().toString().substr(2, 2)].join('/')
		}

		return rtn;
	},
	SetEquipArray: function (params, onlyRelev, sourceCalculo) {
		if (typeof onlyRelev == "undefined") onlyRelev = false;
		if (typeof sourceCalculo == "undefined") sourceCalculo = false;
		var equip = sourceCalculo ? params.obj.calculo.equipamentosPortateisItemCalculo : params.obj.retornoCalculo.equipamentosPortateisItemCalculo,
			newEquip = [],
			notebookIdx = 0,
			smartphoneIdx = 0,
			tabletIdx = 0,
			cameraIdx = 0,
			smartwatchIdx = 0,
			videocameraIdx = 0;

		for (var i = 0, l = equip.length; i < l; i++) {
			if (onlyRelev == true && equip[i].codigoRelevancia > 1) continue;
			switch (equip[i].codigoEquipamento) {
				//401 apple
				//512 notebook
				case 401:
				case 512:
					equip[i].ProtoType = "notebook";
					if (equip[i].codigoRelevancia == 1) {
						notebookIdx++;
						equip[i].ProtoIdx = notebookIdx;
					}
					break;
				case 646:
					equip[i].ProtoType = "smartphone";
					if (equip[i].codigoRelevancia == 1) {
						smartphoneIdx++;
						equip[i].ProtoIdx = smartphoneIdx;
					}
					break;
				case 647:
					equip[i].ProtoType = "smartwatch";
					if (equip[i].codigoRelevancia == 1) {
						smartwatchIdx++;
						equip[i].ProtoIdx = smartwatchIdx;
					}
					break;
				case 655:
					equip[i].ProtoType = "tablet";
					if (equip[i].codigoRelevancia == 1) {
						tabletIdx++;
						equip[i].ProtoIdx = tabletIdx;
					}
					break;
				case 124:
					equip[i].ProtoType = "camera";
					if (equip[i].codigoRelevancia == 1) {
						cameraIdx++;
						equip[i].ProtoIdx = cameraIdx;
					}
					break;
				case 302:
					equip[i].ProtoType = "videocamera";
					if (equip[i].codigoRelevancia == 1) {
						videocameraIdx++;
						equip[i].ProtoIdx = videocameraIdx;
					}
					break;
			}
			newEquip.push(equip[i]);
		}

		return newEquip;
	},
	Home: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: document.getElementById("equipamentosportateisForm").action,
				recuperarAtendimentoAction: "iniciarRecuperarAtendimento.ns",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoProduto: document.getElementById("codigoProduto") != null ? document.getElementById("codigoProduto").value : '',
				codigoCampanha: document.getElementById("codigoCampanha") != null ? document.getElementById("codigoCampanha").value : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				canalVenda: document.getElementById("calculo.canalVenda") != null ? document.getElementById("calculo.canalVenda").value : '',
				browserType: document.getElementById("calculo.browserType") != null ? document.getElementById("calculo.browserType").value : '',
				codigoSusepCorretor: document.getElementById("calculo.susepCorretorWeb") != null ? document.getElementById("calculo.susepCorretorWeb").value : '',
				codigoOperacao: document.getElementById("calculo.codigoOperacao") != null ? document.getElementById("calculo.codigoOperacao").value : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		/*
		window.customData.page = {
			'template': '1'
		}*/

		ctt += '<div class="ep-siteContainer">\
					<form name="calculoEpForm" id="ep-calculoEpForm" action="' + defaultParam.formAction + '" method="post">\
						<input type="hidden" name="codigoAtendimento" value=""/>\
						<input type="hidden" name="calculo.isObjetoClonado" value="false"/>\
						\
						<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '"/>\
						<input type="hidden" name="codigoProduto" value="' + defaultParam.codigoProduto + '" />\
						<input type="hidden" name="codigoCampanha" value="' + defaultParam.codigoCampanha + '" />\
						<input type="hidden" name="codigoCanal" value="' + defaultParam.codigoCanal + '" />\
						<input type="hidden" name="calculo.canalVenda" value="' + defaultParam.canalVenda + '" />\
						<input type="hidden" name="calculo.browserType" value="' + defaultParam.browserType + '" />\
						<input type="hidden" name="calculo.susepCorretorWeb" value="' + defaultParam.codigoSusepCorretor + '" />\
						<input type="hidden" name="calculo.codigoOperacao" value="' + defaultParam.codigoOperacao + '" />\
						<input type="hidden" name="calculo.ref" id="calculo.ref" value="' + (typeof defaultParam.obj.calculo.ref != "undefined" ? defaultParam.obj.calculo.ref : "") + '" />\
						<input type="hidden" name="calculo.campaign" id="calculo.campaign" value="' + (typeof defaultParam.obj.calculo.campaign != "undefined" ? defaultParam.obj.calculo.campaign : "") + '" />\
						<input type="hidden" name="calculo.medium" name="calculo.medium" value="' + (typeof defaultParam.obj.calculo.medium != "undefined" ? defaultParam.obj.calculo.medium : "") + '" />\
						<input type="hidden" name="calculo.source" id="calculo.source" value="' + (typeof defaultParam.obj.calculo.source != "undefined" ? defaultParam.obj.calculo.source : "") + '" />\
						<input type="hidden" name="calculo.content" id="calculo.content" value="' + (typeof defaultParam.obj.calculo.content != "undefined" ? defaultParam.obj.calculo.content : "") + '" />\
						<input type="hidden" name="calculo.origem" id="calculo.origem" value="' + (typeof defaultParam.obj.calculo.origem != "undefined" ? defaultParam.obj.calculo.origem : "") + '" />\
						<input type="hidden" name="calculo.IP" id="calculo.IP" value="" />\
						<input type="hidden" name="calculo.term" id="calculo.term" value="" />\
						<input type="hidden" name="skin" value="' + defaultParam.skin + '" />\
						\
						<div class="ep-lp">\
							<div class="vdo__preheader vdo__landpages_temporto_header">\
								<div class="vdo__preheader--signin">\
									<div class="ps-container">\
										<div class="ps-row">\
											<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1">\
												J&aacute; iniciou uma cota&ccedil;&atilde;o?\
												<a href="'+defaultParam.recuperarAtendimentoAction+'">\
													continue agora\
													<span class="ps-ico ps-ico-arrow-right"></span>\
												</a>\
											</div>\
										</div>\
									</div>\
								</div>\
								'+vdEpSkin.BuildTopBroker(defaultParam, vdEpSkin.IsSkinPartner, vdEpSkin.IsTopoCorretor)+'\
							</div>\
							<div class="ep-lp-startup">\
								<div class="vdo__landpages_temporto_cover vdo__landpages_temporto_cover--ep '+(vdEpSkin.IsSkinPartner ? 'vdo__landpages-cover--broker vdo__landpages-cover--broker-white vdo__landpages-cover--broker-'+window.skinBroker.brokerName+'' : '')+'">\
									<div class="ps-container">\
										<div class="vdo__landpages_temporto_cover--card">\
											<h1 class="vdo__landpages_temporto_cover--title">Seguro de Equipamentos Port&aacute;teis</h1>\
											<ul class="vdo__landpages_temporto_list">\
												<li>Cobertura para roubo</li>\
												<li>Cobertura para danos causados por &aacute;gua</li>\
												<li>Cobertura para danos el&eacute;tricos</li>\
											</ul>\
										</div>\
										<div class="ps-row">\
											<div class="ps-mod8 ps-sm-mod6">\
												<div class="vdo__landpages-cover-brand">\
													<span class="ps-logo '+(vdEpSkin.IsSkinPartner ? 'ps-logo-white' : '')+'"></span>\
												</div>\
												<div class="vdo__landpages-form vdo__landpages_temporto_form vdo__landpages_temporto_form--ep" id="ep-calcStartup-form">\
													<h2 class=" vdo__landpages_temporto_form--title">Fa&ccedil;a agora mesmo a simula&ccedil;&atilde;o do seguro e contrate online</h2>\
													<p class=" vdo__landpages_temporto_form--text">Qual seu tipo de equipamento?</p>\
													'+vdEpSkin.HomeBuildListEquipaments()+'\
												</div>\
											</div>\
										</div>\
									</div>\
								</div>\
							</div>\
							'+vdEpSkin.HomeLandpage()+'\
						</div>';
		ctt += '		<div class="ep-form ep-calc-form ep-top-fixed" style="display: none;">\
							' + vdEpSkin.BuildTop("home", defaultParam) + '\
							<div class="ep-deviceListBig" id="ep-deviceListBig" style="display: none;">\
								<div class="ep-deviceList-header">\
									<div class="ep-container">\
										<div class="ep-row">\
											<div class="ep-mod9">\
												Confira seus <strong>Aparelhos</strong>\
											</div>\
											<div class="ep-mod3 ep-right">\
												<a href="javascript:;" class="ep-deviceList-hideMore"><span class="ep-icon ep-36 ep-close-white-36"></span></a>\
											</div>\
										</div>\
									</div>\
								</div>\
								<div class="ep-container ep-deviceList-content">\
									<div class="ep-row">\
										<div class="ep-mod1"><a href="javascript:;" class="ep-sliderArrowLeft"><span class="ep-icon ep-24 ep-arrow-left-white-24"></span></a>&#160;</div>\
										<div class="ep-mod10 ep-slider" data-slider=".ep-sliderArrowLeft;.ep-sliderArrowRight">\
											<div class="ep-sliderContainer">\
											</div>\
										</div>\
										<div class="ep-mod1 ep-right"><a href="javascript:;" class="ep-sliderArrowRight"><span class="ep-icon ep-24 ep-arrow-right-white-24"></span></a></div>\
									</div>\
								</div>\
							</div>\
							<div class="ep-form ep-calc-step">\
								<div class="ep-container">\
									<div class="ep-row ep-calc-step" id="ep-calc-step1-form">\
										<div class="ep-mod12" id="ep-deviceList-form">\
										</div>\
										<div class="ep-mod12 ep-deviceList-add" id="ep-deviceList-add">\
											<div class="ep-form-accordion ep-accordion-closed">\
												<a href="javascript:;" class="ep-accordion-header">\
													<span class="ep-icon ep-36 ep-plus-blue-36"></span>\
													<strong>Adicionar aparelho</strong>\
												</a>\
												<div class="ep-accordion-container" style="display: none;">\
													<ul class="ep-lp-deviceSelect">\
														<li>\
															<a href="javascript:;" class="ep-device-add" data-type="smartwatch"><span class="ep-icon ep-72 ep-smartwatch-gray-72"></span> <strong>SMARTWATCH</strong></a>\
														</li>\
														<li>\
															<a href="javascript:;" class="ep-device-add" data-type="notebook"><span class="ep-icon ep-72 ep-notebook-gray-72"></span> <strong>NOTEBOOK</strong></a>\
														</li>\
														<li>\
															<a href="javascript:;" class="ep-device-add" data-type="tablet"><span class="ep-icon ep-72 ep-tablet-gray-72"></span> <strong>TABLET</strong></a>\
														</li>\
														<li>\
															<a href="javascript:;" class="ep-device-add" data-type="camera"><span class="ep-icon ep-72 ep-camera-gray-72"></span> <strong>C&Acirc;MERA</strong></a>\
														</li>\
														<li>\
															<a href="javascript:;" class="ep-device-add" data-type="videocamera"><span class="ep-icon ep-72 ep-videocamera-gray-72"></span> <strong>FILMADORA</strong></a>\
														</li>\
													</ul>\
													<br/>\
													<div class="ep-right">\
														<a href="javascript:;" class="ep-trigger" data-trigger="#ep-deviceList-add .ep-accordion-header">cancelar</a>\
													</div>\
												</div>\
											</div>\
										</div>\
										<div class="ep-mod12 ep-submitRow" style="margin-top: 20px;">\
											<div class="ep-mod4 ep-lspan4">\
												<a href="javascript:;" class="ep-btn ep-accordion-nextStep">AVAN&Ccedil;AR</a>\
											</div>\
										</div>\
									</div>\
									<div class="ep-row ep-calc-step" id="ep-calc-step2-lgpd" style="display: none;">\
										'+vdEpSkin.TplLGPD()+'\
									</div>\
								</div>\
							</div>\
						</div>\
					</form>\
					' + vdEpSkin.BuildFooter() + '\
				</div>\
				' + vdEpSkin.BuildModal("ModalAccordionRemove") + vdEpSkin.BuildModal("ModalCEP") + vdEpSkin.BuildModal("ModalErroVerificacao") + vdEpSkin.BuildModal("ModalErroCriticas") + vdEpSkin.BuildModal('ModalLimiteValorApolice') + '\
				' + vdEpSkin.BuildModalCookie();

		return ctt;
	},
	HomeBuildListEquipaments: function(){
		var rtn = '';

		rtn = '\
			<input type="hidden" name="deviceCount" id="ep-deviceCount" value="0" />\
			<ul class="vdo__landpages-form-equip">\
				<li>\
					<a href="javascript:;" id="ep-device-smartphone" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-smartphone"></span>\
						</div>\
						Smartphone\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="smartphoneQtt" id="smartphone-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
				<li>\
					<a href="javascript:;" class="ep-device-showHide" id="ep-device-notebook" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-laptop"></span>\
						</div>\
						Notebook\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="notebookQtt" id="notebook-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
				<li>\
					<a href="javascript:;" class="ep-device-showHide" id="ep-device-tablet" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-tablet"></span>\
						</div>\
						Tablet\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="tabletQtt" id="tablet-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
				<li>\
					<a href="javascript:;" class="ep-device-showHide" id="ep-device-smartwatch" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-smartwatch"></span>\
						</div>\
						Smartwatch\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="smartwatchQtt" id="smartwatch-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
				<li>\
					<a href="javascript:;" class="ep-device-showHide" id="ep-device-camera" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-camera"></span>\
						</div>\
						C&acirc;mera\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="cameraQtt" id="camera-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
				<li>\
					<a href="javascript:;" class="ep-device-showHide" id="ep-device-videocamera" data-calcstartup="#ep-calcStartup-form">\
						<div class="vdo__landpages-form-equip-icon">\
							<span class="ps-ico ps-ico-security-camera"></span>\
						</div>\
						Filmadora\
						<span class="ep-counter" style="display: none;">0</span>\
					</a>\
					<input type="hidden" name="videocameraQtt" id="videocamera-qtde" value="0" maxlength="2" class="ep-form-control ep-onlyNumbers" data-context=".ep-lp-startup" autocomplete="off" />\
				</li>\
			</ul>\
		';

		return rtn;
	},
	HomeLandpage: function() {
		var rtn = '';

		rtn = '\
			<div class="ep-lp-highlight">\
				<div class="ep-container">\
					<div class="ep-row ep-center">\
						<div class="ep-mod10 ep-lspan1 ep-sm-mod12">\
							<h2><strong>Principais Garantias</strong></h2>\
							<p>\
								Com o Porto Seguro Equipamentos Port&aacute;teis, voc&ecirc; protege seu equipamento,<br class="ep-responsive-break" />\
								seja para uso pessoal, lazer ou profissional.<br class="ep-sm-break" /> <strong>Conhe&ccedil;a as principais garantias e benef&iacute;cios:</strong>\
							</p>\
						</div>\
						<ul class="ep-featureList">\
							<li>\
								<span class="ep-icon ep-96 ep-acidente-blue-96"></span>\
								<strong>Caso de<br class="ep-responsive-break" /> acidente</strong>\
							</li>\
							<li>\
								<span class="ep-icon ep-96 ep-incendio-blue-96"></span>\
								<strong>Caso de<br class="ep-responsive-break" /> inc&ecirc;ndio</strong>\
							</li>\
							<li>\
								<span class="ep-icon ep-96 ep-raio-blue-96"></span>\
								<strong>Queda <br class="ep-responsive-break" />de raio</strong>\
							</li>\
							<li>\
								<span class="ep-icon ep-96 ep-tentativaRoubo-blue-96"></span>\
								<strong>Dano na<br class="ep-responsive-break" /> tentativa de roubo</strong>\
							</li>\
							<li>\
								<span class="ep-icon ep-96 ep-impactoVeiculo-blue-96"></span>\
								<strong>Impacto de<br class="ep-responsive-break" /> ve&iacute;culos</strong>\
							</li>\
						</ul>\
					</div>\
				</div>\
			</div>\
			<div class="ep-lp-additional-highlight">\
				<div class="ep-container">\
					<div class="ep-row">\
						<div class="ep-mod12">\
							<h2>Coberturas <strong>Opcionais</strong></h2>\
						</div>\
					</div>	\
					<div class="ep-row">\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-danosEletricos-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Danos El&eacute;tricos</h3>\
								Garante os danos ao equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, em decorr&ecirc;ncia de oscila&ccedil;&otilde;es e descargas el&eacute;tricas de energia, al&eacute;m de curtos-circuitos que afetem o equipamento.\
							</div>\
						</div>\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-lentesAcessorios-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Lentes e Acess&oacute;rios</h3>\
								Cobertura para acess&oacute;rios utilizados junto ao equipamento principal segurado e comprados separados. Em caso de d&uacute;vidas, fale com seu corretor.\
							</div>\
						</div>\
					</div>\
					<div class="ep-row">\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-garantiaInternacional-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Internacional</h3>\
								Contratada esta cobertura, voc&ecirc; ter&aacute; extens&atilde;o das garantias contratadas para sinistros ocorridos fora do Brasil.\
							</div>\
						</div>\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-roubo-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Subtra&ccedil;&atilde;o do Bem</h3>\
								Garante a subtra&ccedil;&atilde;o do equipamento segurado, mediante amea&ccedil;a direta, emprego de viol&ecirc;ncia contra o segurado ou rompimento de obst&aacute;culo onde o equipamento esteja guardado.\
							</div>\
						</div>\
					</div>\
					<div class="ep-row">\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-perdaAluguel-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Perda ou Pagamento de Aluguel</h3>\
								Garante a perda ou o pagamento dos alugu&eacute;is dos equipamentos, caso estes sofram danos devidamente cobertos.\
							</div>\
						</div>\
						<div class="ep-mod6">\
							<div class="ep-mod3 ep-sm-mod3">\
								<span class="ep-icon ep-96 ep-danoLiquido-gray-96"></span>\
							</div>\
							<div class="ep-mod9 ep-sm-mod9">\
								<h3>Danos por &aacute;gua ou l&iacute;quido</h3>\
								Garante os danos ao equipamento e seus acess&oacute;rios, ocasionados por &aacute;gua ou qualquer subst&acirc;ncia l&iacute;quida.\
							</div>\
						</div>\
					</div>\
				</div>\
			</div>\
		';

		return rtn;
	},
	TplLGPD: function() {
		var rtn = '';
	
		rtn = '\
			<div class="vo-lgpd-frm" id="div-lgpd-form">\
				<h1>Dados do <strong>propriet&aacute;rio</strong></h1>\
				'+vdEpSkin.TplLGPDStepA()+'\
				'+vdEpSkin.TplLGPDStepB()+'\
				'+vdEpSkin.TplLGPDStepC()+'\
				'+vdEpSkin.TplLGPDStepD()+'\
			</div>\
		';
	
		return rtn;
	},
	TplLGPDStepA: function() {
		var rtn = '';
	
		rtn = '\
			<div class="ps-wizard-content-item vo-lgpd-frm-step-identify" id="skin-vo-lgpd-frm-step-identify">\
				<p class="vo-lgpd-frm-subheading">Informe os dados abaixo para seguir com a contrata&ccedil;&atilde;o do seguro</p>\
				<div class="ep-row">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">CPF / CNPJ</label>\
					</div>\
					<div class="ep-mod5">\
						<input type="hidden" class="digitoCnpjCpfProponente" name="calculo.digitoCnpjCpfProponente" id="digitoCnpjCpfProponente" value="">\
						<input type="hidden" class="ps-frm-split cpfCpjBuff" id="cpfCpjBuff" data-split="/;.numeroCpfCnpjProponente;.ordemCnpjProponente" value="">\
						<input type="hidden" class="numeroCpfCnpjProponente" name="calculo.numeroCpfCnpjProponente" id="numeroCpfCnpjProponente" value="">\
						<input type="hidden" class="ordemCnpjProponente" name="calculo.ordemCnpjProponente" id="ordemCnpjProponente" value="">\
						<input type="text" class="ep-form-control ep-valid ps-frm-entry ps-frm-valid ps-frm-cpf-cnpj ps-frm-split fl-entry-cpfCnpj-proponente" data-split="-;.cpfCpjBuff;.digitoCnpjCpfProponente" name="vdo-cpfProponente" value="">\
						<small class="ps-helper">'+vdEp.FillContent("#vo-lgpd-frm-step-identify .ps-helper")+'</small>\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-lspan3 ep-mod7 ep-subForm ep-subForm-single">\
						<div class="ep-row ep-mod12 ps-hide">\
							<input type="checkbox" class="ep-checkbox vdo__lgd-checked" name="calculo.optinPolitica" id="vd-calculo-optin1" value="true" class="ep-checkbox" checked>\
							<label class="ep-checkbox" for="vd-calculo-optin1">\
								<span class="ep-icon ep-24 ep-checkbox-icon"></span>\
								Li e concordo com a <a href="'+vdEp.FillContent(vdEpSkin.DefaultLinks.PoliticaPrivacidade, "", true)+'" target="_blank">Pol&iacute;tica de Privacidade.</a>\
							</label>\
						</div>\
						<div class="ep-row ep-mod12 ps-hide">\
							<input type="checkbox" class="ep-checkbox vdo__lgd-checked" name="calculo.optinCompartilhamento" id="vd-calculo-optin2" value="true" class="ep-checkbox" checked>\
							<label class="ep-checkbox" for="vd-calculo-optin2">\
								<span class="ep-icon ep-24 ep-checkbox-icon"></span>\
								Concordo em compartilhar minhas informa&ccedil;&otilde;es com o Grupo Porto Seguro e Corretores Parceiros para realizar meu or&ccedil;amento e comercializa&ccedil;&atilde;o dos produtos e servi&ccedil;os.\
							</label>\
						</div>\
						<div class="ep-row ep-mod12">\
							<input type="checkbox" class="ep-checkbox vdo__lgd-checked" name="calculo.optinComunicacao" id="vd-calculo-optin3" value="false" class="ep-checkbox">\
							<label class="ep-checkbox" for="vd-calculo-optin3">\
								<span class="ep-icon ep-24 ep-checkbox-icon"></span>\
								'+vdEp.FillContent("label[for=calculo-optin3")+'\
							</label>\
						</div>\
						<div class="ep-row ep-mod12">\
							<p>Ao clicar em "Continuar" voc&ecirc; est&aacute; ciente de que a Porto ir&aacute; coletar e tratar seus dados pessoais de acordo com a nossa <a href="'+vdEp.FillContent(vdEpSkin.DefaultLinks.PoliticaPrivacidade, "", true)+'" target="_blank">Pol&iacute;tica de Privacidade</a> e <a href="' + vdEpSkin.DefaultLinks.CondicoesGerais + '" target="_blank">Termos de uso</a>.</p>\
						</div>\
					</div>\
				</div>\
				<div class="ep-row ep-submitRow">\
					<div class="ep-mod4 ep-lspan4">\
						<a href="javascript:;" class="ep-btn btn_passo1 vdo__lgd-cpfcnpj" id="btn_passo1">Continuar</a>\
					</div>\
				</div>\
			</div>\
		';
	
		return rtn;
	},
	TplLGPDStepB: function() {
		var rtn = '';
		
		rtn = '\
			<div class="ps-wizard-content-item vo-lgpd-frm-step-hello" id="skin-vo-lgpd-frm-step-hello" style="display: none;">\
				<div class="ep-row">\
					<div class="ep-mod4 ep-lspan4">\
						<p class="ps-heading-3 ps-light vo-lgpd-frm-title">Ol&aacute; <span class="nome_cliente"></span></p>\
						<p class="vo-lgpd-frm-subtitle">'+vdEp.FillContent("#vo-lgpd-frm-step-hello .vo-lgpd-frm-subtitle")+'</p>\
					</div>\
				</div>\
				<div class="ep-row ep-submitRow">\
					<div class="ep-mod4 ep-lspan4">\
						<a href="javascript:;" class="ep-btn ps-wizard-step-show" data-wizardshow="#skin-vo-lgpd-frm-step-enter-data">Continuar</a>\
					</div>\
				</div>\
			</div>\
		';
	
		return rtn;
	},
	TplLGPDStepC: function() {
		var rtn = '';
		
		rtn = '\
			<div class="ps-wizard-content-item vo-lgpd-frm-step-enter-data" id="skin-vo-lgpd-frm-step-enter-data" style="display: none;">\
				<p class="vo-lgpd-frm-subheading">'+vdEp.FillContent("#vo-lgpd-frm-step-enter-data .vo-lgpd-frm-subheading")+'</p>\
				<div class="ep-row editar-dados" id="editar-dados">\
					<div class="ep-mod3 ep-lspan3">\
						<h4 class="ps-subheading">'+vdEp.FillContent("#vo-lgpd-frm-step-enter-data .ps-subheading")+'</h4>\
					</div>\
					<div class="ep-mod3 ep-lspan2">\
						<a href="javascript:;" class="vo-lgpd-frm-edit vo-lgpd-frm-link ps-wizard-step-show ep-callback" data-callback="$(\'#skin-vo-lgpd-frm-step-edit-data .user\').val($(\'.fl-entry-cpfCnpj-proponente\').val())" data-wizardshow="#skin-vo-lgpd-frm-step-edit-data">Editar dados</a>\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl" id="label-nome">Nome completo</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="hidden" name="calculo.tipoProponente" value="1" id="" class="ep-radio">\
						<input type="text" class="ep-form-control ep-cleanupText ep-valid fl-entry-nome-proponente" name="calculo.nomeProponente" data-exec="blur:vdEpSkin.voScanValidateFields(this);" data-valid="nome" value="">\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-mod6 ep-lspan3">\
						<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-showHide-toggle" id="proponente-nome-social" data-showhide="#ep-nome-social-proponente" />\
						<label class="ep-checkbox" for="proponente-nome-social">\
							<span class="ep-icon ep-24 ep-checkbox-icon" style="margin-right: 4px;"></span>\
							Tenho nome social\
						</label>\
						<span class="ep-fieldTooltip-container">\
							<span class="ep-icon ep-24 ep-help-yellow-24 ep-fieldTooltip-trigger"></span>\
							<div class="ep-fieldTooltip">\
								Nome social &eacute; o nome pelo qual pessoa travesti ou transexual se identifica e &eacute; socialmente reconhecida.\
							</div>\
						</span>\
					</div>\
				</div>\
				<div id="ep-nome-social-proponente" style="display: none;">\
					<div class="ep-row">\
						<div class="ep-mod3 ep-right">\
							<label class="ep-lbl" id="label-nome">Nome social</label>\
						</div>\
						<div class="ep-mod6">\
							<input type="text" class="ep-form-control ep-cleanupText ep-valid fl-entry-nome-social-proponente" name="calculo.nomeSocialProponente" data-exec="blur:vdEpSkin.voScanValidateFields(this);" data-valid="nome" value="">\
						</div>\
					</div>\
					<div class="ep-row">\
						<div class="ep-mod6 ep-lspan3">\
							<div class="ep-info-block">\
								O nome social ser&aacute; utilizado para sua identifica&ccedil;&atilde;o na Porto. Em caso de ajuste em ap&oacute;lice e contratos vigentes, entre em contato com o(a) Corretor(a) respons&aacute;vel ou com nosso canal de atendimento\
							</div>\
						</div>\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">E-mail</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="text" class="ep-form-control ep-valid ep-emailMask fl-entry-email-proponente" name="calculo.emailProponente" data-exec="blur:vdEpSkin.voScanValidateFields(this);" data-valid="email" value="">\
					</div>\
				</div>\
				<div class="ep-row div-sexo-nasc" id="div-sexo-nasc">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">Data de nascimento</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="hidden" class="diaDataNascimento" name="calculo.diaDataNascimento" id="diaDataNascimento" value="">\
						<input type="hidden" class="mesDataNascimento" name="calculo.mesDataNascimento" id="mesDataNascimento" value="">\
						<input type="hidden" class="anoDataNascimento" name="calculo.anoDataNascimento" id="anoDataNascimento" value="">\
						<input type="tel" name="calculo.dataNascimento" id="dataNascimento" value="" class="ep-form-control ps-frm-date ep-valid ps-frm-split dataNascimento" data-split="/;.diaDataNascimento;.mesDataNascimento;.anoDataNascimento" placeholder="somente n&uacute;meros" maxlength="10">\
					</div>\
				</div>\
				<div class="ep-row div-tel" id="div-tel">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">Telefone</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="hidden" name="calculo.foneCelularDDD" value="">\
						<input type="hidden" name="calculo.foneCelularNumero" value="">\
						<input type="hidden" name="calculo.dddCelular" value="">\
						<input type="hidden" name="calculo.numeroCelular" value="">\
						<input type="text" class="ep-form-control ep-valid ep-phoneNumber ep-split fl-entry-fone-celular-proponente" name="vpf.foneCelular" value="" data-split=") ;input[name=\'calculo.foneCelularDDD\'];input[name=\'calculo.foneCelularNumero\']">\
					</div>\
				</div>\
				<div class="ep-row mask-aux" style="display: none;">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">Telefone</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="text" class="ep-form-control numeroTelefoneMask" name="vdo-proposta-emailProponente" value="">\
					</div>\
				</div>\
				<div class="ep-row div-cep">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">CEP Residencial</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="hidden" name="calculo.numeroCepResidencial" class="numeroCep" id="numeroCep">\
						<input type="hidden" name="calculo.complementoCepResidencial" class="numeroComplementoCep" id="numeroComplementoCep">\
						<input type="hidden" name="calculo.tipoEndereco" id="tipoEndereco" value="1">\
						<input type="hidden" name="calculo.tipoLogradouro" class="tipoLogradouro" id="tipoLogradouro">\
						<input type="hidden" name="calculo.nomeLogradouro" class="fl-entry-logradouro-proponente" id="fl-entry-logradouro-proponente">\
						<input type="hidden" name="calculo.nomeBairro" class="fl-entry-bairro-proponente" id="fl-entry-bairro-proponente">\
						<input type="hidden" name="calculo.nomeCidade" class="fl-entry-cidade-proponente" id="fl-entry-cidade-proponente">\
						<input type="hidden" name="calculo.codigoUF" class="fl-entry-uf-proponente" id="fl-entry-uf-proponente">\
						<input type="hidden" name="calculo.codigoCidade" id="codigoCidade" class="codigoCidade">\
						<input type="text" class="ep-form-control ep-valid ep-cepMask ep-callback-blur fl-entry-cep-proponente" data-callback="validarCampoCep(\'numeroCep\',\'numeroComplementoCep\',\'tipoLogradouro\',\'fl-entry-logradouro-proponente\',\'fl-entry-bairro-proponente\',\'fl-entry-cidade-proponente\',\'fl-entry-uf-proponente\',\'codigoCidade\');" value="">\
						<a href="javascript:;" class="ps-open-modal" data-modal="#ModalBuscarCEP">N&atilde;o sabe o seu CEP?</a>\
					</div>\
				</div>\
				<div class="ep-row mask-aux" style="display: none;">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">CEP Residencial</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="text" class="ep-form-control numeroCepMask" id="numeroCepMask" value="">\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-mod3 ep-right">\
						<label class="ep-lbl">N&uacute;mero da resid&ecirc;ncia</label>\
					</div>\
					<div class="ep-mod6">\
						<input type="text" class="ep-form-control ep-valid" name="calculo.numeroResidencial">\
					</div>\
				</div>\
				<div class="cep_generico fl-form-cep-generico" style="display: none;">\
					<div class="ep-row">\
						<div class="ep-mod3 ep-right">\
							<label class="ep-lbl">Bairro</label>\
						</div>\
						<div class="ep-mod6">\
							<input type="text" class="ep-form-control ep-valid ps-frm-cleanup fl-entry-nome-bairro">\
						</div>\
					</div>\
					<div class="ep-row">\
						<div class="ep-mod3 ep-right">\
							<label class="ep-lbl">Endere&ccedil;o</label>\
						</div>\
						<div class="ep-mod6">\
							<input type="text" class="ep-form-control ep-valid ps-frm-cleanup fl-entry-nome-logradouro">\
						</div>\
					</div>\
				</div>\
				<div class="ep-row">\
					<div class="ep-mod6 ep-lspan3 ps-helper descricaoCep" id="descricaoCep"></div>\
				</div>\
				<div class="ep-row ep-submitRow">\
					<div class="ep-mod4 ep-lspan4">\
					<input type="button" class="ep-btn ep-calc-submit-1 ep-calc-validate" value="CALCULAR" data-lucida-form-traked="true">\
						<a href="javascript:;" class="ep-trigger vo-lgpd-frm-link vo-lgpd-frm-cancel" data-trigger="#btn-cancel" style="display: none;">N&atilde;o &eacute; voc&ecirc;?</a>\
					</div>\
				</div>\
			</div>\
		';
	
		return rtn;
	},
	TplLGPDStepD: function() {
		var rtn = '';
	
		rtn = '\
			<div class="ps-wizard-content-item" id="skin-vo-lgpd-frm-step-edit-data" style="display:none">\
				<div class="ep-row">\
					<div class="ep-mod6 ep-lspan3">\
						<h4 class="ps-subheading vo-lgpd-frm-title">'+vdEp.FillContent("#vo-lgpd-frm-step-edit-data .vo-lgpd-frm-title")+'</h4>\
						<p class="vo-lgpd-frm-subtitle">'+vdEp.FillContent("#vo-lgpd-frm-step-edit-data .vo-lgpd-frm-subtitle")+'</p>\
						<div class="ep-row">\
							<div class="ep-mod3 ep-right">\
								<label class="ep-lbl">CPF</label>\
							</div>\
							<div class="ep-mod9">\
								<input type="text" value="" name="user" id="user" class="ep-form-control user" disabled="disabled">\
							</div>\
						</div>\
						<div class="ep-row">\
							<div class="ep-mod3 ep-right">\
								<label class="ep-lbl">Senha</label>\
							</div>\
							<div class="ep-mod9">\
								<input type="password" name="pass" id="pass" class="ep-form-control ep-valid pass ep-callback-blur" data-callback="$(this).val(btoa($(this).val()))" value="">\
							</div>\
						</div>\
						<div class="ep-row ep-submitRow">\
							<div class="ep-lspan3">\
								<a href="javascript:;" class="ep-btn ep-trigger" data-trigger="#btn-efetuar-login">Continuar</a>\
							</div>\
						</div>\
						<div class="ep-row">\
							<div class="ep-lspan3">\
								<p class="ps-subheading vo-lgpd-modal-subheading ps-alignCenter">ou acesse com:</p>\
								<a href="javascript:;" class="ps-btn ps-btn-social ps-btn-ico-right ep-trigger" data-trigger="#btnFormLoginFacebook">\
									Facebook <span class="ps-ico ps-ico-facebook"></span>\
								</a>\
							</div>\
						</div>\
					</div>\
				</div>\
			</div>\
		';
	
		return rtn;
	},
	CheckLGPD: function(frm,btn) {
		var rtn = true,
			button = $(btn);
	
		if(!vdEp.ValidateForm(frm)) {
			rtn = false;
		} else {
			button.trigger("click");
		} 
	
		return rtn; 
	},
	voScanValidateFields: function(obj) {
		var campo_input = $(obj),
			campo = campo_input.data("valid"),
			valor = campo_input.val();

		if(campo_input.val().length > 0 && campo_input.is(":visible")){
			campo_input.removeClass("ep-error");
	
			$.ajax({
				url: "/vendaonline/vocomponente.do?dispatch=validarCampo&campo="+campo+"&valor="+valor,
				success: function(result){
				if(!result.isValid){
					campo_input.addClass("ep-error");
					console.log("campo invalido");
					
					campo_input.val("");
				}
				campo_input = null;
				},
				error: function(error){
					console.error("erro ao realizar integracao", error);
					campo_input = null;
				}
			});
			
			if(false)
				campo_input.addClass("ep-error");
		}
	},
	ValorSeguro: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: $("a.cancel").attr("href"),
				personalizarAction: "personalizaseguro.ns",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoAtendimento: $("input[name='codigoAtendimento']") != null ? $("input[name='codigoAtendimento']").val() : '',
				codigoProduto: document.getElementById("codigoProduto") != null ? document.getElementById("codigoProduto").value : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				numeroCpfCnpjProponente: document.getElementById("calculo.numeroCpfCnpjProponente") != null ? document.getElementById("calculo.numeroCpfCnpjProponente").value : '',
				digitoCnpjCpfProponente: document.getElementById("calculo.digitoCnpjCpfProponente") != null ? document.getElementById("calculo.digitoCnpjCpfProponente").value : '',
				tipoEquipamento: document.getElementById("calculo.tipoEquipamento") != null ? document.getElementById("calculo.tipoEquipamento").value : '',
				susepCorretorWeb: document.getElementById("calculo.susepCorretorWeb") != null ? document.getElementById("calculo.susepCorretorWeb").value : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		var pkg = defaultParam.obj.retornoCalculo.listaPacotesContratacao,
			model = defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo;

		/*
		window.customData.page = {
			'template': '2',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/

		ctt += '<div class="ep-siteContainer">\
					<form name="contratacaoEpForm" id="ep-contratacaoEpForm" action="' + defaultParam.formAction + '" method="post" class="showFeedback">\
						<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '" />\
						<input type="hidden" name="codigoAtendimento" value="' + defaultParam.codigoAtendimento + '" />\
						<input type="hidden" name="codigoProduto" value="' + defaultParam.codigoProduto + '" /> \
						<input type="hidden" name="calculo.tipoEquipamento" value="' + defaultParam.tipoEquipamento + '" />\
						<input type="hidden" name="calculo.susepCorretorWeb" value="' + defaultParam.susepCorretorWeb + '" />\
						<input type="hidden" name="skin" value="' + defaultParam.skin + '" />\
						<input type="hidden" name="calculo.numeroCpfCnpjProponente" value="' + defaultParam.numeroCpfCnpjProponente + '" />\
						<input type="hidden" name="calculo.digitoCnpjCpfProponente" value="' + defaultParam.digitoCnpjCpfProponente + '" />\
						<input type="hidden" name="proposta.pacoteSeguroEscolhido" id="ep-plano-id" value="" />\
						<input type="hidden" name="proposta.valorTotalPacoteEscolhido" id="ep-plano-valor" value="" />\
						<input type="hidden" name="proposta.vezesSemJuros" id="ep-plano-vezes" value="" />\
						\
						<div class="ep-form ep-calc-result">\
						' + vdEpSkin.BuildTop("valorSeguro", defaultParam) + '\
						<div class="ep-form">\
							<div class="ps-container">\
								<div class="ps-row">\
									<div class="ps-mod8 ps-sm-mod12 ps-sm-alignCenter">\
										<h1>Resultado da Cota&ccedil;&atilde;o</h1>\
									</div>\
								</div>\
								<div class="ps-row">\
									<div class="ps-mod8 ps-sm-mod12 ps-lg-mod8 ps-lg-lspan2 ps-noGutter ps-md-gutter">\
										<div class="ep-resultPrices">';
											for (var i = 0, l = pkg.length; i < l; i++) {
												ctt += vdEpSkin.ValorItemPlano(pkg[i], i,pkg.length, model[i], defaultParam.assetPath);
											}

											if (pkg.length == 1) {
												ctt += vdEpSkin.ValorItemPlanoPersonalizar(defaultParam)	
											}
		ctt += '						</div>\
									</div>\
								</div>\
							</div>\
						</div>\
						'+ vdEpSkin.ValorCoverages() + '\
						'+ vdEpSkin.ValorFooterNotes() + '\
						'+ vdEpSkin.BuildFooter() + '\
						'+ vdEpSkin.BuildModal("ModalAceite", defaultParam) + '\
					</form>\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
				</div>';

		setTimeout(function () {
			vdEpSkin.Slides(".ep-resultPrices");
			vdEpSkin.Slides(".ep-pc-features");
			vdEp.FormBindings(".ep-resultPrices");
			psLib.Modal(".vd-pi-cta, .vd-login");

			$("body").on("click",".vd-plan-item .ps-btn",function() {
				verificarLogin("submitTela()")
			});
		}, 100);

		return ctt;
	},
	CheckEquipTypesInCalc: function(obj) {
		var rtn = [];

		for(var i=0,l=obj.length;i<l;i++) {
			var tpEquip = obj[i].descricaoEquipamento.toLowerCase();

			if($.inArray(tpEquip,rtn) == -1)
				rtn.push(tpEquip);
		}

		return rtn;
	},
	ValorItemPlano: function(pkg,idx,count, model, img) {
		var rtn = '',
			label = vdEpSkin.ValorItemPlanoLabel(pkg.nomePacote),
			login = vdEpSkin.IsLoginEnabled,
			recommended = idx == 0 ? true : false,
			parcela = pkg.formasParcelamento.listaParcADC.length >= 6 ? 5 : pkg.formasParcelamento.listaParcADC.length - 1,
			equipamentType = model != undefined ? model.codigoTipoEquipamento : false;

		rtn += '<div class="ps-sm-mod6">\
					<div class="vd-plan-item ' + (recommended ? 'vd-plan-recommended' : '') + ' ep-center">\
						<div class="vd-plan-card vd-center">\
							<p class="vd-pi-title">\
								' + label + '\
								<span class="vd-plan-current">Exibindo: '+count+' Plano'+(count == 1 ? '' : 's')+' - <span>1</span> de '+count+'</span>\
							</p>\
							<div class="vdo-promobar" data-expires="2020-03-22T23:59:59">\
								<img src="https://www.portoseguro.com.br/conteudo/consumidor/promobar/img/promobar-ep.mobile.gif" />\
							</div>\
							<div class="vd-pi-price">\
								<span class="vdo-promobar" data-expires="2020-03-22T23:59:59">de <small class="vd-price-full" style="text-decoration: line-through; display: inline-block; padding-bottom: 10px;">' + vdEp.FormatCurrency(((10/100)* pkg.formasParcelamento.listaParcADC[0].valorPrimeiraParcela) + pkg.formasParcelamento.listaParcADC[0].valorPrimeiraParcela) + '</small> &agrave; vista </span>\
								<div>' + pkg.formasParcelamento.listaParcADC[parcela].numeroParcela + 'x sem juros <small>de</small></div>\
								<sup>R$</sup>\
								<strong>' + vdEp.FormatCurrency(pkg.formasParcelamento.listaParcADC[parcela].valorPrimeiraParcela).replace("R$ ", "") + '</strong>\
								<small class="vd-price-full">' + vdEp.FormatCurrency(pkg.formasParcelamento.listaParcADC[0].valorPrimeiraParcela) + ' &agrave; vista</small>\
							</div>\
							<div class="vd-pi-credit-porto">\
								<img src="'+img+'/cartaoPortoPayment-mini.png">\
								<p>5% de desconto no cart&atilde;o Porto Seguro <span>' + vdEp.FormatCurrency(pkg.formasParcelamento.listaParcCartaoPorto[0].valorPrimeiraParcela) + '  &agrave; vista</span> ou em at&eacute; <span>12x de R$ ' +vdEp.FormatCurrency(pkg.formasParcelamento.listaParcCartaoPorto[11].valorPrimeiraParcela)+ ' </span></p>\
								<a href="javascript:;" class="" data-modal="" style="display:none">ver op&ccedil;&otilde;es de parcelamento &raquo;</a>\
							</div>\
							<div class="vd-pi-cta">\
								<a 	href="javascript:;"\
									class="ps-btn ps-btn-primary ep-setValue ' + (!login ? '' : 'ps-open-modal') + '" \
									id="vd-pi-cta-'+idx+'"\
									data-setvalue="#ep-plano-id==' + (idx + 1) + ';#ep-plano-valor==' + pkg.formasParcelamento.listaParcADC[0].valorPrimeiraParcela + ';#ep-plano-vezes==' + pkg.formasParcelamento.listaParcADC[parcela].numeroParcela + 'x"\
									>CONTRATAR</a>\
							</div>\
						</div>\
						<div class="vd-pi-benefits ' + (pkg.descricaoBeneficiosPortateis != undefined ? '' : ' vd-pi-benefits-center') + '">';

		if (typeof pkg.descricaoBeneficiosPortateis != "undefined") {
			rtn += '				<h4>Seu Seguro Contempla:</h4>\
									'+ vdEpSkin.BuildCoverageIcons(pkg.descricaoBeneficiosPortateis, equipamentType) +'';
			rtn += '				<a style="display:'+ (idx == 1 ? 'block' : 'none') +'" href="personalizaseguro.ns" class="ep-form-changeAction-submit ep-form-changeAction-submit-custom" data-form="#ep-contratacaoEpForm">Editar novamente</a>';

		} else {
			rtn += '				&#160;<div>\
									<a href="personalizaseguro.ns" class="ep-form-changeAction-submit" data-form="#ep-contratacaoEpForm">Editar novamente</a>\
								</div>';
		}
		rtn += '		</div>\
					</div>\
				</div>';

		if(psLib.IsMobile)
			vdEpSkin.ValorBuildButtonsMobile(idx);

		return rtn;
	},
	ValorItemPlanoLabel: function (lbl) {
		var rtn = '';

		lbl = lbl.split(" ");
		rtn = lbl[0].toLowerCase() + " " + lbl[1] + " "+lbl[2].toLowerCase();

		return rtn;
	},
	ValorBuildButtonsMobile: function(idx) {
		var rtn = '';

		rtn += '<div class="vd-pi-cta-mobile" id="vd-pi-cta-mobile-'+idx+'" style="display: '+(idx == 0 ? 'block' : 'none')+'">\
					<a 	href="javascript:;"\
						class="ps-btn ps-btn-primary ps-btn-green ep-trigger" data-trigger="#vd-pi-cta-'+idx+'"\
					>\
						CONTRATAR\
					</a>\
				</div>';

		$("body").append(rtn);
		vdEp.FormBindings("vd-pi-cta-mobile-"+idx+"");
	},
	ValorItemPlanoPersonalizar: function(params) {
		var rtn = "";

		rtn = '<div class="ps-sm-mod6">\
					<div class="vd-plan-item ep-center">\
						<div class="vd-plan-card vd-center">\
							<p class="vd-pi-title">personalize <strong>seu Seguro</strong></p>\
							<div class="vd-pi-price vd-pi-price-personalize">\
								Personalize as coberturas do seu seguro <br class="ps-hide ps-sm-show">contratando tamb&eacute;m <b>Garantia Internacional</b>, <br class="ps-hide ps-sm-show">Danos El&eacute;tricos e <b>Danos por &aacute;gua</b> ou <b>l&iacute;quido</b>.\
							</div>\
							<div class="vd-pi-cta">\
								<a href="' + params.personalizarAction + '" class="ps-btn ep-form-changeAction-submit" data-form="#ep-contratacaoEpForm">EDITAR COBERTURAS</a>\
							</div>\
						</div>\
						<div class="vd-pi-benefits vd-pi-benefits-center">\
							&#160;<div>\
								<h4>Personalize seu seguro</h4>\
								<a href="' + params.personalizarAction + '" class="ep-form-changeAction-submit" data-form="#ep-contratacaoEpForm">escolhendo as coberturas</a></strong>\
							</div>\
						</div>\
					</div>\
				</div>';

		return rtn;
	},
	ValorCoverages: function() {
		var rtn = "";

		rtn = '<div class="ep-priceTable-coverageInfo ep-priceTable-coverageInfo-bgGray ps-alignCenter">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod12 ps-sm-alignCenter">\
								<h1>Com o <strong>seguro de Equipamentos Port&aacute;teis</strong> voc&ecirc; protege seu equipamento, seja para <br class="ps-hide ps-lg-show"> uso pessoal, lazer ou profissional. O seguro d&aacute; cobertura para o casos de:</h1>\
							</div>\
						</div>\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod4 ps-sm-lspan2">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-danosFisicos-blue-48"></span>\
									<strong>Danos F&iacute;sicos ao Bem</strong>\
									Caso o equipamento tenha danos ocasionados <br class="ps-hide ps-lg-show">por queda, inc&ecirc;ndio, impacto de ve&iacute;culos <br class="ps-hide ps-lg-show">ou na tentativa de um roubo\
								</div>\
							</div>\
							<div class="ps-mod8 ps-sm-mod4">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-roubo-blue-48"></span>\
									<strong>Subtra&ccedil;&atilde;o do Bem</strong>\
									Quando o equipamento &eacute; roubado ou subtra&iacute;do sob amea&ccedil;a direta ou ap&oacute;s um arrombamento (exceto se o aparelho for deixado em ve&iacute;culo).\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				<div class="ep-priceTable-coverageInfo ps-alignCenter">\
					<div class="ps-container ps-noGutter ps-md-gutter">\
						<h1>Voc&ecirc; ainda pode, <strong>opcionalmente, garantir a prote&ccedil;&atilde;o</strong> de:</h1>\
						<div class="ep-pc-features">\
							<div class="ep-mod4">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-danosEletricos-blue-48"></span>\
									<strong>Danos El&eacute;tricos</strong>\
									Protege os equipamento e acess&oacute;rios (quando declarados na ap&oacute;lice) contra os danos em caso de oscila&ccedil;&otilde;es  e descargas el&eacute;tricas de energia, al&eacute;m de curtos-circuitos que afetem os equipamentos.\
								</div>\
							</div>\
							<div class="ep-mod4">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-danoLiquido-blue-48"></span>\
									<strong>Danos por &aacute;gua/l&iacute;quido</strong>\
									Garante os danos causados aos equipamentos e acess&oacute;rios por &aacute;gua ou qualquer subst&acirc;ncia l&iacute;quida de origem acidental e involunt&aacute;ria.\
								</div>\
							</div>\
							<div class="ep-mod4">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-garantiaInternacional-blue-48"></span>\
									<strong>Garantia Internacional</strong>\
									Com esta cobertura, voc&ecirc; estende as suas garantias contratadas para sinistros ocorridos fora do Brasil.\
								</div>\
							</div>\
							<div class="ep-mod4">\
								<div class="ep-priceTable-description">\
									<span class="ep-icon ep-48 ep-perdaAluguel-blue-48"></span>\
									<strong>Perda ou pagamento de aluguel</strong>\
									Garante o pagamento de aluguel necess&aacute;rio para loca&ccedil;&atilde;o de outra c&acirc;mera ou filmadora durante o per&iacute;odo de reparo da original.\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>';

		return rtn;
	},
	ValorFooterNotes: function() {
		var rtn = ""

		rtn = '<section class="vdo-calc-plansInfo">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod12 ep-priceTable-legalMessage">\
								<h2>Lembretes importantes</h2>\
								<ol>\
									<li>\
										Sempre que houver um sinistro, a Porto Seguro ir&aacute; solicitar alguns documentos que ser&atilde;o inclu&iacute;dos no processo de an&aacute;lise do caso, como a nota fiscal do equipamento, por exemplo. Por isso, lembre-se de mant&ecirc;-la sempre acess&iacute;vel.\
									</li>\
									<li>\
										Quando voc&ecirc; contrata este tipo de seguro, participa das indeniza&ccedil;&otilde;es em cada sinistro, de acordo a propor&ccedil;&atilde;o de cada situa&ccedil;&atilde;o. Abaixo, detalhamos cada caso:\
									</li>\
									<ul>\
										<strong>Danos El&eacute;tricos</strong>\
										<li>15% das indeniza&ccedil;&otilde;es com o m&iacute;nimo de R$ 150,00 (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total)\</li>\
										<li>Acess&oacute;rios : 15% das indeniza&ccedil;&otilde;es (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total)</li>\
										<strong>Danos F&iacute;sicos</strong>\
										<li>C&acirc;meras fotogr&aacute;ficas e c&acirc;meras filmadoras: 15% das indeniza&ccedil;&otilde;es com m&iacute;nimo de R$ 300,00 (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total)</li>\
										<li>Smartphones, smartwatches, notebooks, tablets: 20% das indeniza&ccedil;&otilde;es com m&iacute;nimo de R$ 150.00 (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total).</li>\
										<li>Acess&oacute;rios : 15% das indeniza&ccedil;&otilde;es (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total)</li>\
										<strong>Subtra&ccedil;&atilde;o do bem</strong>\
										<li>Smartphones e smartwatches: 20% das indeniza&ccedil;&otilde;es com o m&iacute;nimo de R$ 200,00 (aplic&aacute;vel por equipamento)</li>\
										<strong>Danos por &aacute;gua/l&iacute;quido</strong>\
										<li>C&acirc;meras fotogr&aacute;ficas e c&acirc;meras filmadoras: 10% das indeniza&ccedil;&otilde;es com m&iacute;nimo de R$ 500 (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total).</li>\
										<li>Smartphones, Smartwatches, notebooks, Tablets: 25% das indeniza&ccedil;&otilde;es com m&iacute;nimo de R$ 150,00 (aplic&aacute;vel por equipamento em sinistro de perda parcial e perda total).</li>\
										<li>Acess&oacute;rios: 10% das indeniza&ccedil;&otilde;es (aplic&aacute;vel por acess&oacute;rio.</li>\
									</ul>\
								</ol>\
							</div>\
						</div>\
					</div>\
				</section>';

		return rtn;
	},
	ValorPersonalizar: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: "javascript:history.back();",
				codigoAtendimento: document.getElementById("codigoAtendimento") != null ? document.getElementById("codigoAtendimento").value : '',
				codigoEmpresa: document.getElementById("codigoEmpresa") != null ? document.getElementById("codigoEmpresa").value : '',
				codigoProduto: document.getElementById("codigoProduto") != null ? document.getElementById("codigoProduto").value : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam);

		/*
		window.customData.page = {
			'template': '2',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/

		ctt += '<div class="ep-siteContainer">\
					<div class="ep-form ep-calc-config">\
						' + vdEpSkin.BuildTop("valorPersonalizar", defaultParam) + '\
						<div class="ep-form">\
							<div class="ep-container">\
								<div class="ep-row ep-title-block">\
									<div class="ep-mod5">\
										<h1><strong>Resultado</strong> da Cota&ccedil;&atilde;o</h1>\
									</div>\
									' + vdEpSkin.BuildHeaderCalcInfo(defaultParam) + '\
								</div>\
								<form name="contratacaoEpForm" id="ep-contratacaoEpFormMob" action="' + defaultParam.formAction + '" method="post" class="ep-hide ep-md-hide ep-sm-show showFeedback">\
									' + vdEpSkin.ValorPersonalizarHiddens(defaultParam) + '\
									' + vdEpSkin.ValorPersonalizarBlockMobile(defaultParam) + '\
								</form>\
								<form name="contratacaoEpForm" id="ep-contratacaoEpForm" action="' + defaultParam.formAction + '" method="post" class="ep-sm-hide showFeedback">\
									' + vdEpSkin.ValorPersonalizarHiddens(defaultParam) + '\
									<p>Adicione ou exclua as coberturas de acordo com as necessidades do seu equipamento:</p>\
									' + vdEpSkin.ValorPersonalizarBlock(defaultParam) + '\
								</form>\
							</div>\
						</div>\
					</div>\
					' + vdEpSkin.BuildFooter() + '\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
				</div>';

		return ctt;
	},
	ValorPersonalizarHiddens: function (params) {
		var ctt = "";

		ctt += '<input type="hidden" name="codigoAtendimento" value="' + params.codigoAtendimento + '" />\
				<input type="hidden" name="codigoProduto" value="' + params.codigoProduto + '" />\
				<input type="hidden" name="codigoEmpresa" value="' + params.codigoEmpresa + '" />\
				<input type="hidden" name="skin" value="' + params.skin + '" />\
				<input type="hidden" name="indiceEquipamentoAlterar" value="0" />';

		return ctt;
	},
	ValorPersonalizarItemHiddens: function (item, i) {
		var ctt = "";

		if (item.codigoRelevancia == 1) {
			ctt += '\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosFisicos" value="' + $("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosFisicos']").val() + '" class="ep-device' + i + '-set-coberturaDanosFisicos" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosEletricos" value="' + $("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosEletricos']").val() + '" class="ep-device' + i + '-set-coberturaDanosEletricos" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaRouboFurto" value="true" class="ep-device' + i + '-set-coberturaRouboFurto" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaGarantiaInternacional" value="' + $("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaGarantiaInternacional']").val() + '" class="ep-device' + i + '-set-coberturaGarantiaInternacional" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaPerdaAluguel" value="' + $("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaPerdaAluguel']").val() + '" class="ep-device' + i + '-set-coberturaPerdaAluguel" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosAguaLiquido" value="' + $("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosAguaLiquido']").val() + '" class="ep-device' + i + '-set-coberturaDanosAguaLiquido" />';
		} else {
			ctt += '\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosFisicos" value="true" class="ep-device' + i + '-set-coberturaDanosFisicos" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosEletricos" value="false" class="ep-device' + i + '-set-coberturaDanosEletricos" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaRouboFurto" value="true" class="ep-device' + i + '-set-coberturaRouboFurto" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaGarantiaInternacional" value="false" class="ep-device' + i + '-set-coberturaGarantiaInternacional" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaPerdaAluguel" value="false" class="ep-device' + i + '-set-coberturaPerdaAluguel" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].coberturaDanosAguaLiquido" value="false" class="ep-device' + i + '-set-coberturaDanosAguaLiquido" />';
		}

		ctt += '<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].codigoTipoEquipamento" value="' + (typeof item.codigoTipoEquipamento != "undefined" ? item.codigoTipoEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].codigoEquipamento" value="' + (typeof item.codigoEquipamento != "undefined" ? item.codigoEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].grauDeRiscoEquipamento" value="' + (typeof item.grauDeRiscoEquipamento != "undefined" ? item.grauDeRiscoEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].sistemaOperacional" value="' + (typeof item.sistemaOperacional != "undefined" ? item.sistemaOperacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].codigoRelevancia" value="' + (typeof item.codigoRelevancia != "undefined" ? item.codigoRelevancia : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].marcaEquipamento" value="' + (typeof item.marcaEquipamento != "undefined" ? item.marcaEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].equipamentoAlugado" value="' + (typeof item.equipamentoAlugado != "undefined" ? item.equipamentoAlugado : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].principalUsuario" value="' + (typeof item.principalUsuario != "undefined" ? item.principalUsuario : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].quantidadeAcessorios" value="' + (typeof item.quantidadeAcessorios != "undefined" ? item.quantidadeAcessorios : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].clausulaServico" value="' + (typeof item.clausulaServico != "undefined" ? item.clausulaServico : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].descricaoMarcaEquipamento" value="' + (typeof item.descricaoMarcaEquipamento != "undefined" ? item.descricaoMarcaEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].descricaoModelo" value="' + (typeof item.descricaoModelo != "undefined" ? item.descricaoModelo : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].descricaoEquipamento" value="' + (typeof item.descricaoEquipamento != "undefined" ? item.descricaoEquipamento : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].diaNotaFiscal" value="' + (typeof item.diaNotaFiscal != "undefined" ? item.diaNotaFiscal : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].mesNotaFiscal" value="' + (typeof item.mesNotaFiscal != "undefined" ? item.mesNotaFiscal : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].anoNotaFiscal" value="' + (typeof item.anoNotaFiscal != "undefined" ? item.anoNotaFiscal : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].diaNascimentoUsuario" value="' + (typeof item.diaNascimentoUsuario != "undefined" ? item.diaNascimentoUsuario : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].mesNascimentoUsuario" value="' + (typeof item.mesNascimentoUsuario != "undefined" ? item.mesNascimentoUsuario : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].anoNascimentoUsuario" value="' + (typeof item.anoNascimentoUsuario != "undefined" ? item.anoNascimentoUsuario : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].valorNotaFiscal" value="' + (typeof item.valorNotaFiscal != "undefined" ? item.valorNotaFiscal : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioTotalItem" value="' + (typeof item.premioTotalItem != "undefined" ? item.premioTotalItem : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].valorLimiteCobertura" value="' + (typeof item.valorLimiteCobertura != "undefined" ? item.valorLimiteCobertura : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].valorDepreciacao" value="' + (typeof item.valorDepreciacao != "undefined" ? item.valorDepreciacao : '') + '" />\
				\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaBasica" value="' + (typeof item.textoFranquiaBasica != "undefined" ? item.textoFranquiaBasica : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaDanosEletricos" value="' + (typeof item.textoFranquiaDanosEletricos != "undefined" ? item.textoFranquiaDanosEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaRouboFurto" value="' + (typeof item.textoFranquiaRouboFurto != "undefined" ? item.textoFranquiaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaGarantiaInternacional" value="' + (typeof item.textoFranquiaGarantiaInternacional != "undefined" ? item.textoFranquiaGarantiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaBasica" value="' + (typeof item.textoPercentualFranquiaBasica != "undefined" ? item.textoPercentualFranquiaBasica : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaEletricos" value="' + (typeof item.textoPercentualFranquiaEletricos != "undefined" ? item.textoPercentualFranquiaEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaRouboFurto" value="' + (typeof item.textoPercentualFranquiaRouboFurto != "undefined" ? item.textoPercentualFranquiaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaGarantiaInternacional" value="' + (typeof item.textoPercentualFranquiaGarantiaInternacional != "undefined" ? item.textoPercentualFranquiaGarantiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaBasica" value="' + (typeof item.textoValorFranquiaBasica != "undefined" ? item.textoValorFranquiaBasica : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaEletricos" value="' + (typeof item.textoValorFranquiaEletricos != "undefined" ? item.textoValorFranquiaEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaRouboFurto" value="' + (typeof item.textoValorFranquiaRouboFurto != "undefined" ? item.textoValorFranquiaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaGarantiaInternacional" value="' + (typeof item.textoValorFranquiaGarantiaInternacional != "undefined" ? item.textoValorFranquiaGarantiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaBasica" value="' + (typeof item.textoDecorrenciaFranquiaBasica != "undefined" ? item.textoDecorrenciaFranquiaBasica : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaEletricos" value="' + (typeof item.textoDecorrenciaFranquiaEletricos != "undefined" ? item.textoDecorrenciaFranquiaEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaInternacional" value="' + (typeof item.textoDecorrenciaFranquiaInternacional != "undefined" ? item.textoDecorrenciaFranquiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaRouboFurto" value="' + (typeof item.textoDecorrenciaFranquiaRouboFurto != "undefined" ? item.textoDecorrenciaFranquiaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaDanosAguaLiquido" value="' + (typeof item.textoFranquiaDanosAguaLiquido != "undefined" ? item.textoFranquiaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaDanosAguaLiquido" value="' + (typeof item.textoPercentualFranquiaDanosAguaLiquido != "undefined" ? item.textoPercentualFranquiaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaDanosAguaLiquido" value="' + (typeof item.textoValorFranquiaDanosAguaLiquido != "undefined" ? item.textoValorFranquiaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaDanosAguaLiquido" value="' + (typeof item.textoDecorrenciaFranquiaDanosAguaLiquido != "undefined" ? item.textoDecorrenciaFranquiaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoFranquiaPerdaAluguel" value="' + (typeof item.textoFranquiaPerdaAluguel != "undefined" ? item.textoFranquiaPerdaAluguel : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoPercentualFranquiaPerdaAluguel" value="' + (typeof item.textoPercentualFranquiaPerdaAluguel != "undefined" ? item.textoPercentualFranquiaPerdaAluguel : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoValorFranquiaPerdaAluguel" value="' + (typeof item.textoValorFranquiaPerdaAluguel != "undefined" ? item.textoValorFranquiaPerdaAluguel : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].textoDecorrenciaFranquiaPerdaAluguel" value="' + (typeof item.textoDecorrenciaFranquiaPerdaAluguel != "undefined" ? item.textoDecorrenciaFranquiaPerdaAluguel : '') + '" />';


		for (var x = 0, l = item.premioCoberturaPacoteVO.length; x < l; x++) {
			var cc = item.premioCoberturaPacoteVO[x];

			ctt += '\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaDanosFisicos" value="' + (typeof cc.premioCoberturaDanosFisicos != "undefined" ? cc.premioCoberturaDanosFisicos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaDanosEletricos" value="' + (typeof cc.premioCoberturaDanosEletricos != "undefined" ? cc.premioCoberturaDanosEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaRouboFurto" value="' + (typeof cc.premioCoberturaRouboFurto != "undefined" ? cc.premioCoberturaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaGarantiaInternacional" value="' + (typeof cc.premioCoberturaGarantiaInternacional != "undefined" ? cc.premioCoberturaGarantiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaDanosAguaLiquido" value="' + (typeof cc.premioCoberturaDanosAguaLiquido != "undefined" ? cc.premioCoberturaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioCoberturaPerdaAluguel" value="' + (typeof cc.premioCoberturaPerdaAluguel != "undefined" ? cc.premioCoberturaPerdaAluguel : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaDanosFisicos" value="' + (typeof cc.premioLiquidoCoberturaDanosFisicos != "undefined" ? cc.premioLiquidoCoberturaDanosFisicos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaDanosEletricos" value="' + (typeof cc.premioLiquidoCoberturaDanosEletricos != "undefined" ? cc.premioLiquidoCoberturaDanosEletricos : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaRouboFurto" value="' + (typeof cc.premioLiquidoCoberturaRouboFurto != "undefined" ? cc.premioLiquidoCoberturaRouboFurto : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaGarantiaInternacional" value="' + (typeof cc.premioLiquidoCoberturaGarantiaInternacional != "undefined" ? cc.premioLiquidoCoberturaGarantiaInternacional : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaDanosAguaLiquido" value="' + (typeof cc.premioLiquidoCoberturaDanosAguaLiquido != "undefined" ? cc.premioLiquidoCoberturaDanosAguaLiquido : '') + '" />\
				<input type="hidden" name="retornoCalculo.equipamentosPortateisItemCalculo[' + i + '].premioCoberturaPacoteVO[' + x + '].premioLiquidoCoberturaPerdaAluguel" value="' + (typeof cc.premioLiquidoCoberturaPerdaAluguel != "undefined" ? cc.premioLiquidoCoberturaPerdaAluguel : '') + '" />';
		}

		return ctt;
	},
	ValorPersonalizarBlockMobile: function (params) {
		var ctt = "";

		for (var i = 0, l = params.equip.length; i < l; i++) {
			var c = params.equip[i];
			if (c.codigoRelevancia == 1) {
				ctt += '<div class="ep-form-accordion ' + (i > 1 ? 'ep-accordion-closed' : '') + '">\
						<a href="javascript:;" class="ep-accordion-header">\
							<span class="ep-icon ep-36 ep-' + c.ProtoType + '-white-36"></span>\
							<strong>' + c.descricaoEquipamento + ' ' + c.ProtoIdx + '</strong>\
						</a>\
						' + vdEpSkin.ValorPersonalizarItemHiddens(c, i) + '\
						<div class="ep-accordion-container ep-configList" ' + (i > 1 ? 'style="display: none;"' : '') + '>\
							<div class="ep-row ep-configItem ep-enabled ep-obrigatory">\
								<div class="ep-fieldTooltip">\
									Essa cobertura &eacute; obrigat&oacute;ria para todos os seguros.\
								</div>\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox" id="ep-mob-device' + i + '-danosFisicos" checked disabled />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-danosFisicos">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-danosFisicos-lightGray-48"></span>\
								<strong>Danos F&iacute;sicos ao Bem</strong>\
								<p>Garante os danos ao equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, em decorr&ecirc;ncia de inc&ecirc;ndio, raio ou explos&atilde;o; Impacto de ve&iacute;culos e na tentativa de roubo.</p>\
							</div>\
							<div class="ep-row ep-configItem ep-enabled">\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaRouboFurto" id="ep-mob-device' + i + '-roubo" checked ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaRouboFurto']").val() == "true" ? 'checked' : '') + ' />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-roubo">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-roubo-lightGray-48"></span>\
								<strong>Subtra&ccedil;&atilde;o do Bem</strong>\
								<p>Garante a subtra&ccedil;&atilde;o do equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, mediante o rompimento de obst&aacute;culo ou amea&ccedil;a direta, exceto quando o equipamento for deixado em ve&iacute;culos.</p>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosEletricos']").val() == "true" ? 'ep-enabled' : '') + '">\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaDanosEletricos" id="ep-mob-device' + i + '-danosEletricos" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosEletricos']").val() == "true" ? 'checked' : '') + ' />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-danosEletricos">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-danosEletricos-lightGray-48"></span>\
								<strong>Danos El&eacute;tricos</strong>\
								<p>Garante os danos ao equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, em decorr&ecirc;ncia de oscila&ccedil;&otilde;es e descargas el&eacute;tricas de energia, al&eacute;m de curtos-circuitos que afetem o equipamento.</p>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaGarantiaInternacional']").val() == "true" ? 'ep-enabled' : '') + '">\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaGarantiaInternacional" id="ep-mob-device' + i + '-garantiaInternacional" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaGarantiaInternacional']").val() == "true" ? 'checked' : '') + ' />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-garantiaInternacional">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-garantiaInternacional-lightGray-48"></span>\
								<strong>Garantia Internacional</strong>\
								<p>Contratada esta cobertura, voc&ecirc; ter&aacute; extens&atilde;o das garantias contratadas para sinistros ocorridos fora do Brasil.</p>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosAguaLiquido']").val() == "true" ? 'ep-enabled' : '') + '">\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaDanosAguaLiquido" id="ep-mob-device' + i + '-coberturaDanosAguaLiquido" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosAguaLiquido']").val() == "true" ? 'checked' : '') + ' />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-coberturaDanosAguaLiquido">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-danoLiquido-lightGray-48"></span>\
								<strong>Danos por &aacute;gua/l&iacute;quido</strong>\
								<p>Garante os danos aos equipamentos e acess&oacute;rios por &aacute;gua ou qualquer subst&acirc;ncia l&iacute;quida de origem acidental e involunt&aacute;ria.</p>\
							</div>';
				// cobertura para câmera ou filmadora
				if (c.codigoTipoEquipamento == 8 || c.codigoTipoEquipamento == 9) {
					ctt += '	<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaPerdaAluguel']").val() == "true" ? 'ep-enabled' : '') + '">\
								<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaPerdaAluguel" id="ep-mob-device' + i + '-coberturaPerdaAluguel" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaPerdaAluguel']").val() == "true" ? 'checked' : '') + ' />\
								<label class="ep-checkbox" for="ep-mob-device' + i + '-coberturaPerdaAluguel">\
									<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
								</label>\
								<span class="ep-icon ep-48 ep-perdaAluguel-lightGray-48"></span>\
								<strong>Perda ou pagamento de aluguel</strong>\
								<p>Garante a perda ou o pagamento dos alugu&eacute;is dos equipamentos, caso estes sofram danos devidamente cobertos.</p>\
							</div>';
				}
				ctt += '	</div>\
					</div>';
			} else {
				ctt += '<div class="ep-hiddenFields" style="display: none;">' + vdEpSkin.ValorPersonalizarItemHiddens(c, i) + '</div>';
			}
		}

		ctt += '<div class="ep-row ep-submitRow">\
					<input type="submit" value="RECALCULAR" class="ep-btn" />\
				</div>';

		return ctt;
	},
	ValorPersonalizarBlock: function (params) {
		var ctt = "",
			listCounter = 0;

		ctt += '<div class="ep-row">\
					<div class="ep-mod1 ep-right"><a href="javascript:;" class="ep-sliderArrowLeft"><span class="ep-icon ep-24 ep-arrow-left-gray-24-especial"></span></a>&#160;</div>\
					<div class="ep-mod10 ep-slider" data-slider=".ep-sliderArrowLeft;.ep-sliderArrowRight">\
						<div class="ep-sliderContainer">';
		for (var i = 0, l = params.equip.length; i < l; i++) {
			var c = params.equip[i];
			if (c.codigoRelevancia == 1) {
				if (listCounter % 4 == 0) {
					ctt += '<div class="ep-sliderPage">\
								<div class="ep-row">\
									' + vdEpSkin.ValorPersonalizarBlockHeader(params.equip, i) + '\
								</div>\
								<div class="ep-row ep-configList">\
									' + vdEpSkin.ValorPersonalizarBlockConfig(params.equip, i) + '\
								</div>\
							</div>';
				}
				listCounter++;
			} else {
				ctt += '<div class="ep-hiddenFields" style="display: none;">' + vdEpSkin.ValorPersonalizarItemHiddens(c, i) + '</div>';
			}
		}

		ctt += '		</div>\
					</div>\
					<div class="ep-mod1"><a href="javascript:;" class="ep-sliderArrowRight"><span class="ep-icon ep-24 ep-arrow-right-gray-24-especial"></span></a></div>\
				</div>\
				<div class="ep-row ep-submitRow ep-center">\
					<div class="ep-mod4 ep-lspan4">\
						<input type="submit" class="ep-btn" value="RECALCULAR" />\
					</div>\
				</div>';

		return ctt;
	},
	ValorPersonalizarBlockHeader: function (itens, start) {
		var ctt = "",
			limit = 1;

		for (var i = start, l = itens.length; i < l; i++) {
			if (limit == 5) break;
			var c = itens[i];
			if (c.codigoRelevancia == 1) {
				ctt += '<div class="ep-mod3">\
							<a href="javascript:;" class="ep-deviceItem ' + (limit == 1 ? 'ep-selected' : '') + '" data-configblock="#ep-device' + i + '">\
								<span class="ep-icon ep-72 ep-' + c.ProtoType + '-gray-72"></span>\
								<strong>' + c.descricaoEquipamento + ': ' + c.descricaoMarcaEquipamento + '</strong>\
								<span>' + c.descricaoModelo + '</span>\
							</a>\
							' + vdEpSkin.ValorPersonalizarItemHiddens(c, i) + '\
						</div>';
				limit++;
			}
		}

		return ctt;
	},
	ValorPersonalizarBlockConfig: function (itens, start) {
		var ctt = "",
			limit = 1;

		for (var i = start, l = itens.length; i < l; i++) {

			if (limit == 5) break;
			var c = itens[i];
			if (c.codigoRelevancia == 1) {
				ctt += '<div class="ep-configListItem" id="ep-device' + i + '" ' + (limit > 1 ? 'style="display: none;"' : '') + '>\
							<div class="ep-row ep-configItem ep-enabled">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-danosFisicos-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Danos F&iacute;sicos ao Bem</strong>\
									<p>Garante os danos ao equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, em decorr&ecirc;ncia de inc&ecirc;ndio, raio ou explos&atilde;o; Impacto de ve&iacute;culos e na tentativa de roubo.</p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox" id="ep-device' + i + '-danosFisicos" checked disabled />\
									<label class="ep-checkbox" for="ep-device' + i + '-danosFisicos">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
									<div class="ep-deviceCoverage-obrigatory">\
										<span class="ep-icon ep-24 ep-help-yellow-24"></span>\
										<div class="ep-fieldTooltip">\
											Cobertura padr&atilde;o. <br/> N&atilde;o pode ser removida.\
										</div>\
									</div>\
								</div>\
							</div>\
							<div class="ep-row ep-configItem ep-enabled">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-roubo-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Subtra&ccedil;&atilde;o do Bem</strong>\
									<p>Garante a subtra&ccedil;&atilde;o do equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, mediante o rompimento de obst&aacute;culo ou amea&ccedil;a direta, exceto quando o equipamento for deixado em ve&iacute;culos.</p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaRouboFurto" id="ep-device' + i + '-coberturaRouboFurto" checked ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaRouboFurto']").val() == "true" ? 'checked' : '') + '/>\
									<label class="ep-checkbox" for="ep-device' + i + '-coberturaRouboFurto">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
								</div>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosEletricos']").val() == "true" ? 'ep-enabled' : '') + '">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-danosEletricos-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Danos El&eacute;tricos</strong>\
									<p>Garante os danos ao equipamento e seus acess&oacute;rios, quando declarados na ap&oacute;lice, em decorr&ecirc;ncia de oscila&ccedil;&otilde;es e descargas el&eacute;tricas de energia, al&eacute;m de curtos-circuitos que afetem o equipamento. </p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaDanosEletricos" id="ep-device' + i + '-danosEletricos" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosEletricos']").val() == "true" ? 'checked' : '') + ' />\
									<label class="ep-checkbox" for="ep-device' + i + '-danosEletricos">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
								</div>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaGarantiaInternacional']").val() == "true" ? 'ep-enabled' : '') + '">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-garantiaInternacional-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Garantia Internacional</strong>\
									<p>Contratada esta cobertura, voc&ecirc; ter&aacute; extens&atilde;o das garantias contratadas para sinistros ocorridos fora do Brasil.</p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaGarantiaInternacional" id="ep-device' + i + '-internacional" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaGarantiaInternacional']").val() == "true" ? 'checked' : '') + ' />\
									<label class="ep-checkbox" for="ep-device' + i + '-internacional">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
								</div>\
							</div>\
							<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosAguaLiquido']").val() == "true" ? 'ep-enabled' : '') + '">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-danoLiquido-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Danos por &aacute;gua/l&iacute;quido</strong>\
									<p>Garante os danos aos equipamentos e acess&oacute;rios por &aacute;gua ou qualquer subst&acirc;ncia l&iacute;quida de origem acidental e involunt&aacute;ria.</p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaDanosAguaLiquido" id="ep-device' + i + '-agua" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaDanosAguaLiquido']").val() == "true" ? 'checked' : '') + ' />\
									<label class="ep-checkbox" for="ep-device' + i + '-agua">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
								</div>\
							</div>';

				// cobertura para câmera ou filmadora
				if (c.codigoTipoEquipamento == 8 || c.codigoTipoEquipamento == 9) {
					ctt += '<div class="ep-row ep-configItem ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaPerdaAluguel']").val() == "true" ? 'ep-enabled' : '') + '">\
								<div class="ep-mod1">\
									<span class="ep-icon ep-48 ep-perdaAluguel-lightGray-48"></span>\
								</div>\
								<div class="ep-mod7">\
									<strong>Perda ou pagamento de aluguel</strong>\
									<p>Garante a perda ou o pagamento dos alugu&eacute;is dos equipamentos, caso estes sofram danos devidamente cobertos.</p>\
								</div>\
								<div class="ep-mod3 ep-lspan1">\
									<input type="checkbox" name="fake" value="true" class="ep-checkbox ep-switcher" data-switcher="true;false;.ep-device' + i + '-set-coberturaPerdaAluguel" id="ep-device' + i + '-aluguel" ' + ($("input[name='retornoCalculo.equipamentosPortateisItemCalculo[" + i + "].coberturaPerdaAluguel']").val() == "true" ? 'checked' : '') + ' />\
									<label class="ep-checkbox" for="ep-device' + i + '-aluguel">\
										<span class="ep-icon ep-36 ep-slide-disabled-36"></span>\
									</label>\
								</div>\
							</div>';
				}
				ctt += '</div>';
				limit++;
			}
		}

		return ctt;
	},
	Checkout1: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: "voltar.ns?forwardVO=valorseguro",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoAtendimento: document.getElementById("codigoAtendimento") != null ? document.getElementById("codigoAtendimento").value : '',
				codigoEmpresa: document.getElementById("codigoEmpresa") != null ? document.getElementById("codigoEmpresa").value : '',
				codigoProduto: document.getElementById("codigoProduto") != null ? document.getElementById("codigoProduto").value : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam, true);
		defaultParam.equipCalculo = vdEpSkin.SetEquipArray(defaultParam, true, true);

		/*
		window.customData.page = {
			'template': '3',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/

		ctt += '<div class="ep-siteContainer">\
					<form name="checkout" class="ep-checkoutForm" id="ep-checkoutForm" action="' + defaultParam.formAction + '" method="post" onsubmit="return vdEp.DeviceValidateSubmit(this);">\
						<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '" />\
						<input type="hidden" name="codigoAtendimento" value="' + defaultParam.codigoAtendimento + '" />\
						<input type="hidden" name="codigoProduto" value="' + defaultParam.codigoProduto + '" />\
						<input type="hidden" name="codigoEmpresa" value="' + defaultParam.codigoEmpresa + '" />\
						<input type="hidden" name="skin" value="' + defaultParam.skin + '" />\
						<input type="hidden" name="proposta.optinSmartportoid" value="" />\
						<div class="ep-form ep-calc-checkout">\
							' + vdEpSkin.BuildTop("checkout1", defaultParam) + '\
							<div class="ep-form">\
								<div class="ep-container">\
									<div class="ep-row ep-title-block">\
										<div class="ep-mod5">\
											<h1><strong>Valida&ccedil;&atilde;o</strong></h1>\
										</div>\
										' + vdEpSkin.BuildHeaderCalcInfo(defaultParam) + '\
									</div>';
		var fieldIdx = 0;

		for (var i = 0, l = defaultParam.equip.length; i < l; i++) {
			if (typeof defaultParam.equip[i].descricaoEquipamento == "undefined") continue;
			var c = defaultParam.equip[i],
				cc = defaultParam.equipCalculo[i];

			ctt += '<div class="ep-row ep-form-accordion ' + (i > 0 ? 'ep-accordion-closed' : '') + '">\
						<a href="javascript:;" class="ep-accordion-header" id="ep-device' + i + '-accordion-header">\
							<span class="ep-icon ep-36 ep-' + c.ProtoType + '-gray-36"></span>\
							<strong>' + c.descricaoEquipamento + ' ' + c.ProtoIdx + '</strong>\
							<span class="ep-device-info">' + c.descricaoMarcaEquipamento + ' ' + c.descricaoModelo + '</span>\
						</a>\
						<div style="display: none;">' + $("<div>").append($("input[name^='retornoCalculo.equipamentosPortateisItemCalculo[" + fieldIdx + "]']").clone()).html() + '</div>\
						<div class="ep-accordion-container" ' + (i > 0 ? ' style="display: none;"' : '') + '>\
							<div class="ep-row">\
								<div class="ep-mod8">\
									<div class="ep-row">\
										<div class="ep-mod4 ep-right">\
											<label class="ep-lbl">N&uacute;mero de s&eacute;rie:</label>\
										</div>\
										<div class="ep-mod8">\
											<input type="text" name="proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].descricaoSerie" class="ep-form-control ep-valid vdo-cookie" value="' + $('input[name=\'proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].descricaoSerie\']').val() + '" />\
										</div>\
									</div>';
									if(c.ProtoType == "smartphone" || c.ProtoType == "smartwatch") {
			ctt += '				<div class="ep-row">\
										<div class="ep-mod4 ep-right">\
											<label class="ep-lbl">N&uacute;mero da nota fiscal:</label>\
										</div>\
										<div class="ep-mod8">\
											<input type="text" name="proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].numeroNotaFiscal" class="ep-form-control ep-onlyNumbers ep-valid ep-smartphoneValidate-startup" id="ep-device' + i + '-nfNumero" data-device="' + i + '" value="' + $('input[name=\'proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].numeroNotaFiscal\']').val() + '" />\
										</div>\
									</div>';
									}
									if(c.ProtoType == "smartphone" && cc.sistemaOperacional != 4) {
										var imei = vdEp.DeviceImei[cc.sistemaOperacional];
			ctt += '						<div class="ep-row ep-smartphoneIMEI ep-imei-device'+i+'">\
												<p>'+imei.text+'</p>\
												<ol>';
													for(var im = 0,lim = imei.steps.length; im < lim; im++) {
														var steps = imei.steps[im];
			ctt += '									<li>'+steps+'</li>';
													}
			ctt += '							</ol>\
												<p>'+imei.path+'</p>';
												if(cc.sistemaOperacional == 1) {
			ctt += '								<p>'+imei.text2+'</p>\
													<p>'+imei.text3+'</p>';
												}
			ctt += '						</div>';
									}
			ctt += '			</div>\
								<div class="ep-mod4 ep-smartphoneIMEI-image ep-imei-device'+i+'" style="display:' +((c.ProtoType == "smartphone" && cc.sistemaOperacional != 4) ? 'block' : 'none')+ '">\
									<img src="'+defaultParam.assetPath+'/checkout-smartphone-'+cc.sistemaOperacional+'.png">\
								</div>\
							</div>';

			if (c.ProtoType == "smartphone") {
				hasAcessory = false;
				if (cc.acessorioEquipamento.length > 0) {
					for (var x = 0, lx = cc.acessorioEquipamento.length; x < lx; x++) {
						if (typeof cc.acessorioEquipamento[x].descricaoEquipamento == "undefined" || cc.acessorioEquipamento[x].descricaoEquipamento == "")
							continue;

						hasAcessory = true;
					}
				}

				ctt

				ctt += '	<div id="ep-device' + i + '-smartphoneValidateBlock" style="display: none;">\
								<div class="ep-row">\
									<div class="ep-mod3 ep-right">\
										<label class="ep-lbl">Valida&ccedil;&atilde;o:</label>\
									</div>\
									<div class="ep-mod7">\
										Para sua maior seguran&ccedil;a, a Porto Seguro precisa identificar o seu aparelho. Para isto, voc&ecirc; precisa instalar o aplicativo SmartPorto ID e gerar o c&oacute;digo de verifica&ccedil;&atilde;o que ser&aacute; usado para continuar a compra on-line do seu seguro.\
									</div>\
								</div>\
								<div class="ep-row">\
									<div class="ep-mod7 ep-lspan3 ep-md-mod9 ep-smartphoneValidate" id="ep-device' + i + '-smartphoneValidate" data-validated="false">\
										<div class="ep-mod3 ep-smartphoneScreen ep-sm-hide">\
											<div class="ep-smartPorto-screen1" id="ep-device' + i + '-screen">&#160;</div>\
										</div>\
										<div class="ep-mod8 ep-smartphoneValidateForm">\
											<div class="ep-device' + i + '-smartphoneValidate-step" id="ep-device' + i + '-smartphoneValidate-step1">\
												<ul class="ep-calc-steps">\
													<li>\
														<a href="javascript:;" class="ep-step ep-selected">PASSO 1</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 2</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 3</a>\
													</li>\
												</ul>\
												<div class="ep-smartphoneValidateCtt ep-smartphoneValidateSmartPorto">\
													<p>Voc&ecirc; precisa ler e aceitar os termos de uso para prosseguir com a instala&ccedil;&atilde;o do SmartPorto ID.</p>\
													<p><a href="javascript:;" class="ps-open-modal" data-modal="#ModalAceiteSmartPortoID" data-modalkeyboarddisable="false" data-modalonshow="vdEpSkin.Checkout1ValidateTerm(\'#ModalAceiteSmartPortoID\')" data-modalonhide="vdEpSkin.Checkout1ProcessTerm()">Ler o termo</a></p>\
												</div>\
												<div class="ep-smartphoneValidateCtt ep-smartphoneValidateSmartPortoInicial" style="display: none;">\
													<p>Pegue seu celular e baixe o app SmartPorto ID agora.</p>\
													<a href="https://itunes.apple.com/br/app/smartporto-id/id661824869?mt=8" target="_blank">\
														<span class="ep-icon ep-appleStore"></span>\
													</a>\
													<a href="https://play.google.com/store/apps/details?id=br.com.portoseguro.smartportoid" target="_blank">\
														<span class="ep-icon ep-googlePlay"></span>\
													</a>\
													<div class="ep-submitRow">\
														<a href="javascript:;" class="ep-btn ep-smartphoneValidate-stepPass" data-device="' + i + '">OK, J&Aacute; BAIXEI</a>\
													</div>\
												</div>\
											</div>\
											<div class="ep-device' + i + '-smartphoneValidate-step" id="ep-device' + i + '-smartphoneValidate-step2" style="display: none;">\
												<ul class="ep-calc-steps">\
													<li>\
														<a href="javascript:;" class="ep-step ep-showHideBlock" data-showhideblock=".ep-device' + i + '-smartphoneValidate-step;#ep-device' + i + '-smartphoneValidate-step1">PASSO 1</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step ep-selected">PASSO 2</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 3</a>\
													</li>\
												</ul>\
												<div class="ep-smartphoneValidateCtt">\
													<p>Acesse o aplicativo atrav&eacute;s do protocolo:</p>\
													<strong id="ep-device' + i + '-codigoAtendimento">' + defaultParam.codigoAtendimento + '</strong>\
													<p>Em seguida o aplicativo ir&aacute; gerar um c&oacute;digo.</p>\
													<div class="ep-submitRow">\
														<a href="javascript:;" class="ep-btn ep-nextStep">OK, J&Aacute; TENHO O C&Oacute;DIGO EM M&Atilde;OS</a>\
													</div>\
												</div>\
											</div>\
											<div class="ep-device' + i + '-smartphoneValidate-step" id="ep-device' + i + '-smartphoneValidate-step2b" style="display: none;">\
												<ul class="ep-calc-steps">\
													<li>\
														<a href="javascript:;" class="ep-step ep-showHideBlock" data-showhideblock=".ep-device' + i + '-smartphoneValidate-step;#ep-device' + i + '-smartphoneValidate-step1">PASSO 1</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step ep-selected ep-showHideBlock" data-showhideblock=".ep-device' + i + '-smartphoneValidate-step;#ep-device' + i + '-smartphoneValidate-step2">PASSO 2</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 3</a>\
													</li>\
												</ul>\
												<div class="ep-smartphoneValidateCtt">\
													<p>Agora voc&ecirc; informa para a gente<br/> o c&oacute;digo gerado pelo aplicativo.</p>\
													<div class="ep-mod7">\
														<input type="tel" name="device' + i + '.validacao.codigo" id="ep-device' + i + '-validateCode" class="ep-form-control ep-valid ep-onlyNumbers" />\
													</div>\
													<div class="ep-mod5">\
														<div class="ep-fieldTooltip ep-fieldTooltip-errorVoid" style="display: none;">\
															Preencha o n&uacute;mero da nota fiscal.\
														</div>\
														<div class="ep-fieldTooltip ep-fieldTooltip-error" style="display: none;">\
															O c&oacute;digo est&aacute; incorreto, tente novamente.\
														</div>\
													</div>\
													<div class="ep-submitRow">\
														<a href="javascript:;" class="ep-btn ep-nextStep">AVAN&Ccedil;AR</a>\
													</div>\
												</div>\
											</div>\
											<div class="ep-device' + i + '-smartphoneValidate-step" id="ep-device' + i + '-smartphoneValidate-step3" style="display: none;">\
												<ul class="ep-calc-steps">\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 1</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step">PASSO 2</a>\
													</li>\
													<li>\
														<a href="javascript:;" class="ep-step ep-selected">PASSO 3</a>\
													</li>\
												</ul>\
												<div class="ep-smartphoneValidateCtt">\
													<strong class="ep-success">\
														<span class="ep-icon ep-24 ep-statusOk-green-24"></span>\
														Seu aparelho foi validado com sucesso!\
													</strong>\
													<div class="ep-row-info">\
														<span class="ep-icon ep-24 ep-statusAlert-gray-24"></span>\
														Mantenha o aplicativo SmartPorto ID instalado para facilitar o bloqueio &agrave;s informa&ccedil;&otilde;es e a localiza&ccedil;&atilde;o do smartphone.\
													</div>\
													<div class="ep-submitRow">\
														<a href="javascript:;" class="ep-btn ep-wizardFinish" data-deviceidx="' + i + '" data-fieldidx="' + fieldIdx + '" data-hasacessory="' + hasAcessory + '">CONTINUAR</a>\
													</div>\
												</div>\
											</div>\
										</div>\
									</div>\
								</div>\
							</div>';
			}

			if (cc.acessorioEquipamento.length > 0) {
				var bufFieldIdx = fieldIdx,
					bufFieldStart = fieldIdx;
				for (var x = 0, lx = cc.acessorioEquipamento.length; x < lx; x++) {
					if (typeof cc.acessorioEquipamento[x].descricaoEquipamento == "undefined" || cc.acessorioEquipamento[x].descricaoEquipamento == "")
						continue;

					fieldIdx++;

					if (c.ProtoType != "smartphone" && c.ProtoType != "camera" && c.ProtoType != "videocamera")
						continue;


					if (x == 0) {
						ctt += '			<div class="ep-row">\
												<div class="ep-mod12">\
													<h1>Acess&oacute;rios</h1>\
												</div>';
					}
					ctt += '					<div class="ep-row">\
													<div class="ep-mod3 ep-right">\
														<label class="ep-lbl">Acess&oacute;rio ' + (x + 1) + ':</label>\
													</div>\
													<div class="ep-mod3 ep-subForm ep-subForm-accessory">\
														<h2>' + cc.acessorioEquipamento[x].descricaoEquipamento + (typeof cc.acessorioEquipamento[x].descricaoModelo != 'undefined' ? ': ' + cc.acessorioEquipamento[x].descricaoModelo : '') + '</h2>\
														<div style="display: none;">' + $("<div>").append($("input[name^='retornoCalculo.equipamentosPortateisItemCalculo[" + fieldIdx + "]']").clone()).html() + '</div>\
														<div class="ep-row">';
					if (c.ProtoType == "smartphone") {
						ctt += '							<div class="ep-mod12">\
																<label class="ep-lbl ep-lbl-inline">N&uacute;mero da nota fiscal:</label>\
																<input type="text" name="proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].numeroNotaFiscal" class="ep-form-control ep-valid"  value="' + $('input[name=\'proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].numeroNotaFiscal\']').val() + '" />\
															</div>';
					}
					if (c.ProtoType == "camera" || c.ProtoType == "videocamera") {
						ctt += '							<div class="ep-mod12">\
																<label class="ep-lbl ep-lbl-inline">N&uacute;mero de s&eacute;rie:</label>\
																<input type="text" name="proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].descricaoSerie" class="ep-form-control ep-valid" value="' + $('input[name=\'proposta.equipamentosPortateisItemCalculo[' + fieldIdx + '].descricaoSerie\']').val() + '" />\
															</div>';
					}
					ctt += '							</div>\
													</div>\
												</div>';
					bufFieldIdx++;
				}

				if (bufFieldStart != bufFieldIdx)
					ctt += '				</div>';
			}

			//accordion footer
			ctt += '						<div class="ep-accordion-submitRow">';
			if (i == 0 && l > 1) {
				ctt += '						<div class="ep-row ep-submitRow"> \
													<div class="ep-mod3 ep-lspan9 ep-sm-mod6"> \
														<a href="javascript:;" class="ep-btn ep-nextAccordion" id="ep-nextAccordion-' + i + '" data-nextaccordion="#ep-device' + (i + 1) + '-accordion-header">PR&Oacute;XIMO APARELHO</a> \
													</div>\
												</div>';
			} else if (i < (l - 1)) {
				ctt += '						<div class="ep-row ep-submitRow"> \
													<div class="ep-mod3 ep-sm-mod6"> \
														' + (i > 0 ? '<a href="javascript:;" class="ep-btn ep-gray ep-trigger" data-trigger="#ep-device' + (i - 1) + '-accordion-header">APARELHO ANTERIOR</a>' : '&#160;') + ' \
													</div> \
													<div class="ep-mod3 ep-lspan6 ep-sm-mod6"> \
														' + (i < l ? '<a href="javascript:;" class="ep-btn ep-nextAccordion" id="ep-nextAccordion-' + i + '" data-nextaccordion="#ep-device' + (i + 1) + '-accordion-header">PR&Oacute;XIMO APARELHO</a>' : '&#160;') + ' \
													</div>\
												</div>';
			}
			ctt += '						</div>\
										</div>\
									</div>';
			fieldIdx++;
		}

		ctt += '					<div class="ep-row ep-submitRow ep-center">\
										<div class="ep-mod4 ep-lspan4">\
											<input type="submit" class="ep-btn" value="CONTINUAR" />\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</form>\
					' + vdEpSkin.BuildFooter() + '\
					' + vdEpSkin.BuildModal("ModalSmartPortoID", defaultParam) + '\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
					' + vdEpSkin.BuildModalCookie() + '\
				</div>';

		return ctt;
	},
	Checkout1ValidateTerm: function(container){
		var ctt = $(container),
			ipt = $("#vd-calculo-optin-smart"),
			opt = $("input[name='proposta.optinSmartportoid']");

		ipt.change("click.smarporto",function(){
			var t = $(this),
				btn = $(".ps-btn",container);

			if(t.is(':checked')) {
				btn.removeClass("ps-btn-disabled");
				opt.val("true");
			} else {
				btn.addClass("ps-btn-disabled");
				opt.val("");
			}
		});
	},
	Checkout1ProcessTerm: function(){
		var opt = $("input[name='proposta.optinSmartportoid']");

		if(opt.val() != "true" || opt.val() == "") return;

		$(".ep-smartphoneValidateSmartPorto").hide();
		$(".ep-smartphoneValidateSmartPortoInicial").fadeIn();
	},
	Checkout2: function(params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: "voltar.ns?forwardVO=dadosequipamento",
				numeroPagina: document.getElementById("numeroPagina").value,
				codigoAtendimento: $("input[name='codigoAtendimento']").val(),
				canalVenda: $("input[name='calculo.canalVenda']").val(),
				dataExpedicao: document.getElementById("proposta.dataExpedicao").value,
				numeroCpfCnpjProponente: document.getElementById("calculo.numeroCpfCnpjProponente").value,
				ordemCnpjProponente: document.getElementById("calculo.ordemCnpjProponente").value,
				digitoCnpjCpfProponente: document.getElementById("calculo.digitoCnpjCpfProponente").value,
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset",
				getVar: vdEp.GetVar()
			};

		$.extend(defaultParam,params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam,true);
		defaultParam.isPJ = defaultParam.ordemCnpjProponente != "" ? true : false;
		/*
		window.customData.page = {
			'template': '7',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/

		ctt += '<div class="ep-siteContainer">\
					<form name="checkout" class="ep-checkoutForm" id="ep-checkoutForm" action="'+defaultParam.formAction+'" method="post" onsubmit="return vdEp.CheckoutSubmitStep2(this);">\
						<input type="hidden" name="numeroPagina" value="'+defaultParam.numeroPagina+'" />\
						<input type="hidden" name="codigoAtendimento" value="'+defaultParam.codigoAtendimento+'" />\
						<input type="hidden" name="calculo.canalVenda" value="'+defaultParam.canalVenda+'" />\
						<input type="hidden" name="proposta.dataExpedicao" value="'+defaultParam.dataExpedicao+'" />\
						<input type="hidden" name="calculo.numeroCpfCnpjProponente" value="'+defaultParam.numeroCpfCnpjProponente+'" />\
						<input type="hidden" name="calculo.ordemCnpjProponente" value="'+defaultParam.ordemCnpjProponente+'" />\
						<input type="hidden" name="calculo.digitoCnpjCpfProponente" value="'+defaultParam.digitoCnpjCpfProponente+'" />\
						<input type="hidden" name="calculo.digitoCnpjCpfProponente" value="'+defaultParam.digitoCnpjCpfProponente+'" />\
						<input type="hidden" name="skin" value="'+defaultParam.skin+'" />\
						<input type="hidden" name="codigoProduto" value="2" id="ep-form-codigoProduto" />\
						\
						<input type="hidden" name="cepAux" value="" />\
						<input type="hidden" name="compCepAux" value="" />\
						<input type="hidden" name="codProfAux" value="" />\
						<input type="hidden" name="descProfAux" value="" />\
						<input type="hidden" name="codAtivEcoAux" value="" />\
						<input type="hidden" name="descAtivEcoAux" value="" />\
						<input type="hidden" name="cTipoDoc" value="" />\
						<input type="hidden" name="cNaciona" value="" />\
						<input type="hidden" name="cCodPaises" value="" />\
						<input type="hidden" name="cDescPaises" value="" />\
						<input type="hidden" name="cResidePais" value="" />\
						<input type="hidden" name="documentoIdentificacao" value="" />\
						<input type="hidden" name="residePais" value="" />\
						<input type="hidden" name="paisResidente" value="" />\
						<input type="hidden" name="bairroTela" value="" />\
						<input type="hidden" name="cidadeTela" value="" />\
						<input type="hidden" name="codigoUFTela" value="" />\
						<input type="hidden" name="flagProfissao" value="S" />\
						\
						<div class="ep-form ep-calc-checkout">\
							'+vdEpSkin.BuildTop("checkout2",defaultParam)+'\
							<div class="ep-form">\
								<div class="ep-container">\
									<div class="ep-row ep-title-block">\
										<div class="ep-mod5">\
											<h1>Dados do <strong>Segurado</strong></h1>\
										</div>\
										'+vdEpSkin.BuildHeaderCalcInfo(defaultParam)+'\
									</div>';
		if(defaultParam.isPJ) {
			ctt += '				<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Raz&atilde;o Social:</label>\
										</div>\
										<div class="ep-mod5">\
											<input type="hidden" name="proposta.nomeProponente" value="'+$("input[name='proposta.nomeProponente']").val()+'" />\
											<div id="pf_datagrid_nomeProponente">\
												<strong>'+$("input[name='proposta.nomeProponente']").val()+'</strong> \
												<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_nomeProponente;input[name=\'fakeNomeProponente\']">editar</a>\
											</div>\
											<input type="text" name="fakeNomeProponente" class="ep-form-control ep-valid ep-cleanupText ep-setValue" value="'+$("input[name='proposta.nomeProponente']").val()+'" data-setvalue="input[name=\'proposta.nomeProponente\']=={value}" style="display: none;" />\
										</div>\
									</div>\
									<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">CNPJ:</label>\
										</div>\
										<div class="ep-mod3">\
											<strong>'+$("input[name='numeroCnpj']").val()+'</strong>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Patrim&ocirc;nio l&iacute;quido:</label>\
										</div>\
										<div class="ep-mod6">\
											<label class="ep-select">\
												<select name="proposta.patrimonioLiquido" class="ep-valid vdo-cookie">\
													'+$("select[name='proposta.patrimonioLiquido']").clone().html()+'\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Receita operacional bruta anual:</label>\
										</div>\
										<div class="ep-mod6">\
											<label class="ep-select">\
												<select name="proposta.receitaOperacionalBruta" class="ep-valid vdo-cookie">\
													'+$("select[name='proposta.receitaOperacionalBruta']").clone().html()+'\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Atividade econ&ocirc;mica:</label></div>\
										<div class="ep-mod9 ep-md-mod9">\
											<input type="hidden" name="proposta.atividadeEconomica" class="ep-form-codActivity" value="'+$("input[name='proposta.atividadeEconomica']").val()+'" />\
											<input type="hidden" name="proposta.descricaoAtividadeEconomica" class="ep-form-descActivity" value="'+$("input[name='proposta.descricaoAtividadeEconomica']").val()+'" />\
											<input type="text" name="activitiesFinder" placeholder="digite a atividade" \
												class="ep-form-control ep-valid ep-callback-keyup" id="ep-form-atividadesFinder" \
												data-callback="vdEp.DoBusinnessActivitiesSearch(this,\''+vdEpSkin.XHR_Actions.ActivitiesSearch+'\',\'#ep-activities-result\',\''+$("#codigoParametroSistema").val()+'\')" \
												data-activitiessearchcallback="#ep-form-atividadesFinder;.ep-form-codActivity;.ep-form-descActivity"\
												value="'+$("input[name='proposta.descricaoAtividadeEconomica']").val()+'" />\
										</div>\
										<div class="ep-mod9 ep-lspan3" id="ep-activities-result">\
											<div class="ep-profession-validate" style="display: none;">\
												<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
												Voc&ecirc; precisa escolher uma atividade na lista\
											</div>\
											<div class="ep-profession-loading" style="display: none;">\
												<span class="ep-icon ep-48 ep-loading-48"></span>&#160;\
												Aguarde\
											</div>\
											<div class="ep-profession-error" style="display: none;">\
												<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
												Houve um erro ao buscar as atividades \
											</div>\
											<div class="ep-subForm ep-subForm-single ep-profession-ctt" style="display: none;">\
												<div style="display: none;">\
													<h3>Escolha uma das op&ccedil;&otilde;es</h3>\
													<div class="ep-formList"></div>\
												</div>\
												<div style="display: none;margin: -5px;">\
													<em>Nenhuma atividade encontrada, digite outra.</em>\
												</div>\
											</div>\
										</div>\
									</div>\
									<div class="ep-row ep-datagrid" id="ep-addressData">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Endere&ccedil;o:</label></div>';
										if(vdEpSkin.IsMostraEndereco) {
											ctt += '<div class="ep-mod6" id="pf_datagrid_address">\
													<div class="ep-addressFields">\
														<div class="ep-row">\
															<input type="hidden" name="proposta.tipoLogradouro" value="'+defaultParam.obj.calculo.tipoLogradouro+'" class="ep-form-tipoLogradouro">\
															<input type="hidden" name="proposta.nomeLogradouro" value="'+defaultParam.obj.calculo.nomeLogradouro+'" class="ep-form-nomeLogradouro">\
															<input type="hidden" name="proposta.nomeBairro" value="'+defaultParam.obj.calculo.nomeBairro+'" class="ep-form-nomeBairro">\
															<input type="hidden" name="proposta.nomeCidade" value="'+defaultParam.obj.calculo.nomeCidade+'" class="ep-form-nomeCidade">\
															<input type="hidden" name="proposta.codigoUF" value="'+defaultParam.obj.calculo.codigoUF+'" class="ep-form-codigoUF">\
															<input type="hidden" name="proposta.numeroLogradouro" value="'+defaultParam.obj.calculo.numeroResidencial+'" class="ep-form-numeroLogradouro">\
															<input type="hidden" name="proposta.numeroCep" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="proposta.complementoCep" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<input type="hidden" name="calculo.numeroCepResidencial" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="calculo.complementoCepResidencial" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<input type="hidden" name="calculo.tipoLogradouro" value="'+defaultParam.obj.calculo.tipoLogradouro+'" class="ep-form-tipoLogradouro">\
															<input type="hidden" name="calculo.nomeLogradouro" value="'+defaultParam.obj.calculo.nomeLogradouro+'" class="ep-form-nomeLogradouro">\
															<input type="hidden" name="calculo.nomeBairro" value="'+defaultParam.obj.calculo.nomeBairro+'" class="ep-form-nomeBairro">\
															<input type="hidden" name="calculo.nomeCidade" value="'+defaultParam.obj.calculo.nomeCidade+'" class="ep-form-nomeCidade">\
															<input type="hidden" name="calculo.codigoUF" value="'+defaultParam.obj.calculo.codigoUF+'" class="ep-form-codigoUF">\
															<input type="hidden" name="calculo.numeroLogradouro" value="'+defaultParam.obj.calculo.numeroResidencial+'" class="ep-form-numeroLogradouro">\
															<input type="hidden" name="calculo.numeroCep" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="calculo.complementoCep" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<strong class="ep-mod12 ep-logradouro">\
																'+defaultParam.obj.calculo.tipoLogradouro+'\
																'+defaultParam.obj.calculo.nomeLogradouro+', '+defaultParam.obj.calculo.numeroResidencial+' - \
																'+defaultParam.obj.calculo.nomeBairro+'<br/>\
																'+defaultParam.obj.calculo.nomeCidade+'/\
																'+defaultParam.obj.calculo.codigoUF+' - '+(("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'-'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3))+'\
																<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit ep-showCepGeneric" data-showhideblock="#pf_datagrid_address;#pf_field_address1,#pf_field_address2">editar</a>\
															</strong>\
														</div>\
														<div class="ep-row">\
															<div class="ep-mod12">\
																<input type="text" name="fakeCompLogradouro" placeholder="complemento" class="ep-form-control ep-cleanupText ep-setValue" data-cleanuptext="allownumbers" data-setvalue="input[name=\'proposta.compLogradouro\']=={value}" value="'+$("input[name='proposta.compLogradouro']").val()+'" />\
															</div>\
														</div>\
													</div>\
												</div>\
												'+vdEpSkin.BuildCEPGeneric(defaultParam)+'\
										</div>';
									} else {
											ctt += vdEpSkin.BuildCEPGeneric(defaultParam)+'\
										</div>';
									}
									ctt += '<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">E-mail:</label></div>\
										<div class="ep-mod7" id="pf_datagrid_email">\
											<input type="hidden" name="proposta.emailProponente" value="'+$("input[name='proposta.emailProponente']").val()+'" />\
											<strong>'+$("input[name='proposta.emailProponente']").val()+'</strong>\
											<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_email;#pf_datagrid_email_edit">editar</a>\
										</div>\
										<div class="ep-mod7" id="pf_datagrid_email_edit" style="display: none;">\
											<input type="email" name="fakeEmailProponente" placeholder="e-mail" class="ep-form-control ep-valid ep-emailMask ep-setValue" data-setvalue="input[name=\'proposta.emailProponente\']=={value}" value="'+$("input[name='proposta.emailProponente']").val()+'" />\
										</div>\
									</div>\
									<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Telefone celular:</label></div>\
										<div class="ep-mod7" id="pf_datagrid_phone">\
											<input type="hidden" name="proposta.foneResidencialDDD" value="'+document.getElementById("calculo.foneCelularDDD").value+'" id="ep-form-celddd-real" />\
	 										<input type="hidden" name="proposta.foneResidencialNumero" value="'+document.getElementById("calculo.foneCelularNumero").value+'" id="ep-form-celnum-real" />\
											<input type="hidden" name="calculo.foneCelularDDD" value="'+document.getElementById("calculo.foneCelularDDD").value+'" id="ep-form-celddd" />\
											<input type="hidden" name="calculo.foneCelularNumero" value="'+document.getElementById("calculo.foneCelularNumero").value+'" id="ep-form-celnum" />\
											<strong>'+(document.getElementById("calculo.foneCelularNumero").value != "" ? '('+document.getElementById("calculo.foneCelularDDD").value+') '+document.getElementById("calculo.foneCelularNumero").value.slice(0,4)+'.'+document.getElementById("calculo.foneCelularNumero").value.slice(4,9) : '')+'</strong>\
											<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_phone;#pf_datagrid_phone_edit">editar</a>\
										</div>\
										<div class="ep-mod3" id="pf_datagrid_phone_edit" style="display: none;">\
											<input type="tel" name="" placeholder="telefone para contato" class="ep-form-control ep-valid ep-phoneNumber ep-split" \
													data-split=") ;#ep-form-celddd,#ep-form-celddd-real;#ep-form-celnum,#ep-form-celnum-real" \
													value="'+(document.getElementById("calculo.foneCelularNumero").value != "" ? '('+document.getElementById("calculo.foneCelularDDD").value+') '+document.getElementById("calculo.foneCelularNumero").value.slice(0,4)+'.'+document.getElementById("calculo.foneCelularNumero").value.slice(4,9) : '')+'" />\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Controladores:</label>\
										</div>\
										<div class="ep-mod6">\
											<div>\
												<input type="radio" name="proposta.controladores" value="S" id="ep-controladores-S" class="ep-radio ep-show ep-valid" \
												'+($("input[name='proposta.controladores']:checked").val() == "S" ? 'checked' : '')+' \
												data-show="#ep-controladores-fields" />\
												<label class="ep-radio" for="ep-controladores-S">\
													<span class="ep-icon ep-24 ep-radio-icon"></span>\
													H&aacute; administradores, controladores e procuradores\
												</label>\
											</div>\
											<div>\
												<input type="radio" name="proposta.controladores" value="N" id="ep-controladores-N" class="ep-radio ep-hide ep-valid" \
												'+($("input[name='proposta.controladores']:checked").val() == "N" ? 'checked' : '')+' \
												data-hide="#ep-controladores-fields" />\
												<label class="ep-radio" for="ep-controladores-N">\
													<span class="ep-icon ep-24 ep-radio-icon"></span>\
													n&atilde;o h&aacute; administradores, controladores e procuradores\
												</label>\
											</div>\
											<div>\
												<input type="radio" name="proposta.controladores" value="I" id="ep-controladores-I" class="ep-radio ep-hide ep-valid" \
												'+($("input[name='proposta.controladores']:checked").val() == "I" ? 'checked' : '')+' \
												data-hide="#ep-controladores-fields" />\
												<label class="ep-radio" for="ep-controladores-I">\
													<span class="ep-icon ep-24 ep-radio-icon"></span>\
													n&atilde;o desejo informar\
												</label>\
											</div>\
										</div>\
									</div>\
									<div class="ep-row" id="ep-controladores-fields" style="display: none;">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Lista de s&oacute;cios:</label>\
										</div>\
										<div class="ep-mod8 ep-subForm">\
											<div id="ep-controladores-container">\
												<div id="ep-controladores-item0">\
													<div class="ep-row">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Tipo de v&iacute;nculo</label>\
															<label class="ep-select">\
																<select name="proposta.equipamentosPortateisSocio[0].tipoVinculo" class="ep-valid">\
																	<option value="">Selecione</option>\
																	<option value="A">Administrador</option>\
																	<option value="C">Controlador</option>\
																	<option value="P">Procurador</option>\
																</select>\
															</label>\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Tipo de pessoa</label>\
															<label class="ep-select">\
																<select name="proposta.equipamentosPortateisSocio[0].tipoPessoa" class="ep-valid ep-showHide" \
																	data-showhide="1==#ep-controladores-item0-pf;#ep-controladores-item0-pj">\
																	<option value="1" selected>F&iacute;sica</option>\
																	<option value="2">Jur&iacute;dica</option>\
																</select>\
															</label>\
														</div>\
														<div class="ep-mod2 ep-center ep-sm-mod1" style="padding: 0;"> \
															<a href="javascript:;" class="ep-duplicateEraser" data-duplicateerase="#ep-controladores-duplicateAction;#ep-controladores-item0"><span class="ep-icon ep-36 ep-close-gray-36"></span></a> \
														</div> \
													</div>\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[0].nomeSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[0].numeroCpfCnpjSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[0].ordemCnpjSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[0].digitoCpfCnpjSocio" value="" />\
													<div class="ep-row" id="ep-controladores-item0-pf">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Nome completo:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-valid ep-setValue" data-setvalue="input[name=\'proposta.equipamentosPortateisSocio[0].nomeSocio\']" />\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">CPF:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-cpfMask ep-valid ep-split vdo-cookie" data-split="-;input[name=\'proposta.equipamentosPortateisSocio[0].numeroCpfCnpjSocio\'];input[name=\'proposta.equipamentosPortateisSocio[0].digitoCpfCnpjSocio\']" />\
														</div>\
														<div class="ep-mod10" style="margin-top: 20px;">\
															<strong>Pessoa exposta politicamente?</strong><br/>\
															<small>Pessoa que ocupa ou ocupou, nos &uacute;ltimos 5 anos, cargo pol&iacute;tico ou posi&ccedil;&atilde;o relevante em empresas p&uacute;blicas ou p&uacute;blico-privadas, no Brasil ou no exterior. Voc&ecirc; pode n&atilde;o ser pessoa exposta politicamente, mas possuir RELACIONAMENTO pr&oacute;ximo onde s&atilde;o considerados parentes, s&oacute;cios, representantes ou quaisquer outras pessoas que se relacionem com alguma pessoa exposta politicamente.</small>\
														</div>\
														<div class="ep-mod10">\
															<input type="radio" name="proposta.equipamentosPortateisSocio[0].pessoaExpostaPoliticamenteSocio" value="0" id="ep-controlador0-pep-0" class="ep-radio ep-hide" data-hide="#ep-controlador0-pepFields" />\
															<label class="ep-radio" for="ep-controlador0-pep-0">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> n&atilde;o\
															</label>\
															&#160;&#160;\
															<input type="radio" name="proposta.equipamentosPortateisSocio[0].pessoaExpostaPoliticamenteSocio" value="-1" id="ep-controlador0-pep-1" class="ep-radio ep-hide" data-hide="#ep-controlador0-pepFields" />\
															<label class="ep-radio" for="ep-controlador0-pep-1">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim\
															</label>\
															&#160;&#160;\
															<input type="radio" name="proposta.equipamentosPortateisSocio[0].pessoaExpostaPoliticamenteSocio" value="3" id="ep-controlador0-pep-2" class="ep-radio ep-show" data-show="#ep-controlador0-pepFields" />\
															<label class="ep-radio ep-radio-lineBreak" for="ep-controlador0-pep-2">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim, uma pessoa com relacionamento pr&oacute;ximo\
															</label>\
														</div>\
														<div class="ep-mod12" id="ep-controlador0-pepFields" style="display: none; margin-top: 20px;">\
															<label class="ep-lbl ep-lbl-inline ep-sm-mod12">Dados da pessoa exposta politicamente</label>\
															<div class="ep-row">\
																<div class="ep-mod5">\
																	<input type="text" name="proposta.equipamentosPortateisSocio[0].nomePepSocio" placeholder="nome" class="ep-form-control ep-valid ep-cleanupField" value="" />\
																</div>\
																<div class="ep-mod5">\
																	<input type="hidden" name="proposta.equipamentosPortateisSocio[0].numeroCpfSocioPep" value="" id="ep-controlador0-pepcpf1">\
																	<input type="hidden" name="proposta.equipamentosPortateisSocio[0].digitoCpfSocioPep" value="" id="ep-controlador0-pepcpf2">\
																	<input type="tel" name="" placeholder="CPF" class="ep-form-control ep-valid ep-cpfMask ep-split" data-split="-;#ep-controlador0-pepcpf1;#ep-controlador0-pepcpf2" value="" />\
																</div>\
																<div class="ep-mod5" style="margin-top: 10px;">\
																	<label class="ep-select">\
																		<select name="proposta.equipamentosPortateisSocio[0].grauRelacionamentoPepSocio" class="ep-valid">\
																			<option value="">Grau de parentesco</option>\
																			<option value="1">Pai ou m&atilde;e</option>\
																			<option value="2">C&ocirc;njuge</option>\
																			<option value="3">Companheiro(a)</option>\
																			<option value="4">Filho(a)</option>\
																			<option value="5">Enteado(a)</option>\
																			<option value="6">Outros</option>\
																		</select>\
																	</label>\
																</div>\
															</div>\
														</div>\
													</div>\
													<div class="ep-row" id="ep-controladores-item0-pj" style="display: none;">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Raz&atilde;o social:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-valid ep-setValue" data-setvalue="input[name=\'proposta.equipamentosPortateisSocio[0].nomeSocio\']" />\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">CNPJ:</label>\
															<input type="hidden" name="controladores[0].cnpj.ordemBuffer" value="" class="ep-split" data-split="-;input[name=\'proposta.equipamentosPortateisSocio[0].ordemCnpjSocio\'];input[name=\'proposta.equipamentosPortateisSocio[0].digitoCpfCnpjSocio\']" />\
															<input type="text" name="" value="" class="ep-form-control ep-cnpjMask ep-valid ep-split vdo-cookie" data-split="/;input[name=\'proposta.equipamentosPortateisSocio[0].numeroCpfCnpjSocio\'];input[name=\'controladores[0].cnpj.ordemBuffer\']" />\
														</div>\
													</div>\
													<div class="ep-row-divisor ep-mod12"></div> \
												</div>\
											</div>\
											<div class="ep-row">\
												<div class="ep-mod12">\
													<a href="javascript:;" class="ep-btn ep-gray ep-duplicateBlock" id="ep-controladores-duplicateAction" data-duplicateblock="#ep-controladores-matrix;#ep-controladores-container;9" data-duplicatecurrent="1">+ INCLUIR NOVO S&Oacute;CIO</a> \
												</div>\
											</div>\
											<div id="ep-controladores-matrix" style="display: none;">\
												<div class="ep-duplicatedItem" id="ep-controladores-item{i}">\
													<div class="ep-row">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Tipo de v&iacute;nculo</label>\
															<label class="ep-select">\
																<select name="proposta.equipamentosPortateisSocio[{i}].tipoVinculo" class="ep-valid">\
																	<option value="">Selecione</option>\
																	<option value="A">Administrador</option>\
																	<option value="C">Controlador</option>\
																	<option value="P">Procurador</option>\
																</select>\
															</label>\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Tipo de pessoa</label>\
															<label class="ep-select">\
																<select name="proposta.equipamentosPortateisSocio[{i}].tipoPessoa" class="ep-valid ep-showHide" \
																	data-showhide="1==#ep-controladores-item{i}-pf;#ep-controladores-item{i}-pj">\
																	<option value="1" selected>F&iacute;sica</option>\
																	<option value="2">Jur&iacute;dica</option>\
																</select>\
															</label>\
														</div>\
														<div class="ep-mod2 ep-center ep-sm-mod1" style="padding: 0;"> \
															<a href="javascript:;" class="ep-duplicateEraser" data-duplicateerase="#ep-controladores-duplicateAction;#ep-controladores-item{i}"><span class="ep-icon ep-36 ep-close-gray-36"></span></a> \
														</div> \
													</div>\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].nomeSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].numeroCpfCnpjSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].ordemCnpjSocio" value="" />\
													<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].digitoCpfCnpjSocio" value="" />\
													<div class="ep-row" id="ep-controladores-item0-pf">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Nome completo:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-valid ep-setValue" data-setvalue="input[name=\'proposta.equipamentosPortateisSocio[{i}].nomeSocio\']" />\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">CPF:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-cpfMask ep-valid ep-split vdo-cookie" data-split="-;input[name=\'proposta.equipamentosPortateisSocio[{i}].numeroCpfCnpjSocio\'];input[name=\'proposta.equipamentosPortateisSocio[{i}].digitoCpfCnpjSocio\']" />\
														</div>\
														<div class="ep-mod10" style="margin-top: 20px;">\
															<strong>Pessoa exposta politicamente?</strong><br/>\
															<small>Pessoa que ocupa ou ocupou, nos &uacute;ltimos 5 anos, cargo pol&iacute;tico ou posi&ccedil;&atilde;o relevante em empresas p&uacute;blicas ou p&uacute;blico-privadas, no Brasil ou no exterior. Voc&ecirc; pode n&atilde;o ser pessoa exposta politicamente, mas possuir RELACIONAMENTO pr&oacute;ximo onde s&atilde;o considerados parentes, s&oacute;cios, representantes ou quaisquer outras pessoas que se relacionem com alguma pessoa exposta politicamente.</small>\
														</div>\
														<div class="ep-mod10">\
															<input type="radio" name="proposta.equipamentosPortateisSocio[{i}].pessoaExpostaPoliticamenteSocio" value="0" id="ep-controlador{i}-pep-0" class="ep-radio ep-hide" data-hide="#ep-controlador{i}-pepFields" />\
															<label class="ep-radio" for="ep-controlador0-pep-0">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> n&atilde;o\
															</label>\
															&#160;&#160;\
															<input type="radio" name="proposta.equipamentosPortateisSocio[{i}].pessoaExpostaPoliticamenteSocio" value="-1" id="ep-controlador{i}-pep-1" class="ep-radio ep-hide" data-hide="#ep-controlador{i}-pepFields" />\
															<label class="ep-radio" for="ep-controlador0-pep-1">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim\
															</label>\
															&#160;&#160;\
															<input type="radio" name="proposta.equipamentosPortateisSocio[{i}].pessoaExpostaPoliticamenteSocio" value="3" id="ep-controlador{i}-pep-2" class="ep-radio ep-show" data-show="#ep-controlador{i}-pepFields" />\
															<label class="ep-radio ep-radio-lineBreak" for="ep-controlador1-pep-2">\
																<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim, uma pessoa com relacionamento pr&oacute;ximo\
															</label>\
														</div>\
														<div class="ep-mod12" id="ep-controlador{i}-pepFields" style="display: none; margin-top: 20px;">\
															<label class="ep-lbl ep-lbl-inline ep-sm-mod12">Dados da pessoa exposta politicamente</label>\
															<div class="ep-row">\
																<div class="ep-mod5">\
																	<input type="text" name="proposta.equipamentosPortateisSocio[{i}].nomePepSocio" placeholder="nome" class="ep-form-control ep-valid ep-cleanupField" value="" />\
																</div>\
																<div class="ep-mod5">\
																	<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].numeroCpfSocioPep" value="" id="ep-controlador0-pepcpf1">\
																	<input type="hidden" name="proposta.equipamentosPortateisSocio[{i}].digitoCpfSocioPep" value="" id="ep-controlador0-pepcpf2">\
																	<input type="tel" name="" placeholder="CPF" class="ep-form-control ep-valid ep-cpfMask ep-split" data-split="-;#ep-controlador{i}-pepcpf1;#ep-controlador{i}-pepcpf2" value="" />\
																</div>\
																<div class="ep-mod5" style="margin-top: 10px;">\
																	<label class="ep-select">\
																		<select name="proposta.equipamentosPortateisSocio[{i}].grauRelacionamentoPepSocio" class="ep-valid">\
																			<option value="">Grau de parentesco</option>\
																			<option value="1">Pai ou m&atilde;e</option>\
																			<option value="2">C&ocirc;njuge</option>\
																			<option value="3">Companheiro(a)</option>\
																			<option value="4">Filho(a)</option>\
																			<option value="5">Enteado(a)</option>\
																			<option value="6">Outros</option>\
																		</select>\
																	</label>\
																</div>\
															</div>\
														</div>\
													</div>\
													<div class="ep-row" id="ep-controladores-item{i}-pj" style="display: none;">\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">Raz&atilde;o social:</label>\
															<input type="text" name="" value="" class="ep-form-control ep-valid ep-setValue" data-setvalue="input[name=\'proposta.equipamentosPortateisSocio[{i}].nomeSocio\']" />\
														</div>\
														<div class="ep-mod5">\
															<label class="ep-lbl ep-lbl-inline">CNPJ:</label>\
															<input type="hidden" name="controladores[{i}].cnpj.ordemBuffer" value="" class="ep-split" data-split="-;input[name=\'proposta.equipamentosPortateisSocio[{i}].ordemCnpjSocio\'];input[name=\'proposta.equipamentosPortateisSocio[{i}].digitoCpfCnpjSocio\']" />\
															<input type="text" name="" value="" class="ep-form-control ep-cnpjMask ep-valid ep-split" data-split="/;input[name=\'proposta.equipamentosPortateisSocio[{i}].numeroCpfCnpjSocio\'];input[name=\'controladores[{i}].cnpj.ordemBuffer\']" />\
														</div>\
													</div>\
													<div class="ep-row-divisor ep-mod12"></div> \
												</div>\
											</div>\
										</div>\
									</div>\
							';
		} else {
			ctt += '				<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Nome completo:</label>\
										</div>\
										<div class="ep-mod5">\
											<input type="hidden" name="proposta.nomeProponente" value="'+$("input[name='proposta.nomeProponente']").val()+'" />\
											<div id="pf_datagrid_nomeProponente">\
												<strong>'+$("input[name='proposta.nomeProponente']").val()+'</strong> \
												<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_nomeProponente;input[name=\'fakeNomeProponente\']" style="display: none;">editar</a>\
											</div>\
											<input type="text" name="fakeNomeProponente" class="ep-form-control ep-valid ep-cleanupText ep-setValue" value="'+$("input[name='proposta.nomeProponente']").val()+'" data-setvalue="input[name=\'proposta.nomeProponente\']=={value}" style="display: none;" />\
										</div>\
									</div>';
			if($("input[name='proposta.nomeSocialProponente']").val() !== '' && $("input[name='proposta.nomeSocialProponente']").val() !== undefined) {
				ctt += '			<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Nome social:</label>\
										</div>\
										<div class="ep-mod5">\
											<input type="hidden" name="proposta.nomeSocialProponente" value="'+$("input[name='proposta.nomeSocialProponente']").val()+'" />\
											<div id="pf_datagrid_nomeSocialProponente">\
												<strong>'+$("input[name='proposta.nomeSocialProponente']").val()+'</strong> \
												<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_nomeSocialProponente;input[name=\'fakeNomeSocialProponente\']" style="display: none;">editar</a>\
											</div>\
											<input type="text" name="fakeNomeSocialProponente" class="ep-form-control ep-valid ep-cleanupText ep-setValue" value="'+$("input[name='proposta.nomeSocialProponente']").val()+'" data-setvalue="input[name=\'proposta.nomeSocialProponente\']=={value}" style="display: none;" />\
										</div>\
									</div>';
			}

			ctt += '				<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">CPF:</label>\
										</div>\
										<div class="ep-mod3">\
											<strong>'+$("input[name='numeroCpf']").val()+'</strong>\
										</div>\
									</div>\
									<div class="ep-row ep-datagrid" id="ep-addressData">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Endere&ccedil;o:</label></div>';
										if(vdEpSkin.IsMostraEndereco) {
											ctt += '<div class="ep-mod6" id="pf_datagrid_address">\
													<div class="ep-addressFields">\
														<div class="ep-row">\
															<input type="hidden" name="proposta.tipoLogradouro" value="'+defaultParam.obj.calculo.tipoLogradouro+'" class="ep-form-tipoLogradouro">\
															<input type="hidden" name="proposta.nomeLogradouro" value="'+defaultParam.obj.calculo.nomeLogradouro+'" class="ep-form-nomeLogradouro">\
															<input type="hidden" name="proposta.nomeBairro" value="'+defaultParam.obj.calculo.nomeBairro+'" class="ep-form-nomeBairro">\
															<input type="hidden" name="proposta.nomeCidade" value="'+defaultParam.obj.calculo.nomeCidade+'" class="ep-form-nomeCidade">\
															<input type="hidden" name="proposta.codigoUF" value="'+defaultParam.obj.calculo.codigoUF+'" class="ep-form-codigoUF">\
															<input type="hidden" name="proposta.numeroLogradouro" value="'+defaultParam.obj.calculo.numeroResidencial+'" class="ep-form-numeroLogradouro">\
															<input type="hidden" name="proposta.numeroCep" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="proposta.complementoCep" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<input type="hidden" name="calculo.numeroCepResidencial" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="calculo.complementoCepResidencial" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<input type="hidden" name="calculo.tipoLogradouro" value="'+defaultParam.obj.calculo.tipoLogradouro+'" class="ep-form-tipoLogradouro">\
															<input type="hidden" name="calculo.nomeLogradouro" value="'+defaultParam.obj.calculo.nomeLogradouro+'" class="ep-form-nomeLogradouro">\
															<input type="hidden" name="calculo.nomeBairro" value="'+defaultParam.obj.calculo.nomeBairro+'" class="ep-form-nomeBairro">\
															<input type="hidden" name="calculo.nomeCidade" value="'+defaultParam.obj.calculo.nomeCidade+'" class="ep-form-nomeCidade">\
															<input type="hidden" name="calculo.codigoUF" value="'+defaultParam.obj.calculo.codigoUF+'" class="ep-form-codigoUF">\
															<input type="hidden" name="calculo.numeroLogradouro" value="'+defaultParam.obj.calculo.numeroResidencial+'" class="ep-form-numeroLogradouro">\
															<input type="hidden" name="calculo.numeroCep" class="ep-form-cep1" value="'+("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'" />\
															<input type="hidden" name="calculo.complementoCep" class="ep-form-cep2" value="'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3)+'" />\
															<strong class="ep-mod12 ep-logradouro">\
																'+defaultParam.obj.calculo.tipoLogradouro+'\
																'+defaultParam.obj.calculo.nomeLogradouro+', '+defaultParam.obj.calculo.numeroResidencial+' - \
																'+defaultParam.obj.calculo.nomeBairro+'<br/>\
																'+defaultParam.obj.calculo.nomeCidade+'/\
																'+defaultParam.obj.calculo.codigoUF+' - '+(("00000"+defaultParam.obj.calculo.numeroCepResidencial).slice(-5)+'-'+("00000"+defaultParam.obj.calculo.complementoCepResidencial).slice(-3))+'\
																<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit ep-showCepGeneric" data-showhideblock="#pf_datagrid_address;#pf_field_address1,#pf_field_address2" style="display: none;">editar</a>\
															</strong>\
														</div>\
														<div class="ep-row">\
															<div class="ep-mod12">\
																<input type="text" name="fakeCompLogradouro" placeholder="complemento" class="ep-form-control ep-onlyNumbers ep-setValue vdo-cookie" data-setvalue="input[name=\'proposta.compLogradouro\']=={value}" value="'+$("input[name='proposta.compLogradouro']").val()+'" maxlength="10" />\
															</div>\
														</div>\
													</div>\
												</div>\
												'+vdEpSkin.BuildCEPGeneric(defaultParam)+'\
										</div>';
									} else {
											ctt += vdEpSkin.BuildCEPGeneric(defaultParam)+'\
										</div>';
									}
									ctt += '<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">E-mail:</label></div>\
										<div class="ep-mod7" id="pf_datagrid_email">\
											<input type="hidden" name="proposta.emailProponente" value="'+$("input[name='proposta.emailProponente']").val()+'" />\
											<strong>'+$("input[name='proposta.emailProponente']").val()+'</strong>\
											<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_email;#pf_datagrid_email_edit" style="display: none;">editar</a>\
										</div>\
										<div class="ep-mod7" id="pf_datagrid_email_edit" style="display: none;">\
											<input type="email" name="fakeEmailProponente" placeholder="e-mail" class="ep-form-control ep-valid ep-emailMask ep-setValue" data-setvalue="input[name=\'proposta.emailProponente\']=={value}" value="'+$("input[name='proposta.emailProponente']").val()+'" />\
										</div>\
									</div>\
									<div class="ep-row ep-datagrid">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Telefone celular:</label></div>\
										<div class="ep-mod7" id="pf_datagrid_phone">\
											<input type="hidden" name="proposta.foneResidencialDDD" value="'+document.getElementById("calculo.foneCelularDDD").value+'" id="ep-form-celddd-real" />\
	 										<input type="hidden" name="proposta.foneResidencialNumero" value="'+document.getElementById("calculo.foneCelularNumero").value+'" id="ep-form-celnum-real" />\
											<input type="hidden" name="calculo.foneCelularDDD" value="'+document.getElementById("calculo.foneCelularDDD").value+'" id="ep-form-celddd" />\
											<input type="hidden" name="calculo.foneCelularNumero" value="'+document.getElementById("calculo.foneCelularNumero").value+'" id="ep-form-celnum" />\
											<strong>'+(document.getElementById("calculo.foneCelularNumero").value != "" ? '('+document.getElementById("calculo.foneCelularDDD").value+') '+document.getElementById("calculo.foneCelularNumero").value.slice(0,4)+'.'+document.getElementById("calculo.foneCelularNumero").value.slice(4,9) : '')+'</strong>\
											<a href="javascript:;" class="ep-showHideBlock ep-datagrid-edit" data-showhideblock="#pf_datagrid_phone;#pf_datagrid_phone_edit" style="display: none;">editar</a>\
										</div>\
										<div class="ep-mod3" id="pf_datagrid_phone_edit" style="display: none;">\
											<input type="tel" name="" placeholder="telefone para contato" class="ep-form-control ep-valid ep-phoneNumber ep-split" \
													data-split=") ;#ep-form-celddd,#ep-form-celddd-real;#ep-form-celnum,#ep-form-celnum-real" \
													value="'+(document.getElementById("calculo.foneCelularNumero").value != "" ? '('+document.getElementById("calculo.foneCelularDDD").value+') '+document.getElementById("calculo.foneCelularNumero").value.slice(0,4)+'.'+document.getElementById("calculo.foneCelularNumero").value.slice(4,9) : '')+'" />\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Sexo:</label>\
										</div>\
										<div class="ep-mod6">\
											<input type="radio" name="proposta.sexoProponente" value="F" id="ep-sexoProponente-F" class="ep-radio ep-valid vdo-cookie" '+($("input[name='proposta.sexoProponente']:checked").val() == "F" ? 'checked' : '')+' />\
											<label class="ep-radio" for="ep-sexoProponente-F">\
												<span class="ep-icon ep-24 ep-radio-icon"></span>\
												Feminino\
											</label>\
											<input type="radio" name="proposta.sexoProponente" value="M" id="ep-sexoProponente-M" class="ep-radio ep-valid vdo-cookie" '+($("input[name='proposta.sexoProponente']:checked").val() == "M" ? 'checked' : '')+' />\
											<label class="ep-radio" for="ep-sexoProponente-M">\
												<span class="ep-icon ep-24 ep-radio-icon"></span>\
												Masculino\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Documento de identifica&ccedil;&atilde;o:</label></div>\
										<div class="ep-mod3 ep-md-mod4">\
											<label class="ep-select">\
												<select name="proposta.tipoDocumento" class="ep-callback ep-valid" data-callback="vdEp.ChangeDocumentType(this);" data-changedocumenttype="#ep-form-nacionalidade-usr">\
													<option value="">Tipo</option>\
													<option value="1" '+($("#documentoIdentificacao").val() == '1' ? 'selected' : '')+'>RG</option>\
													<option value="2" '+($("#documentoIdentificacao").val() == '2' ? 'selected' : '')+'>Carteira de classe</option>\
													<option value="3" '+($("#documentoIdentificacao").val() == '3' ? 'selected' : '')+'>RNE</option>\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row ep-sm-join">\
										<div class="ep-mod3 ep-right ep-sm-hide">&#160;</div>\
										<div class="ep-mod3">\
											<input type="text" name="proposta.numeroDocumento" placeholder="n&uacute;mero" class="ep-form-control ep-valid" value="'+$("input[name='proposta.numeroDocumento']").val()+'" />\
										</div>\
										<div class="ep-mod2">\
											<input type="text" name="proposta.orgaoExpedidor" placeholder="&oacute;rg&atilde;o" class="ep-form-control ep-valid ep-cleanupText" value="'+$("input[name='proposta.orgaoExpedidor']").val()+'" />\
										</div>\
										<div class="ep-mod3">\
											<input type="hidden" name="proposta.diaDataExpedicao" value="'+$("input[name='proposta.diaDataExpedicao']").val()+'" id="ep-form-diaExp" />\
											<input type="hidden" name="proposta.mesDataExpedicao" value="'+$("input[name='proposta.mesDataExpedicao']").val()+'" id="ep-form-mesExp" />\
											<input type="hidden" name="proposta.anoDataExpedicao" value="'+$("input[name='proposta.anoDataExpedicao']").val()+'" id="ep-form-anoExp" />\
											<input type="tel" name="proposta.dataExpedicao" placeholder="data de expedi&ccedil;&atilde;o" class="ep-form-control ep-dateMask ep-valid ep-valDate ep-split" data-validdate="after;'+$("input[name='calculo.dataNascimento']").val()+'" data-split="/;#ep-form-diaExp;#ep-form-mesExp;#ep-form-anoExp" \
												value="'+($("input[name='proposta.diaDataExpedicao']").val() != "" ? $("input[name='proposta.diaDataExpedicao']").val()+'/'+$("input[name='proposta.mesDataExpedicao']").val()+'/'+$("input[name='proposta.anoDataExpedicao']").val() : '')+'" />\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Nacionalidade:</label></div>\
										<div class="ep-mod3 ep-md-mod4">\
											<label class="ep-select">\
												<input type="hidden" name="proposta.nacionalidade" value="'+$("#nacionalidade").val()+'" />\
												<select name="fake.nacionalidade" class="ep-valid ep-setValue vdo-cookie" data-setvalue="input[name=\'proposta.nacionalidade\']=={value}" id="ep-form-nacionalidade-usr">\
													<option value="1" '+($("#nacionalidade").val() == "1" ? 'selected' : '')+'>Brasileira</option>\
													<option value="2" '+($("#nacionalidade").val() == "2" ? 'selected' : '')+'>Estrangeira</option>\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Reside no Brasil?</label></div>\
										<div class="ep-mod3">\
											<label class="ep-select">\
												<select name="proposta.residePais" class="ep-valid ep-callback" data-callback="vdEp.ShowHideCountries(this);" data-showhidecountries="#ep-form-countryList;#ep-form-paisesOrigem,#ep-form-codPaisesOrigem">\
													<option value="1" '+($("#residePais").val() == "1" ? 'selected' : '')+'>Sim</option>\
													<option value="2" '+($("#residePais").val() == "2" ? 'selected' : '')+'>N&atilde;o</option>\
												</select>\
											</label>\
										</div>\
										<div class="ep-mod5 ep-sm-join" id="ep-form-countryList" '+($("#residePais").val() != "2" ? 'style="display: none;"' : '')+'>\
											<input type="hidden" name="proposta.paisesOrigem" value="'+$("input[name='proposta.codPaisesOrigem']").val()+'" id="ep-form-paisesOrigem">\
											<label class="ep-select">\
												<select name="proposta.codPaisesOrigem" class="ep-valid ep-fillOptionText" data-filloptiontext="#ep-form-paisesOrigem" id="ep-form-codPaisesOrigem">\
													'+$("#paisResidente").clone().html()+'\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right"><label class="ep-lbl">Profiss&atilde;o:</label></div>\
										<div class="ep-mod9">\
											<input type="hidden" name="proposta.codigoProfissao" class="ep-form-codProf" value="'+$("input[name='proposta.codigoProfissao']").val()+'" />\
											<input type="hidden" name="proposta.descricaoProfissao" class="ep-form-descProf" value="'+$("input[name='proposta.descricaoProfissao']").val()+'" />\
											<input type="text" name="profissaoFinder" placeholder="digite sua profiss&atilde;o" \
												class="ep-form-control ep-valid ep-callback-keyup" id="ep-form-profissaoFinder" \
												data-callback="vdEp.DoProfessionSearch(this,\''+vdEpSkin.XHR_Actions.ProfessionSearch+'\',\'#ep-profession-result\',\'#ep-form-codigoProduto\')" \
												data-professionsearchcallback="#ep-form-profissaoFinder;.ep-form-codProf;.ep-form-descProf"\
												value="'+$("input[name='proposta.descricaoProfissao']").val()+'" />\
										</div>\
										<div class="ep-mod9 ep-lspan3" id="ep-profession-result">\
											<div class="ep-profession-validate" style="display: none;">\
												<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
												Voc&ecirc; precisa escolher uma profiss&atilde;o na lista\
											</div>\
											<div class="ep-profession-loading" style="display: none;">\
												<span class="ep-icon ep-48 ep-loading-48"></span>&#160;\
												Aguarde\
											</div>\
											<div class="ep-profession-error" style="display: none;">\
												<span class="ep-icon ep-24 ep-statusError-red-24"></span>\
												Houve um erro ao buscar profiss&otilde;es\
											</div>\
											<div class="ep-subForm ep-subForm-single ep-profession-ctt" style="display: none;">\
												<div style="display: none;">\
													<h3>Escolha uma das op&ccedil;&otilde;es</h3>\
													<div class="ep-formList"></div>\
												</div>\
												<div style="display: none;margin: -5px;">\
													<em>Nenhuma profiss&atilde;o encontrada, digite outra.</em>\
												</div>\
											</div>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Faixa de renda:</label>\
										</div>\
										<div class="ep-mod4 ep-md-mod5">\
											<label class="ep-select">\
												<select name="proposta.faixaRenda" class="ep-valid vdo-cookie">\
													<option value="">Selecione</option>\
													<option value="1" '+($("select[name='proposta.faixaRenda']").val() == "1" ? 'selected' : '')+'>Sem renda</option>\
													<option value="2" '+($("select[name='proposta.faixaRenda']").val() == "2" ? 'selected' : '')+'>at&eacute; R$ 2.500</option>\
													<option value="3" '+($("select[name='proposta.faixaRenda']").val() == "3" ? 'selected' : '')+'>De R$ 2.501 a R$ 5.000</option>\
													<option value="4" '+($("select[name='proposta.faixaRenda']").val() == "4" ? 'selected' : '')+'>De R$ 5.001 a R$ 10.000</option>\
													<option value="5" '+($("select[name='proposta.faixaRenda']").val() == "5" ? 'selected' : '')+'>Acima de R$ 10.000,00</option>\
													<option value="0" '+($("select[name='proposta.faixaRenda']").val() == "0" ? 'selected' : '')+'>N&atilde;o desejo informar</option>\
												</select>\
											</label>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod3 ep-right">\
											<label class="ep-lbl">Estado civil:</label>\
										</div>\
										<div class="ep-mod4">\
											<label class="ep-select">\
												<select name="proposta.codigoEstadoCivil" class="ep-valid">\
													<option value="">Selecione</option>\
													<option value="1" '+($("select[name='proposta.codigoEstadoCivil']").val() == "1" ? 'selected' : '')+'>Solteiro(a)</option>\
													<option value="2" '+($("select[name='proposta.codigoEstadoCivil']").val() == "2" ? 'selected' : '')+'>Casado(a)</option>\
													<option value="3" '+($("select[name='proposta.codigoEstadoCivil']").val() == "3" ? 'selected' : '')+'>Vi&uacute;vo(a)</option>\
													<option value="4" '+($("select[name='proposta.codigoEstadoCivil']").val() == "4" ? 'selected' : '')+'>Divorciado(a)</option>\
													<option value="5" '+($("select[name='proposta.codigoEstadoCivil']").val() == "5" ? 'selected' : '')+'>Outros</option>\
												</select>\
											</label>\
										</div>\
									</div>\
									<br/>\
									<div class="ep-row">\
										<div class="ep-mod8">\
											<h4>Pessoa exposta politicamente?</h4>\
											<small>Pessoa que ocupa ou ocupou, nos &uacute;ltimos 5 anos, cargo pol&iacute;tico ou posi&ccedil;&atilde;o relevante em empresas p&uacute;blicas ou p&uacute;blico-privadas, no Brasil ou no exterior. Voc&ecirc; pode n&atilde;o ser pessoa exposta politicamente, mas possuir RELACIONAMENTO PR&Oacute;XIMO onde s&atilde;o considerados parentes, s&oacute;cios, representantes ou quaisquer outras pessoas que se relacionem com alguma pessoa exposta politicamente.</small>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod12">\
											<input type="radio" name="proposta.pessoaExpostaPoliticamente" value="0" id="ep-pep-0" checked class="ep-radio ep-hide" data-hide="#ep-pepFields" \
											'+($("input[name='proposta.pessoaExpostaPoliticamente']:checked").val() == "2" ? 'checked' : '')+' />\
											<label class="ep-radio" for="ep-pep-0">\
												<span class="ep-icon ep-24 ep-checkbox-icon"></span> N&atilde;o\
											</label>\
											&#160;&#160;\
											<input type="radio" name="proposta.pessoaExpostaPoliticamente" value="-1" id="ep-pep-1" class="ep-radio ep-hide" data-hide="#ep-pepFields" \
											'+($("input[name='proposta.pessoaExpostaPoliticamente']:checked").val() == "1" ? 'checked' : '')+' />\
											<label class="ep-radio" for="ep-pep-1">\
												<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim\
											</label>\
											&#160;&#160;\
											<input type="radio" name="proposta.pessoaExpostaPoliticamente" value="3" id="ep-pep-2" class="ep-radio ep-show" data-show="#ep-pepFields" \
											'+($("input[name='proposta.pessoaExpostaPoliticamente']:checked").val() == "3" ? 'checked' : '')+' />\
											<label class="ep-radio ep-radio-lineBreak" for="ep-pep-2">\
												<span class="ep-icon ep-24 ep-checkbox-icon"></span> Sim, uma pessoa com relacionamento pr&oacute;ximo\
											</label>\
										</div>\
									</div>\
									<div class="ep-row" id="ep-pepFields" '+($("input[name='proposta.pessoaExpostaPoliticamente']:checked").val() != "3" ? 'style="display: none;"' : '')+'>\
										<div class="ep-subForm ep-subForm-single">\
											<label class="ep-lbl ep-lbl-inline ep-sm-mod12">Dados da pessoa exposta politicamente</label>\
											<div class="ep-row">\
												<div class="ep-mod5">\
													<input type="text" name="proposta.nomePessoaExpostaPoliticamente" placeholder="nome" class="ep-form-control ep-valid ep-cleanupField" value="'+$("input[name='proposta.nomePessoaExpostaPoliticamente']").val()+'" />\
												</div>\
												<div class="ep-mod3 ep-sm-join">\
													<input type="hidden" name="proposta.numeroCpfPessoaExpostaPoliticamente" value="'+$("input[name='proposta.numeroCpfPessoaExpostaPoliticamente']").val()+'" id="ep-pepcpf1">\
													<input type="hidden" name="proposta.digitoCpfPessoaExpostaPoliticamente" value="'+$("input[name='proposta.digitoCpfPessoaExpostaPoliticamente']").val()+'" id="ep-pepcpf2">\
													<input type="tel" name="numeroCpfPessoaExposta" placeholder="CPF" class="ep-form-control ep-valid ep-cpfMask ep-split" data-split="-;#ep-pepcpf1;#ep-pepcpf2" value="'+$("input[name='numeroCpfPessoaExposta']").val()+'" />\
												</div>\
												<div class="ep-mod4 ep-sm-join">\
													<label class="ep-select">\
														<select name="proposta.grauRelacionamentoPep" class="ep-valid">\
															<option value="">Grau de parentesco</option>\
															<option value="1" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "1" ? 'selected' : '')+'>Pai ou m&atilde;e</option>\
															<option value="2" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "2" ? 'selected' : '')+'>C&ocirc;njuge</option>\
															<option value="3" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "3" ? 'selected' : '')+'>Companheiro(a)</option>\
															<option value="4" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "4" ? 'selected' : '')+'>Filho(a)</option>\
															<option value="5" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "5" ? 'selected' : '')+'>Enteado(a)</option>\
															<option value="6" '+($("input[name='proposta.grauRelacionamentoPep']:checked") == "6" ? 'selected' : '')+'>Outros</option>\
														</select>\
													</label>\
												</div>\
											</div>\
										</div>\
									</div>\
							';
		}
		
		ctt += '					<div class="ep-row ep-submitRow ep-center">\
										<div class="ep-mod4 ep-lspan4">\
											<input type="submit" class="ep-btn" value="CONTINUAR" />\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</form>\
		';

		var agentData = defaultParam.obj.calculo.corretor;
			ctt += '<div class="ep-agentBlock">\
						<div class="ep-container">\
							<div class="ep-row">\
								<div class="ep-mod12">\
									<h4>Seu corretor:</h4>\
								</div>\
							</div>\
							<div class="ep-row">\
								<div class="ep-mod2 ep-sm-hide">\
									<span class="ep-icon ep-96 ep-corretorAvatar-gray-96"></span>\
									<input type="hidden" name="susep" id="corretorId" value="'+agentData.susepWeb+'" />\
								</div>\
								<div class="ep-mod4">\
									<label class="ep-lbl ep-lbl-inline" id="corretorNome">'+agentData.nome+'</label>\
									'+(!vdEpSkin.IsTopoCorretor ? '<a href="javascript:;" class="ep-showModal" data-modalref="#ModalBuscaCorretor" data-modalcallback="vdEp.GTMBehavior(\'alterar-corretor\')" style="display: none"><span class="ep-icon ep-24 ep-back-blue-24"></span>alterar corretor</a>' : '')+'\
								</div>\
								<div class="ep-mod6">\
									<div class="ep-row">\
										<div class="ep-mod12">\
											<span class="ep-icon ep-24 ep-phone-black-24"></span>\
											<span id="corretorPhone">\
												'+(typeof agentData.telefoneCorretorVendaOnline == "undefined" ? 'sem telefone cadastrado' : '('+agentData.telefoneCorretorVendaOnline.numeroDddTelefoneCorretor+') '+agentData.telefoneCorretorVendaOnline.numeroTelefoneCorretor)+'\
											</span>\
										</div>\
									</div>\
									<div class="ep-row">\
										<div class="ep-mod12"><span class="ep-icon ep-24 ep-email-black-24"></span> \
											<span id="corretorEmail">'+(typeof agentData.email == "undefined" || agentData.email == "" ? 'sem e-mail cadastrado' : agentData.email.toLowerCase())+'</span>\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
		';

		ctt +=		vdEpSkin.BuildFooter()+'\
					'+vdEpSkin.BuildModal("ModalResumoSeguro",defaultParam)+'\
					'+vdEpSkin.BuildModal("ModalCEP",defaultParam)+'\
					'+vdEpSkin.BuildModal("ModalBuscaCorretor",defaultParam)+'\
				</div>';

		return ctt;
	},
	Checkout2PrefillData: function (params) {
		var container = ".ep-checkoutForm",
			d = params.obj,
			isPJ = typeof d.calculo.ordemCnpjProponente != "undefined" && d.calculo.ordemCnpjProponente != "" ? true : false;

		if (isPJ) {
			$("input[name='proposta.nomeProponente']", container).val(d.proposta.nomeProponente).trigger("change").trigger("blur");
			$("select[name='proposta.patrimonioLiquido']", container).val(d.proposta.patrimonioLiquido).trigger("change");
			$("select[name='proposta.receitaOperacionalBruta']", container).val(d.proposta.receitaOperacionalBruta).trigger("change");
			$("input[name='proposta.atividadeEconomica']", container).val(d.proposta.atividadeEconomica).trigger("change");
			$("input[name='proposta.descricaoAtividadeEconomica']", container).val(d.proposta.descricaoAtividadeEconomica).trigger("change");
			$("input[name='activitiesFinder']", container).val(d.proposta.descricaoAtividadeEconomica);

			$("input[name='proposta.controladores'][value='" + d.proposta.controladores + "']", container).trigger("click");

			if (d.proposta.controladores == "S") {
				var l = d.proposta.equipamentosPortateisSocio.length;
				for (var i = 1; i < l; i++) {
					$("#ep-controladores-duplicateAction").trigger("click");
				}

				for (var i = 0; i < l; i++) {
					var cd = d.proposta.equipamentosPortateisSocio[i];
					$("select[name='proposta.equipamentosPortateisSocio[" + i + "].tipoVinculo']").val(cd.tipoVinculo).trigger("change");
					$("select[name='proposta.equipamentosPortateisSocio[" + i + "].tipoPessoa']").val(cd.tipoPessoa).trigger("change");

					if (cd.tipoPessoa == "2") {
						$("input[name='razaoSocialSocio[" + i + "]']").val(cd.nomeSocio).trigger("blur");
						$("input[name='cnpjSocio[" + i + "]']").val(cd.numeroCpfCnpjSocio + cd.ordemCnpjSocio + cd.digitoCpfCnpjSocio).trigger("blur");
					} else {
						$("input[name='nomeSocio[" + i + "]']").val(cd.nomeSocio).trigger("blur");
						$("input[name='cpfSocio[" + i + "]']").val(cd.numeroCpfCnpjSocio + cd.digitoCpfCnpjSocio).trigger("blur");
						$("input[name='proposta.equipamentosPortateisSocio[" + i + "].pessoaExpostaPoliticamenteSocio']").val(cd.pessoaExpostaPoliticamenteSocio).trigger("click");

						$("input[name='proposta.equipamentosPortateisSocio[" + i + "].nomePepSocio']").val(cd.nomePepSocio).trigger("blur");
						$("input[name='cpfPepSocio[" + i + "]']").val(cd.numeroCpfSocioPep + cd.digitoCpfSocioPep).trigger("blur");
						$("select[name='proposta.equipamentosPortateisSocio[" + i + "].grauRelacionamentoPepSocio']").val(cd.grauRelacionamentoPepSocio).trigger("change");
					}

				}
			}

		} else {
			$("input[name='proposta.nomeProponente']", container).val(d.proposta.nomeProponente).trigger("change").trigger("blur");
			$("input[name='proposta.sexoProponente'][value='" + d.proposta.sexoProponente + "']", container).trigger("click");
			$("select[name='proposta.tipoDocumento']", container).val(d.proposta.tipoDocumento).trigger("change");
			// $("input[name='numeroCpf']").val() // O n&uacute;mero do CPF j&aacute; vem cadastrado

			$("input[name='proposta.numeroDocumento']", container).val(d.proposta.numeroDocumento).trigger("change").trigger("blur");
			$("input[name='proposta.orgaoExpedidor']", container).val(d.proposta.orgaoExpedidor).trigger("change").trigger("blur");
			$("input[name='proposta.dataExpedicao']", container).val(d.proposta.dataExpedicao).trigger("change").trigger("blur");

			$("select[name='fake.nacionalidade']", container).val(d.proposta.nacionalidade).trigger("change");
			$("select[name='proposta.residePais']", container).val(d.proposta.residePais).trigger("change");
			$("select[name='proposta.codPaisesOrigem']", container).val(d.proposta.codPaisesOrigem).trigger("change");
			$("select[name='proposta.faixaRenda']", container).val(d.proposta.faixaRenda).trigger("change");
			$("select[name='proposta.codigoEstadoCivil']", container).val(d.proposta.codigoEstadoCivil).trigger("change");

			$("input[name='proposta.pessoaExpostaPoliticamente'][value='" + d.proposta.pessoaExpostaPoliticamente + "']", container).trigger("click");
		}

		if (d.calculo.complementoCepResidencial === 0) {
			$("input[name='fake.nomeLogradouro']", container).val(d.proposta.nomeLogradouro).trigger("change").trigger("blur");
			$("input[name='fake.nomeBairro']", container).val(d.proposta.nomeBairro).trigger("change").trigger("blur");
		}

		$("input[name='proposta.emailProponente']", container).val(d.proposta.emailProponente).trigger("change").trigger("blur");
		$("input.ep-phoneNumber", container).val(d.proposta.foneResidencialDDD + d.proposta.foneResidencialNumero).trigger("blur").trigger("change");
	},
	Checkout3: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: "voltar.ns?forwardVO=dadossegurado",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoAtendimento: $("input[name='codigoAtendimento']") != null ? $("input[name='codigoAtendimento']").val() : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				canalVenda: $("input[name='calculo.canalVenda']") != null ? $("input[name='calculo.canalVenda']").val() : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam, true);

		/*
		window.customData.page = {
			'template': '8',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/


		ctt += '<div class="ep-siteContainer">\
					<form name="checkout" class="ep-checkoutForm" id="ep-checkoutForm" action="' + defaultParam.formAction + '" method="post">\
						<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '" />\
						<input type="hidden" name="skin" value="' + defaultParam.skin + '" />\
						<input type="hidden" name="codigoAtendimento" value="' + defaultParam.codigoAtendimento + '" id="ep-codigoAtendimento" />\
						<input type="hidden" name="CalcConfig.canalVenda" value="' + defaultParam.canalVenda + '" />\
						<input type="hidden" name="codigoCanal" value="' + defaultParam.codigoCanal + '" />\
						\
						<div class="ep-form ep-calc-checkout">\
							' + vdEpSkin.BuildTop("checkout3", defaultParam) + '\
							<div class="ep-form">\
								<div class="ep-container">\
									<div class="ep-row ep-title-block">\
										<div class="ep-mod5">\
											<h1><strong>Corretor</strong></h1>\
										</div>\
										' + vdEpSkin.BuildHeaderCalcInfo(defaultParam) + '\
									</div>\
									<div class="ep-row">\
										<p class="ep-mod12">Confira o Corretor de Seguros que est&aacute; <strong>vinculado ao seu cadastro</strong>:</p>\
									</div>\
									<div class="ep-row ep-corretor-info">\
										<div class="ep-mod2 ep-sm-mod4">\
											<div class="ep-corretorAvatar ep-center"><span class="ep-icon ep-96 ep-corretorAvatar-gray-96"></span></div>\
										</div>\
										<div class="ep-mod5 ep-sm-mod8">\
											<input type="hidden" name="susep" id="corretorId" value="32" />\
											<h2 id="corretorNome">' + $("#nome-corretora").html() + '</h2>\
											<a href="javascript:;" class="ep-showModal" data-modalref="#ModalBuscaCorretor" data-modalcallback="vdEp.GTMBehavior(\'alterar-corretor\')" style="display: none;"><span class="ep-icon ep-24 ep-back-blue-24"></span>alterar corretor</a>\
										</div>\
										<div class="ep-mod5 ep-corretorData">\
											<div class="ep-row">\
												<span class="ep-icon ep-24 ep-phone-black-24"></span> <span id="corretorPhone">' + $("#fone-corretora").html() + '</span>\
											</div>\
											<div class="ep-row">\
												<span class="ep-icon ep-24 ep-email-black-24"></span> <span id="corretorEmail">' + $("#email-corretora").html() + '</span>\
											</div>\
										</div>\
									</div>';


		ctt += '					<div class="ep-row ep-submitRow ep-center">\
										<div class="ep-mod4 ep-lspan4">\
											<input type="submit" class="ep-btn" value="CONTINUAR" />\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</form>\
					' + vdEpSkin.BuildFooter() + '\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
					' + vdEpSkin.BuildModal("ModalBuscaCorretor", defaultParam) + '\
				</div>';

		return ctt;
	},
	Checkout4: function (params) {

		var ctt = "",
			tipoProponente = params.obj.calculo.tipoProponente,
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: "voltar.ns?forwardVO=dadoscorretor",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoAtendimento: $("input[name='codigoAtendimento']") != null ? $("input[name='codigoAtendimento']").val() : '',
				codigoCanal: document.getElementById("codigoCanal") != null ? document.getElementById("codigoCanal").value : '',
				canalVenda: $("input[name='calculo.canalVenda']") != null ? $("input[name='calculo.canalVenda']").val() : '',
				codigoProduto: document.getElementById("codigoProduto") != null ? document.getElementById("codigoProduto").value : '',
				valorTotalSeguro: $("input[name='proposta.dadosPagamento.valorTotalSeguro']") != null ? $("input[name='proposta.dadosPagamento.valorTotalSeguro']").val() : '',
				skin: $("#hiddenskin").length > 0 ? document.getElementById("hiddenskin").value : '',
				nomeProponente: document.getElementById("it_nomecom") != null ? document.getElementById("it_nomecom").value : '',
				cpfProponente: document.getElementById("it_cpf") != null ? document.getElementById("it_cpf").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam, true);

		var options = '',
			disabledField;
		if (tipoProponente == "1") {
			options = '<option>001</option><option>013</option>';
		} else {
			options = '<option selected="selected">003</option>';
			disabledField = "disabled";
		}

		vdEp.StartScreens();

		/*
		window.customData.page = {
			'template': '9',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}*/

		ctt += '<div class="ep-siteContainer">\
					<form name="checkout" class="ep-checkoutForm" id="ep-checkoutForm" action="' + defaultParam.formAction + '" method="post" onsubmit="return vdEp.Checkout4Submit(this);">\
						<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '" />\
						<input type="hidden" name="skin" value="' + defaultParam.skin + '" />\
						<input type="hidden" name="codigoAtendimento" value="' + defaultParam.codigoAtendimento + '" />\
						<input type="hidden" name="calculo.canalVenda" value="' + defaultParam.canalVenda + '" />\
						<input type="hidden" name="codigoProduto" value="' + defaultParam.codigoProduto + '" />\
						<input type="hidden" name="proposta.dadosPagamento.tipoPagamento" value="" id="ep-tipoPagamento" />\
						<input type="hidden" name="proposta.dadosPagamento.codigoTipoPagamento" value="" id="ep-codigoTipoPagamento" />\
						\
						<input type="hidden" name="proposta.dadosPagamento.valorTotalSeguro" value="' + defaultParam.valorTotalSeguro + '" />\
						<input type="hidden" name="proposta.dadosPagamento.quantidadeParcelas" value="" id="ep-quantidadeParcelas" />\
						<input type="hidden" name="proposta.dadosPagamento.valorParcela" value="" id="ep-valorParcela" />\
						<input type="hidden" name="proposta.dadosPagamento.valorEncargos" value="" id="" />\
						<input type="hidden" name="proposta.dadosPagamento.valorJuros" value="" id="ep-valorJuros" />\
						<input type="hidden" name="proposta.dadosPagamento.percentualJuros" value="" />\
						<input type="hidden" name="it_nomecom" value="' + defaultParam.nomeProponente + '" />\
						<input type="hidden" name="it_cpf" value="' + defaultParam.cpfProponente + '" />\
						<input type="hidden" name="proposta.dadosPagamento.percentualJuros" value="" />\
						<input type="hidden" name="proposta.dadosPagamento.dataVencimentoDebito" value="' + $("input[name='proposta.dadosPagamento.dataVencimentoDebito']").val() + '" />\
						\
						<div class="ep-form ep-calc-checkout">\
							' + vdEpSkin.BuildTop("checkout4", defaultParam) + '\
							<div class="ep-form">\
								<div class="ep-container">\
									<div class="ep-row ep-title-block">\
										<div class="ep-mod5">\
											<h1>Realize o <strong>Pagamento</strong></h1>\
										</div>\
										' + vdEpSkin.BuildHeaderCalcInfo(defaultParam) + '\
									</div>\
									<div class="ep-row" style="margin: 0;">\
										<div class="ep-row ep-tab ep-md-mod10 ep-md-lspan1 ep-sm-mod12">\
											<a href="javascript:;" class="ep-md-mod4 ep-sm-mod5 ep-tabItem ep-selected ep-showHideBlock ep-hide" data-showhideblock=".ep-tab-content .ep-cc;#ep-form-cardData;.ep-tabItem" data-hide="#ep-form-bankData">\
									 			<span class="ep-icon ep-24 ep-ico-credit-card"></span>\
												<span class="ep-text-type ep-sm-hide">Cart&otilde;es <br/>de cr&eacute;dito</span>\
											</a>\
											<a href="javascript:;" class="ep-md-mod5 ep-sm-mod5 ep-tabItem ep-showHideBlock ep-hide" data-showhideblock=".ep-tab-content .e-adc;#ep-form-bankData;.ep-tabItem" data-hide="#ep-form-cardData">\
												<span class="ep-icon ep-24 ep-ico-bank"></span>\
									 			<span class="ep-text-type ep-sm-hide">D&eacute;bito em <br/>conta corrente</span>\
											</a>\
										</div>\
										<div class="ep-row ep-tab-content ep-md-mod10 ep-md-lspan1 ep-sm-mod12">\
											<div class="ep-mod12 ep-cc" id="ep-form-cardData">\
												<input type="hidden" name="proposta.dadosPagamento.codigoBandeiraCartaoCredito" value="" id="" class="ep-creditCard-type" />\
												<div class="ep-mod6 ep-lspan3 ep-sm-lspan0 ep-sm-mod12 ep-md-lspan0 ep-md-mod12">\
													<h3 class="ep-row ep-row-noBottom">Agora preencha as <strong>informa&ccedil;&otilde;es</strong> referentes ao cart&atilde;o selecionado:</h3>\
													<div class="ep-row">\
														<div id="card-error-msg" class="ep-panel-error" style="display:none;"></div>\
													</div>\
													<div class="ep-row">\
														<input type="hidden" name="primNumeroDemaisCartoes" value="" id="primNumeroDemaisCartoes" class="ep-cc-default-0" />\
														<input type="hidden" name="segNumeroDemaisCartoes" value="" id="segNumeroDemaisCartoes" class="ep-cc-default-1"/>\
														<input type="hidden" name="terNumeroDemaisCartoes" value="" id="terNumeroDemaisCartoes" class="ep-cc-default-2"/>\
														<input type="hidden" name="quartNumeroDemaisCartoes" value="" id="quartNumeroDemaisCartoes" class="ep-cc-default-3"/>\
														\
														<input type="hidden" name="primNumeroCartaoPorto" value="" id="primNumeroCartaoPorto" class="ep-cc-porto-0"/>\
														<input type="hidden" name="segNumeroCartaoPorto" value="" id="segNumeroCartaoPorto" class="ep-cc-porto-1"/>\
														<input type="hidden" name="terNumeroCartaoPorto" value="" id="terNumeroCartaoPorto" class="ep-cc-porto-2"/>\
														<input type="hidden" name="quartNumeroCartaoPorto" value="" id="quartNumeroCartaoPorto" class="ep-cc-porto-3">\
														\
														<input type="hidden" name="proposta.dadosPagamento.primNumCartaoCredito" value="" id="proposta.dadosPagamento.primNumeroCartaoPorto" class="ep-cc-0">\
														<input type="hidden" name="proposta.dadosPagamento.segNumCartaoCredito" value="" id="proposta.dadosPagamento.segNumCartaoCredito" class="ep-cc-1">\
														<input type="hidden" name="proposta.dadosPagamento.terNumCartaoCredito" value="" id="proposta.dadosPagamento.terNumCartaoCredito" class="ep-cc-2">\
														<input type="hidden" name="proposta.dadosPagamento.quarNumCartaoCredito" value="" id="proposta.dadosPagamento.quarNumCartaoCredito" class="ep-cc-3">\
														\
														<label class="ep-lbl ep-lbl-inline">N&uacute;mero do cart&atilde;o:</label>\
														<input type="tel" name="cardNumber" class="ep-form-control ep-valid ep-cardMask ep-split" data-creditcardtype=".ep-creditCard-type" data-parcelamento=".ep-cc-default;.ep-cc-porto" />\
														<div class="ep-creditCard-icons">\
															<span class="ep-ico-creditCardPorto"></span>\
															<span class="ep-ico-creditCardMaster"></span>\
															<span class="ep-ico-creditCardVisa"></span>\
															<span class="ep-ico-creditCardDiners"></span>\
															<span class="ep-ico-creditCardElo"></span>\
														</div>\
													</div>\
													<div class="ep-row" style="display: none;">\
														<label class="ep-lbl ep-lbl-inline">Nome impresso no cart&atilde;o:</label>\
														<input type="hidden" name="nomeTitularCartaoPorto" value="" id="ep-nomeTitularCartaoPorto" />\
														<input type="text" name="proposta.dadosPagamento.nomeTitularCartao" value="" class="ep-form-control ep-valid ep-cleanupText ep-setValue" data-setvalue="#ep-nomeTitularCartaoPorto=={value}" />\
													</div>\
													<div class="ep-row ep-row-noBottom">\
														<div class="ep-row ep-row-noBottom">\
														<div class="ep-mod8 ep-sm-mod8">\
															<div class="ep-row">\
																<div class="ep-mod6 ep-sm-mod6">\
																	<label class="ep-lbl ep-lbl-inline">Validade:</label>\
																	<input type="hidden" name="proposta.dadosPagamento.mesValidadeCartaoCredito" value="" id="ep-mesValidadeCartao" />\
																	<div class="ep-cc-default">\
																		<label class="ep-select">\
																			<select id="mesValidacao" name="hidden" class="ep-callback ep-valid ep-setValue" data-setvalue="#ep-mesValidadeCartao=={value}">\
																				' + $("select[name='mesValidadeDemaisCartoes']").clone().html() + '\
																			</select>\
																		</label>\
																	</div>\
																	<div class="ep-cc-porto" style="display:none">\
																		<label class="ep-select">\
																			<select id="mesValidacao" name="mesValidacao" class="ep-callback ep-valid ep-setValue" data-setvalue="#ep-mesValidadeCartao=={value}">\
																				' + $("select[name='mesValidadeCartaoPorto']").clone().html() + '\
																			</select>\
																		</label>\
																	</div>\
																</div>\
																<div class="ep-mod6 ep-sm-mod6">\
																	<input type="hidden" name="proposta.dadosPagamento.anoValidadeCartaoCredito" value="" id="ep-anoValidadeCartao" />\
																	<div class="ep-cc-default">\
																		<label class="ep-lbl ep-lbl-inline">&#160;</label>\
																		<label class="ep-select">\
																			<select id="anoValidacao" name="anoValidacao" class="ep-callback ep-valid ep-setValue" data-setvalue="#ep-anoValidadeCartao=={value}">\
																				' + $("select[name='anoValidadeDemaisCartoes']").clone().html() + '\
																			</select>\
																		</label>\
																	</div>\
																	<div class="ep-cc-porto" style="display:none">\
																		<label class="ep-lbl ep-lbl-inline">&#160;</label>\
																		<label class="ep-select">\
																			<select id="anoValidacao" name="anoValidacao" class="ep-callback ep-valid ep-setValue" data-setvalue="#ep-anoValidadeCartao=={value}">\
																				' + $("select[name='anoValidadeCartaoPorto']").clone().html() + '\
																			</select>\
																		</label>\
																	</div>\
																</div>\
															</div>\
														</div>\
														<div class="ep-mod4 ep-sm-mod4">\
															<label class="ep-lbl ep-lbl-inline ep-lbl-cvv">\
																CVV:\
																<span class="ep-icon ep-24 ep-help-yellow-24 ep-tooltip ep-md-hide ep-sm-hide" style="position:relative; float: right; top: -3px;"></span>\
															</label>\
															<span class="tooltip">\
																<input type="hidden" name="codSeguCartaoPorto" value="" id="ep-codSeguCartaoPorto" />\
																<input id="codeCard" type="text" name="proposta.dadosPagamento.codigoSegurancaCartaoCredito" class="ep-form-control ep-valid ep-onlyNumbers ep-setValue" maxlength="3" data-setvalue="#ep-codSeguCartaoPorto=={value}" />\
																<strong>Insira o c&oacute;digo de seguran&ccedil;a impresso no verso do seu cart&atilde;o de cr&eacute;dito com 3 (tr&ecirc;s) d&iacute;gitos.</strong>\
															</span>\
														</div>\
														</div>\
													</div>\
													<div class="ep-row">\
														<label class="ep-lbl ep-lbl-inline">Parcelamento:</label>\
														<div class="ep-cc-default">\
															<label class="ep-select">\
																<select name="" class="ep-valid ep-callback" data-callback="vdEp.ChangeCreditCard(this);" data-split="#ep-quantidadeParcelas;#ep-valorParcela;#ep-valorJuros" data-setvalue="#ep-codigoTipoPagamento:62;#ep-tipoPagamento:2">\
																	' + vdEpSkin.Checkout4CreditPayment() + '\
																</select>\
															</label>\
														</div>\
														<div class="ep-cc-porto" style="display: none">\
															<label class="ep-select">\
																<select name="" class="ep-valid ep-callback" data-callback="vdEp.ChangeCreditCard(this);" data-split="#ep-quantidadeParcelas;#ep-valorParcela;#ep-valorJuros" data-setvalue="#ep-codigoTipoPagamento:97;#ep-tipoPagamento:2">\
																	' + vdEpSkin.Checkout4CreditPortoPayment() + '\
																</select>\
															</label>\
														</div>\
													</div>\
													<div class="ep-row">'+vdEpSkin.Checkout4BrokerEarnings(defaultParam)+'</div>\
													<div class="ep-row ep-submitRow">\
														<input type="submit" class="ep-btn" value="CONTINUAR" />\
													</div>\
												</div>\
											</div>\
											<div class="ep-mod12 ep-adc" id="ep-form-bankData" style="display: none;">\
												<div class="ep-mod8 ep-lspan2 ep-sm-lspan0 ep-sm-mod12 ep-md-lspan0 ep-md-mod12">\
													<div class="ep-row ep-row-noBottom">\
														<h3>Agora preencha as <strong>informa&ccedil;&otilde;es</strong> referentes da conta corrente:</h3>\
														<div class="clearfix">&nbsp;</div>\
														<div class="ep-row-info" style="margin-bottom:10px">\
															<span class="ep-icon ep-24 ep-statusAlert-gray-24"></span>\
															<strong>Aten&ccedil;&atilde;o:</strong> O titular do seguro deve ser obrigatoriamente tamb&eacute;m titular da conta corrente.<br/>\
															<br/>\
															O primeiro d&eacute;bito ser&aacute; efetuado cinco dias ap&oacute;s a emiss&atilde;o de sua ap&oacute;lice.\
														</div>\
													</div>\
													<div class="ep-row">\
														<label class="ep-lbl ep-lbl-inline">Banco:</label>\
														<label class="ep-select">\
															<select name="proposta.dadosPagamento.codigoBanco" class="ep-valid ep-callback ep-showHide" data-showhide="1==#BB-Debito-Info;341==#Itau-Debito-Info;104==#cefAccount;104!=#cefAccount;104==#selectOperation;104==#Caixa-Debito-Info" data-showhidemultiple="true" data-callback="vdEp.ChangeBankFields(this);" data-changebankfields="#ep-codigoAgencia;#ep-account;#ep-digitoContaDebito;#ep-cefAccount">\
																' + $("select[name='proposta.dadosPagamento.codigoBanco']").clone().html() + '\
															</select>\
														</label>\
													</div>\
													<div class="ep-row ep-row-noBottom">\
														<div class="ep-row ep-row-noBottom">\
															<div class="ep-mod6">\
																<label class="ep-lbl ep-lbl-inline">\
																	Ag&ecirc;ncia\
																		<small>(sem d&iacute;gito):</small>\
																</label>\
																<input type="tel" name="proposta.dadosPagamento.codigoAgencia" id="ep-codigoAgencia" class="ep-form-control ep-valid ep-onlyNumbers ep-jumpNext ep-cleanupText" data-jumpnext="#ep-numeroContaDebito" data-cleanuptext="allownumbers" maxlength="7" />\
															</div>\
															<div class="ep-mod3" id="selectOperation" style="display:none;">\
																<label class="ep-lbl ep-lbl-inline">Opera&ccedil;&atilde;o</label>\
																<label class="ep-select">\
																	<select id="ep-cefAccount" ' + disabledField + ' data-operacao="" class="ep-form-control ep-valid">\
																		' + options +
			'</select>\
																</label>\
															</div>\
															<div class="ep-mod6">\
																<label class="ep-lbl ep-lbl-inline">Conta corrente:</label>\
																<div class="ep-row">\
																	<input type="hidden" name="proposta.dadosPagamento.numeroContaDebito" id="ep-numeroContaDebito"/>\
																	<div class="ep-mod6 ep-sm-mod6">\
																		<input type="tel" name="" id="ep-account" class="account ep-form-control ep-valid ep-onlyNumbers ep-jumpNext" maxlength="11" placeholder="N&uacute;mero" />\
																	</div>\
																	<div class="ep-mod6 ep-sm-mod6">\
																		<input type="tel" name="proposta.dadosPagamento.digitoContaDebito" id="ep-digitoContaDebito" class="ep-digitoContaDebito ep-form-control ep-valid ep-onlyNumbers" maxlength="5" placeholder="D&iacute;gito" />\
																	</div>\
																</div>\
															</div>\
														</div>\
													</div>\
													<div class="ep-row">\
														<label class="ep-lbl ep-lbl-inline">Parcelamento:</label>\
														<label class="ep-select">\
															<select name="" class="ep-valid ep-callback" data-callback="vdEp.ChangeCreditCard(this);" data-split="#ep-quantidadeParcelas;#ep-valorParcela;#ep-valorJuros" data-setvalue="#ep-codigoTipoPagamento:52;#ep-tipoPagamento:1">\
																' + $("select[name='parcelamentoDebito']").clone().html() + '\
															</select>\
														</label>\
													</div>\
													<div class="ep-row">\
														<div class="ep-row-frm-group ep-row-info" id="BB-Debito-Info" style="display: none;">\
															<span class="ep-icon ep-24 ep-statusAlert-gray-24"></span>\
															<strong>Banco do Braisl</strong>\
															<p>A autoriza&ccedil;&atilde;o do d&eacute;bito dever&aacute; ser realizada at&eacute; dois dias &uacute;teis antes do vencimento.<br/>\
															<a href="' + defaultParam.assetPath + '/vd-autorizacao-banco-bb.pdf" target="_blank">Clique aqui</a>, baixe o manual e saiba como realizar a opera&ccedil;&atilde;o pelos canais de auto atendimento.</p>\
														</div>\
														<div class="ep-row-frm-group ep-row-info" id="Itau-Debito-Info" style="display: none;">\
															<span class="ep-icon ep-24 ep-statusAlert-gray-24"></span>\
															<strong>Ita&uacute;</strong>\
															<p>A autoriza&ccedil;&atilde;o do d&eacute;bito dever&aacute; ser realizada at&eacute; dois dias &uacute;teis antes do vencimento.<br/>\
															<a href="' + defaultParam.assetPath + '/vd-autorizacao-banco-itau.pdf" target="_blank">Clique aqui</a>, baixe o manual e saiba como realizar a opera&ccedil;&atilde;o pelos canais de auto atendimento.</p>\
														</div>\
														<div class="ep-row-frm-group ep-row-info" id="Caixa-Debito-Info" style="display: none;">\
															<span class="ep-icon ep-24 ep-statusAlert-gray-24"></span>\
															<strong>Caixa Econ&ocirc;mica Federal</strong>\
															<p>S&atilde;o v&aacute;lidas apenas as contas Pessoas F&iacute;sica de c&oacute;digo de opera&ccedil;&atilde;o 001 e 013, e Pessoas Jur&iacute;dica c&oacute;digo 003</p>\
															<ul>\
																<li>No campo Opera&ccedil;&atilde;o selecione o c&oacute;digo de sua conta: 001 ou 013 para pessoa F&iacute;sia ou 003 para pessoa Jur&iacute;dida.</li>\
																<li>No campo N&uacute;mero, insira o n&uacute;mero de sua conta corrente. Deve ser preenchido sem o c&oacute;digo do tipo de conta (001, 013 e 003).</li>\
																<li>No campo d&iacute;gito, insira o n&uacute;mero da conta que aparece ap&oacute;s o tra&ccedil;o.</li>\
															</ul>\
															<p>\
																<strong>Exemplo Conta Corrente Pessoa F&iacute;sica:</strong><br/>\
																0123 (ag&ecirc;ncia)  001 (c&oacute;digo de opera&ccedil;&atilde;o) 011111 (n&uacute;mero da conta) - 1 (d&iacute;gito)</p>\
															<p>\
																<strong>Exemplo Conta pessoa Jur&iacute;dica:</strong><br/>\
																0123 (ag&ecirc;ncia)  003 (c&oacute;digo de opera&ccedil;&atilde;o) 011111 (n&uacute;mero da conta) - 1 (d&iacute;gito)<p>\
														</div>\
													</div>\
													<div class="ep-row">'+vdEpSkin.Checkout4BrokerEarnings(defaultParam)+'</div>\
													<div class="ep-row ep-submitRow">\
														<input type="submit" class="ep-btn" value="CONTINUAR" />\
													</div>\
												</div>\
											</div>\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</form>\
					' + vdEpSkin.BuildFooter() + '\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
				</div>';

		return ctt;
	},
	Checkout4CreditPayment: function() {
		return $("select[name='parcelamentoDemaisCartoes']").clone().html();
	},
	Checkout4CreditPortoPayment: function() {
		return $("select[name='parcelamentoCartaoPorto']").clone().html();
	},
	Checkout4BuildPaymentOption: function (obj, id, codigoTipoEquipamento, tipoPagamento, mobile) {
		var ctt = "",
			counter = 0;

		obj.each(function () {
			var cv = $(this).val(),
				cvArray = cv.split("|");
			parcela = cvArray[0],
				total = cvArray[1],
				juros = parseInt(cvArray[2]) > 0 ? true : false;

			if (cv == "") return true;

			if (mobile == true) {
				ctt += '<option value="' + cv + '">' + parcela + 'x de ' + vdEp.FormatCurrency(total) + ' ' + (juros ? 'c/' : 's/') + ' juros' + '</option>';
			} else {
				ctt += '<div class="ep-mod4">\
						<input type="radio" name="pagamento" value="' + cv + '" id="ep-' + id + '-' + counter + '" class="ep-radio ep-show ep-callback ep-showHideBlock" \
							data-callback="vdEp.ChangeCreditCard(this);" \
							data-split="#ep-quantidadeParcelas;#ep-valorParcela;#ep-valorJuros" \
							data-setvalue="#ep-codigoTipoPagamento:' + codigoTipoEquipamento + ';#ep-tipoPagamento:' + tipoPagamento + '" \
							data-showhideblock="' + (id == "adc" ? '#ep-form-cardData;.ep-submitRow, #ep-form-bankData' : '#ep-form-bankData;.ep-submitRow, #ep-form-cardData') + '" />\
						\
						<label class="ep-radio" for="ep-' + id + '-' + counter + '" style="margin-right: 5px">\
							<span class="ep-icon ep-24 ep-radio-icon"></span>\
							' + parcela + 'x de ' + vdEp.FormatCurrency(total) + ' <small>(' + (juros ? 'com' : 'sem') + ' juros)</small>\
						</label>\
					</div>';
			}


			counter++;
		});

		return ctt;
	},
	Checkout4BrokerEarnings: function(params) {
		if(typeof params == "undefined") percent = "0";

		var rtn = "",
			percent = params.obj.proposta.percentualComissao;

		rtn = '<p class="ep-broker-earnigns">'+$("#equipamentosportateis_form_resolucao_cnsp p").html()+'</p>';

		return rtn;
	},
	Checkout5: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='equipamentosportateisForm']").attr("action"),
				voltarAction: params.obj.codigoCanal != 1 ? "https://wwws.portoseguro.com.br/vendaonline/equipamentosportateis/home.ns?cod="+params.obj.calculo.codigoCadastroConfLink+"&utm_source="+params.obj.calculo.corretor.susepPrincipal+"&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_"+params.obj.calculo.corretor.susepY+"&utm_content="+params.obj.calculo.content+"&origem="+params.obj.calculo.origem : $("a.cancel").attr("href"),
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				codigoAtendimento: params.obj.codigoAtendimento != null ? params.obj.codigoAtendimento : '',
				codigoCanal: params.obj.codigoCanal != null ? params.obj.codigoCanal : '',
				numeroProposta: "",
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};

		$.extend(defaultParam, params);
		defaultParam.vigencia = vdEpSkin.SetInsuranceDates(defaultParam);
		defaultParam.equip = vdEpSkin.SetEquipArray(defaultParam, true);
		defaultParam.codigoAtendimento = defaultParam.obj.codigoAtendimento;
		defaultParam.numeroProposta = defaultParam.obj.retornoProposta.origemProposta + "-" + defaultParam.obj.retornoProposta.numeroProposta;

		/*
		window.customData.page = {
			'template': '10',
			'brand': '1',
			'sub_product': vdEpSkin.GetGTMProductString(true,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo),
			'acessories': vdEpSkin.GetGTMProductString(false,defaultParam.obj.retornoCalculo.equipamentosPortateisItemCalculo)
		}
		window.customData.page.purchase = {
			'transactionid': defaultParam.codigoAtendimento,
			'revenue': $("#valorSeguroGA").val()
		};*/

		/*var bufCount = 0;
		$("#equipamentosportateisForm h2[style]").each(function() {
			var t = $(this).html();
			t = t.replace("Origem nº: ","").replace("Proposta nº: ","");

			if(bufCount == 0) t += " - ";

			defaultParam.numeroProposta += t;
			bufCount++;
		});*/

		ctt += '<div class="ep-siteContainer">\
					<div class="ep-form ep-feedback">\
						' + vdEpSkin.BuildTop("checkout5", defaultParam) + '\
						<div class="ep-form">\
							<div class="ep-container">\
								<div class="ep-row ep-title-block">\
									<div class="ep-mod12">';
		if (defaultParam.equip.length > 1) {
			ctt += '<h1>Recebemos sua solicita&ccedil;&atilde;o. Em breve, seu(s) equipamentos(s) estar&atilde;o protegidos :)</h1>';
		} else {
			ctt += '<h1>Recebemos sua solicita&ccedil;&atilde;o. Em breve, seu equipamento estar&aacute; protegido :)</h1>';
		}
		ctt += '</div>\
								</div>\
								<div class="ep-row">\
									<div class="ep-mod6">\
										<div class="ep-subForm">\
											<small><strong>Anote</strong> o n&uacute;mero da proposta do seu seguro:</small>\
											<strong>' + defaultParam.numeroProposta + '</strong>\
										</div>\
									</div>\
									<div class="ep-mod6">\
										<div class="ep-subForm">\
											<small><strong>Valor</strong> total do seguro:</small>\
											<strong>' + vdEp.FormatCurrency($("#valorSeguroGA").val()) + '</strong>\
										</div>\
									</div>\
									<!--div class="ep-mod4">\
										<div class="ep-subForm">\
											<small><strong>Salvar</strong> dados da proposta:</small>\
											<a href="#" target="_blank"><span class="ep-icon ep-24 ep-download-blue-24"></span> DOWNLOAD DE PDF</a>\
										</div>\
									</div-->\
								</div>\
								<div class="ep-row">\
									<div class="ep-mod12">\
										<h1><strong>Resumo</strong> dos itens:</h1>\
										<ul class="ep-deviceList">';
		for (var i = 0, l = defaultParam.equip.length; i < l; i++) {
			ctt += '		<li>\
												<div class="ep-iconBlock"><span class="ep-icon ep-72 ep-' + defaultParam.equip[i].ProtoType + '-gray-72"></span></div>\
												' + defaultParam.equip[i].descricaoMarcaEquipamento + ' ' + defaultParam.equip[i].descricaoModelo + '<br/>\
												<a href="javascript:" class="ep-showModal" data-modalref="#ModalResumoSeguro" data-modalcallback="vdEp.GTMBehavior(\'resumo-seguro\')">ver coberturas</a>\
											</li>';
		}
		ctt += '					</ul>\
									</div>\
								</div>';
		/*if(defaultParam.equip.length > 1) {
			ctt += '			<div class="ep-row">\
									<div class="ep-mod12">\
										<h1>Voc&ecirc; receber&aacute; em seu e-mail <strong>o seu contrato:</strong></h1>\
										<div class="ep-ctt-info">\
											<div class="ep-iconRound"><span class="ep-icon ep-48 ep-email-gray-48"></span></div>\
											Nesse contrato voc&ecirc; poder&aacute; conferir todas as informa&ccedil;&otilde;es referentes ao seguro do seu aparelho, como coberturas contratadas,<br/> datas de vig&ecirc;ncia, e muito mais.\
										</div>\
									</div>\
								</div>\
								<div class="ep-row">\
									<div class="ep-mod12">\
										<h1><strong>Resumo</strong> dos itens</h1>\
										<ul class="ep-deviceList">';
			for(var i=0,l=defaultParam.equip.length;i<l;i++) {
							ctt += '		<li>\
												<div class="ep-iconBlock"><span class="ep-icon ep-72 ep-'+defaultParam.equip[i].ProtoType+'-gray-72"></span></div>\
												'+defaultParam.equip[i].descricaoMarcaEquipamento+' '+defaultParam.equip[i].descricaoModelo+'<br/>\
												<a href="javascript:" class="ep-showModal" data-modalref="#ModalResumoSeguro" data-modalcallback="vdEp.GTMBehavior(\'resumo-seguro\')">ver coberturas</a>\
											</li>';
			}
			ctt += '					</ul>\
									</div>\
								</div>';
		} else {
			ctt += '			<div class="ep-row">\
									<div class="ep-mod4">\
										<h1><strong>Resumo</strong> dos itens</h1>\
										<ul class="ep-deviceList">\
											<li>\
												<div class="ep-iconBlock"><span class="ep-icon ep-72 ep-'+defaultParam.equip[0].ProtoType+'-gray-72"></span></div>\
												'+defaultParam.equip[0].descricaoMarcaEquipamento+' '+defaultParam.equip[0].descricaoModelo+'<br/>\
												<a href="javascript:" class="ep-showModal" data-modalref="#ModalResumoSeguro" data-modalcallback="vdEp.GTMBehavior(\'resumo-seguro\')">ver coberturas</a>\
											</li>\
										</ul>\
									</div>\
									<div class="ep-mod8">\
										<h1>Voc&ecirc; receber&aacute; em seu e-mail <strong>o seu contrato:</strong></h1>\
										<div class="ep-ctt-info">\
											<div class="ep-iconRound"><span class="ep-icon ep-48 ep-email-gray-48"></span></div>\
											Nesse contrato voc&ecirc; poder&aacute; conferir todas as informa&ccedil;&otilde;es referentes ao seguro do seu aparelho, como coberturas contratadas, datas de vig&ecirc;ncia, e muito mais.\
										</div>\
									</div>\
								</div>';
		}*/

		ctt += '				<div class="ep-row ep-contactRow">\
									<div class="ep-mod12">\
										<h1>Ainda est&aacute; com <strong>d&uacute;vidas?</strong></h1>\
										Fale com seu corretor ou com a Porto Seguro.\
									</div>\
									<div class="ep-mod6 ep-contact">\
										<div class="ep-iconRound"><span class="ep-icon ep-48 ep-corretorAvatar-gray-48"></span></div>\
										<strong>Seu Corretor:</strong><br/>\
										' + defaultParam.obj.calculo.corretor.nome + '<br/>\
										Tel.: (' + defaultParam.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroDddTelefoneCorretor + ') ' + defaultParam.obj.calculo.corretor.telefoneCorretorVendaOnline.numeroTelefoneCorretor + '<br/>\
										E-mail: <a href="mailto:' + defaultParam.obj.calculo.corretor.email + '">' + defaultParam.obj.calculo.corretor.email + '</a>\
									</div>\
									<div class="ep-mod6 ep-contact">\
										<div class="ep-iconRound"><span class="ep-icon ep-48 ep-centralAtendimento-gray-48"></span></div>\
										<strong>Central de Atendimento</strong><br/>\
										Grande S&atilde;o Paulo: 3366-3377<br/>\
										Demais Localidades: 0800-727-9393<br/>\
										De 2&ordf; a 6&ordf;, das 8h &agrave;s 18h30\
									</div>\
								</div>\
								<div class="ep-row ep-submitRow">\
									<div class="ep-mod3 ep-md-mod4">\
										<a href="http://www.portoseguro.com.br/" class="ep-btn">IR PARA PORTO SEGURO</a>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
					<form name="" class="ep-checkoutForm" id="ep-checkoutForm" action="" method="get"></form>\
					' + vdEpSkin.BuildFooter() + '\
					' + vdEpSkin.BuildModal("ModalResumoSeguro", defaultParam) + '\
				</div>';



		return ctt;
	},
	Recuperar: function (params) {
		var ctt = "",
			defaultParam = {
				formAction: $("form[name='recuperarCalculoForm']").attr("action"),
				voltarAction: "javascript:history.back();",
				numeroPagina: document.getElementById("numeroPagina") != null ? document.getElementById("numeroPagina").value : '',
				imgPath: "/static/img",
				assetPath: "/static/asset"
			};
		$.extend(defaultParam, params);

		/*
		window.customData.page = {
			'template': '13'
		}*/

		ctt += '<div class="ep-siteContainer">\
					<div class="ep-form ep-recuperarProtocolo">\
						' + vdEpSkin.BuildTop("recuperar", defaultParam) + '\
						<div class="ep-container">\
							<div class="ep-row ep-title-block">\
								<div class="ep-mod12">\
									<h1>Recuperar &uacute;ltimas <strong>cota&ccedil;&otilde;es</strong></h1>\
								</div>\
							</div>\
							<div class="ep-row">\
								<div class="ep-sm-mod12"><p>Para efetuar a recupera&ccedil;&atilde;o de cota&ccedil;&otilde;es, &eacute; necess&aacute;rio que estejam nas seguintes condi&ccedil;&otilde;es:</p></div>\
							</div>\
							<div class="ep-row ep-recuperarInfo">\
								<div class="ep-mod1 ep-sm-mod4">\
									<span class="ep-icon ep-48 ep-recuperarPrazo-48"></span>\
								</div>\
								<div class="ep-mod3 ep-sm-mod8">\
									Realizado a Cota&ccedil;&atilde;o nos<br class="ep-responsive-break" /> &uacute;ltimos 30 dias\
								</div>\
								<div class="ep-mod1 ep-lspan1 ep-sm-mod4">\
									<span class="ep-icon ep-48 ep-recuperarCadastro-48"></span>\
								</div>\
								<div class="ep-mod4 ep-sm-mod8">\
									Escolhido um dos pacotes de seguro e preenchido os dados do segurado\
								</div>\
							</div>\
							<div class="ep-subForm ep-row">\
								<form name="" class="showFeedback" id="ep-checkoutForm" action="' + defaultParam.formAction + '" method="post" onsubmit="return vdEp.ValidateForm(this);">\
									<input type="hidden" name="numeroPagina" value="' + defaultParam.numeroPagina + '" />\
									<div class="ep-row ep-form-group">\
										<div class="ep-mod3 ep-md-mod3 ep-sm-lspan0">\
											<label class="ep-lbl">Tipo de pessoa:</label>\
											<div>\
												<input type="radio" name="tipoPessoa" value="1" id="ep-tipoPessoa-pf" class="ep-radio ep-showHideBlock" data-showhideblock=".ep-tipoPessoa-block;#ep-tipoPessoaBlock-pf" checked  />\
												<label class="ep-radio" for="ep-tipoPessoa-pf">\
													<span class="ep-icon ep-24 ep-radio-icon"></span>\
													Pessoa f&iacute;sica\
												</label>\
												<input type="radio" name="tipoPessoa" value="2" id="ep-tipoPessoa-pj" class="ep-radio ep-showHideBlock" data-showhideblock=".ep-tipoPessoa-block;#ep-tipoPessoaBlock-pj"  />\
												<label class="ep-radio" for="ep-tipoPessoa-pj">\
													<span class="ep-icon ep-24 ep-radio-icon"></span>\
													Pessoa Jur&iacute;dica\
												</label>\
											</div>\
											<br/>\
										</div>\
										<input type="hidden" name="numeroCpfCnpjProponente" value="" />\
										<div class="ep-mod3 ep-md-mod4 ep-sm-lspan0 ep-tipoPessoa-block" id="ep-tipoPessoaBlock-pf">\
											<label class="ep-lbl ep-lbl-inline">CPF do segurado:</label>\
											<input type="tel" name="" placeholder="somente n&uacute;meros" class="ep-form-control ep-valid ep-cpfMask ep-setValue" data-setvalue="input[name=\'numeroCpfCnpjProponente\']=={value}" />\
										</div>\
										<div class="ep-mod3 ep-md-mod3 ep-md-lspan1 ep-sm-lspan0 ep-tipoPessoa-block" id="ep-tipoPessoaBlock-pj" style="display: none;">\
											<label class="ep-lbl ep-lbl-inline">CNPJ do segurado:</label>\
											<input type="tel" name="" placeholder="somente n&uacute;meros" class="ep-form-control ep-valid ep-cnpjMask ep-setValue" data-setvalue="input[name=\'numeroCpfCnpjProponente\']=={value}" />\
										</div>\
										<div class="ep-mod3 ep-md-mod4">\
											<label class="ep-lbl ep-lbl-inline">Protocolo:</label>\
											<input type="tel" name="codigoAtendimento" placeholder="somente n&uacute;meros" class="ep-form-control ep-valid" />\
										</div>\
										<div class="ep-mod3 ep-md-mod4 ep-md-lspan4 ep-sm-mod12 ep-sm-lspan0">\
											<label class="ep-lbl ep-lbl-inline">&#160;</label>\
											<input type="submit" name="s" value="CONTINUAR" class="ep-btn" />\
										</div>\
									</div>\
								</form>\
							</div>\
						</div>\
					</div>\
					' + vdEpSkin.BuildFooter() + '\
				</div>';

		return ctt;
	},
	GetGTMProductString: function (onlyProducts, data) {
		var rtn = onlyProducts ? "" : [];

		for (var i = 0, l = data.length; i < l; i++) {
			if (onlyProducts == true && data[i].codigoRelevancia == 1) {
				switch (data[i].codigoTipoEquipamento) {
					case 1:
						rtn += (i > 0 ? "|" : "") + "notebook";
						break;
					case 2:
						rtn += (i > 0 ? "|" : "") + "smartphone";
						if (data[i].sistemaOperacional != 0) {
							if (data[i].sistemaOperacional == 1) rtn += "-android";
							if (data[i].sistemaOperacional == 2) rtn += "-ios";
							if (data[i].sistemaOperacional == 3) rtn += "-windowsphone";
							if (data[i].sistemaOperacional == 4) rtn += "-outros";
						}
						break;
					case 5:
						rtn += (i > 0 ? "|" : "") + "tablet";
						break;
					case 9:
						rtn += (i > 0 ? "|" : "") + "camera";
						break;
					case 8:
						rtn += (i > 0 ? "|" : "") + "filmadora";
						break;
				}
			} else if (onlyProducts == false && data[i].codigoRelevancia > 1) {
				var equip = data[i].descricaoEquipamento.toLowerCase();
				equip = equip.replace(/ /g, "-");
				rtn.push({
					'type': equip,
					'quantity': '1'
				});
			}
		}

		return rtn;
	},
	Slides: function (container) {

		var defaultCfg = {
			slidesToScroll: 1,
			dots: true,
			infinite: false,
			centerPadding: '28px',
			centerMode: true,
			arrows: true,
			responsive: [
				{
					breakpoint: 767,
					settings: "unslick",
					arrows: false
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1
					}
				}
			]
		}

		if(container != ".ep-pc-features"){
			var configMobile = {
				mobileFirst: true
			};

			$.extend(defaultCfg,configMobile);
		} else {
			var confingDesk = {
				infinite: true,
				slidesToShow: 3
			};

			$.extend(defaultCfg,confingDesk);
		}

		vdEp.SlickSlides(container, defaultCfg);

		if(container == ".ep-resultPrices") {
			$('.slick-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
				$(".vd-pi-cta-mobile").hide();
				$("#vd-pi-cta-mobile-"+currentSlide+"").show();

				$(".vd-plan-current span").html(currentSlide+1);
			});
		}
	}
}

//função que sobrescreve o submit do legado para executar o login
function submitTela() {
	$("#ModalLogin").hide();
	$(".ep-modal-loading").fadeIn("fast");

	setTimeout(function() {
		$("#ep-contratacaoEpForm").submit();
	},100);	
}
